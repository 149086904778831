import React, { Component } from 'react';

class ActionsTableUsers extends Component {
    render() {
        const user = this.props.user;
        const u = this.props.u;
        return (
            <div className="button-container">
                <button
                    onClick={(e) =>
                        user &&
                        user.role &&
                        user.role.actions.some(
                            (a) => a.method === 'delete-user'
                        ) &&
                        window.confirm(
                            '¿Está seguro que desea deshabilitar el usuario?'
                        ) &&
                        this.props.handleDelete(u)}
                    className="btn btn-danger btn-sm action-button"
                >
                    Deshabilitar
                </button>
                {user.role.actions.some(
                    (a) => a.method === 'notification-testimony'
                ) && u.testimnyNotification === true ? <button
                    className="btn btn-sm action-button p-2"
                    onClick={(e) => {
                        e.preventDefault();
                        this.props.handleUpdate(u, this.props.nameUser);
                    }}
                >
                    <i className="fa fa-bell" />
                </button> : null}
                {user.role.actions.some(
                    (a) => a.method === 'notification-testimony'
                ) && u.testimnyNotification === false ? <button
                    className="btn btn-sm action-button p-2"
                    onClick={(e) => {
                        e.preventDefault();
                        this.props.handleUpdate(u, this.props.nameUser);
                    }}
                >
                    <i className="fa fa-bell-slash" />
                </button> : null}

            </div>
        );
    }
}

export default ActionsTableUsers;
