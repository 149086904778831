import http from "./httpService";

const apiEndpoint = process.env.REACT_APP_API_URL + "/merchants";

export function merchantUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getMerchants() {
  return http.get(apiEndpoint);
}

export function getMerchant(merchantId) {
  return http.get(merchantUrl(merchantId));
}

export function saveMerchant(merchant) {
  if (merchant._id) {
    const body = { ...merchant };
    delete body._id;
    return http.put(merchantUrl(merchant._id), body);
  }

  return http.post(apiEndpoint, merchant);
}

export function deleteMerchant(merchantId) {
  return http.delete(merchantUrl(merchantId));
}
