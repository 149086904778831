import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import { ObjectID } from 'bson'
import { Link } from 'react-router-dom'
import { currencyFormat } from '../../../utils/currency'
import { download, upload } from '../../../utils/s3'


const bucket = 'boxes-cp8'
class Invoices extends Component {
  state = {
    expenses: [],
    fees: [],
    show: false,
    data: {
      _id: new ObjectID(),
      type: '',
      number: '',
      amount: 0,
      iva: 0,
      currency: 'MXN',
      total: 0,
      file: null,
      isLoading: false,
    },
    types: [{ _id: new ObjectID(), name: 'Total' }, { _id: new ObjectID(), name: 'Parcial' }, {
      _id: new ObjectID(),
      name: 'Orden de compra'
    }, { _id: new ObjectID(), name: 'Recibo' }],
    readOnly: false,
    showItems: true,
    total: 0,
    totalIva: 0,
    currency: ''
  }

  fileInputs = {};

  componentDidMount () {
    if (this.props.expenses) {
      this.setState({ expenses: this.props.expenses })
    }

    if (this.props.fees) {
      this.setState({ fees: this.props.fees })
    }
  }


  componentDidUpdate (prevProps, prevState) {
    if (prevProps !== this.props) {
      this.setState({ expenses: this.props.expenses, fees: this.props.fees })
    }
  }


  showModal = edit => {
    let { data, showItems } = this.state
    if (edit) {
      data = {
        _id: edit._id,
        type: edit.type,
        number: edit.number,
        amount: edit.amount,
        iva: edit.iva,
        currency: edit.currency,
        total: edit.total,
        file: null,
        isLoading: false,
      }

    } else {
      data = { _id: new ObjectID(), type: '', number: '', amount: '', currency: 'MXN', total: '', file: null, isLoading: false }
      showItems = false
    }
    this.setState({ data, show: true, showItems, readOnly: false })
  }

  hideModal = () => {
    this.setState({ show: false })
  }

  handleChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data }
    if (input.name === 'amount') {
      data[input.name] = Number(parseFloat(input.value).toFixed(2))
      data.total = Number(parseFloat(data.amount * (this.state.currency === 'USD' ? this.props.usd : 1)).toFixed(2))
      data.iva = data.total * 0.16
      //data[input.name] = Math.max(Number(input.min), Math.min(Number(input.max), Number(input.value)));
    } else if (input.name === 'currency') {
      data.total = Number(parseFloat(data.amount * (input.value === 'USD' ? this.props.usd : 1)).toFixed(2))
      data[input.name] = input.value
      this.setState({ currency: input.value })
    } else {
      data[input.name] = input.value
    }
    this.setState({ data })
  }

  handleChangeSelect = ({ currentTarget: input }) => {
    const { data} = this.state
    let { readOnly, showItems } = this.state
    data[input.name] = input.value
    if (input.value === 'Total') {
      data['amount'] = this.props.total
      data['iva'] = this.props.iva
      data['total'] = data['amount'] + data['iva']
      readOnly = true
      showItems = false
    } else {
      data['amount'] = 0
      data['iva'] = 0
      data['total'] = 0
      readOnly = false
      showItems = true
    }
    this.setState({ showItems, readOnly, data })
  }

  handleChangeCheck = (item) => {
    const { data } = this.state
    let { total, totalIva, readOnly } = this.state

    item.checked = !item.checked
    if (item.checked) {
      item.invoiceId = data._id
      total = total + parseFloat(item.total)
      totalIva = totalIva + parseFloat(item.iva)
      data.amount = total
      data.iva = totalIva
      data.total = total
      readOnly = true
    } else {
      item.invoiceId = ''
      total = total - parseFloat(item.total)
      totalIva = totalIva - parseFloat(item.iva)
      data.amount = total
      data.iva = totalIva
      data.total = total
      readOnly = false
    }

    this.setState({ readOnly, total, totalIva, data })
    this.props.checkedItem(item)
  }

  handleSubmit = e => {
    e.preventDefault()
    const data = { ...this.state.data }

    this.setState({ data: data, show: false })
    this.props.onInvoice(data)
  }

  /**
   * 
   * @param File file
   */
  handleUpload = async (file, number) => {
    try {
      if (file) {
          const filename = `${number}${file.name.substring(file.name.lastIndexOf('.'))}`
          const path = `${this.props.numberBox}/invoices/${filename}`;
          const result = await upload(path, file, bucket)
          return result
      }
    } catch (e) {
        console.error(e);
        return null
    }
  }

  handleFileChange = async (event, invoice) => {
    const [file] = event.target.files;
    if (file) {
      const { _id: id, number } = invoice
      this.props.onLoadingDocument(id, true)
      const document = await this.handleUpload(file, number)
      this.props.onUploadDocument(id, document)
      this.props.onLoadingDocument(id, false)
    }
  };

  triggerFileInput = (id) => {
    if (this.fileInputs[id]) {
      this.fileInputs[id].click();
    }
  };

  render () {
    const { data, types, showItems, fees, expenses } = this.state
    const { user, total } = this.props
    return (
      <div>
        <div className="form-row">
          <div className="col-md-6 mb-6">
            <h5>Facturas</h5>
          </div>
          <div className="col-md-6 mb-6 pr-0">
            {user && user.role && user.role.actions.some(a => a.method === 'add-invoices-box') && !this.props.invoices.some(i => i.type === 'Total') && (
              <button type='button' className="btn btn-secondary btn-sm float-right link-add" onClick={() => this.showModal(false)}><i
                className="fa fa-plus"/></button>
            )}
          </div>
        </div>
        <div className="form-row form-row">
          <table className="table table-bordered">
            <thead>
            <tr>
              <th scope="col">Tipo</th>
              <th scope="col">Numero factura</th>
              <th scope="col">Monto</th>
              <th scope="col">Tipo de Moneda</th>
              <th scope="col">IVA</th>
              <th scope="col"><abbr title="Documento">Doc.</abbr></th>
              <th scope="col">Acción</th>
            </tr>
            </thead>
            <tbody>
            {this.props.invoices.map(invoice => (
              <tr key={invoice._id}>
                <td><Link to="#"
                          onClick={() => (user && user.role && user.role.actions.some(a => a.method === 'edit-invoices-box')) ? this.showModal(invoice) : ''}>{invoice.type}</Link>
                </td>
                <td>{invoice.number}</td>
                <td>{currencyFormat(invoice.amount)}</td>
                <td>{invoice.currency}</td>
                <td>{currencyFormat(invoice.iva || 0)}</td>
                <td>
                  {
                    invoice.file
                    ? <>
                        <i className="fa fa-file fa-fw" style={{cursor: 'pointer'}} title={invoice.file.key} onClick={() => download(invoice.file.key, bucket)}></i>
                        <i className="fa fa-trash fa-fw" style={{cursor: 'pointer', color: 'brown'}} title="Eliminar Documento" onClick={() => {
                          if (window.confirm('¿Desea eliminar el documento?')) {
                            this.props.onDeleteDocument(invoice._id)
                          }
                        }}></i>
                      </>
                    : <>
                        {
                          invoice.isLoading
                          ? <><i className="fa fa-fw fa-spinner fa-pulse"></i></>
                          : <>
                              <input 
                                type="file" 
                                accept='.pdf,.doc,.docx'
                                style={{display: 'none'}} 
                                ref={(input) => this.fileInputs[invoice._id] = input} 
                                onChange={(ev) => this.handleFileChange(ev, invoice)}
                              />
                              <i className="fa fa-upload fa-fw" style={{cursor: 'pointer'}} onClick={() => this.triggerFileInput(invoice._id)}></i>
                            </>
                        }
                      
                    </>
                  }
                  
                </td>
                { user && user.role && user.role.actions.some(a => a.method === 'delete-invoices-box') && (
                  <td className="text-center">
                    <Link 
                      to="#" 
                      onClick={() => this.props.onDelete(invoice)}
                      style={{ color: 'red' }}
                    >
                      Eliminar
                    </Link>
                  </td>)
                }
              </tr>
            ))}
            </tbody>
          </table>
        </div>
        <Modal show={this.state.show} onHide={this.hideModal}>
          <form>
            <Modal.Header closeButton>
              <Modal.Title>Agregar Factura</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-row">
                <div className="col-md-12 mb-3">
                  <select name="type" id="type" className="form-control" onChange={this.handleChangeSelect} value={data.type}>
                    <option value="">Selecciona...</option>
                    {types.map(option => (
                      <option key={option._id} value={option.name}>{option.name}</option>
                    ))}
                  </select>
                  {/* <input placeholder="Tipo" className="form-control" name="type" type="text" onChange={this.handleChange} value={data.type}/>                                 */}
                </div>
                <div className="col-md-12 mb-3">
                  <input placeholder="Numero factura" className="form-control" name="number" type="text"
                         onChange={this.handleChange} value={data.number}/>
                </div>
                {/* <div className="col-md-12 mb-3">
                                    <input placeholder="Monto" className="form-control" name="amount" type="number" onChange={this.handleChange} value={data.amount} readOnly={this.state.readOnly} min={0} max={this.props.total} />
                                </div>  */}
                <div className="form-row col-md-12">
                  <div className="col-md-9 mb-3">
                    <input placeholder="Monto" className="form-control" name="amount" type="number"
                           onChange={this.handleChange} value={data.amount}
                           readOnly={this.state.readOnly} /*min={0} max={total}*/ />
                  </div>
                  <div className="col-md-3 mb-3">
                    <select name="currency" id="currency" className="form-control" onChange={this.handleChange} value={data.currency}>
                      <option value="MXN">MXN</option>
                      <option value="USD">USD</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <input placeholder="Total" className="form-control" name="total" type="number" readOnly
                         onChange={this.handleChange} value={data.total}/>
                </div>
                <div className="col-md-12 mb-3">
                  <input placeholder="IVA" className="form-control" name="iva" type="number"
                         onChange={this.handleChange} value={data.iva} readOnly={this.state.readOnly} min={0}
                         max={total}/>
                </div>
                {showItems && (
                  <React.Fragment>
                    <div className="form-group col-md-12">
                      <h5>Honorarios</h5>
                      {fees && fees.map(fee => (
                        <div key={fee._id} className="form-check form-check-inline">
                          <input type="checkbox" name={fee.concept} className="form-check-input" checked={fee.checked}
                                 onChange={() => this.handleChangeCheck(fee)}/>
                          <label className="form-check-label">{fee.concept}</label>
                        </div>
                      ))}
                    </div>
                    <div className="form-group col-md-12">
                      <h5>Gastos</h5>
                      {expenses.map(expense => (
                        <div key={expense._id} className="form-check form-check-inline">
                          <input type="checkbox" name={expense.concept} className="form-check-input"
                                 checked={expense.checked} onChange={() => this.handleChangeCheck(expense)}/>
                          <label className="form-check-label">{expense.concept}</label>
                        </div>
                      ))}
                    </div>
                  </React.Fragment>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button type="submit" className="btn btn-primary" onClick={this.handleSubmit}>Guardar</button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    )
  }
}

export default Invoices
