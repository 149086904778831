import http from "./httpService";

const apiEndpoint = process.env.REACT_APP_API_URL + "/receivables";

export function receivableUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getReceivables() {
  return http.get(apiEndpoint);
}