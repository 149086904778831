import React, { Component } from "react";
import Table from "./common/table";

class ReceivablesTable extends Component {
  columns = [ 
    { 
        field: "week", 
        label: "Semana",
        width: 110        
    },
    {
        field: "15",
        label: "15 dias",
        width: 120
    },
    {
        field: "30",
        label: "30 dias",
        width: 120
    },
    {
        field: "45",
        label: "45 dias",
        width: 120
    },
    {
        field: "60",
        label: "60 dias",
        width: 120
    },
    {
        field: "90",
        label: "90 dias",
        width: 120
    },
    {
        field: "120",
        label: "120 dias",
        width: 120
    },
    {
        field: "150",
        label: "150 dias",
        width: 120
    },
    {
        field: "180",
        label: "180 dias"
    },
    {
        field: "more",
        label: "180 +"
    },    
    {
        field: "total",
        label: "Total",
        width: 120
    }      
  ];

  render() {
    const { receivables } = this.props;

    return (
      <Table
        columns={this.columns}
        data={receivables}
      />
    );
  }
}

export default ReceivablesTable;
