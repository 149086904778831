import http from "./httpService";

const apiEndpoint = process.env.REACT_APP_API_URL + "/cff";

export function cffUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getCffs() {
  return http.get(apiEndpoint);
}

export function getCff(cffId) {
  return http.get(cffUrl(cffId));
}

export function getFormsCff(cffId) {
  return http.get(cffUrl(`/forms/${cffId}`));
}

export function saveCff(cff) {
  if (cff.data._id) {
    const id = cff.data._id;
    delete cff.data._id;
    return http.put(cffUrl(id), cff);
  }
  return http.post(apiEndpoint, cff);
}

export function deleteCff(cffId) {
  return http.delete(cffUrl(cffId));
}
