import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { saveAs } from 'file-saver'
import Table from '../../../common/materia-reac-table/table'
import moment from 'moment/moment'
import { generate_workbook } from '../../../../utils/js2xlsx'

class AccountsPayableTable extends Component {

  columns = [
    {
      header: 'Factura',
      accessorKey: 'invoice',
      Cell: ({ row }) => <Link
        to={(this.props.user?.role?.actions?.some(a => a.method === 'update-accounts-payable') ? `/boxes/accounts-payable/${row.original._id}` : `#`)}>
        {String(row.original.invoice)}
      </Link>,
      size: 70,
    },
    {
      header: 'Proveedor',
      accessorKey: 'provider.name',
      Cell: ({ row }) => <Link
        to={(this.props.user?.role?.actions?.some(a => a.method === 'update-provider') ? `/boxes/accounts-payable/provider/${row.original.provider._id}` : `#`)}>
        {String(row.original.provider.name)}
      </Link>,
      size: 70,
    },
    {
      header: 'Cliente',
      accessorKey: 'client.name',
      Cell: ({ row }) => {
        const isAuthorized = this.props.user?.role?.actions?.some(a => a.method === 'edit-client');
        if (row.original.client && isAuthorized) {
          return <Link
            to={`/clients/${row.original.client?._id}`}>
            {row.original.client?.name}
          </Link>
        } else if (row.original.client) {
          return row.original.client?.name
        } else {
          return '-';
        }
      },
      size: 70,
    },
    {
      header: 'Concepto',
      accessorKey: 'concept',
    },
    {
      header: 'Fecha de Factura',
      // format date dd/mm/yyyy
      accessorFn: row => moment(row.date).format('DD/MM/YYYY'),
      id: 'date'
    },
    {
      header: 'Caja',
      accessorKey: 'box',
      accessorFn: (row) => row.box?.numberBox,
      Cell: ({ row }) => row.original.box?._id ? (<Link
        to={(this.props.user?.role?.actions?.some(a => a.method === 'edit-box') ? `/boxes/${row.original.box._id}` : `#`)}>
        {String(row.original.box.numberBox)}
      </Link>) : '-',
      size: 70,
    },
    {
      header: 'Importe',
      accessorKey: 'amount',
      Cell: ({ cell }) => cell.getValue().toLocaleString('es-MX', {
        style: 'currency',
        currency: 'MXN',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }),
    },
    {
      header: 'Total',
      accessorKey: 'total',
      Cell: ({ cell }) => cell.getValue().toLocaleString('es-MX', {
        style: 'currency',
        currency: 'MXN',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }),
    },
    {
      header: 'Estatus',
      accessorKey: 'status',
    }
  ]

  handleExport = async (accounts) => {
    const filename = `Cuentas por pagar${(moment().format('YYYYMMDDHHmmss'))}.xlsx`

    const cols = [
      { title: 'Factura', width: 20 },
      { title: 'Proveedor', width: 30 },
      { title: 'Cliente', width: 30 },

      { title: 'Concepto', width: 40 },
      { title: 'Fecha de factura', width: 15 },
      { title: 'Caja', width: 15 },
      { title: 'Importe', width: 15 },
      { title: 'Total', width: 15 },
      { title: 'Estatus', width: 15 },

      { title: 'Autorizado', width: 15 },
      { title: 'Observaciones', width: 40 },
      { title: 'Fecha de pago', width: 20 },
    ]

    const rows = accounts.map((element) => [
      element.invoice,
      element.provider?.name ?? '-',
      element.client?.name ?? '-',
      element.concept,
      element.date ? moment(element.date).format('DD-MM-YYYY') : '-',
      element.box?.numberBox,
      element.amount ? Intl.NumberFormat('es-MX',{ style:'currency', currency: 'MXN', minimumFractionDigits: 2 }).format(element.amount) : '-',
      element.total ? Intl.NumberFormat('es-MX',{ style:'currency', currency: 'MXN', minimumFractionDigits: 2 }).format(element.total) : '-',
      element.status,

      element.isAuthorized ? 'Si' : 'No',
      element.observations,
      element.paymentDate ? moment(element.paymentDate).format('DD-MM-YYYY') : '-',
    ])

    saveAs(
      await generate_workbook({
        sheets: [{
          name: 'Cuentas por pagar',
          cols: cols,
          data: rows,
        }],
        props: {
          Title: 'Cuentas por pagar',
          CreatedDate: new Date(),
        },
        type: 'blob',
      }),
      filename,
    )
  }

  deleteRowAction = ({ row, table }) => <button
    onClick={(e) => window.confirm('Esta seguro que desea eliminar la cuenta por pagar') && this.props.handleDelete(row.original)}
    className="btn btn-danger btn-sm">
    Eliminar
  </button>

  render () {
    const { accountsPayable, user } = this.props
    return (user && this.columns ?
        <Table
          columns={this.columns}
          data={accountsPayable}
          user={user}
          isLoading={this.props.isLoading}
          rowAction={(user && user.role && user.role.actions.some(a => a.method === 'delete-accounts-payable')) && this.props.handleDelete ? this.deleteRowAction : null}
          dataExport={(
              <button
                onClick={() => this.handleExport(accountsPayable)}
                className="btn btn-primary"
              >
                <i className="fa fa-download" aria-hidden="true" /><span> </span>
                Exportar
              </button>)
          }
        /> : <></>
    )
  }
}

export default AccountsPayableTable
