import React, { Component } from "react";
import { ToastContainer } from "react-toastify";
import { Route, Redirect, Switch } from "react-router-dom";
import { Auth } from 'aws-amplify';
import { getCurrentUser } from "./services/authService";

import Dashboard from "./components/dashboard/dashboard";
import Boxes from "./components/boxes/boxes";
import BoxForm from "./components/boxes/boxForm";
import Requestfortestimony from "./components/boxes/Requestfortestimony";
import Instruments from "./components/instruments/instruments";
import InstrumentForm from "./components/instruments/instrumentForm";
import Assigned from "./components/instrumentAssigned/instrumentsAssigned";
import Unpaid from "./components/boxUnpaid"
import Penalties from "./components/penalties";
import Valuations from "./components/valuations";
import ValuationForm from "./components/valuationForm";
import Mediations from "./components/mediations/mediations";
import MediationForm from "./components/mediationForm";
import DenominationForm from "./components/denominations/denominationForm";
import Clients from "./components/clients/clients";
import ClientForm from "./components/clients/clientForm";
import Originators from "./components/originators";
import OriginatorForm from "./components/originatorForm";
import Responsible from "./components/responsible";
import ResponsibleForm from "./components/responsibleForm";
import Status from "./components/status";
import StatusForm from "./components/statusForm";
import Types from "./components/types";
import TypeForm from "./components/typeForm";
import Users from "./components/users";
import UserForm from "./components/userForm";
import Roles from "./components/roles/roles";
import RoleForm from "./components/roles/roleForm";
import Configurations from "./components/configurations";
import ConfigurationForm from "./components/configurationForm";
import SreList from "./components/sreList";
import PldList from "./components/pld/pldList";
import RnieList from "./components/instruments/rnieList";
import RpcList from "./components/rpc/rpcList";
import PcmList from "./components/pcmList";
import CffList from "./components/bc32/cffList";
import Backups from "./components/backups";
import Receivables from "./components/receivables";
import Documents from "./components/documents";
import NotFound from "./components/notFound";
import NavBar from "./components/navBar";
import LoginForm from "./components/loginForm";
import SignupForm from "./components/signupForm";
import VerificationForm from "./components/verificationForm";
import PublicCff from "./components/bc32/publicCff";
import PublicForm from "./components/bc32/publicForm";
import Logout from "./components/logout";
import ProtectedRoute from "./components/common/protectedRoute";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import CffForm from "./components/bc32/cffForm";
import DeclaranotList from './components/declaranot/declaranotList'
import Denominations from './components/denominations/denominations'
import ProvidersPayment from './components/boxes/components/providersPayment'
import Calendar from './components/calendar/calendar'
import Objectives from './components/objectives/objectives'
import ObjectiveForm from './components/objectives/objectiveForm'
import AccountsPayable from './components/boxes/accounts-payable/accountsPayable'
import ProviderForm from './components/boxes/accounts-payable/providerForm'
import AccountsPayableForm from './components/boxes/accounts-payable/accountsPayableForm'

class App extends Component {
  state = {
    showMenu: false,
    user: ''
  };

  async componentDidMount() {
    Auth.currentAuthenticatedUser().then(async (auth) => {
      if(auth){
        const {data: user } = await getCurrentUser();
        if(user){
          this.setState({ user });
        }
      }
    }).catch(ex => {
      console.log(ex);
    });
  }

  handleMenu = showMenu => {
    this.setState({ showMenu: !showMenu });
  }

  render() {
    const { user, showMenu } = this.state;

    return (
      <React.Fragment>
        <ToastContainer />
        <div className="row">
          <div className="col-lg-2 col-md-0 pl-0">
            <NavBar user={user} showMenu={showMenu} handleMenu={this.handleMenu} />
          </div>
          <div className={user ? "col-lg-10 col-md-12" : "col-md-12" }>
            <main className="ml-sm-auto">
              <Switch>
                {/* <Route path="/register" component={RegisterForm} /> */}
                <Route path="/public/cff/:id" component={PublicCff} />
                <Route path="/form/:id" component={PublicForm} />
                <Route path="/verification" component={VerificationForm} />
                <Route path="/register" component={SignupForm} />
                <Route path="/login" component={LoginForm} />
                <Route path="/logout" component={Logout} />
                <ProtectedRoute path="/boxes/suppliers-payment" component={ProvidersPayment} />
                <ProtectedRoute path="/boxes/accounts-payable/add" component={AccountsPayableForm} />
                <ProtectedRoute path="/boxes/accounts-payable/add-provider" component={ProviderForm} />
                <ProtectedRoute path="/boxes/accounts-payable/provider/:id" component={ProviderForm} />
                <ProtectedRoute path="/boxes/accounts-payable/:id" component={AccountsPayableForm} />
                <ProtectedRoute path="/boxes/accounts-payable" component={AccountsPayable} />
                <ProtectedRoute path="/boxes/request-for-testimony" component={Requestfortestimony} />
                <ProtectedRoute path="/boxes/:id" component={BoxForm} />
                <ProtectedRoute
                  path="/boxes"
                  render={props => <Boxes {...props} user={user} />}
                />
                <ProtectedRoute
                  path="/boxes"
                  render={props => <Boxes {...props} user={user} />}
                />
                <ProtectedRoute
                  path="/dashboard"
                  render={props => <Dashboard {...props} user={user} />}
                />
                <ProtectedRoute
                  path="/calendar"
                  render={props => <Calendar />}
                />
                <ProtectedRoute path="/instruments/:id" component={InstrumentForm} />
                <ProtectedRoute
                  path="/pending"
                  render={props => <Assigned {...props} user={user} />}
                />
                <ProtectedRoute
                  path="/unpaid"
                  render={props => <Unpaid {...props} user={user} />}
                />
                <ProtectedRoute
                  path="/instruments"
                  render={props => <Instruments {...props} user={user} />}
                />
                <ProtectedRoute path="/valuations/:id" component={ValuationForm} />
                <ProtectedRoute
                  path="/valuations"
                  render={props => <Valuations {...props} user={user} />}
                />
                <ProtectedRoute path="/mediations/:id" component={MediationForm} />
                <ProtectedRoute
                  path="/mediations"
                  render={props => <Mediations {...props} user={user} />}
                />
                <ProtectedRoute path="/denominations/:status/:id" component={DenominationForm} />
                {/* <Route
                  path="/denominations"
                  render={props => <Denominations {...props} user={user} />}
                /> */}
                <ProtectedRoute path="/denominations/:status" component={Denominations} />
                <ProtectedRoute path="/clients/:id" component={ClientForm} />
                <ProtectedRoute
                  path="/clients"
                  render={props => <Clients {...props} user={user} />}
                />
                <ProtectedRoute path="/originators/:id" component={OriginatorForm} />
                <ProtectedRoute
                  path="/originators"
                  render={props => <Originators {...props} user={user} />}
                />
                <ProtectedRoute path="/responsible/:id" component={ResponsibleForm} />
                <ProtectedRoute
                  path="/responsible"
                  render={props => <Responsible {...props} user={user} />}
                />
                <ProtectedRoute path="/status/:id" component={StatusForm} />
                <ProtectedRoute
                  path="/status"
                  render={props => <Status {...props} user={user} />}
                />
                <ProtectedRoute path="/types/:id" component={TypeForm} />
                <ProtectedRoute
                  path="/types"
                  render={props => <Types {...props} user={user} />}
                />
                <ProtectedRoute path="/users/:id" component={UserForm} />
                <ProtectedRoute
                  path="/users"
                  render={props => <Users {...props} user={user} />}
                />
                <ProtectedRoute path="/roles/:id" component={RoleForm} />
                <ProtectedRoute
                  path="/roles"
                  render={props => <Roles {...props} user={user} />}
                />
                <ProtectedRoute path="/configurations/:id" component={ConfigurationForm} />
                <ProtectedRoute
                  path="/configurations"
                  render={props => <Configurations {...props} user={user} />}
                />
                <ProtectedRoute
                  path="/backups"
                  render={props => <Backups {...props} user={user} />}
                />
                <ProtectedRoute
                  path="/receivables"
                  render={props => <Receivables {...props} user={user} />}
                />
                <ProtectedRoute
                  path="/declaranot"
                  render={props => <DeclaranotList {...props} user={user} />}
                />
                <ProtectedRoute path="/sre/:status" component={SreList} />
                <ProtectedRoute path="/pld/:status" component={PldList} />
                <ProtectedRoute path="/rnie/:status" component={RnieList} />
                <ProtectedRoute path="/rpc/:status" component={RpcList} />
                <ProtectedRoute path="/pcm/:status" component={PcmList} />
                <ProtectedRoute path="/cff/list/:status" component={CffList} />
                <ProtectedRoute path="/cff/:id" component={CffForm} />
                <ProtectedRoute path="/penalties/:status" component={Penalties} />
                <ProtectedRoute path="/objectives/:id" component={ObjectiveForm} />
                <ProtectedRoute path="/objectives" render={props => <Objectives {...props} user={user} />}/>
                <ProtectedRoute path="/documents" component={Documents} />
                <Route path="/not-found" component={NotFound} />
                <Redirect from="/" exact to={ user ? "/dashboard" : "/login" } />
                <Redirect to="/not-found" />
              </Switch>
            </main>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default App;
