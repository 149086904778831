import React, { Component } from 'react';
import { Link, NavLink } from "react-router-dom";
import { Auth } from 'aws-amplify';
import { Dropdown } from 'react-bootstrap';
import { getCurrentUser } from "../services/authService";
import { getUserNotifications, saveNotification } from "../services/notificationService";
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    style={{ color: '#fff', fontSize: 'large' }}
    ref={ref}
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </div>
));

class NavBar extends Component {
  state = {
    metrics: {},
    notifications: []
  }

  async populateNotifications(user) {
    const { data: notifications } = await getUserNotifications(user._id);
    this.setState({ notifications });
  }

  async componentDidMount() {
    Auth.currentAuthenticatedUser().then(async (auth) => {
      if (auth) {
        const { data: user } = await getCurrentUser();
        if (user) {
          this.populateNotifications(user);
          this.setState({ user });
        }
      }
    }).catch(ex => {
      console.log(ex);
    });
  }

  async handleNotification(notification) {
    notification.active = false;
    const { data: notifications } = await saveNotification(notification);
    this.setState({ notifications });
    window.location.href = notification.url;
  }

  render() {
    const { showMenu, handleMenu } = this.props;
    const { user, notifications } = this.state;

    return (
      user ? (
        <React.Fragment>
          <ProSidebar breakPoint="lg md" toggled={showMenu} onToggle={(val) => handleMenu(!val)}>
            <Menu iconShape="square">
              {user && user.role && user.role.actions.some(a => a.method === "view-dashboard") ?
                <MenuItem icon={<i className="fas fa-tachometer-alt" aria-hidden="true" />}><NavLink to="/dashboard">Dashboard</NavLink></MenuItem>
                : <MenuItem style={{ display: "none" }}></MenuItem>}
              {user && user.role && user.role.actions.some(a => a.method === "view-objective") ?
                <MenuItem icon={<i className="fas fa-bullseye" aria-hidden="true" />}>
                  <NavLink to="/objectives">Objetivos</NavLink>
                </MenuItem> : <MenuItem style={{ display: "none" }}></MenuItem>}
              {/* {user && user.role !== "Archivista" ? ( */}
              <MenuItem icon={<i className="far fa-calendar" aria-hidden="true" />}><NavLink to="/calendar">Calendario</NavLink></MenuItem>
              {user && user.role && user.role.actions.some(a => a.method === "view-box") ?
                <SubMenu title="Caja" icon={<i className="fas fa-cash-register" aria-hidden="true" />}>
                  <MenuItem icon={<i className="fa fa-file" aria-hidden="true" />}><NavLink to="/boxes">Caja</NavLink></MenuItem>
                  {user && user.role && user.role.actions.some(a => a.method === 'view-providers-payment') &&
                    <MenuItem icon={<i className="fa fa-file" aria-hidden="true" />}><NavLink to="/boxes/suppliers-payment">Pago a proveedores</NavLink></MenuItem>}
                  {user && user.role && user.role.actions.some(a => a.method === 'view-accounts-payable') &&
                    <MenuItem icon={<i className="fa fa-file" aria-hidden="true" />}><NavLink to="/boxes/accounts-payable">Cuentas por pagar</NavLink></MenuItem>}
                  {user && user.role && user.role.actions.some(a => a.method === 'notification-testimony') &&
                    <MenuItem icon={<i className="fa fa-file" aria-hidden="true" />}><NavLink to="/boxes/request-for-testimony">Solicitud de testimonio</NavLink></MenuItem>}
                  <MenuItem icon={<i className="fas fa-file-invoice-dollar" aria-hidden="true" />}><NavLink to="/unpaid">Pendientes facturar</NavLink></MenuItem>
                </SubMenu>
                : <MenuItem style={{ display: "none" }}></MenuItem>}
              {user && user.role && user.role.actions.some(a => a.method === "view-instrument") ?
                <SubMenu title="Instrumentos" icon={<i className="fas fa-book" aria-hidden="true" />}>
                  <MenuItem icon={<i className="fa fa-book" aria-hidden="true" />}><NavLink to="/instruments">Instrumentos</NavLink></MenuItem>
                  <MenuItem icon={<i className="fas fa-folder-open" aria-hidden="true" />}><NavLink to="/pending">Pendientes archivo</NavLink></MenuItem>
                </SubMenu>
                : <MenuItem style={{ display: "none" }}></MenuItem>}
              {user && user.role && user.role.actions.some(a => a.method === "view-valuation") ?
                <MenuItem icon={<i className="fas fa-file-invoice-dollar" aria-hidden="true" />}><NavLink to="/valuations">Avalúos</NavLink></MenuItem>
                : <MenuItem style={{ display: "none" }}></MenuItem>}
              {user && user.role && user.role.actions.some(a => a.method === "view-mediation") ?
                <MenuItem icon={<i className="fas fa-handshake" aria-hidden="true" />}><NavLink to="/mediations">Mediación</NavLink></MenuItem>
                : <MenuItem style={{ display: "none" }}></MenuItem>}
              {user && user.role && user.role.actions.some(a => a.method === "view-denomination") ?
                <SubMenu title="Denominaciones" icon={<i className="fas fa-file-signature" aria-hidden="true" />}>
                  <MenuItem icon={<i className="fa fa-file" aria-hidden="true" />}><NavLink to="/denominations/available">Disponibles</NavLink></MenuItem>
                  <MenuItem icon={<i className="fa fa-file" aria-hidden="true" />}><NavLink to="/denominations/used">No disponibles</NavLink></MenuItem>
                  <MenuItem icon={<i className="fa fa-file" aria-hidden="true" />}><NavLink to="/denominations/apart">Apartadas</NavLink></MenuItem>
                  <MenuItem icon={<i className="fa fa-file" aria-hidden="true" />}><NavLink to="/denominations/uses">Usadas</NavLink></MenuItem>
                </SubMenu>
                : <MenuItem style={{ display: "none" }}></MenuItem>}
              {user && user.role && user.role.actions.some(a => a.method === "view-sre") ?
                <SubMenu title="Avisos" icon={<i className="fas fa-flag" aria-hidden="true" />}>
                  {user && user.role && user.role.actions.some(a => a.method === "view-sre") ?
                    <SubMenu title="SRE" icon={<i className="fas fa-flag" aria-hidden="true" />}>
                      <MenuItem icon={<i className="fas fa-folder" aria-hidden="true" />}><NavLink to="/sre/pending">Pendientes</NavLink></MenuItem>
                      <MenuItem icon={<i className="fas fa-folder" aria-hidden="true" />}><NavLink to="/sre/presented">Presentados</NavLink></MenuItem>
                    </SubMenu>
                    : <MenuItem style={{ display: "none" }}></MenuItem>}
                  {user && user.role && user.role.actions.some(a => a.method === "view-pld") ?
                    <SubMenu title="PLD" icon={<i className="fas fa-soap" aria-hidden="true" />}>
                      <MenuItem icon={<i className="fas fa-folder" aria-hidden="true" />}><NavLink to="/pld/pending">Pendientes</NavLink></MenuItem>
                      <MenuItem icon={<i className="fas fa-folder" aria-hidden="true" />}><NavLink to="/pld/presented">Presentados</NavLink></MenuItem>
                    </SubMenu>
                    : <MenuItem style={{ display: "none" }}></MenuItem>}
                  {user && user.role && user.role.actions.some(a => a.method === "view-rpc") ?
                    <SubMenu title="RPC" icon={<i className="fab fa-usps" aria-hidden="true" />}>
                      <MenuItem icon={<i className="fas fa-folder" aria-hidden="true" />}><NavLink to="/rpc/pending">En trámite</NavLink></MenuItem>
                      <MenuItem icon={<i className="fas fa-folder" aria-hidden="true" />}><NavLink to="/rpc/inscribed">Inscritos</NavLink></MenuItem>
                      <MenuItem icon={<i className="fas fa-folder" aria-hidden="true" />}><NavLink to="/rpc/special">Especiales</NavLink></MenuItem>
                    </SubMenu>
                    : <MenuItem style={{ display: "none" }}></MenuItem>}
                  {user && user.role && user.role.actions.some(a => a.method === "view-declaranot") ?
                    <MenuItem icon={<i className="fas fa-user-secret" aria-hidden="true" />}><NavLink to="/declaranot">Declaranot</NavLink></MenuItem>
                    : <MenuItem style={{ display: "none" }}></MenuItem>}
                </SubMenu>
                : <MenuItem style={{ display: "none" }}></MenuItem>}
              <SubMenu title="PSM" icon={<i className="far fa-newspaper" aria-hidden="true" />}>
                <MenuItem icon={<i className="fas fa-folder" aria-hidden="true" />}><NavLink to="/pcm/pending">En proceso</NavLink></MenuItem>
                <MenuItem icon={<i className="fas fa-folder" aria-hidden="true" />}><NavLink to="/pcm/review">Revisados</NavLink></MenuItem>
              </SubMenu>
              <SubMenu title="BC32 Ter" icon={<i className="fas fa-link" aria-hidden="true" />}>
                <MenuItem icon={<i className="fas fa-folder" aria-hidden="true" />}><NavLink to="/cff/list/pending">En proceso</NavLink></MenuItem>
                <MenuItem icon={<i className="fas fa-folder" aria-hidden="true" />}><NavLink to="/cff/list/review">En revisión</NavLink></MenuItem>
                <MenuItem icon={<i className="fas fa-folder" aria-hidden="true" />}><NavLink to="/cff/list/complete">Terminadas</NavLink></MenuItem>
              </SubMenu>
              {user && user.role && user.role.actions.some(a => a.method === "view-penalty") ?
                <SubMenu title="Penalizaciones" icon={<i className="fas fa-pepper-hot" aria-hidden="true" />}>
                  <MenuItem icon={<i className="fas fa-folder" aria-hidden="true" />}><NavLink to="/penalties/pending">Pendientes</NavLink></MenuItem>
                  <MenuItem icon={<i className="fas fa-folder" aria-hidden="true" />}><NavLink to="/penalties/apply">Aplicadas</NavLink></MenuItem>
                </SubMenu>
                : <MenuItem style={{ display: "none" }}></MenuItem>}
              {user && user.role && user.role.actions.some(a => a.method === "view-rnie") ?
                <SubMenu title="RNIE" icon={<i className="fas fa-globe" aria-hidden="true" />}>
                  <MenuItem icon={<i className="fas fa-folder" aria-hidden="true" />}><NavLink to="/rnie/pending">Pendientes</NavLink></MenuItem>
                  <MenuItem icon={<i className="fas fa-folder" aria-hidden="true" />}><NavLink to="/rnie/presented">Presentados</NavLink></MenuItem>
                </SubMenu>
                : <MenuItem style={{ display: "none" }}></MenuItem>}

              {user && user.role && user.role.actions.some(a => a.method === "view-system") ?
                <SubMenu title="Sistema" icon={<i className="fa fa-cog" aria-hidden="true" />}>
                  {user && user.role && user.role.actions.some(a => a.method === "view-client") && (
                    <MenuItem icon={<i className="fas fa-folder" aria-hidden="true" />}><NavLink to="/clients">Clientes</NavLink></MenuItem>)}
                  <MenuItem icon={<i className="fas fa-folder" aria-hidden="true" />}><NavLink to="/originators">Originadores</NavLink></MenuItem>
                  <MenuItem icon={<i className="fas fa-folder" aria-hidden="true" />}><NavLink to="/status">Estatus</NavLink></MenuItem>
                  <MenuItem icon={<i className="fas fa-folder" aria-hidden="true" />}><NavLink to="/types">Tipos</NavLink></MenuItem>
                  <MenuItem icon={<i className="fa fa-users" aria-hidden="true" />}><NavLink to="/users">Usuarios</NavLink></MenuItem>
                  <MenuItem icon={<i className="fa fa-users" aria-hidden="true" />}><NavLink to="/roles">Roles</NavLink></MenuItem>
                  <MenuItem icon={<i className="fa fa-cog" aria-hidden="true" />}><NavLink to="/configurations">Configuraciones</NavLink></MenuItem>
                  <MenuItem icon={<i className="fa fa-download" aria-hidden="true" />}><NavLink to="/backups">Respaldos</NavLink></MenuItem>
                  <MenuItem icon={<i className="fa fa-download" aria-hidden="true" />}><NavLink to="/receivables">Cuentas X Cobrar</NavLink></MenuItem>
                </SubMenu>
                : <MenuItem style={{ display: "none" }}></MenuItem>}
            </Menu>
            {/* <SidebarFooter>
              <div className="mt-auto" style={{padding: "15px"}}>
                  <h5 style={{fontSize: "1rem", fontWeight: 400 }}>Archivados</h5>
                  <table className="table table-borderless" style={{fontSize: "11px", color: '#adadad'}}>
                      <thead>
                          <tr>
                              <th scope="col" style={{padding: ".55rem 0rem"}}>Tipo</th>
                              <th scope="col" style={{padding: ".55rem 0rem", textAlign: "center"}}>Pendientes</th>
                              <th scope="col" style={{padding: ".55rem 0rem", textAlign: "center"}}>Totales</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr>
                              <td style={{padding: ".55rem 0rem"}}>Facturación</td>
                              <td style={{padding: ".55rem 0rem", textAlign: "center"}}>{metrics && metrics.boxes ? metrics.boxes.false : 0}</td>
                              <td style={{padding: ".55rem 0rem", textAlign: "center"}}>{metrics && metrics.boxes ? metrics.boxes.total : 0}</td>
                          </tr>
                          <tr>
                              <td style={{padding: ".55rem 0rem"}}>Instrumentos</td>
                              <td style={{padding: ".55rem 0rem", textAlign: "center"}}>{metrics && metrics.instruments ? metrics.instruments.false : 0}</td>
                              <td style={{padding: ".55rem 0rem", textAlign: "center"}}>{metrics && metrics.instruments ? metrics.instruments.total : 0}</td>
                          </tr>
                      </tbody>
                  </table>
                </div>
            </SidebarFooter> */}
          </ProSidebar>
          <nav className="navbar navbar-expand-lg navbar-dark fixed-top bg-dark flex-md-nowrap">
            <Link className="navbar-brand" to="/">
              CP8CDMX
            </Link>
            <button className="navbar-toggler" type="button" onClick={() => handleMenu(showMenu)} data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="navbar-nav flex-row ml-md-auto d-none d-md-flex">
              <Dropdown>
                <Dropdown.Toggle as={CustomToggle}>
                  <div style={{ cursor: 'pointer' }}>
                    <i className="fas fa-bell" ></i>
                    <span className='badge badge-warning' id='notCount'>{notifications.length}</span>
                  </div>
                </Dropdown.Toggle>
                {
                  notifications.length > 0 && (
                    <Dropdown.Menu>
                      {
                        notifications.map(notification => (
                          <Dropdown.Item onClick={() => this.handleNotification(notification)} key={notification._id}>
                            {notification.name}
                          </Dropdown.Item>
                        ))
                      }
                    </Dropdown.Menu>
                  )
                }
              </Dropdown>
            </div>
            <Link className="btn btn-bd-download d-none d-lg-inline-block mb-3 mb-md-0 ml-md-3" style={{ color: '#fff' }} to="/logout"><i className="fas fa-power-off"></i></Link>
          </nav>
        </React.Fragment>
      ) : ""
    );
  }
};

export default NavBar;
