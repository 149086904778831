import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'

export function Testimonies ({ responsibles, testimonies, instruments, onAddTestimony, onRemoveTestimony, type }) {
  const [show, setShow] = useState(false)
  const [number, setNumber] = useState('')
  const [responsible, setResponsible] = useState({ _id: '', name: '' })
  const [instrument, setInstrument] = useState({ _id: '', number: '', name: '' })
  const [errors, setErrors] = useState({})

  const showModal = () => setShow(true)

  const hideModal = () => setShow(false)

  useEffect(() => {
    if (type === 'Testimonio'){
      setInstrument({
        _id: instruments[0]._id,
        number: instruments[0].number,
        name:  instruments[0].name
      })
    }
  } , [show])

  const validateForm = () => {
    const errors = {}
    if (!number) errors.number = 'El número de testimonio es requerido'
    if (!responsible._id) errors.responsible = 'El responsable es requerido'
    if (!instrument._id) errors.instrument = 'El instrumento es requerido'
    setErrors(errors)
    console.log(errors,'errors')
    return Object.keys(errors).length === 0
  }

  const resetForm = () => {
    setNumber('')
    setResponsible({
      _id: '',
      name: ''
    })
    setInstrument({
      _id: '',
      number: '',
      name: ''
    })
    setErrors({})
  }

  const handleChangeInstrument = e => {
    setInstrument({
      _id: e.target.value,
      number: instruments.find(i => i._id === e.target.value).number,
      name:  e.target.options[e.target.selectedIndex].text
    })
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (!validateForm()) return
    setShow(false)
    resetForm()
    onAddTestimony({ number, responsible, instrument })
  }

  return (
    <div className="my-2">
      <div className="form-row">
        <div className="col">
          <a className="btn btn-info btn-sm float-right link-add" onClick={showModal}>
            <i className="fa fa-plus"/> Agregar testimonios
          </a>
        </div>
      </div>
      {testimonies.length > 0 &&
        <div className="form-row form-row">
          <div className="col">
            <table className="table table-bordered table-sm mt-2">
              <thead>
              <tr>
                <th>No. de testimonio</th>
                <th>Responsable</th>
                {type === 'Instrumento' && (<th>Instrumento</th>)}
                <th></th>
              </tr>
              </thead>
              <tbody>
              {testimonies.map((t, i) => (
                <tr key={i}>
                  <td>{t.number}</td>
                  <td>{t.responsible.name}</td>
                  {type === 'Instrumento' &&
                    (<td>{t.instrument.number}</td>)}
                  <td className="text-center">
                    <a href="#" onClick={(e) => onRemoveTestimony(e, t)}>
                      <i className="fa fa-trash"/>
                    </a>
                  </td>
                </tr>
              ))}

              </tbody>
            </table>

          </div>
        </div>}

      <Modal show={show} onHide={hideModal}>
        <form>
          <Modal.Header closeButton>
            <Modal.Title>Agregar Testimonios</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <div className="col-md-12 form-label-group mb-3">
                  <input
                    className="form-control"
                    name="number"
                    type="text"
                    onChange={e => setNumber(e.target.value)}
                    value={number}/>
                  <label>No. de Testimonio</label>
                  {errors.number && <div className="alert alert-danger">{errors.number}</div>}
                </div>
              </div>
            </div>
            {type === 'Instrumento' &&(
              <div className="form-row">
              <div className="col-md-12 mb-3">
                <div className="col-md-12 form-label-group mb-3">
                  <select
                    id="instruments"
                    name="instruments"
                    className="form-control"
                    onChange={(e) => handleChangeInstrument(e)}
                    value={instrument._id}>
                    <option value="">Selecciona...</option>
                    {instruments.map(r => (
                      <option key={r.name} value={r._id}>
                        {r.name}
                      </option>
                    ))}
                  </select>
                  <label>Instrumento</label>
                  {errors.instrument && <div className="alert alert-danger">{errors.instrument}</div>}
                </div>
              </div>
            </div>
              )}
            <div className="form-row">
              <div className="col-md-12 mb-3">
                <div className="col-md-12 form-label-group mb-3">
                  <select
                    id="responsible"
                    name="responsible"
                    className="form-control"
                    onChange={(e) => setResponsible({
                      _id: e.target.value,
                      name: e.target.options[e.target.selectedIndex].text
                    })}
                    value={responsible._id}>
                    <option value="">Selecciona...</option>
                    {responsibles.map(r => (
                      <option key={r.name} value={r._id}>
                        {r.name}
                      </option>
                    ))}
                  </select>
                  <label>Solicitante</label>
                  {errors.responsible && <div className="alert alert-danger">{errors.responsible}</div>}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary" onClick={handleSubmit}>Guardar</button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  )
}
