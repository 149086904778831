import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { ObjectID } from 'bson';
import { Link } from "react-router-dom";
import Autocomplete from 'react-autocomplete'
import { currencyFormat } from "../../../utils/currency";

class Expenses extends Component {
    state = {
        show: false,
        data: {
            _id: new ObjectID(),
            concept: "",
            type: "Referral fee",
            amount: 0,
            quantity: 1,
            currency: "MXN",
            iva: 0,
            total: "",
            hasBreakdown: true,
            hasIva: true,
            checked: false,
            status: "No Pagado",
            invoiceId: ""
        },
        concepts: []
    };

    async populateBox() {
        const concepts2 = new Set();
        const concepts = [];
        for (const { expenses } of this.props.boxes) {
            for (const { concept } of expenses) {
                if (!concepts2.has(concept)) {
                    concepts.push({ _id: new ObjectID(), name: concept })
                    concepts2.add(concept)
                }
            }
        }
        this.setState({ concepts });
    }

    async componentDidMount() {
        this.populateBox();
    }

    showModalExpense = edit => {
        let { data } = this.state;

        if (edit) {
            data = { _id: edit._id, concept: edit.concept, type: edit.type, amount: edit.amount, quantity: edit.quantity, currency: edit.currency, iva: edit.iva, hasBreakdown: edit.hasBreakdown, total: edit.total, hasIva: edit.hasIva, checked: edit.checked, invoiceId: edit.invoiceId, status: edit.status };
        } else {
            data = { _id: new ObjectID(), concept: "", type: "", amount: "", quantity: 1, currency: "MXN", iva: "", hasBreakdown: true, total: "", hasIva: true, checked: false, invoiceId: "", status: "No Pagado" };
        }

        this.setState({ data: data, show: true });
    };

    hideModal = () => {
        this.setState({ show: false });
    };

    handleChange = ({ currentTarget: input }) => {
        //const errors = { ...this.state.errors };
        //const errorMessage = this.validateProperty(input);
        // if (errorMessage) errors[input.name] = errorMessage;
        // else delete errors[input.name];

        const data = { ...this.state.data };
        data[input.name] = input.value;
        if ((input.name === "amount" || input.name === "quantity") && data.hasIva) {
            data.total = parseFloat(data.amount * data.quantity);
            data.iva = parseFloat(data.total * 0.16);
        } else if (input.name === "currency") {
            data.total = parseFloat(((data.amount * data.quantity)) * (input.value === "USD" ? this.props.usd : 1));
            data.iva = parseFloat(data.total * 0.16).toFixed(2);
        }

        this.setState({ data });
    };

    handleIva = ({ currentTarget: input }) => {
        const { data } = this.state;

        if (input.checked) {
            data.iva = 0;
            data.hasIva = false;
        } else {
            data.iva = parseFloat(data.amount * 0.16);
            data.hasIva = true;
        }

        this.setState({ data });
    };

    handleBreakdown = ({ currentTarget: input }) => {
        const { data } = this.state;

        if (input.checked) {
            data.hasBreakdown = false;
        } else {
            data.hasBreakdown = true;
        }

        this.setState({ data });
    };

    handleSubmitExpense = e => {
        e.preventDefault();
        const { data } = this.state;
        let editData = this.props.expenses.filter(m => m._id === data._id);

        if (editData.length < 1)
            data._id = new ObjectID();

        this.setState({ data: data, show: false });
        this.props.onExpense(data);

        // const errors = this.validate();
        // this.setState({ errors: errors || {} });
        // if (errors) return;

        // this.doSubmit();
    };

    conceptsFiltered(concept) {
        return this.state.concepts.filter(({ name }) => name.toLowerCase().includes(concept.toLowerCase()))
    }

    render() {
        const { data } = this.state;

        const { user } = this.props;

        return (
            <div>
                <div className="form-row">
                    <div className="col-md-6 mb-6">
                        <h5>Gastos</h5>
                    </div>
                    {user && user.role && user.role.actions.some(a => a.method === "add-expenses-box") && this.props.addItems && (
                        <div className="col-md-6 mb-6 pr-0">
                            <button type="button" className="btn btn-success btn-sm float-right link-add" onClick={() => this.showModalExpense(false)}><i className="fa fa-plus" /></button>
                        </div>
                    )}
                </div>
                <div className="form-row">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">Concepto</th>
                                <th scope="col">Tipo</th>
                                <th scope="col">Estatus</th>
                                <th scope="col">Monto</th>
                                <th scope="col">Cant.</th>
                                <th scope="col">Total (MXN)</th>
                                <th scope="col">Tipo de Moneda</th>
                                <th scope="col">IVA</th>
                                <th scope="col">Desglosar</th>
                                <th scope="col">Acción</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.expenses.map(expense => (
                                <tr key={expense._id}>
                                    <td><Link to="#" onClick={() => (user && user.role && user.role.actions.some(a => a.method === "edit-expenses-box")) ? this.showModalExpense(expense) : ""}>{expense.concept}</Link></td>
                                    <td>{expense.type}</td>
                                    <td>{expense.status}</td>
                                    <td>{currencyFormat(expense.amount)}</td>
                                    <td>{expense.quantity}</td>
                                    <td>{currencyFormat(expense.total)}</td>
                                    <td>{expense.currency}</td>
                                    <td>{currencyFormat(expense.iva)}</td>
                                    <td>{!expense.hasBreakdown ? "No" : "Si"}</td>
                                    {user && user.role && user.role.actions.some(a => a.method === "delete-expenses-box") && (
                                        <td className="text-center"><Link to="#" onClick={() => this.props.onDelete(expense)} style={{ color: 'red' }}>Eliminar</Link></td>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <Modal show={this.state.show} onHide={this.hideModal}>
                    <form>
                        <Modal.Header closeButton>
                            <Modal.Title>Agregar Gastos</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <Autocomplete
                                        inputProps={{ id: 'concept', name: 'concept', className: 'form-control', placeholder: 'Concepto' }}
                                        wrapperStyle={{ position: '', background: '#b8daff' }}
                                        value={data.concept}
                                        items={this.conceptsFiltered(data.concept)}
                                        getItemValue={(item) => item.name}
                                        onSelect={(value, item) => {
                                            data.concept = item.name;
                                            this.setState({ data })
                                        }}
                                        onChange={(event, value) => {
                                            data.concept = value;
                                            this.setState({ data })
                                        }}
                                        renderMenu={children => (
                                            <div className="menu">
                                                {children}
                                            </div>
                                        )}
                                        renderItem={(item, isHighlighted) => (
                                            <div
                                            className={`item ${isHighlighted ? 'item-highlighted' : ''}`}
                                            key={item._id}
                                            >{item.name}</div>
                                        )}
                                        renderInput={(props) => (<>
                                            <input {...props} />
                                            <label htmlFor="concept">Concepto</label>
                                        </>)}
                                        wrapperProps={{ className: 'form-label-group' }}
                                    />
                                </div>

                                <div className="col-md-12 mb-3">
                                    <select
                                      name="type"
                                      className="form-control"
                                      value={data.type}
                                      onChange={this.handleChange}>
                                        <option value="Referral fee">Referral fee</option>
                                        <option value="Derechos de legalización y apostilla">Derechos de legalización y apostilla</option>
                                        <option value="Derechos de registro">Derechos de registro</option>
                                        <option value="Honorarios 3ero">Honorarios 3ero</option>
                                        <option value="Derechos IMPI">Derechos IMPI</option>
                                        <option value="Papelería">Papelería</option>
                                        <option value="Viáticos">Viáticos</option>
                                        <option value="Otros">Otros</option>
                                    </select>
                                </div>
                                <div className="col-md-12 mb-3">
                                    <select
                                      className="form-control"
                                      name="status"
                                      value={data.status}
                                      onChange={this.handleChange}>
                                        <option value="No Pagado">No Pagado</option>
                                        <option value="Parcialmente Pagado">Parcialmente Pagado</option>
                                        <option value="Pagado">Pagado</option>
                                    </select>
                                </div>
                                <div className="form-row col-md-12">
                                    <div className="col-md-9 mb-3">
                                        <input
                                          placeholder="Monto"
                                          className="form-control"
                                          name="amount"
                                          type="number"
                                          onChange={this.handleChange}
                                          value={data.amount} />
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <select
                                          name="currency"
                                          id="currency"
                                          value={data.currency}
                                          className="form-control"
                                          onChange={this.handleChange}>
                                            <option value="MXN">MXN</option>
                                            <option value="USD">USD</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-12 mb-3">
                                    <input
                                      placeholder="Cantidad"
                                      className="form-control"
                                      name="quantity"
                                      type="number"
                                      onChange={this.handleChange}
                                      value={data.quantity}
                                      min={1}
                                      required />
                                </div>
                                <div className="col-md-12 mb-3">
                                    <input
                                      placeholder="Total"
                                      className="form-control"
                                      name="total"
                                      type="number"
                                      readOnly
                                      onChange={this.handleChange}
                                      value={data.total}/>
                                </div>
                                <div className="col-md-12 mb-3">
                                    <input
                                      placeholder="IVA"
                                      className="form-control"
                                      name="iva"
                                      type="number"
                                      readOnly
                                      onChange={this.handleChange}
                                      value={data.iva}/>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="col-md-12 form-check">
                                    <input
                                      type="checkbox"
                                      name="is-hasBreakdown"
                                      className="form-check-input"
                                      checked={!data.hasBreakdown}
                                      onChange={this.handleBreakdown}/>
                                    <label className="form-check-label">No desglosar</label>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="col-md-12 form-check">
                                    <input
                                      type="checkbox"
                                      name="is-iva"
                                      className="form-check-input"
                                      checked={!data.hasIva}
                                      onChange={this.handleIva}/>
                                    <label className="form-check-label">No incluir IVA</label>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button
                              type="submit"
                              className="btn btn-primary"
                              onClick={this.handleSubmitExpense}>Guardar
                            </button>
                        </Modal.Footer>
                    </form>
                </Modal>
            </div>
        );
    }
}

export default Expenses;
