import React from 'react'
import Joi from 'joi-browser'
import Form from '../../common/form'
import { toast } from 'react-toastify'

import { getProvider, saveProvider } from '../../../services/providerService'
import { Link } from 'react-router-dom'

class ProviderForm extends Form {

  constructor () {
    super()
    this.state = {
      data: {
        name: '',
        email: '',
        description: '',
        rfc: '',
        phone: '',
        address: '',
      },
      errors: {},
      isSubmitting: false,
    }
  }

  schema = {
    name: Joi.string().label('Nombre').required(),
    email: Joi.string().email().label('Correo Electrónico').allow(''),
    description: Joi.string().label('Descripción').required(),
    rfc: Joi.string().label('RFC').optional().allow(''),
    phone: Joi.string().label('Teléfono').optional().allow(''),
    address: Joi.string().label('Dirección').optional().allow(''),
  }

  async componentDidMount () {
    if (this.props.match.params.id) {
      const { data: provider } = await getProvider(this.props.match.params.id)
      this.setState({ data: provider })
    }
  }

  doSubmit = async () => {
    const { data } = this.state
    //validate the data
    const errors = this.validate()
    this.setState({ errors: errors || {} })
    if (errors) return
    this.setState({ errors: errors || {}, isSubmitting: true })

    try {
      console.log('submit', data)
      await saveProvider(data)
      this.props.history.push('/boxes/accounts-payable')
      toast.success('El proveedor se guardo correctamente.')
    } catch (ex) {
      toast.error(ex.response.data.error)
    }
  }

  render () {
    const { isSubmitting } = this.state
    return (
      <div>
        <fieldset>
          <legend className="mb-3">
            {this.props.match.params.id ? '' : 'Agregar'} Proveedor
          </legend>
          <form onSubmit={this.handleSubmit}>
            <div className="form-row">
              <div className="col-md-6">
                {this.renderInput('name', 'Nombre')}
              </div>
              <div className="col-md-6">
                {this.renderInput('description', 'Descripción')}
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-4">
                {this.renderInput('email', 'Correo Electrónico')}
              </div>
              <div className="col-md-4">
                {this.renderInput('rfc', 'RFC')}
              </div>
              <div className="col-md-4">
                {this.renderInput('phone', 'Teléfono')}
              </div>
            </div>
            <div className="form-row">
              <div className="col-md-12">
                {this.renderTextArea('address', 'Dirección')}
              </div>
            </div>

            <button className="btn btn-primary float-right" style={{ marginTop: 15 }} type="submit" disabled={isSubmitting} >Guardar</button>
            <Link to="/boxes/accounts-payable" className="btn btn-danger float-right" style={{ marginTop: 15, marginRight: 10 }}>Cancelar</Link>
          </form>
        </fieldset>

      </div>
    )
  }
}

export default ProviderForm
