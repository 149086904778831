import React, { Component } from "react";
import { getCurrentUser } from "../../services/authService";
import { Link } from "react-router-dom";
import Table from "../common/table";

class ClientsTable extends Component {
  columns = [
    {
        field: "name",
        label: "Nombre del cliente",
        width: 300,
        sort: "asc",
        content: client => <Link to={`/clients/${client._id}`}>{client.name}</Link>
    },
    {
      field: "offices",
      label: "Asunto",
      width: 300,
      sort: "asc"
    },
    {
      field: "originator",
      label: "Originador",
      sort: "asc"
    },
  ];

  deleteColumn = {
    key: "delete",
    field: "delete",
    label: "Acciones",
    width: 150,
    content: client => (
      <button
        onClick={(e) =>
          window.confirm("Esta seguro que desea eliminar al cliente?")
          && this.props.onDelete(client)
        }
        className="btn btn-danger btn-sm"
      >
        Eliminar
      </button>
    )
  };

  async componentDidMount() {
    const { data: user } = await getCurrentUser();
    if(user && user.role && user.role.actions.some(a => a.method === "delete-client")) this.columns.push(this.deleteColumn);

    const columns = this.columns;
    this.setState({ columns });
  }


  render() {
    const { user, clients, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        data={clients}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default ClientsTable;
