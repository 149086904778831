import React from 'react'
import Joi from 'joi-browser'
import Select from 'react-select'

import Form from '../../common/form'
import { getAccountPayable, saveAccountPayable } from '../../../services/accountsPayableService'
import { getCurrentUser } from '../../../services/authService'
import { getProviders } from '../../../services/providerService'
import { getBoxes } from '../../../services/boxService'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import { getClients } from '../../../services/clientService'

class AccountsPayableForm extends Form {

  constructor () {
    super()
    this.state = {
      data: {
        invoice: '',
        provider: null,
        client: null,
        box: null,
        amount: '',
        total: '',
        status: 'Pendiente',
        date: '',
        paymentDate: '',
        observations: '',
        concept: '',
        isAuthorized: false
      },
      errors: {},
      accountPayable: null,
      providers: [],
      boxes: [],
      clients: [],
      user: null,
      isLoading: true,
      isSubmitting: false,
    }
  }

  schema = {
    _id: Joi.string().allow(''),
    invoice: Joi.string().label('Factura').required(),
    provider: Joi.object().label('Proveedor').required(),
    client: Joi.object().label('Cliente').allow(null),
    date: Joi.date().label('Fecha').required(),
    box: Joi.object().label('Caja').allow(null),
    amount: Joi.number().label('Importe').required(),
    total: Joi.number().label('Total').required(),
    paymentDate: Joi.date().label('Fecha de Pago').allow(''),
    status: Joi.string().label('Estatus').required(),
    observations: Joi.string().label('Observaciones').allow(''),
    concept: Joi.string().label('Concepto').allow(''),
    isAuthorized: Joi.boolean()
  }

  async componentDidMount () {
    if (this.props.match.params.id) {
      const { options, data } = this.state
      const { data: accountPayable } = await getAccountPayable(this.props.match.params.id)
      accountPayable.date = new Date(accountPayable.date)
      accountPayable.paymentDate = accountPayable.paymentDate ? new Date(accountPayable.paymentDate) : ''
      delete accountPayable.createdAt
      delete accountPayable.updatedAt
      this.setState({ data: accountPayable, options })
    }
    const { data: providers } = await getProviders()
    const { data: clients } = await getClients()
    const { data: boxes } = await getBoxes()
    const { data: user } = await getCurrentUser()

    this.setState({ providers, boxes, user, clients, isLoading: false })
  }

  handleChangeProvider = e => {
    const providerId = e.target.value
    const { data } = this.state
    data.provider = this.state.providers.find(p => p._id === providerId)
    // console.log(data, 'data')
    this.setState({ data })
  }

  handleChangeClient = e => {
    const clientId = e.target.value
    const { data } = this.state
    data.client = this.state.clients.find(c => c._id === clientId)
    // console.log(data, 'data')
    this.setState({ data })
  }

  onChange = (selectedList, selectedItem) => {
    const { data } = this.state
    if (selectedList) {
      data.box = {
        _id: selectedList._id,
        numberBox: selectedList.numberBox
      }
    }else {
      data.box = null
    }

    this.setState({ data })
  }

  onRemove = () => {
    const { data } = this.state
    data.box = null
    this.setState({ data })
  }

  handleAuthorize = async () => {
    const { data } = this.state
    data.isAuthorized = !data.isAuthorized
    data.status = 'Autorizado'
    this.setState({ data })
    await this.doSubmit()
  }

  handlePaymentDate = date => {
    const data = { ...this.state.data }
    data.paymentDate = date
    this.setState({ data })
  }

  handleDate = date => {
    const data = { ...this.state.data }
    data.date = date
    this.setState({ data })
  }

  handleDoPayment = async () => {
    const { data } = this.state
    data.status = 'Pagado'
    this.setState({ data })
    await this.doSubmit()
  }

  doSubmit = async () => {
    const { data } = this.state
    // console.log(data, 'data')
    //validate the data
    const errors = this.validate()
    this.setState({ errors: errors || {} })
    console.log(errors, 'errors')
    if (errors) return

    this.setState({ isSubmitting: true })
    try {
      // console.log('submit', data)
      await saveAccountPayable(data)
      this.props.history.push('/boxes/accounts-payable')
      toast.success('La cuenta por pagar se guardo correctamente.')
    } catch (ex) {
      toast.error(ex.response.data.error)
    }
  }

  render () {
    const { providers, boxes, data, errors, isLoading, user, isSubmitting, clients } = this.state
    return (
      <div>
        <div className="row">
          <div className="col">
            <div className="form-row">
              <div className="col-lg-7">
                <h3 className="mb-3">Cuentas por pagar</h3>
              </div>
            </div>
            <form onSubmit={this.handleSubmit}>
              <div className="form-row">
                <div className="col-md-4">
                  {this.renderInput('invoice', 'Factura')}
                </div>
                <div className=" col-md-4">
                  {this.renderSelect('provider', 'Proveedor', providers, this.handleChangeProvider)}
                </div>
                <div className="col-md-4">
                  {this.renderInput('concept', 'Concepto')}
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-3">
                  <div className="form-label-group">
                    <Select
                      id="box"
                      className="select-autocomplete"
                      isClearable={true}
                      isSearchable={true}
                      options={boxes}
                      name="box"
                      onChange={this.onChange}
                      placeholder="Caja"
                      isLoading={isLoading}
                      getOptionLabel={option => option.numberBox}
                      getOptionValue={option => option._id}
                      value={data.box}
                    />
                    <label htmlFor="box">Caja</label>
                    {errors['box'] && (
                      <div className="alert alert-danger">{errors['box']}</div>
                    )}
                  </div>
                </div>
                <div className=" col-md-3">
                  {this.renderSelect('client', 'Cliente', clients, this.handleChangeClient)}
                </div>
                <div className="col-md-3">
                  {this.renderInput('amount', 'Importe', 'number')}
                </div>
                <div className="col-md-3">
                  {this.renderInput('total', 'Total', 'number')}
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-4">
                  {this.renderDate('date', 'Fecha', this.handleDate)}
                </div>
                <div className="col-md-4">
                  {this.renderDate('paymentDate', 'Fecha de pago', this.handlePaymentDate)}
                </div>

                <div className="col-md-4">
                  {this.renderInput('status', 'Estatus', true)}
                </div>
              </div>

              <div className="form-row">
                <div className="col-md-12">
                {this.renderTextArea('observations', 'Observaciones')}
                </div>
              </div>
              {!data.isAuthorized && this.props.match.params.id && (user?.role?.actions?.some(a => a.method === 'approve-payment-accounts-payable')) &&
                (<button className="btn btn-success float-right" style={{ marginTop: 15 }} onClick={this.handleAuthorize}>Autorizar Pago</button>)}
              {data.isAuthorized && data.status !== 'Pagado' && (user?.role?.actions?.some(a => a.method === 'pay-accounts-payable')) &&
                (<button className="btn btn-success float-right" style={{ marginTop: 15 }} onClick={this.handleDoPayment}>Pagar</button>)}
              {(data.status !== 'Pagado' || data.status === '') && (user?.role?.actions?.some(a => a.method === 'add-accounts-payable' || a.method === 'update-accounts-payable')) &&
                (<button className="btn btn-primary float-right" style={{ marginTop: 15, marginRight: 10 }} type="submit" disabled={isSubmitting}>Guardar</button>)}
              <Link to="/boxes/accounts-payable" className="btn btn-danger float-right" style={{ marginTop: 15, marginRight: 10 }}>Atrás</Link>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default AccountsPayableForm
