import { saveAs as download } from 'file-saver';
import { jsPDF } from 'jspdf';
import moment from 'moment';


/**
 *
 * @param {*} report
 * @param {*} save
 */
 const generatePdf = async (files, name, save = false) => {

    const doc = new jsPDF('p', 'cm', 'letter')
        .setProperties({ title: moment().format('dd/mm/YYYY') });

    let y_par = 2.5, y_imp = 2.5, line = 0.5;

    files.forEach((item, index) => {              
        if(index % 2 === 0){
            doc.addImage(item, 'JPG', 1, y_par += (index % 6) === 0 ? line : line * 16, 9.8, 7,undefined,'FAST');
        } else {
            doc.addImage(item, 'JPG', 11, y_imp += (index % 6) === 1 ? line : line * 16, 9.8, 7,undefined,'FAST');
        }                  

        if(y_par === 19 && y_imp === 19){            
            doc.addPage();     
            y_par = 2.5;
            y_imp = 2.5; 
            line = 0.5;   
        }                       
    });

    const blob = doc.output('blob');
    const filename = `${name}.pdf`;

    download(blob, filename);

    return true;
};


export const generateDoc = async (files, name, save = false) => {
    let promises = [];
    files.forEach(file => {
        promises.push(file);
    });

    Promise.all(promises).then((values) => {
        return generatePdf(values, name, save);
    });
};