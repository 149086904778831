import React, { Component } from "react";
import Table from "../common/table";

class RolesTable extends Component {
  columns = [
    {
        field: "name",
        label: "Nombre",
        width: 150
    },
    {
        field: "actions",
        label: "Acciones"
    },
  ];

  render() {
    const { roles } = this.props;

    return (
      <Table
        columns={this.columns}
        data={roles}
      />
    );
  }
}

export default RolesTable;
