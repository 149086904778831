import React, { Component } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import esLocale from "@fullcalendar/core/locales/es";
import CalendarModal from "./calendarModal";
import { getCurrentUser } from "../../services/authService";
import { getUsers } from "../../services/userService";
import {
  deleteCalendarEvent,
  getCalendarEvents,
  saveCalendarEvent,
} from "../../services/calendarService";
import { MultiSelect } from "react-multi-select-component";
import "./calendar.css";

class Calendar extends Component {
  state = {
    user: {},
    showModal: false,
    dateInfo: {},
    currentEvents: [],
    events: [],
    users: [],
    userId: "",
    isMobile: window.innerWidth < 768,
    filters: {
      type: "",
      users: [],
    },
    savedFilters: {
      type: "",
      users: [],
    },
  };

  render() {
    const { user, showModal, dateInfo, events, users, filters, isMobile } = this.state;

    const filteredEvents = events.filter(event => {
      const filterUsers = filters.users.length ? filters.users.map(item => item.value) : null;
      const matchesType = filters.type ? event.type === filters.type : true;
      const matchesUsers = filterUsers ? filterUsers.includes(event.user._id) : true;
      return matchesType && matchesUsers;
    });

    return (
      <div className="calendar">
        <h3>Calendario</h3>
        <div className="filters row mb-3">
          <div className="filter-group col-md-4 mb-3">
            <label htmlFor="filter-type filter">Por tipo:</label>
            <select
              id="filter-type"
              className="form-control"
              name="type"
              value={filters.type}
              onChange={this.handleFilterChange}
            >
              <option value="">Todos</option>
              <option value="Home Office">Home Office</option>
              <option value="En Oficina">En Oficina</option>
              <option value="Vacaciones">Vacaciones</option>
              <option value="Guardia">Guardia</option>
              <option value="Fuera del pais">Fuera del país</option>
              <option value="Diligencia">Diligencia</option>
            </select>
          </div>
          <div className="filter-group col-md-4 mb-3">
            <label htmlFor="filter-users filter">Por persona:</label>
            <MultiSelect
                options={users.map((user) => ({
                  label: user.name,
                  value: user._id,
                }))
                }
                value={filters.users}
                onChange={(event)=>{
                  this.handleFilterChange({target: {name: "users", value: event}})
                }}
                labelledBy="Select"
              />
          </div>
          <div className="filter-buttons col-md-4 d-flex justify-content-md-start align-items-center">
            <div className="ml-md-auto cont-btn">
              <button className="btn btn-primary mr-2 btn-mob" onClick={this.handleSaveFilter}>
                Guardar filtro
              </button>
              <button className="btn btn-secondary clean-btn btn-mob" onClick={this.handleClearFilter}>
                Limpiar filtro
              </button>
            </div>
          </div>

        </div>

        <div className="calendar-main">
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={{
              left: isMobile ? "title" : "prev,next,today",
              center: isMobile ? "prev,next,today" : "title",
              right: isMobile ? "dayGridMonth,timeGridWeek,timeGridDay" : "dayGridMonth,timeGridWeek,timeGridDay",
            }}
            initialView={isMobile ? "timeGridDay" : "dayGridMonth"}
            editable={true}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            weekends={true}
            events={filteredEvents}
            dateClick={this.handleEventClick}
            select={this.handleDateSelect}
            eventContent={renderEventContent} // custom render function
            eventClick={this.handleEventClick}
            eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
            eventDrop={this.handleDrop}
            locale={esLocale}
            timeZone={"America/Mexico_City"}
            eventCapture={true}
          />
        </div>
        <CalendarModal
          userId={this.state.userId}
          users={this.state.users}
          showModal={showModal}
          handleShow={this.handleShow}
          handleClose={this.handleClose}
          handleDelete={this.handleDelete}
          dateInfo={dateInfo}
          user={user}
        />
      </div>
    );
  }

  async componentDidMount() {
    document.body.addEventListener("touchmove", this.handleTouchMove, {
      passive: true,
    });
    const savedFilters = localStorage.getItem("Cp8savedFilters");
    if (savedFilters) {
      this.setState({ filters: JSON.parse(savedFilters) });
    }
    await this.populateUser();
    await this.populateEvents();
  }

  async populateUser() {
    const { data: user } = await getCurrentUser();
    if (user) this.setState({ user });
    if (user && user.role && user.role.name === "Administrador") {
      this.setState({ userId: user._id });
    }
    const { data: users } = await getUsers();
    this.setState({ users });
  }

  async populateEvents() {
    const { data: events } = await getCalendarEvents('');
    if (events) this.setState({ events });
  }

  handleShow = () => this.setState({ showModal: true });
  handleClose = async () => {
    this.setState({ showModal: false });
    await this.populateEvents();
  };

  handleDateSelect = async (selectInfo) => {
    this.setState({ dateInfo: selectInfo });
    this.handleShow();
  };

  handleEventClick = (clickInfo) => {
    if (!clickInfo || !clickInfo.event || !clickInfo.event._def) {
      const newDateInfo = {
        user: this.state.user._id,
        startStr: clickInfo.dateStr,
        endStr: clickInfo.endStr,
        allDay: clickInfo.allDay,
        type: '',
      };

      this.setState({ dateInfo: newDateInfo });
      this.handleShow();
      return;
    }

    const { event } = clickInfo;
    const { extendedProps } = event._def;

    if (!extendedProps) {
      alert("No puedes modificar este evento");
      return;
    }

    const newDateInfo = {
      _id: extendedProps._id,
      user: extendedProps.user._id,
      startStr: clickInfo.dateStr,
      endStr: clickInfo.endStr,
      allDay: clickInfo.allDay,
      type: extendedProps.type,
    };

    this.setState({ dateInfo: newDateInfo });
    this.handleShow();
  };

  handleDrop = async (eventDropInfo) => {
    const { user } = this.state;
    if (eventDropInfo.event._def.extendedProps.user._id === user._id) {
      const event = {
        _id: eventDropInfo.event._def.extendedProps._id,
        user: eventDropInfo.event._def.extendedProps.user._id,
        type: eventDropInfo.event._def.extendedProps.type,
        startDate: eventDropInfo.event.startStr,
        endDate: eventDropInfo.event.endStr,
        allDay: eventDropInfo.event.allDay,
      };
      await saveCalendarEvent(event);
    } else {
      alert("No puedes modificar este evento");
    }
    await this.populateEvents();
  };

  handleDelete = async (id) => {
    if (window.confirm("¿Deseas eliminar el evento?")) {
      await deleteCalendarEvent(id);
      await this.handleClose();
    }
  };

  handleEvents = (events) => {
    this.setState({
      currentEvents: events,
    });
  };

  handleFilterChange = (e) => {
    const { name, value } = e.target;
    if (name === "users") {
      const selectedUsers = value;
      console.log(selectedUsers)
      this.setState((prevState) => ({
        filters: { ...prevState.filters, users: selectedUsers },
      }));
    } else {
      this.setState((prevState) => ({
        filters: { ...prevState.filters, [name]: value },
      }));
    }

    console.log(this.state.filters, 'filters fin');
  };

  handleSaveFilter = () => {
    this.setState((prevState) => ({
      savedFilters: { ...prevState.filters },
    }));
    localStorage.setItem("Cp8savedFilters", JSON.stringify(this.state.filters));
  };

  handleClearFilter = () => {
    this.setState({
      filters: { type: "", users: [] },
      savedFilters: { type: "", users: [] },
    });
    localStorage.removeItem("Cp8savedFilters");
  };

  handleTouchMove = (event) => {
    event.preventDefault();
  };

}

function renderEventContent(eventInfo) {
  return (
    <>
      <b>{eventInfo.timeText}</b>
      <i>{eventInfo.event.title}</i>
    </>
  );
}

export default Calendar;
