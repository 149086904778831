import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import logo  from '../logo.png';

class VerificationForm extends Component {
    state = {
        data: { email: '', code: '' },
        error: '',
    };

    doConfirmation = () => {
        const { email, code } = this.state.data;
        Auth.confirmSignUp(email, code)
            .then(async () => {                
                window.location.href = '/login';
            }).catch((err) => {
                console.log(err);
                this.setState({ error: err.message });
            });
    }

    handleSubmit = e => {
        e.preventDefault();

        this.doConfirmation();
    };

    handleChange = ({ currentTarget: input }) => {
        const data = { ...this.state.data };
        data[input.name] = input.value;

        this.setState({ data });
    };

    componentDidMount() {
        document.getElementById('root').classList.add('auth');
    }

    render() {

        const { data, error } = this.state;

        return (
            <form onSubmit={this.handleSubmit} className="form-signin">
                <div className="text-center mb-4">
                    <img className="mb-4" src={logo} alt="" width="50%" height="100%" />                    
                </div>
                <div className="form-label-group">
                    <input name="email" className="form-control" placeholder="Correo electronico" value={data.email} onChange={this.handleChange} required />
                    <label>Correo electronico</label>
                </div>
                <div className="form-label-group">
                    <input name="code" type="code" className="form-control" placeholder="Codigo de Verificación" value={data.code} onChange={this.handleChange} required />
                    <label>Codigo de Verificación</label>
                </div>
                {error &&
                    <label color="red">{error}</label>
                }  
                <button className="btn btn-lg btn-primary btn-block">Confirmar usuario</button>
            </form>
        );
    }
}

export default VerificationForm;
