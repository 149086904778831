import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Check from '../common/check'
import moment from 'moment'
import { getCurrentUser } from '../../services/authService'
import { getInstruments, saveInstrument } from '../../services/instrumentService'
import Table from '../common/materia-reac-table/table'

class PldTable extends Component {
  state = {
    pld: [],
    isLoading: true,
    pdlExport: []
  }

  columns = [
    {
      accessorKey: 'number',
      header: 'No. Instrumento',
      Cell: ({ row }) => <Link to={`/instruments/${row.original._id}`} searchvalue={row.original.number}
        className={row.original.pld && row.original.pld.status === 'delayed' ? 'badge badge-danger' : ''}>{String(row.original.number)}</Link>,
      size: 170
    },
    {
      accessorKey: 'date',
      header: 'Fecha instrumento',
      Cell: ({ row }) => moment(row.original.date).format('DD/MM/YYYY'),
      size: 180
    },
    {
      accessorKey: 'type',
      header: 'Tipo de aviso',
      Cell: ({ row }) => row.original.pld && row.original.pld.type ? row.original.pld.type : '',
      size: 150
    },
    {
      accessorKey: 'noticeDate',
      header: 'Fecha de aviso',
      Cell: ({ row }) => row.original.pld && row.original.pld.noticeDate ? moment(row.original.pld.noticeDate).format('DD/MM/YYYY') : '',
      size: 180
    },
    {
      accessorKey: 'reference',
      header: 'No. de referencia',
      size: 250,
      Cell: ({ row }) => row.original.pld.reference ? row.original.pld.reference : '',
    },
    {
      accessorKey: 'archived',
      header: 'Archivado',
      Cell: ({ row }) => <Check
        archived={row.original.pld && row.original.pld.archived ? row.original.pld.archived : false}
        onClick={() => this.handleArchive(row.original)} />,
      size: 120
    }
  ]

  async populateUser() {
    const { data: user } = await getCurrentUser()
    this.setState({ user })
  }

  async componentDidMount() {
    await this.populateUser()
    const { data: instruments } = await getInstruments({ type: 'pld' })
    this.setState({ pld: instruments, isLoading: false })
  }

  handleDelete = async (instrument) => {
    const originalPld = this.state.pld
    const updatePld = originalPld.filter(p => p !== instrument)
    this.setState({ pld: updatePld })

    try {
      instrument.pld = {}
      delete instrument.responsible
      delete instrument.name
      await saveInstrument(instrument)
    } catch (ex) {

      this.setState({ pld: originalPld })
    }
  }

  getPagedData = () => {
    const {
      pld: allPld,
    } = this.state
    let filtered = allPld
    filtered = allPld.filter(m =>
      m.pld?.active === true &&
      ((this.props.match.params.status === 'pending'
        && (m.pld?.status === this.props.match.params.status || m.pld?.status === 'delayed')) ||
        (m.pld?.status === this.props.match.params.status))
    )
    return { data: filtered }
  }

  exportFilteredDataToExcel = () => {
    const tabla = document.querySelector('.MuiTable-root');

    // Verificar si se encontró la tabla
    if (tabla) {
      // Array para almacenar los datos de la tabla
      const datosTabla = [];

      // Seleccionar todas las filas de la tabla excepto la primera (encabezados)
      const filas = tabla.querySelectorAll('tr:not(.MuiTableRow-head)');

      // Iterar sobre cada fila
      filas.forEach(fila => {
        // Objeto para almacenar los datos de cada fila
        const datosFila = {};

        // Seleccionar todas las celdas de la fila
        const celdas = fila.querySelectorAll('td');

        // Iterar sobre cada celda y obtener su contenido
        celdas.forEach((celda, index) => {
          // Obtener el título de la columna desde los encabezados
          const tituloColumna = tabla.querySelector(`th:nth-child(${index + 1})`).textContent.trim();
          // Agregar el contenido de la celda al objeto de datos de la fila
          if (tituloColumna === 'No. Instrumento0') {
            datosFila['number'] = celda.textContent.trim();
          }

          if (tituloColumna === 'Fecha instrumento0') {
            datosFila['date'] = celda.textContent.trim();
          }

          if (tituloColumna === 'Tipo de aviso0') {
            datosFila['type'] = celda.textContent.trim();
          }

          if (tituloColumna === 'Fecha de aviso0') {
            datosFila['noticeDate'] = celda.textContent.trim();
          }
          if (tituloColumna === 'No. de referencia0') {
            datosFila['reference'] = celda.textContent.trim();
          }
          if (tituloColumna === 'Archivado0') {
            datosFila['archived'] = celda.textContent.trim();
          }
        });

        // Agregar los datos de la fila al array de datos de la tabla
        datosTabla.push(datosFila);
      });


      const fileName = 'PLD_Filtrado'.concat(moment().format('DD-MM-YYYY')).concat('.xls');
      const fileType = 'application/vnd.ms-excel';
      const table = document.createElement('table');
      table.innerHTML = '<thead><tr><th>No. Instrumento</th><th>Fecha instrumento</th><th>Tipo de aviso</th><th>Fecha de aviso</th><th>No. de referencia</th><th>Archivado</th></tr></thead>';
      const tbody = document.createElement('tbody');
      datosTabla.forEach((instrument) => {
        console.log(instrument);
        const row = document.createElement('tr');
        row.innerHTML = `<td>${instrument.number}</td><td>${instrument.date}</td><td>${instrument.type}</td><td>${instrument.noticeDate}</td><td>${instrument.reference}</td><td>${instrument.archived ? 'Si' : 'No'}</td>`;
        tbody.appendChild(row);
      });
      table.appendChild(tbody);
      document.body.appendChild(table);
      table.style.display = 'none';
      const blob = new Blob([table.outerHTML], {
        type: fileType
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
      console.log(datosTabla);

    }
  }

  deleteRowAction = ({ row }) => <button
    onClick={(e) => window.confirm('Esta seguro que desea eliminar el PLD?') && this.handleDelete(row.original)}
    className="btn btn-danger btn-sm">
    Eliminar
  </button>

  render() {
    const { isLoading, user } = this.state
    const { data: pld } = this.getPagedData()

    return (user && this.columns ?
      <div>
        <Table
          columns={this.columns}
          data={pld}
          rowAction={(user && user.role && user.role.actions.some(a => a.method === 'delete-pld')) ? this.deleteRowAction : null}
          isLoading={isLoading}

        />
      </div> : <></>
    )
  }
}

export default PldTable
