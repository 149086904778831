import React from 'react'
import Joi from 'joi-browser'
import { toast } from 'react-toastify'
import { ObjectID } from 'bson'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import Form from '../common/form'
import Sre from '../sre'
import SreTable from '../sreTable'
import Pld from '../pld/pld'
import PldTable from '../pld/pldTable'
import Rnie from './rnie'
import RnieTable from './rnieTable'
import Rpc from '../rpc/rpc'
import RpcTable from '../rpc/rpcTable'
import Testimonies from './testimonies'
import Calculator from '../common/calculator'
import { getCurrentUser } from '../../services/authService'
import { getUsers, getUser } from '../../services/userService'
import {
  getInstruments,
  getInstrument,
  saveInstrument,
  getInstrumentFiles,
  instrumentFilesAdd
} from '../../services/instrumentService'
import { authToken } from '../../services/sharePointService'
import { getConfigurations } from '../../services/configurationService'
import { getBoxInstrument } from '../../services/boxService'
import { getDenomination, getDenominations } from '../../services/denominationService'
import { upload, download, getSignedUrl } from '../../utils/s3'
import Autocomplete from 'react-autocomplete'
import { SingleDatePicker } from 'react-dates'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import moment from 'moment'
import _ from 'lodash'
import Declaranot from '../declaranot/declaranot'
import DeclaranotTable from '../declaranot/declaranotTable'

class InstrumentForm extends Form {
  state = {
    data: {
      number: 0,
      type: '',
      date: moment(),
      book: 1,
      parts: '',
      description: '',
      testimony: {},
      sre: {},
      pld: {},
      rnie: [],
      rpc: {},
      declaranot: {},
      isBC: false
      // files: []
    },
    types: [{ _id: new ObjectID(), 'name': 'Poliza' }, { _id: new ObjectID(), 'name': 'Acta' }],
    errors: {},
    focused: false,
    minDate: moment(),
    minInstrument: 0,
    maxInstrument: '',
    maxDate: '',
    objectsInstrument: [],
    partsInstrument: [],
    numbersInstrument: [],
    instruments: [],
    users: [],
    readOnly: false,
    numbersMissing: '',
    box: [],
    files: [],
    filesURL: [],
    editImages: true,
    denominations: [],
    isEditable: true
  }

  schema = {
    _id: Joi.string(),
    number: Joi.number()
      .required()
      .min(0)
      .label('Numero de Instrumento'),
    type: Joi.string()
      .required()
      .label('Tipo'),
    date: Joi.string()
      .required()
      .label('Fecha'),
    book: Joi.string()
      .required()
      .label('Libro'),
    parts: Joi.string()
      .required()
      .label('Partes'),
    object: Joi.string()
      .required()
      .label('Objeto'),
    description: Joi.string()
      .required()
      .label('Objeto'),
    testimony: Joi.string()
      .required()
      .label('Testimonios'),
    responsibleId: Joi.string()
      .required()
      .label('Responsable'),
    sre: Joi.object()
      .required()
      .label('SRE'),
    pld: Joi.object()
      .required()
      .label('PLD'),
    rnie: Joi.array()
      .required()
      .label('RNIE'),
    rpc: Joi.object()
      .required()
      .label('RPC'),
    denominationId: Joi.string()
      .required()
      .label('Denominación'),
  }

  async populateUsers () {
    const { data: users } = await getUsers()
    this.setState({ users })
  }

  async populateInstrument () {
    try {
      const instrumentId = this.props.match.params.id
      const { data: instruments } = await getInstruments()
      if (instrumentId === 'new') {
        const { data } = this.state
        const { data: configurations } = await getConfigurations()
        const numberBook = configurations.filter(config => config.number === 1)
        const numberInstrument = configurations.filter(config => config.number === 4)
        data.book = Number(numberBook[0].value)

        let dates = []
        let objectsInstrument = []
        let partsInstrument = []
        for (let instrument of instruments) {
          dates.push(instrument.date)
          this.state.numbersInstrument.push(instrument.number)
          objectsInstrument.push({ _id: new ObjectID(), object: instrument.description })
          partsInstrument.push({ _id: new ObjectID(), parts: instrument.parts })
        }

        objectsInstrument = objectsInstrument.filter((elem, index, self) =>
            index === self.findIndex((t) => (
              t.object === elem.object
            ))
        )
        partsInstrument = partsInstrument.filter((elem, index, self) =>
            index === self.findIndex((t) => (
              t.parts === elem.parts
            ))
        )

        const minDate = dates[0] ? moment(dates[0]) : moment() //(dates.length > 0 ? moment(_.max(dates)) : moment());
        const maxDate = (dates.length > 0 ? moment(_.max(dates)) : moment())
        const minInstrument = this.state.numbersInstrument[this.state.numbersInstrument.length - 1] ? (this.state.numbersInstrument[this.state.numbersInstrument.length - 1] + 1) : (Number(numberInstrument[0].value) + 1)
        const maxInstrument = this.state.numbersInstrument.length > 0 ? (_.max(this.state.numbersInstrument) + 1) : (Number(numberInstrument[0].value) + 1)
        data.date = maxDate
        data.number = maxInstrument

        for (let i = minInstrument; i <= maxInstrument; i++) {
          if (!this.state.numbersInstrument.includes(i)) {
            this.state.numbersMissing += i + ', '
          }
        }
        this.populateDenomination({ status: 'available' })

        this.setState({ instruments, partsInstrument, objectsInstrument, minInstrument, minDate, data })
        return
      }

      const { data: instrument } = await getInstrument(instrumentId)
      const index = instruments.findIndex(m => m._id === instrument._id)
      const minInstrument = instruments[index + 1] ? (instruments[index + 1].number + 1) : instruments[0].number
      const maxInstrument = instruments[index - 1] ? (instruments[index - 1].number - 1) : ''
      const minDate = moment((instruments[index + 1] ? instruments[index + 1].date : instruments[0].date))
      const maxDate = moment((instruments[index - 1] ? instruments[index - 1].date : ''))
      this.populateFiles(instrument._id)
      this.populateDenomination({ status: 'available' })

      const data = await this.mapToViewModel(instrument)

      for (let i = minInstrument; i <= maxInstrument; i++) {
        if (!this.state.numbersInstrument.includes(i)) {
          this.state.numbersMissing += i + ', '
        }
      }

      this.setState({ instruments, minDate, maxDate, minInstrument, maxInstrument, data: data })
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace('/not-found')
    }
  }

  async populateBox () {
    const { data } = this.state
    const { data: box } = data._id && data.used ? await getBoxInstrument(data._id) : []
    this.setState({ box })
  }

  async populateFiles (id) {
    const { data: files } = await getInstrumentFiles(id)
    this.setState({ files })
  }

  async populateDenomination (filter) {
    const { data: denominations } = await getDenominations(filter)
    this.setState({ denominations })
  }

  async componentDidMount () {
    await this.populateUsers()
    await this.populateInstrument()
    await this.populateBox()
    const { data } = this.state;
    let { isEditable } = this.state;
    const { data: user } = await getCurrentUser()
    if (user) this.setState({ user })
    isEditable = !((user.role.name === "Abogado" && this.props.match.params.id !== 'new') && (data.responsibleId !== user._id));
    // console.log('(user.role.name === "Administrador" && this.props.match.params.id !== \'new\' && (data.responsibleId !== user._id))', ((user.role.name === "Abogado" && this.props.match.params.id !== 'new') && (data.responsibleId !== user._id)));
    this.setState({ isEditable });
  }

  // getImages = (images) => {
  //   const { files } = this.state.data;
  //   images.forEach(async (file,index) => {
  //     const url = await getSignedUrl(file.url, 'instruments-files');
  //     files.push({
  //       name: file.name,
  //       url: file.url,
  //       urlTkn: url,
  //       uid: 'image'+index
  //     });
  //     this.setState({ files });
  //   });
  // }

  mapToViewModel = async (instrument) => {
    const data = {
      _id: instrument._id,
      number: instrument.number,
      type: instrument.type,
      date: moment(instrument.date),
      book: instrument.book,
      parts: instrument.parts,
      description: instrument.description,
      testimony: instrument.testimony,
      archived: instrument.archived,
      sre: instrument.sre ? instrument.sre : {},
      pld: instrument.pld ? instrument.pld : {},
      rnie: instrument.rnie ? instrument.rnie : [],
      rpc: instrument.rpc ? instrument.rpc : {},
      used: instrument.used,
      isBC: instrument.isBC || false,
      declaranot: instrument.declaranot,
      // files: instrument.files ? instrument.files : [],
    }

    try {
      const { data: user } = await getUser(instrument.responsible._id)
      if (user) data.responsibleId = user._id

      const { data: denomination } = await getDenomination(instrument.denomination._id)
      if (denomination) {
        data.denominationId = denomination._id
        this.state({ denominations: [...this.state.denominations, denomination] })
        // console.log(this.state.denominations);
      }
    } catch {
    }
    return data
  }

  doSubmit = async () => {
    const { files, data, editImages } = this.state
    // console.log(editImages,'editImages');
    try {
      // if(!editImages){
      //   files.map(async (item, index) => {
      //     const ext = item.file.name.split('.').slice(-1)[0];
      //     const path = `${data.number}/${new Date().getTime()}.${ext}`;
      //     const file = item.data.replace(/^data:image\/\w+;base64,/, '');

      //     await upload(path, Buffer.from(file, 'base64'), 'instruments-files')
      //       .then(async (s3) => {
      //         const image = {
      //           name: path,
      //           url: s3.Location
      //         }
      //         data.files.push(image);
      //         this.setState({ data });
      //         if (files.length === index + 1) {
      //           await saveInstrument(data);
      //           this.props.history.push("/instruments");
      //           toast.success("El instrumento se guardo correctamente.");
      //         }
      //       })
      //       .catch(error => {
      //         console.log(error);
      //       });
      //   });
      // } else {
      //data.files = files;
      console.log('submit', data)
      await saveInstrument(data)
      this.props.history.push('/instruments')
      toast.success('El instrumento se guardo correctamente.')
      //}
    } catch (ex) {
      toast.error(ex.response.data.errors.number.message)
    }
  }

  handleTestimony = async (item) => {
    const { data } = this.state
    data.testimony = item
    this.setState({ data })
    console.log(data.testimony.items)
  }

  handleDateChange = date => {
    const { data } = this.state
    data.date = date
    this.setState({ data })
  }

  matchStateToTerm = (client, value, type) => {
    return (
      type === 'object' ? client.object.toLowerCase().indexOf(value.toLowerCase()) !== -1 : client.parts.toLowerCase().indexOf(value.toLowerCase()) !== -1
    )
  }

  fakeRequest = async (value, type) => {
    let { data } = this.state
    let { data: instruments } = await getInstruments()
    let objectsInstrument = []
    let partsInstrument = []
    for (let inst of instruments) {
      objectsInstrument.push({ _id: new ObjectID(), object: inst.description })
      partsInstrument.push({ _id: new ObjectID(), parts: inst.parts })
    }

    objectsInstrument = objectsInstrument.filter((thing, index, self) =>
        index === self.findIndex((t) => (
          t.object === thing.object
        ))
    )
    partsInstrument = partsInstrument.filter((thing, index, self) =>
        index === self.findIndex((t) => (
          t.parts === thing.parts
        ))
    )
    if (value) {
      if (type === 'object') {
        objectsInstrument = objectsInstrument.filter(client => this.matchStateToTerm(client, value, type))
      } else {
        partsInstrument = partsInstrument.filter(client => this.matchStateToTerm(client, value, type))
      }
    }

    this.setState({ partsInstrument, objectsInstrument, data })
  }

  handleNumberChange = ({ currentTarget: input }) => {
    const { instruments, data } = this.state
    let minDate = moment()
    let maxDate = ''

    instruments.forEach(function (instrument, index) {
      if (Number(input.value) === instrument.number) {
        minDate = moment(instruments[index].date)
        maxDate = moment(instruments[index - 1] ? instruments[index - 1].date : instruments[index].date)
        data.date = moment(instruments[index - 1] ? instruments[index - 1].date : instruments[index].date)
      }

      if (Number(input.value) > instrument.number && Number(input.value) < (instruments[index - 1] ? instruments[index - 1].number : instruments[0].number)) {
        minDate = moment((instruments[index] ? instruments[index].date : instruments[instruments.length - 1].date))
        maxDate = moment((instruments[index - 1] ? instruments[index - 1].date : instruments[0].date))
        data.date = moment(instruments[index].date)
      }

      if (Number(input.value) > instruments[0].number && index === 0) {
        minDate = moment((instruments[0] ? instruments[0].date : instruments[instruments.length - 1].date))
        maxDate = ''
      }
    })

    data[input.name] = input.value
    this.setState({ data, minDate, maxDate })
  }

  handleCheck = (e, type) => {
    const { data } = this.state
    if (data[type] && data[type].active) data[type].active = e.target.checked

    this.setState({ data })
  }

  handleSre = async (sre) => {
    try {
      const data = { ...this.state.data }
      data.sre = sre
      // const editData = fees.findIndex(m => m._id === fee._id);

      // if(editData < 0){
      //   fees.push(fee);
      // } else {
      //   fees[editData] = fee;
      // }
      //console.log(data);
      this.setState({ data })
    } catch (ex) {
    }
  }

  handlePld = async (pld) => {
    try {
      const data = { ...this.state.data }
      data.pld = pld
      // const editData = fees.findIndex(m => m._id === fee._id);

      // if(editData < 0){
      //   fees.push(fee);
      // } else {
      //   fees[editData] = fee;
      // }
      //console.log(data);
      this.setState({ data })
    } catch (ex) {
    }
  }

  handleDeclaranot = async (declaranot) => {
    try {
      const data = { ...this.state.data }
      console.log('declaranot', declaranot)
      data.declaranot = declaranot
      this.setState({ data })
    } catch (ex) {
      toast.error("Error al activar declaranot")
    }
  }

  handleRnie = async (rnie) => {
    try {
      const data = { ...this.state.data }
      const rnieArray = data.rnie
      const editData = rnieArray.findIndex(m => m._id === rnie._id)

      if (editData < 0) {
        rnieArray.push(rnie)
      } else {
        rnieArray[editData] = rnie
      }

      // const editData = fees.findIndex(m => m._id === fee._id);

      // if(editData < 0){
      //   fees.push(fee);
      // } else {
      //   fees[editData] = fee;
      // }
      this.setState({ data })
    } catch (ex) {
    }
  }

  handleRpc = async (rpc) => {
    try {
      const data = { ...this.state.data }
      data.rpc = rpc
      // const editData = fees.findIndex(m => m._id === fee._id);

      // if(editData < 0){
      //   fees.push(fee);
      // } else {
      //   fees[editData] = fee;
      // }
      //console.log(data);
      this.setState({ data })
    } catch (ex) {
    }
  }

  handleBC = async (bc) => {
    try {
      const data = { ...this.state.data }
      data.bc = bc
      this.setState({ data })
    } catch (ex) {
    }
  }

  handleUpload = async (images, edit) => {
    // console.log(images);
    const { data } = this.state
    const { data: file } = await instrumentFilesAdd(data._id, images)
    // console.log(file);
    //this.setState({ files: images, editImages: edit });
    // images.map(async (item, index) => {
    //   const { data } = this.state;
    //   const doc = item.data.replace(/^data:image\/\w+;base64,/, '');
    //   const buffer = Buffer.from(doc, 'base64');
    //   console.log(buffer);
    //   const { data: file } = await instrumentFilesAdd(data._id,buffer);
    //   console.log(file);
    // });
  }

  render () {
    const {
      data,
      types,
      minDate,
      maxDate,
      minInstrument,
      maxInstrument,
      partsInstrument,
      objectsInstrument,
      users,
      readOnly,
      numbersMissing,
      user,
      box,
      files,
      denominations,
      isEditable
    } = this.state
    return (
      <div>
        <form className="mt-4" onSubmit={this.handleSubmit}>
          <div className="form-row">
            <div className="col-md-4 form-label-group mb-3">
              <OverlayTrigger
                placement={'bottom'}
                overlay={<Tooltip id="tooltip-disabled"><span>{'Sugerencia de numero: ' + numbersMissing}</span></Tooltip>}
              >
                <input placeholder="Numero de Instrumento" className="form-control" name="number" type="number"
                       onChange={this.handleNumberChange} value={data.number} min={minInstrument} max={maxInstrument}/>
              </OverlayTrigger>
              <label>Numero de Instrumento</label>
              {/* {this.renderInput("numberInstrument", "Numero de Instrumento", "number")} */}
            </div>
            <div className="col-md-4 form-label-group  mb-3">
              <select name="type" id="type" className="form-control" onChange={this.handleChange}>
                <option value="">Selecciona...</option>
                {types.map(option => (
                  <option key={option._id} selected={(option.name === data.type) ? true : false} value={option.name}>
                    {option.name}
                  </option>
                ))}
              </select>
              <label>Tipo</label>
            </div>
            <div className="col-md-4 mb-3">
              {/* <label>Fecha</label>    */}
              <SingleDatePicker
                date={data.date} // momentPropTypes.momentObj or null
                onDateChange={(date) => this.handleDateChange(date)} // PropTypes.func.isRequired
                focused={this.state.focused} // PropTypes.bool
                onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
                displayFormat="DD/MM/YYYY"
                numberOfMonths={1}
                isOutsideRange={(day) => day.isAfter(moment(maxDate)) || day.isBefore(moment(minDate))}
                placeholder={'Fecha'}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-4 mb-3">
              {this.renderInput('book', 'Libro', 'text', true)}
            </div>
            <div className="col-md-4 mb-3">
              {/* <label>Partes</label>   */}
              < Autocomplete
                inputProps={{ id: 'parts', name: 'parts', className: 'form-control', placeholder: 'Partes' }}
                wrapperStyle={{ position: '', background: '#b8daff' }}
                value={data.parts}
                items={partsInstrument}
                getItemValue={(item) => item.parts}
                onSelect={(value, item) => {
                  data.parts = item.parts
                  // set the menu to only the selected item
                  this.setState({ data })
                  // or you could reset it to a default list again
                  //this.setState({ clients: clients })
                }}
                onChange={(event, value) => {
                  data.parts = value
                  this.setState({ data })
                  this.fakeRequest(value, 'parts')
                }}
                renderMenu={children => (
                  <div className="menu">
                    {children}
                  </div>
                )}
                renderItem={(item, isHighlighted) => (
                  <div
                    className={`item ${isHighlighted ? 'item-highlighted' : ''}`}
                    key={item._id}
                  >{item.parts}</div>
                )}
              />
            </div>
            <div className="col-md-4 mb-3">
              {/* <label>Objeto</label>   */}
              <Autocomplete
                inputProps={{ id: 'object', name: 'description', className: 'form-control', placeholder: 'Objeto' }}
                wrapperStyle={{ position: '', background: '#b8daff' }}
                value={data.description}
                items={objectsInstrument}
                getItemValue={(item) => item.object}
                onSelect={(value, item) => {
                  data.description = item.object
                  // set the menu to only the selected item
                  this.setState({ data })
                  // or you could reset it to a default list again
                  //this.setState({ clients: clients })
                }}
                onChange={(event, value) => {
                  data.description = value
                  this.setState({ data })
                  this.fakeRequest(value, 'object')
                }}
                renderMenu={children => (
                  <div className="menu">
                    {children}
                  </div>
                )}
                renderItem={(item, isHighlighted) => (
                  <div
                    className={`item ${isHighlighted ? 'item-highlighted' : ''}`}
                    key={item._id}
                  >{item.object}</div>
                )}
              />
            </div>
            <div className="col-md-4 mb-3">
              {this.renderSelect('responsibleId', 'Responsable', users)}
            </div>
            <div className="col-md-4 mb-3">
              {this.renderSelect('denominationId', 'Denominación', denominations)}
            </div>
            <div className="col-md-12 mb-3">
              <div className="form-row">
                {user && user.role && user.role.actions.some(a => a.method === 'add-sre') && (
                  <div className="col-md-3">
                    <Sre onCheck={this.handleCheck} onSre={this.handleSre} sre={data.sre} user={user}/>
                  </div>
                )}
                {user && user.role && user.role.actions.some(a => a.method === 'add-pld') && (
                  <div className="col-md-3">
                    <Pld onCheck={this.handleCheck} onPld={this.handlePld} pld={data.pld} user={user}/>
                  </div>
                )}
                {user && user.role && user.role.actions.some(a => a.method === 'add-rnie') && (
                  <div className="col-md-3">
                    <Rnie onCheck={this.handleCheck} onRnie={this.handleRnie} rnie={data.rnie} user={user}
                          date={data.date}/>
                  </div>
                )}
                {user && user.role && user.role.actions.some(a => a.method === 'add-rpc') && (
                  <div className="col-md-3">
                    <Rpc onCheck={this.handleCheck} onRpc={this.handleRpc} rpc={data.rpc} user={user}/>
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-12 mb-3">
              <div className="form-row">
                {user && user.role && user.role.actions.some(a => a.method === 'add-rpc') && (
                  <div className="col-md-3">
                    <input type="checkbox" id="bc" checked={data.isBC} onChange={(e) => {
                      data.isBC = e.target.checked
                      this.setState({ data })
                    }}/>
                    <label className="form-check-label" htmlFor="bc">BC</label>
                  </div>
                )}
                {user && user.role && user.role.actions.some(a => a.method === 'view-declaranot') && (
                  <div className="col-md-3">
                    <Declaranot onCheck={this.handleCheck} onDeclaranot={this.handleDeclaranot} declaranot={data.declaranot} user={user}/>
                  </div>
                )}
              </div>
            </div>
          </div>
          <Calculator element={box} show={false}/>
          {user && user.role && user.role.actions.some(a => a.method === 'testimony-instrument') && (
            <div className="form-row">
              <div className="col-md-12 mb-3 table-pr">
                <Testimonies onTestimony={this.handleTestimony} testimony={data.testimony} user={user} users={users} instrument={data} />
              </div>
            </div>
          )}
          {user && user.role && user.role.actions.some(a => a.method === 'list-sre') && data.sre && data.sre.active && (
            <div className="form-row">
              <div className="col-md-6 mb-6">
                <h5>
                  <span className={data.sre.status === 'presented' ? 'badge badge-success' : 'badge badge-secondary'}>
                    SRE - {data.sre.status === 'presented' ? 'Presentado' : (data.sre.status === 'pending' ? 'Pendiente' : '')}
                  </span>
                </h5>
              </div>
              <div className="col-md-12 mb-3 table-pr">
                <SreTable sre={data.sre} onSre={this.handleSre} user={user}/>
              </div>
            </div>
          )}
          {user && user.role && user.role.actions.some(a => a.method === 'list-pld') && data.pld && data.pld.active && (
            <div className="form-row">
              <div className="col-md-6 mb-6">
                <h5>
                  <span
                    className={data.pld.status === 'presented' ? 'badge badge-success' : (data.pld.status === 'pending' ? 'badge badge-secondary' : 'badge badge-danger')}>
                    PLD - {data.pld.status === 'presented' ? 'Presentado' : (data.pld.status === 'pending' ? 'Pendiente' : (data.pld.status === 'delayed' ? 'No Presentado' : ''))}
                  </span>
                </h5>
              </div>
              <div className="col-md-12 mb-3 table-pr">
                <PldTable pld={data.pld} onPld={this.handlePld} user={user}/>
              </div>
            </div>
          )}
          {user && user.role && user.role.actions.some(a => a.method === 'list-rnie') && data.rnie && data.rnie.length > 0 && (
            <div className="form-row">
              <RnieTable rnie={data.rnie} onRnie={this.handleRnie} user={user} date={data.date}/>
            </div>
          )}
          {user && user.role && user.role.actions.some(a => a.method === 'list-rpc') && data.rpc && data.rpc.active && (
            <div className="form-row">
              <div className="col-md-6 mb-6">
                <h5>
                  <span
                    className={data.rpc.status === 'inscribed' ? 'badge badge-success' : (data.rpc.status === 'pending' ? 'badge badge-secondary' : 'badge badge-danger')}>
                    RPC - {data.rpc.status === 'inscribed' ? 'Inscrito' : (data.rpc.status === 'pending' ? 'En tramite' : (data.rpc.status === 'delayed' ? 'No Tramitado' : ''))}
                  </span>
                </h5>
              </div>
              <div className="col-md-12 mb-3 table-pr">
                <RpcTable rpc={data.rpc} onRpc={this.handleRpc} user={user}/>
              </div>
            </div>
          )}
          {user && user.role && user.role.actions.some(a => a.method === 'list-pld') && data.declaranot && data.declaranot.active && (
            <div className="form-row">
              <div className="col-md-6 mb-6">
                <h5>
                  <span>
                    Declaranot
                  </span>
                </h5>
              </div>
              <div className="col-md-12 mb-3 table-pr">
                <DeclaranotTable declaranot={data.declaranot} onDeclaranot={this.handleDeclaranot} user={user}/>
              </div>
            </div>
          )}
          <div className="form-row">
            <div className="col-md-12 mb-6">
              <h5>Documentos</h5>
              <div>
                {files && files.length > 0 && (
                  <div>
                    {
                      files.map(file => (
                        <div><a
                          href={file.LinkingUrl || (`https://pbpabogados.sharepoint.com${file.ServerRelativeUrl}`)}
                          target="_blank">{file.Name}</a></div>
                      ))
                    }
                  </div>
                )}
              </div>
            </div>
          </div>
          {
            user && user.role && user.role.actions.some(a => a.method === 'save-instrument') && isEditable && (
              <button className="btn btn-primary float-right" style={{ marginTop: 15 }} type="submit">Guardar</button>
            )
          }
        </form>
      </div>
    )
  }
}

export default InstrumentForm
