import http from "./httpService";

const apiEndpoint = process.env.REACT_APP_API_URL + "/roles";

export function roleUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getRoles() {
  return http.get(apiEndpoint);
}

export function getRole(roleId) {
  return http.get(roleUrl(roleId));
}

export function saveRole(role) {
  if (role._id) {
    const body = { ...role };
    delete body._id;
    return http.put(roleUrl(role._id), body);
  }

  return http.post(apiEndpoint, role);
}

export function deleteRole(roleId) {
  return http.delete(roleUrl(roleId));
}
