import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import { getRole, saveRole } from "../../services/roleService";
import { getActions } from "../../services/actionService";

class RoleForm extends Form {
  state = {
    data: {
      name: "",
      actions: []
    },
    methods: [],
    errors: {}
  };

  schema = {
    _id: Joi.string(),
    name: Joi.string()
      .required()
      .label("Nombre"),
    actions: Joi.array()
      .required()
      .label("Acciones")
  };

  async populateRole() {
    try {
      const roleId = this.props.match.params.id;
      if (roleId === "new") return;

      const { data: role } = await getRole(roleId);
      this.setState({ data: this.mapToViewModel(role) });
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  }

  async populateActions() {
        const { data: methods } = await getActions();
        this.setState({ methods });
  }

  async componentDidMount() {
    await this.populateRole();
    await this.populateActions();
  }

  mapToViewModel(role) {
    return {
      _id: role._id,
      name: role.name,
      actions: role.actions
    };
  }

  doSubmit = async () => {
    await saveRole(this.state.data);

    this.props.history.push("/roles");
  };

  handleCheck = (e,action) => {
    const { data } = this.state;

    console.log(e, action)
    if(e.target.checked)
      data.actions.push(action);
    else
      data.actions = data.actions.filter(item => item._id !== action._id)

    this.setState({ data });
  }

  render() {
    const { data, methods } = this.state;

    return (
      <div>
        <form className="mt-4" onSubmit={this.handleSubmit}>
            <div className="form-row">
                <div className="col-md-4 mb-3">
                {this.renderInput("name", "Nombre del Rol")}
                </div>
            </div>
            { methods.map(m => {
                const actions = m.actions.map(a => (
                  <div key={a._id} className="col-md-2">
                    <input type="checkbox" onClick={(e) => this.handleCheck(e,a)} checked={data.actions.some(act => act.method === a.method)} />
                    <label className="form-check-label" htmlFor={a.method}>{a.name}</label>
                  </div>
                ))
                return <div className="form-row mb-4"><div className="col-md-12"><h3>{m._id}</h3></div>{actions}</div>
              }
            )}
          <button className="btn btn-primary float-right" type="submit">Guardar</button>
        </form>
      </div>
    );
  }
}

export default RoleForm;
