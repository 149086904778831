import React, { Component } from 'react'
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import InstrumentsTable from '../instruments/instrumentsTable'
import { getConfigurations } from '../../services/configurationService'
import { getMetricsInstruments } from '../../services/metricService'
import { getInstruments, instrumentArchivedUpdate, instrumentDigitalUpdate } from '../../services/instrumentService'
import { getCurrentUser } from '../../services/authService'

class InstrumentsAssigned extends Component {
  state = {
    metricsInstruments: [],
    instruments: [],
    sortColumn: { path: 'number', order: 'asc' },
    penalty: 0,
    numbersInstrument: [],
    numbersMissing: [],
    isFilterActive: false,
    isLoading: true,
  }

  async populateUser() {
    const {data: user } = await getCurrentUser();
    if(user) this.setState({ user });
  }

  async populateConfig () {
    const { data: configurations } = await getConfigurations()
    const { data: instruments } = await getInstruments()

    let { numbersInstrument, numbersMissing } = this.state
    let [firstInstrument] = configurations.filter(config => config.number === 4)
    let [lastInstrument] = configurations.filter(config => config.number === 8)
    const minInstrument = Number(firstInstrument.value)
    const maxInstrument = Number(lastInstrument.value)

    for (let instrument of instruments) {
      numbersInstrument.push(instrument.number)
    }

    for (let i = minInstrument; i <= maxInstrument; i++) {
      if (!numbersInstrument.includes(i))
        numbersMissing.push(i)
    }
    this.setState({ numbersMissing, isLoading: false })
  }

  async populateMetricsInstruments () {
    const filter = { archived: false }
    const { data: metricsInstruments } = await getMetricsInstruments(filter)
    this.setState({ metricsInstruments })
  }

  async populateInstruments (name) {
    const filter = {
      name: name,
      type: 'pendingArchive'
    }
    const { data: instruments } = await getInstruments(filter)
    this.setState({ instruments: instruments })
    this.setState({ isFilterActive: !!filter.name })
  }

  async componentDidMount () {
    await this.populateUser()
    await this.populateConfig()
    await this.populateMetricsInstruments()
    await this.populateInstruments()
  }

  onLoadInfo = async (data, index) => {
    const name = data && data.name ? (data.name) : ''
    await this.populateInstruments(name)
  }

  handleArchive = async updateInstrument => {
    const originalInstruments = this.state.instruments
    const instruments = [...originalInstruments] // Required to update state
    const index = instruments.indexOf(updateInstrument)
    instruments[index] = { ...instruments[index] }
    instruments[index].archived = !updateInstrument.archived
    this.setState({ instruments })
    try {
      await instrumentArchivedUpdate(updateInstrument._id, !updateInstrument.archived)
    } catch (ex) {
      if (ex.response && ex.response.status === 400)
        //console.log(originalInstruments);
        //toast.error("This movie has already been deleted.");
        this.setState({ instruments: originalInstruments })
    }
  }

  handleDigital = async (e, instrument) => {
    e.preventDefault()
    const originalInstruments = this.state.instruments
    const instruments = [...originalInstruments] // Required to update state
    const index = instruments.findIndex(i => i._id === instrument._id)
    instruments[index] = { ...instruments[index] }
    instruments[index].isDigital = !instrument.isDigital
    this.setState({ instruments })
    try {
      await instrumentDigitalUpdate(instrument._id, !instrument.isDigital)
    } catch (ex) {
      if (ex.response && ex.response.status === 400)
        //console.log(originalInstruments);
        //toast.error("This movie has already been deleted.");
        this.setState({ instruments: originalInstruments })
    }
  }

  handleSort = sortColumn => {
  }

  render () {
    const { metricsInstruments, instruments, sortColumn, numbersMissing, isFilterActive, user, isLoading } = this.state

    return (
      <>
        <div className="card-deck mb-3 text-center">
          <div className="card mb-4 shadow-sm">
            <div className="card-header">
              <h4 className="my-0" style={{ fontWeight: 400, fontSize: '0.9rem' }}>Instrumentos Pendientes de Cargar
                - <b>{numbersMissing.length}</b></h4>
              <h4 className="my-0" style={{ fontWeight: 400, fontSize: '0.9rem' }}>Instrumentos Pendientes de Archivo
                - <b>{metricsInstruments.reduce((prev, cur) => prev + cur.total, 0)}</b></h4>
              {isFilterActive && (<button
                onClick={() => this.onLoadInfo()}
                className="btn btn-success btn-sm float-left"
              >
                <i className="fa fa-eye"/><span> </span>
                Ver Todos
              </button>)}
            </div>
            <div style={{ width: '100%', height: 300 }}>
              <ResponsiveContainer>
                <BarChart
                  layout="vertical"
                  data={metricsInstruments}
                  margin={{ left: 50, right: 50 }}
                >
                  <CartesianGrid strokeDasharray="6 6"/>
                  <XAxis type="number"/>
                  <YAxis dataKey="name" type="category" tick={{ fontSize: 10, width: 150 }}/>
                  <Tooltip/>
                  {/* <Legend /> */}
                  <Bar dataKey="total" barSize={20} fill="#86cfda"
                       onClick={(data, index) => this.onLoadInfo(data, index)}/>
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
        <InstrumentsTable
          instruments={instruments}
          isPending={true}
          handleArchive={this.handleArchive}
          handleDigital={this.handleDigital}
          user={user}
          isLoading={isLoading}
        />
      </>
    )
  }
}

export default InstrumentsAssigned
