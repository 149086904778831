import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { getCurrentUser } from '../../services/authService'
import { getInstruments, saveInstrument } from '../../services/instrumentService'
import Table from '../common/materia-reac-table/table'

class RnieTable extends Component {
  state = {
    rnie: [],
    isLoading: true
  }

  columns = [
    {
      accessorKey: 'number',
      header: 'No. Instrumento',
      Cell: ({ row }) => <Link to={`/instruments/${row.original.instrument._id}`}>{String(row.original.number)}</Link>,
      size: 130
    },
    {
      accessorKey: 'date',
      header: 'Fecha instrumento',
      Cell: ({ cell }) => moment(cell.getValue()).format('DD/MM/YYYY'),
      size: 180
    },
    {
      accessorKey: 'applicant',
      header: 'Solicitante',
      size: 300
    },
    {
      accessorKey: 'representative',
      header: 'Representante',
      size: 300
    },
    {
      accessorKey: 'noticeDate',
      Cell: ({ cell }) => cell.getValue() ? moment(cell.getValue()).format('DD/MM/YYYY') : '',
      header: 'Fecha de presentación',
      size: 180
    }
  ]

  async populateUser () {
    const { data: user } = await getCurrentUser()
    this.setState({ user })
  }

    async componentDidMount() {
        await this.populateUser();
        const { data: instruments } = await getInstruments({type: 'rnie'});
        let array = []
        instruments.map(i => {
            i.rnie && i.rnie.length > 0 && i.rnie.map(r => {
                let obj = {};
                obj._id = r._id;
                obj.number = i.number
                obj.date = i.date
                obj.applicant = r.applicant ? r.applicant : "";
                obj.representative = r.representative ? r.representative : "";
                obj.noticeDate = r.noticeDate;
                obj.status = r.status;
                obj.active = r.active;
                obj.instrument = i;
                array.push(obj);
                return array;
            });
            return array;
        });
        this.setState({ rnie: array, isLoading: false });
    }

    handleDelete = async (rnie) => {
        const originalRnie = this.state.rnie;
        const updateRnie = originalRnie.filter(m => m !== rnie);
        this.setState({ rnie: updateRnie });

    try {
      rnie.instrument.rnie = rnie.instrument.rnie.filter(r => r._id !== rnie._id)
      delete rnie.instrument.responsible
      delete rnie.instrument.name
      await saveInstrument(rnie.instrument)
    } catch (ex) {
      // if (ex.response && ex.response.status === 404)
      // toast.error("This client has already been deleted.");

      this.setState({ rnie: originalRnie })
    }
  }

    getPagedData = () => {
        const {
          rnie: allRnie,
        } = this.state;

        let filtered = allRnie;
        filtered = allRnie.filter(m =>
            m.active === true &&
            ((this.props.match.params.status === 'pending' && (m.status === this.props.match.params.status || m.status === 'delayed')) ||
            (m.status === this.props.match.params.status))
        );

        return { data: filtered };
    };

  deleteRowAction = ({ row }) => <button
    onClick={(e) => window.confirm('Esta seguro que desea eliminar el RNIE?') && this.handleDelete(row.original)}
    className="btn btn-danger btn-sm">
    Eliminar
  </button>

  render () {
    const { isLoading, user } = this.state
    const { data: rnie } = this.getPagedData()

    return (user && this.columns ?
        <Table
          columns={this.columns}
          data={rnie}
          rowAction={(user && user.role && user.role.actions.some(a => a.method === 'delete-rnie')) ? this.deleteRowAction : null}
          isLoading={isLoading}
        /> : <></>
    )
  }
}

export default RnieTable
