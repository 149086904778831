import React from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import esLocale from '@fullcalendar/core/locales/es'

export default function DashboardCalendar ({ events }) {
  return (
    <div className="dashboard-calendar">
      <div className="calendar-main">
        <FullCalendar
          plugins={[dayGridPlugin]}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: false
          }}
          initialView="dayGridDay"
          editable={false}
          disableDragging={true}
          selectable={true}
          selectMirror={true}
          dayMaxEvents={true}
          weekends={false}
          events={events}
          locale={esLocale}
          timeZone={'America/Mexico_City'}
          eventContent={renderEventContent}
        />
      </div>
    </div>
  )
}

function renderEventContent (eventInfo) {
  return (
    <>
      <b>{eventInfo.timeText}</b>
      <i>{eventInfo.event.title}</i>
    </>
  )
}

