import http from "./httpService";

const apiEndpoint = process.env.REACT_APP_API_URL + "/metrics";

export function userUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getMetrics(user,filter) {
  return http.get(apiEndpoint+`?user=${user}`,{ params: filter });
}

export function getMetricsArchive(user) {
  return http.get(apiEndpoint+`/archives?user=${user}`);
}

export function getMetricsLawyer(filter) {
  return http.get(apiEndpoint+`/lawyer`,{ params: filter });
}

export function getMetricsClients(filter) {
  return http.get(apiEndpoint+`/clients`,{ params: filter });
}

export function getMetricsMonthly(user) {
  return http.get(apiEndpoint+`/monthly`);
}

export function getMetricsBoxes(filter) {
  return http.get(apiEndpoint+`/boxes`,{ params: filter });
}

export function getMetricsInstruments(filter) {
  return http.get(apiEndpoint+`/instruments`,{ params: filter });
}

export function getMetricsInstrumentsPendingInvoice(filter) {
  return http.get(apiEndpoint+`/instruments/pending-invoice`,{ params: filter });
}

export function getMetricsInstrumentsCount(filter) {
  return http.get(apiEndpoint+`/instruments-count`,{ params: filter });
}
export function getMetricsProvidersPayment(filter) {
  return http.get(apiEndpoint+`/providers-payment`, { params: filter });
}

export function getMetricsOriginators(filter) {
  return http.get(apiEndpoint+`/originators`,{ params: filter });
}

export function getReceivables(filter) {
  return http.get(apiEndpoint+`/receivables`,{ params: filter });
}

export function getReceivablesClients(filter) {
  return http.get(apiEndpoint+`/receivablesClients`,{ params: filter });
}
