import React, { Component } from 'react'
import Check from '../common/check'
import Table from '../common/materia-reac-table/table'
import { Link } from 'react-router-dom'
import moment from 'moment/moment'

class MediationsTable extends Component {
  columns = [
    {
      accessorKey: 'number',
      Cell: ({ row }) => <Link
        to={this.props.user && this.props.user.role && this.props.user.role.actions.some(a => a.method === 'edit-mediation') ? `/mediations/${row.original._id}` : '#'}>{row.original.number}</Link>,
      header: 'No. Convenio',
      size: 110
    },
    {
      accessorKey: 'type',
      header: 'Clave de convenio',
      size: 150
    },
    {
      accessorKey: 'date',
      Cell: ({ cell }) => cell.getValue() ? moment(cell.getValue()).format('DD/MM/YYYY') : '-',
      header: 'Fecha',
      size: 105
    },
    {
      accessorKey: 'mediations',
      header: 'Mediados',
      size: 250
    },
    {
      accessorKey: 'description',
      header: 'Descripción',
      size: 220
    },
    {
      accessorKey: 'datePresentation',
      Cell: ({ cell }) => cell.getValue() ? moment(cell.getValue()).format('DD/MM/YYYY') : '-',
      header: 'Fecha de presentación a registro',
      size: 150
    },
    {
      accessorKey: 'dateRegister',
      Cell: ({ cell }) => cell.getValue() ? moment(cell.getValue()).format('DD/MM/YYYY') : '-',
      header: 'Fecha de registro',
      size: 150
    },
    {
      accessorKey: 'archived',
      Cell: ({ row }) => <Check archived={row.original.archived}
                                onClick={() => this.props.handleArchive(row.original)}></Check>,
      header: 'Archivado',
      size: 120,
    },
  ]

  render () {
    const { mediations, user } = this.props

    return (this.columns && user ?
        <Table
          columns={this.columns}
          data={mediations}
          user={user}
          initialState={{
            columnVisibility: {
              archived: (user && user.role && user.role.actions.some(a => a.method === 'archive-mediation')),
            }
          }}
          isLoading={this.props.isLoading}
        /> : <></>
    )
  }
}

export default MediationsTable
