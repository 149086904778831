import React from "react";
import { MDBDataTable } from 'mdbreact';
import "mdbreact/dist/scss/addons/_datatables.scss";
import { Box, Button } from '@mui/material'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import moment from 'moment'

const Table = ({ columns, data }) => {
  const dataTable = {
    columns: columns,
    rows: data
  }

  const handleExportRows = (rows) => {
    const fileName = 'PLD_Filtrado'.concat(moment().format('DD-MM-YYYY')).concat('.xls');
    const fileType = 'application/vnd.ms-excel';
    const table = document.createElement('table');
    table.innerHTML = '<thead><tr><th>No. Instrumento</th><th>Fecha instrumento</th><th>Tipo de aviso</th><th>Fecha de aviso</th><th>No. de referencia</th><th>Archivado</th></tr></thead>';
    const tbody = document.createElement('tbody');
    rows.forEach((instrument) => {
      const row = document.createElement('tr');
      row.innerHTML = `<td>${instrument.original.number}</td><td>${moment(instrument.original.date).format('YYYY MM DD')}</td><td>${instrument.original.pld.type}</td><td>${moment(instrument.original.pld.noticeDate).format('YYYY MM DD')}</td><td>${instrument.original.pld.reference}</td><td>${instrument.original.pld.archived ? 'Si' : 'No'}</td>`;
      tbody.appendChild(row);
    });
    table.appendChild(tbody);
    document.body.appendChild(table);
    table.style.display = 'none';
    const blob = new Blob([table.outerHTML], {
      type: fileType
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <MDBDataTable
      displayEntries={false}
      entries={50}
      data={dataTable}
      scrollX={true}
      sortRows={['nameCff', 'name', 'numberBox', 'number', 'archived', 'review', 'paymentDate', 'date', 'datePresentation', 'dateRegister', 'expired']}
      searchLabel="Buscar"
      renderTopToolbarCustomActions={({ table }) => (
        <Box
          sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
        >
          <Button
            color="primary"
            disabled={table.getPrePaginationRowModel().rows.length === 0}
            onClick={() =>
              handleExportRows(table.getPrePaginationRowModel().rows)
            }
            startIcon={<FileDownloadIcon />}
            variant="contained"
          >
            Exportar
          </Button>
        </Box>
      )}
      noRecordsFoundLabel="No se encontraron datos"
      paginationLabel={["Ant", "Sig"]}
      infoLabel={['', 'al', 'de', 'datos']}
      pagesAmount={5}
      maxHeight='500px'
      sortable={true}
      bordered
      hover
      striped
    />
  );
};

export default Table;
