import React, { Component } from "react";
import { Modal } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { ObjectID } from 'bson';
import Moment from 'react-moment';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';

class PldTable extends Component {
    state = {
        data: {
            _id: new ObjectID(),
            active: this.props.pld.active,
            noticeDate: this.props.pld.noticeDate ? moment(this.props.pld.noticeDate) : moment(),
            reference: this.props.pld.reference,
            archived: this.props.pld.archived,
            type: this.props.pld.type
        },
        types: ["Constitutiva", "Fusión", "Fideicomiso", "Crédito", "Otro"],
        show: false
    }

    showModal = () => {
        this.setState({ show: true });
    };

    hideModal = () => {
        this.setState({ show: false });
    };

    handleChange = ({ currentTarget: input }) => {
        const data = { ...this.state.data };
        data[input.name] = input.value;
        if (input.name === "archived" && input.checked) {
            data['archived'] = true;
        } else {
            data['archived'] = false;
        }
        this.setState({ data });
    };

    handleDateChange = date => {
        const { data } = this.state;
        data.noticeDate = date;
        this.setState({ data })
    };

    handleCheck = (e, type) => {
        const { data } = this.state;
        if (e.target.checked) {
            data.active = true;
            this.showModal();
        } else {
            data.active = false;
        }
        this.props.onCheck(e, type);
        this.setState({ data });
    }

    handleSubmit = e => {
        e.preventDefault();
        const { data } = this.state;
        data.status = 'presented';
        // let editData = this.props.fees.filter(m => m._id === data._id);

        // if(editData.length < 1)
        //     data._id = new ObjectID();

        this.setState({ data: data, show: false });
        this.props.onPld(data);
    };

    render() {
        const { pld, user } = this.props;
        const { data, types } = this.state;

        return (
            <>
                <div className="form-row form-row">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">Tipo de aviso</th>
                                <th scope="col">Fecha de aviso</th>
                                <th scope="col">Referencia</th>
                                <th scope="col">Archivado</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{pld.status !== 'presented' ? <Link to="#" onClick={() => user && user.role && user.role.actions.some(a => a.method === "edit-pld") && this.showModal(pld)}>{pld.type}</Link> : pld.type}</td>
                                <td>{pld.noticeDate ? (<Moment format="DD/MM/YYYY">{pld.noticeDate}</Moment>) : ""}</td>
                                <td>{pld.reference}</td>
                                <td><i
                                    className={pld.archived ? "fas fa-folder" : "far fa-folder"}
                                    aria-hidden="true"
                                />{pld.archived}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <Modal show={this.state.show} onHide={this.hideModal}>
                    <form>
                        <Modal.Header closeButton>
                            <Modal.Title>Completa el formulario</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="form-row">
                                <div className="col-md-12 form-label-group mb-3">
                                    <select name="type" id="type" className="form-control" onChange={this.handleChange} >
                                        <option value="">Selecciona...</option>
                                        {types.map(type => (
                                            <option key={type} selected={(type === pld.type) ? true : false} value={type}>
                                                {type}
                                            </option>
                                        ))}
                                    </select>
                                    <label>Tipo de aviso</label>
                                </div>
                                <div className="col-md-12 mb-3">
                                    <SingleDatePicker
                                        date={data.noticeDate} // momentPropTypes.momentObj or null
                                        onDateChange={(date) => this.handleDateChange(date)} // PropTypes.func.isRequired
                                        focused={this.state.focused} // PropTypes.bool
                                        onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
                                        displayFormat="DD/MM/YYYY"
                                        numberOfMonths={1}
                                        isOutsideRange={() => false}
                                        placeholder={"Fecha de aviso"}
                                        id="noticeDate"
                                    />
                                </div>
                                <div className="col-md-12 form-label-group mb-3">
                                    <input placeholder="No. de referencia" className="form-control" name="reference" onChange={this.handleChange} value={data.reference} required />
                                    <label>No. de referencia</label>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="col-md-12 form-check">
                                    <input type="checkbox" name="archived" className="form-check-input" onChange={this.handleChange} checked={data.archived} />
                                    <label className="form-check-label">Archivado</label>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            {/* { user && user.role && user.role === 'Gestor' && ( */}
                            {user && user.role && user.role.actions.some(a => a.method === "send-pld") && (
                                <button className="btn btn-primary" onClick={this.handleSubmit}>Enviar a presentados</button>
                            )}
                        </Modal.Footer>
                    </form>
                </Modal>
            </>
        );
    }
}

export default PldTable;
