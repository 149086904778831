import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import InstrumentsTable from './instrumentsTable'
import { getCurrentUser } from '../../services/authService'
import { deleteInstrument, getInstruments, instrumentArchivedUpdate } from '../../services/instrumentService'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'

class Instruments extends Component {
  state = {
    instruments: [],
    currentPage: 1,
    pageSize: 100000,
    searchQuery: '',
    sortColumn: { path: 'number', order: 'asc' },
    startDate: null,
    endDate: null,
    focusedInput: null,
    isLoading: true
  }

  async populateUser () {
    const { data: user } = await getCurrentUser()
    if (user) this.setState({ user })
  }

  async populateInstruments () {
    const { user } = this.state
    const { data: instruments } = user && user.role && user.role.actions.some(a => a.method === 'all-instrument') ? await getInstruments() : []
    this.setState({ instruments: instruments, isLoading: false })
  }

  async componentDidMount () {
    await this.populateUser()
    await this.populateInstruments()
  }

  handleDelete = async instrument => {
    const originalInstruments = this.state.instruments
    const instruments = originalInstruments.filter(m => m !== instrument)
    this.setState({ instruments })

    try {
      await deleteInstrument(instrument._id)
    } catch (ex) {
      this.setState({ instruments: originalInstruments })
    }
  }

  handleArchive = async updateInstrument => {
    const originalInstruments = this.state.instruments
    const instruments = [...originalInstruments] // Required to update state
    const index = instruments.indexOf(updateInstrument)
    instruments[index] = { ...instruments[index] }
    instruments[index].archived = !updateInstrument.archived
    this.setState({ instruments })
    try {
      await instrumentArchivedUpdate(updateInstrument._id, !updateInstrument.archived)
    } catch (ex) {
      if (ex.response && ex.response.status === 400)
        //console.log(originalInstruments);
        //toast.error("This movie has already been deleted.");
        this.setState({ instruments: originalInstruments })
    }
  }

  render () {
    const { instruments, user, isLoading } = this.state

    return (
      <div>
        <div className="form-row mt-3 mb-3">
          <div className="col-lg-7">
            <div className="float-left">
              {user && user.role && user.role.actions.some(a => a.method === 'add-instrument') ? (
                //{user && (user.role === "Abogado" || user.role === "Administrador") ? (
                <Link
                  to="/instruments/new"
                  className="btn btn-primary float-right"
                >
                  <i className="fa fa-plus" aria-hidden="true"/><span> </span>
                  Nuevo Instrumento
                </Link>
              ) : ''}
            </div>
          </div>
        </div>
        <InstrumentsTable
          instruments={instruments}
          isPending={false}
          handleArchive={this.handleArchive}
          handleDelete={this.handleDelete}
          user={user}
          isLoading={isLoading}
        />
      </div>
    )
  }
}

export default Instruments
