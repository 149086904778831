import React, { Component } from "react";
import { Link } from "react-router-dom";
import Table from "./common/table";
import moment  from 'moment';
import { getCurrentUser } from "../services/authService";
import { getInstruments, saveInstrument } from '../services/instrumentService';

class SreTable extends Component {
    state = {
        sre: []
    }

    columns = [
        { 
            field: "number", 
            label: "No. Instrumento",
            width: 170
        },   
        { field: "date", label: "Fecha instrumento", width: 180 }, 
        { field: "corporateName", label: "Nombre de la sociedad", width: 300}, 
        { field: "noticeDate", label: "Fecha de presentación", width: 180 }
    ];
    
    deleteColumn = {
        key: "delete",
        field: "delete",
        label: "Acciones",
        width: 130
    };

    async populateUser() { 
        const { data: user } = await getCurrentUser();    
        if(user && user.role && user.role.actions.some(a => a.method === "delete-sre")) this.columns.push(this.deleteColumn);
    
        const columns = this.columns;
        this.setState({ columns }); 
    }

    async componentDidMount() { 
        await this.populateUser();
        const { data: instruments } = await getInstruments({type: 'sre'}); 
        let sreArray = [];
        instruments.map(i => {
            let sre = {};
            sre.number = <Link to={`/instruments/${i._id}`} searchvalue={i.number} className={i.sre && i.sre.status === "delayed" ? "badge badge-danger": ""}>{String(i.number)}</Link>
            sre.date = moment(i.date).format("DD/MM/YYYY");
            sre.corporateName = i.sre && i.sre.corporateName ? i.sre.corporateName : "";
            sre.noticeDate = i.sre && i.sre.noticeDate ? moment(i.sre.noticeDate).format("DD/MM/YYYY") : "";            
            sre.active = i.sre && i.sre.active ? i.sre.active : "";
            sre.status = i.sre && i.sre.status ? i.sre.status : "";
            sre.delete= <button onClick={(e) => window.confirm("Esta seguro que desea eliminar el SRE?") && this.handleDelete(sre,i)} className="btn btn-danger btn-sm">Eliminar</button>;
            sreArray.push(sre);
            return i;
        });    
        this.setState({ sre: sreArray });
    }

    handleDelete = async (obj,instrument) => {  
        const originalSre = this.state.sre;
        const updateSre = originalSre.filter(p => p !== obj);
        this.setState({ sre: updateSre });

        try {
            instrument.sre = {};            
            delete instrument.responsible;
            delete instrument.name;
            await saveInstrument(instrument);
        } catch (ex) {
            // if (ex.response && ex.response.status === 404)
            // toast.error("This client has already been deleted.");

            this.setState({ sre: originalSre });
        }
    };

    getPagedData = () => {
        const {
          sre: allSre,
        } = this.state;
    
        let filtered = allSre;        
        filtered = allSre.filter(m =>
            m.active === true &&
            ((this.props.match.params.status === 'pending' && (m.status === this.props.match.params.status || m.status === 'delayed')) ||
            (m.status === this.props.match.params.status))
            
        );                
        return { data: filtered };
    };

    render() {
        const { data: sre } = this.getPagedData();

        return (
            <Table
            columns={this.columns}
            data={sre}
        />
        );
    }
}

export default SreTable;
