import React, { Component } from 'react';
import { Link } from "react-router-dom";
import OriginatorsTable from "./originatorsTable";
import { getOriginators } from "../services/originatorService";
import { paginate } from "../utils/paginate";
import _ from "lodash";

class Originators extends Component {
    state = { 
        originators: [],
        currentPage: 1,
        pageSize: 20,
        sortColumn: { path: "name", order: "asc" }
     }

     async componentDidMount() {            
        const { data: originators } = await getOriginators(); 
        
        const originatorsUpdate = originators.map(o => {
            o.name = <Link to={`/originators/${o._id}`}>{o.name}</Link>     
            return o;
        });
        this.setState({ originators: originatorsUpdate });
    }

     handlePageChange = page => {
        this.setState({ currentPage: page });
      };

     handleSearch = query => {
        this.setState({ searchQuery: query, currentPage: 1 });
     };

     getPagedData = () => {
        const {
          pageSize,
          currentPage,
          sortColumn,
          searchQuery,
          originators: allOriginators
        } = this.state;
    
        let filtered = allOriginators;
        if (searchQuery)
            filtered = allOriginators.filter(m =>
                m.name.toLowerCase().startsWith(searchQuery.toLowerCase()) ||
                m.abbreviation.toLowerCase().startsWith(searchQuery.toLowerCase()) ||
                m.percentage.toString().toLowerCase().startsWith(searchQuery.toLowerCase())
            );
        
        const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
    
        const originators = paginate(sorted, currentPage, pageSize);
    
        return { totalCount: filtered.length, data: originators };
      };

      handleSort = sortColumn => {
        this.setState({ sortColumn });
      };

    render() { 
        //const { length: count } = this.state.originators;
        const { sortColumn } = this.state;
        const { user } = this.props;

        //if (count === 0) return <p>There are no instrumentos in database.</p>;

        const { data: originators } = this.getPagedData();
        return (
            <div>
                <div className="form-row mt-3 mb-3">
                    <div className="col-lg-10">    
                        <div className="float-left">
                            {user && (
                                <Link
                                    to="/originators/new"
                                    className="btn btn-primary float-right"                                
                                    >
                                    <i className="fa fa-plus" aria-hidden="true" /><span> </span>
                                    Nuevo Originador
                                </Link>
                            )}                            
                        </div>
                    </div>                    
                    {/* <div className="col-lg-2">
                        <SearchBox value={searchQuery} onChange={this.handleSearch} />
                    </div> */}
                </div>
                <OriginatorsTable
                    originators={originators}
                    sortColumn={sortColumn}
                    // onDelete={this.handleDelete}
                    onSort={this.handleSort}
                />
                {/* <Pagination
                    itemsCount={totalCount}
                    pageSize={pageSize}
                    currentPage={currentPage}
                    onPageChange={this.handlePageChange}
                /> */}
            </div>
        );
    }
}
 
export default Originators;