import React from 'react'
import { Link } from 'react-router-dom'
import { download } from '../../utils/s3'
import { UploadFileStatus } from '@progress/kendo-react-upload'

export default function CustomFileList ({ files, onRemove, bucket }) {
  return (
    <>
      {files && files.map((file) => (
        <div className="k-file-single" key={file.uid}>
          <div className="k-progressbar" style={{ transition: 'opacity 1s ease-in-out 0s', opacity: 1 }}>
            <span className="k-progress" style={{ width: '0%' }}></span>
          </div>
          <span className="k-file-group-wrapper">
                            <span className={'k-file-group k-icon ' + getIcon(file.extension)}></span>
                            <span className="k-file-state"></span>
                        </span>
          <span className="k-file-name-size-wrapper">
                            <span className="k-file-name" title={file.name}>
                                <Link to="#" onClick={() => download(file.name, bucket)}>
                                    {file.name.split('/').pop()}
                                </Link>
                            </span>
                            <span className="k-file-size"></span>
                        </span>
          {onRemove && <strong className="k-upload-status">
            <button type="button" tabIndex="-1" className="k-button k-button-icon k-flat k-upload-action"
                    onClick={(e) => onRemove(file, formatFiles(files, null))}>
              <span aria-label="Eliminar" title="Eliminar" className="k-icon k-delete k-i-x"></span>
            </button>
          </strong>}
        </div>
      ))
      }
    </>
  )
}

const formatFiles = (files, type) => {
  return files.map((f) => {
    f.progress = 0
    f.status = UploadFileStatus.Initial
    f.uid = f._id || f.uid
    return f
  })
}
const getIcon = (extension) => {
  if (extension === '.pdf') {
    return 'k-i-file-pdf'
  } else if (extension === '.doc' || extension === '.docx') {
    return 'k-i-file-word'
  } else if (extension === '.xls' || extension === '.xlsx') {
    return 'k-i-file-excel'
  } else if (extension === '.ppt' || extension === '.pptx') {
    return 'k-i-file-powerpoint'
  } else if (extension === '.jpg' || extension === '.jpeg' || extension === '.png') {
    return 'k-i-file-image'
  } else {
    return 'k-i-file'
  }
}
