import React, { Component } from "react";
//import auth from "../services/authService";
import { Link } from "react-router-dom";
import Table from "./common/table";

class StatusTable extends Component {
  columns = [
    { 
        field: "name", 
        label: "Nombre del estatus",
        content: status => <Link to={`/status/${status._id}`}>{status.name}</Link>
    },   
  ];

  render() {
    const { status, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        data={status}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default StatusTable;
