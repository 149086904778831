import React, { Component } from 'react';
import { Link } from "react-router-dom";
import BackupsTable from "./backupsTable";
import { saveAs } from 'file-saver';
import { getSignedUrl } from '../utils/s3';
import moment  from 'moment';
import { getBackups, createBackup } from "../services/backupService";

class Clients extends Component {
    state = {
        backups: []
     }

    async populateBackups () {
        const { data: backups } = await getBackups();

        const backupsUpdate = backups.map(c => {
            // Manejar la fecha de expiración
            const formattedDate = moment(c.date).format('DD/MM/YYYY HH:mm');
            c.date = formattedDate;
            c.download = <button onClick={(e) => window.confirm("¿Está seguro que desea descargar el respaldo?") && this.handleBackup(c)} className="btn btn-info btn-sm">Descargar</button>;
            return c;
        });

        this.setState({ backups: backupsUpdate });
    }

    async componentDidMount() {
        await this.populateBackups();
    }

    handleBackup = async backup => {
        getSignedUrl(backup.file, 'bkp-cp8')
        .then(url => {
            console.log(url);
            saveAs(url,backup.file);
        }).catch((err) => console.log(err));
        
    };

    handleCreateBkp = async () => {
        await createBackup();
        await this.populateBackups();
    }

    render() {
        const { backups } = this.state;

        return (
            <div>
                <div className="form-row mt-3 mb-3">
                    <div className="col-lg-10">
                        <div className="float-left">
                            <Link
                                to="#"
                                onClick={() => this.handleCreateBkp()}
                                className="btn btn-primary float-right"
                                >
                                <i className="fa fa-download" aria-hidden="true" /><span> </span>
                                Nuevo Respaldo
                            </Link>
                        </div>
                    </div>
                </div>
                <BackupsTable
                    backups={backups}
                />
            </div>
        );
    }
}

export default Clients;
