import React, { Component } from "react";
import { Link } from "react-router-dom";
import Table from '../common/materia-reac-table/table'

class CffTable extends Component {
    columns = [
        {
            accessorKey: "nameCff",
            Cell: ({ row }) => <Link to={`/cff/${row.original._id}`}>{String(row.original.nameCff)}</Link>,
            header: "Nombre",
            size: 200
        },
        {
            accessorKey: 'status',
            header: 'Estatus',
            Cell: ({ row }) => <span
                className={row.original.aux === 'pending' ? 'badge badge-warning' : (row.original.aux === 'review' ? 'badge badge-primary' : 'badge badge-success')}>{row.original.aux === 'pending' ? 'Pendiente' : (row.original.aux === 'review' ? 'En revisión' : 'Terminado')}</span>,
            size: 150
        },
    ];
    rowAction = ({ row, table }) => (row.original.aux === 'pending' ? <button
        onClick={(e) => window.confirm('Esta seguro que desea eliminar el CFF?') && this.props.handleDelete(row.original)}
        className="btn btn-danger btn-sm">Eliminar</button> : '')
    render() {
        const { cff, user } = this.props;

        return (this.columns ?
            <Table
                columns={this.columns}
                data={cff}
                isLoading={this.props.isLoading}
                rowAction={this.rowAction}
            /> : <></>
        );
    }
}

export default CffTable;
