import React, { Component } from "react";
//import auth from "../services/authService";
import { Link } from "react-router-dom";
import Table from "./common/table";

class TypesTable extends Component {
  columns = [
    { 
        field: "name", 
        label: "Nombre del tipo",
        content: type => <Link to={`/types/${type._id}`}>{type.name}</Link>
    },   
  ];

  render() {
    const { types, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        data={types}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default TypesTable;
