import React from "react";

const Textarea = ({ name, label, rows, error, ...rest }) => {
  return (
    <div className="form-label-group">
      <textarea
        name={name}
        id={name}
        rows={rows}
        placeholder={label}
        className="form-control"
        {...rest}
      />
      <label htmlFor={name}>{label}</label>
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
}

export default Textarea;
