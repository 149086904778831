import React from 'react'
import Joi from 'joi-browser'
import Form from '../common/form'
import { getObjective, saveObjective } from '../../services/objectiveService'
import { saveBox } from '../../services/boxService'
import { toast } from 'react-toastify'

export default class ObjectiveForm extends Form {
  state = {
    data: {
      name: '',
      target: 0,
      current: 0,
    },
    errors: {},
    isNew: null
  }

  schema = {
    _id: Joi.string(),
    name: Joi.string().required().label('Concepto'),
    target: Joi.number().required().min(1).label('Objetivo'),
    current: Joi.number().required().min(1).label('Actual')
  }

  async componentDidMount () {
    const id = this.props.match.params.id
    if (id === 'new') {
      this.setState({ isNew: true })
      return
    }
    const { data: objective } = await getObjective(id)
    this.setState({ data: objective, isNew: false })
  }

  doSubmit = async () => {
    const errors = this.validate()
    this.setState({ errors: errors || {} })
    if (errors) return
    try {
      const { data } = this.state
      const { data: objective } = await saveObjective(data)
      toast.success('El objetivo se guardó correctamente.')
      this.props.history.push('/objectives')
    } catch (ex) {
      toast.error(ex.response.data)
    }
  }

  render () {
    const { isNew } = this.state
    return (
      <>
        <h3 className="mb-3">{isNew ? 'Agregar' : 'Editar'} Objetivo</h3>
        <form onSubmit={this.handleSubmit}>
          {this.renderInput('name', 'Concepto')}

          {this.renderInput('target', 'Objetivo', 'number')}

          {this.renderInput('current', 'Actual', 'number')}

          <button className="btn btn-primary" type="submit">
            Guardar
          </button>
          <a href="/objectives" className="btn btn-secondary ml-2">
            Cancelar
          </a>
        </form>
      </>
    )
  }
}
