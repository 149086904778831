import http from "./httpService";

const apiEndpoint = process.env.REACT_APP_API_URL + "/instruments";

export function instrumentUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getInstruments(filter) {
  return http.get(apiEndpoint,{ params: filter });
}

export function getInstrumentsPendingInvoice(filter) {
  return http.get(apiEndpoint+'/invoice-pending',{ params: filter });
}

export function getInstrument(instrumentId) {
  return http.get(instrumentUrl(instrumentId));
}

export function getInstrumentFiles(instrumentId) {
  return http.get(instrumentUrl(`${instrumentId}/files`));
}

export function instrumentFilesAdd(instrumentId,file) {
  const body = {
    data: file
  };

  return http.post(instrumentUrl(`${instrumentId}/files/add`),body);
}

export function saveInstrument(instrument) {
  if (instrument._id) {
    const body = { ...instrument };
    delete body._id;
    return http.put(instrumentUrl(instrument._id), body);
  }

  return http.post(apiEndpoint, instrument);
}

export function deleteInstrument(instrumentId) {
  return http.delete(instrumentUrl(instrumentId));
}

export function instrumentArchivedUpdate(instrumentId, archived) {
  const body = {
    archived: archived
  }
  return http.put(instrumentUrl(instrumentId)+'/archived', body)
}

export function instrumentSpecialUpdate(instrumentId, isSpecial) {
  const body = {
    special: isSpecial
  }
  return http.put(instrumentUrl(instrumentId)+'/special', body)
}

export function instrumentDigitalUpdate(instrumentId, isDigital) {
  const body = {
    isDigital: isDigital
  }
  return http.put(instrumentUrl(instrumentId)+'/digital', body)
}

export function getRnieNames(filter) {
  return http.get(apiEndpoint+'/rnie/names', { params: filter });
}
