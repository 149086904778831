import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { ExternalDropZone, Upload, UploadFileStatus } from '@progress/kendo-react-upload';
import "@progress/kendo-theme-default/dist/all.css";
import {
    IntlProvider,
    LocalizationProvider,
    loadMessages,
} from "@progress/kendo-react-intl";
import bgMessages from "../../utils/es.json";
import { download } from '../../utils/s3';

loadMessages(bgMessages, "es-ES");
const uploadRef = React.createRef();
const hint = <span>Arrastrar y soltar archivos aqui para subir.</span>;
const note = <span></span>;

class Uploader extends Component {
    state = {
        files: [],
        signed_files: [],
        edit: false,
        type: ''
    };

    onAdd = (event, type) => {
        const { files } = this.state;
        event && event.affectedFiles.forEach(file => {
            files.push(file);
            this.props.onUpload(file, false, type);
        });
        this.setState({ type });
    };

    readFile = async (file) => {
        const arrayBuffer = await new Promise(resolve => {
            const reader = new FileReader();
            reader.onload = (e) => resolve(e.target.result);
            reader.readAsArrayBuffer(file);
        });
        return arrayBuffer;
    }

    onRemove = async (file) => {
        this.props.onRemove(file);
    };

    onStatusChange = (event) => {
        const { files, edit, type } = this.state;
        //this.props.onUpload(files, edit, type);
    };

    formatFiles = (files, type) => {
        return files.map((f) => {
            f.progress = 0;
            f.status = UploadFileStatus.Initial;
            f.uid = f._id || f.uid;
            return f;
        })
    };

    getIcon = (extension) => {
       if (extension === '.pdf') {
           return 'k-i-file-pdf';
       } else if (extension === '.doc' || extension === '.docx') {
            return 'k-i-file-word';
       } else if (extension === '.xls' || extension === '.xlsx') {
            return 'k-i-file-excel';
       } else if (extension === '.ppt' || extension === '.pptx') {
            return 'k-i-file-powerpoint';
       } else if (extension === '.jpg' || extension === '.jpeg' || extension === '.png') {
            return 'k-i-file-image';
       } else {
            return 'k-i-file';
       }
    }

    customListItem = (props) => {
        return (
            <>
                {props.files.map((file, index) => (
                    <div className="k-file-single" key={file.uid}>
                        <div className="k-progressbar" style={{ transition: "opacity 1s ease-in-out 0s", opacity: 1 }}>
                            <span className="k-progress" style={{ width: '0%' }}></span>
                        </div>
                        <span className="k-file-group-wrapper">
                            <span className={'k-file-group k-icon ' + this.getIcon(file.extension)}></span>
                            <span className="k-file-state"></span>
                        </span>
                        <span className="k-file-name-size-wrapper">
                            <span className="k-file-name" title={file.name}>
                                <Link to="#" onClick={() => download(file.name, this.props.bucket)}>
                                    {file.name}
                                </Link>
                            </span>
                            <span className="k-file-size"></span>
                        </span>
                        <strong className="k-upload-status">
                            <button type="button" tabIndex="-1" className="k-button k-button-icon k-flat k-upload-action" onClick={(e) => this.onRemove(file, this.formatFiles(props.files, this.props.type))} >
                                <span aria-label="Eliminar" title="Eliminar" className="k-icon k-delete k-i-x"></span>
                            </button>
                        </strong>
                    </div>
                ))
                }
            </>
        );
    };

    render() {
        const { files, type, label } = this.props;
        let docs = this.formatFiles(files, type);
        return (
            <>
                <div style={{ marginTop: 10 }}>
                    <LocalizationProvider language="es-ES">
                        <IntlProvider locale="es">
                            <h5>{label}</h5>
                            <Upload
                                batch={false}
                                multiple={true}
                                files={docs}
                                withCredentials={false}
                                listItemUI={this.customListItem}
                                onAdd={(file) => this.onAdd(file, this.props.type)}
                            //onRemove={(file) => this.onRemove(file, docs)}
                            // onStatusChange={this.onStatusChange}
                            // saveUrl={"https://demos.telerik.com/kendo-ui/service-v4/upload/save"}
                            // removeUrl={
                            //     "https://demos.telerik.com/kendo-ui/service-v4/upload/remove"
                            // }
                            />
                        </IntlProvider>
                    </LocalizationProvider>
                </div>
            </>
        );
    }
};

export default Uploader;
