import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import { ObjectID } from 'bson'

class Declaranot extends Component {
  state = {
    data: {
      _id: new ObjectID(),
      active: false,
      type: ''
    },
    types: ['Omisión de inscripción ', 'Omisión de liquidación ', 'Omisión de cancelación', 'Omisión RFC de socios'],
    show: false
  }

  showModal = () => {
    this.setState({ show: true })
  }

  hideModal = () => {
    this.setState({ show: false })
  }

  handleChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data }
    data[input.name] = input.value
    this.setState({ data })
  }

  handleCheck = (e, type) => {
    const { data } = this.state
    if (e.target.checked) {
      data.active = true
      this.showModal()
    } else {
      data.active = false
    }
    this.props.onCheck(e, type)
    this.setState({ data })
  }

  handleSubmit = e => {
    e.preventDefault()
    const { data } = this.state
    this.setState({ show: false });
    this.props.onDeclaranot(data)
  }

  render () {
    const { data, types } = this.state
    const { declaranot, user } = this.props

    return (
      <>
        <input type="checkbox" id="declaranot" onClick={(e) => this.handleCheck(e, 'declaranot')} checked={!!declaranot?.active}/>
        <label className="form-check-label" htmlFor="declaranot">Declaranot</label>
        <Modal show={this.state.show} onHide={this.hideModal}>
          <form>
            <Modal.Header closeButton>
              <Modal.Title>Declaranot</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-row">
                <div className="col-md-12 form-label-group mb-3">
                  <select name="type" id="type" className="form-control" onChange={this.handleChange} value={data.type}>
                    <option value="">Selecciona...</option>
                    {types.map(type => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </select>
                  <label>Tipo de omisión</label>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {
                user && user.role && user.role.actions.some(a => a.method === 'save-declaranot') && (
                  <button className="btn btn-primary" onClick={this.handleSubmit}>Guardar</button>
                )
              }
            </Modal.Footer>
          </form>
        </Modal>
      </>
    )
  }
}

export default Declaranot
