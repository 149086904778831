import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { getCurrentUser } from '../../../services/authService'
import { deleteAccountPayable, getAccountsPayable } from '../../../services/accountsPayableService'
import AccountsPayableTable from './components/accountsPayableTable'
import { Tab, Tabs } from 'react-bootstrap'

class AccountsPayable extends Component {
  state = {
    user: null,
    accountsPayable: [],
    accountsPayed: [],
    accountsPending: [],
    isLoading: true,
    sumPayed: 0,
    sumPending: 0

  }

  async populateUser () {
    const { data: user } = await getCurrentUser()
    if (user) this.setState({ user })
  }

  async populateAccountsPayable () {
    const { data: accountsPayable } = await getAccountsPayable()
    let sumPayed = 0
    let sumPending = 0
    const accountsPayed = [];
    const accountsPending = [];
    for (const account of accountsPayable) {
      if (account.status === 'Pagado') {
        sumPayed += account.total;
        accountsPayed.push(account);
      } else {
        sumPending += account.total;
        accountsPending.push(account);
      }
    }
    this.setState({ accountsPayable, accountsPayed, accountsPending, isLoading: false, sumPayed, sumPending })
  }

  async componentDidMount () {
    await this.populateUser()
    await this.populateAccountsPayable()
  }

  handleDelete = async account => {
    const originalAccountsPending = this.state.accountsPending
    const accountsPending = originalAccountsPending.filter(m => m !== account)
    this.setState({ accountsPending })

    try {
      await deleteAccountPayable(account._id)
    } catch (ex) {
      this.setState({ accountsPending: originalAccountsPending })
    }
  }

  currencyFormat = (amount) => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    })
    return formatter.format(amount)
  }

  render () {
    const { user, isLoading, accountsPayed, accountsPending, sumPending, sumPayed } = this.state
    return (
      <Tabs defaultActiveKey="porpagar" id="uncontrolled-tab-example">
        <Tab eventKey="porpagar" title="Cuentas por pagar">
          <div className="form-row mt-3 mb-3">
            <div className="col-lg-7">
              {user && user.role && user.role.actions.some(a => a.method === 'add-accounts-payable') && (
                <Link to="/boxes/accounts-payable/add" className="btn btn-primary mr-3">
                  <i className="fa fa-plus" aria-hidden="true"/><span> </span>
                  Nueva Cuenta por Pagar
                </Link>
              )}
              {user && user.role && user.role.actions.some(a => a.method === 'add-provider') && (
                <Link to="/boxes/accounts-payable/add-provider" className="btn btn-success mr-5">
                  <i className="fa fa-plus" aria-hidden="true"/><span> </span>
                  Nuevo Proveedor
                </Link>
              )}
              <span>
                <strong>Total: </strong>{this.currencyFormat(sumPending)}
              </span>

            </div>
          </div>
          <AccountsPayableTable
            user={user}
            accountsPayable={accountsPending}
            isLoading={isLoading}
            handleDelete={this.handleDelete}
          />
        </Tab>
        <Tab eventKey="pagadas" title="Cuentas pagadas">
          <div className="form-row mt-3 mb-3">
            <div className="col-lg-7">
              {user && user.role && user.role.actions.some(a => a.method === 'add-provider') && (
                <Link to="/boxes/accounts-payable/add-provider" className="btn btn-success mr-5">
                  <i className="fa fa-plus" aria-hidden="true"/><span> </span>
                  Nuevo Proveedor
                </Link>
              )}
              <span><strong>Total: </strong>{this.currencyFormat(sumPayed)}</span>
            </div>
          </div>
          <AccountsPayableTable
            user={user}
            accountsPayable={accountsPayed}
            isLoading={isLoading}
          />
        </Tab>
      </Tabs>
    )
  }
}

export default AccountsPayable
