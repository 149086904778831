import http from "./httpService";

const apiEndpoint = process.env.REACT_APP_API_URL + "/backups";

export function backupUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getBackups() {
  return http.get(apiEndpoint);
}

export function createBackup(filter) {
  return http.get(apiEndpoint+'/create',{ params: filter });
}