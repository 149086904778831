import React, { Component } from "react";
import Table from "./common/table";

class BackupsTable extends Component {
  columns = [
    {
        field: "date",
        label: "Fecha de creación",
        width: 200
    },
    {
        field: "download",
        label: "Acciones",
        width: 150
    }
  ];

  render() {
    const { backups } = this.props;

    return (
      <Table
        columns={this.columns}
        data={backups}
      />
    );
  }
}

export default BackupsTable;
