import React from 'react'

const Check = props => {
  let classes = 'fas fa-folder'
  if (!props.archived) classes = 'far fa-folder'
  return (
    <i
      onClick={props.onClick}
      style={{ cursor: 'pointer' }}
      className={classes}
    />
  )
}

export default Check
