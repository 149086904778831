import React from "react";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import Form from "../common/form";
import { getCurrentUser } from "../../services/authService";
import { getDenomination, saveDenomination } from "../../services/denominationService";
import { SingleDatePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';


class DenominationForm extends Form {
  state = {
    data: {
      name: "",
      type: "",
      account: "",
      created: "",
      expired: moment(),
      comments: "",
      isPulledApart: false,
      isPulledUses: false
    },
    errors: {},
    focusedCreated: false,
    focusedExpired: false,
  };

  schema = {
    _id: Joi.string(),
    name: Joi.string()
      .required()
      .label("Denominación"),
    type: Joi.string()
      .required()
      .label("Tipo"),
    account: Joi.string()
      .required()
      .label("Cuenta"),
    created: Joi.date()
      .required()
      .label("Emisión"),
    expired: Joi.date()
      .required()
      .label("Vencimiento"),
    comments: Joi.string()
      .required()
      .label("Observaciones")
  };

  async populateUser() {
    const { data: user } = await getCurrentUser();
    if (user) this.setState({ user });
  }

  async populateDenomination() {
    try {
      const denominationId = this.props.match.params.id;
      if (denominationId === "new") {
        const { data } = this.state;

        this.setState({ data });
        return;
      }

      const { data: denomination } = await getDenomination(denominationId);
      this.setState({ data: this.mapToViewModel(denomination) });
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  }

  async componentDidMount() {
    await this.populateUser();
    await this.populateDenomination();
  }

  mapToViewModel(denomination) {
    return {
      _id: denomination._id,
      name: denomination.name,
      type: denomination.type,
      account: denomination.account,
      created: moment(denomination.created),
      expired: moment(denomination.expired),
      status: denomination.status,
      comments: denomination.comments,
      isPulledApart: denomination.isPulledApart,
      isPulledUses: denomination.status === 'uses' ? true : false
    };
  }

  doSubmit = async () => {
    try {
      if (this.props.match.params.status === 'uses') {
        this.state.data.isPulledApart = this.state.data.isPulledApart === null ? false : this.state.data.isPulledApart;
      }
      this.state.data.comments = this.state.data.comments === null ? '' : this.state.data.comments;
      const currentDate = new Date();
      const selectedDate = new Date(this.state.data.expired); // Change "selectedDate" to the actual field name
      if (selectedDate <= currentDate) {
        this.state.data.status = this.state.data.isPulledUses === true  ? 'uses' : 'expired';
      }
      else {
        this.state.data.status = this.state.data.isPulledUses === true ? 'uses' : 'available';
      }
      await saveDenomination(this.state.data);
      this.props.history.push(`/denominations/${this.props.match.params.status}`);
      toast.success("La denominación se guardo correctamente.");
    } catch (ex) {
      // console.log(ex.response)
      toast.error("Intetar nuevamente.");
    }
  };


  handleDateChange = date => {
    const { data } = this.state;
    data.created = date;
    data.expired = moment(date).add(180, 'days');
    this.setState({ data })
  }

  render() {
    const { data, user } = this.state;
    return (
      <div>
        <form className="mt-4" onSubmit={this.handleSubmit}>
          <div className="form-row">
            <div className="form-label-group col-md-4 mb-3">
              <input placeholder="Denominación" className="form-control" name="name" type="text" onChange={this.handleChange} value={data.name} />
              <label>Denominación</label>
            </div>
            <div className="form-label-group col-md-4 mb-3">
              <input placeholder="Tipo" className="form-control" name="type" type="text" onChange={this.handleChange} value={data.type} />
              <label>Tipo</label>
            </div>
            <div className="form-label-group col-md-4 mb-3">
              <input placeholder="Cuenta" className="form-control" name="account" type="text" onChange={this.handleChange} value={data.account} />
              <label>Cuenta</label>
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-4 mb-3">
              <SingleDatePicker
                date={data.created} // momentPropTypes.momentObj or null
                onDateChange={(date) => this.handleDateChange(date)} // PropTypes.func.isRequired
                focused={this.state.focusedCreated} // PropTypes.bool
                onFocusChange={({ focused: focusedCreated }) => this.setState({ focusedCreated })} // PropTypes.func.isRequired
                displayFormat="DD/MM/YYYY"
                numberOfMonths={1}
                isOutsideRange={() => false}
                placeholder={"Emisión"}
                id="created"
              />
            </div>
            <div className="form-label-group  col-md-4 mb-3">
              <input placeholder="Observaciones" className="form-control" name="comments" type="text" onChange={this.handleChange} value={data.comments} />
              <label>Observaciones</label>
            </div>
            {data.status === 'available' && <div className="form-label-group col-md-2 mb-3 pl-4 pt-2">
              <div className="form-check">
                <input className="form-check-input" id="pulledApart" name="pulledApart" type="checkbox"
                  onChange={(e) => {
                    data.isPulledApart = e.target.checked
                    this.setState({ data })
                  }}
                  checked={data.isPulledApart} />
                <label className="form-check-label" htmlFor="pulledApart">
                  Apartado
                </label>
              </div>
            </div>}
            <div className="form-label-group col-md-2 mb-3 pl-4 pt-2">
              <div className="form-check">
                <input className="form-check-input" id="pulledUses" name="pulledUses" type="checkbox"
                  onChange={(e) => {
                    data.isPulledUses = e.target.checked
                    this.setState({ data })
                  }}
                  checked={data.isPulledUses} />
                <label className="form-check-label" htmlFor="pulledUses">
                  Usada
                </label>
              </div>
            </div>
          </div>
          {
            user && user.role && user.role.actions.some(a => a.method === "save-denomination") && (
              <button className="btn btn-primary float-right" type="submit">Guardar</button>
            )
          }
        </form>
      </div>
    );
  }
}

export default DenominationForm;
