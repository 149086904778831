import http from "./httpService";

const apiEndpoint = process.env.REACT_APP_API_URL + "/notifications";

export function notificationUrl(id) {
    return `${apiEndpoint}/${id}`;
}

export function getNotifications() {
  return http.get(apiEndpoint);
}

export function getUserNotifications(id) {
    return http.get(`${apiEndpoint}/${id}`);
}

export function saveNotification(notification) {
    if (notification._id) {
      const body = { ...notification };
      delete body._id;
      return http.put(notificationUrl(notification._id), body);
    }

    return http.post(apiEndpoint, notification);
}

export function postBoxtestimonio (body) {
  return http.post(`${apiEndpoint}/generate-testimonial`, body)
}

