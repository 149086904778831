import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { ObjectID } from 'bson';
import { Link } from "react-router-dom";

class Members extends Component {
    state = {
        show: false,
        data: {
            name: '',
            position: ''
        },
        fiscalRegimes: [{ _id: 1, name: 'Persona Física' }, { _id: 2, name: 'Persona Moral' }],
    };

    showModal = edit => {
        let { data } = this.state;
        if (edit) {
            data = { _id: edit._id, name: edit.name, position: edit.position };
        } else {
            data = { _id: new ObjectID(), name: "", position: "" };
        }

        this.setState({ data: data, show: true });
    };

    hideModal = () => {
        this.setState({ show: false });
    };

    handleChange = ({ currentTarget: input }) => {
        const { data } = this.state;
        data[input.name] = input.value;

        this.setState({ data });
    };

    handleSubmit = e => {
        e.preventDefault();
        const { data } = this.state;
        this.setState({ show: false });
        this.props.onMember(data);
    };

    render() {
        const { data, fiscalRegimes } = this.state;

        return (
            <div>
                <div className="form-row">
                    <div className="col-md-8 mb-6">
                        <h5>Nombre del administrador único o miembros del consejo de administración</h5>
                    </div>
                    <div className="col-md-4 mb-6 pr-0">
                        <Link className="btn btn-info btn-sm float-right link-add" to={"#"} onClick={() => this.showModal(false)} data-html2canvas-ignore="true"><i className="fa fa-plus" /></Link>
                    </div>
                </div>
                <div className="form-row form-row">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">Nombre</th>
                                <th scope="col">Régimen Fiscal</th>
                                <th scope="col">Cargo</th>
                                <th data-html2canvas-ignore="true"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props && this.props.members && this.props.members.map(member => (
                                <tr key={member._id}>
                                    <td><Link to={"#"} onClick={() => this.showModal(member)}>{member.name}</Link></td>
                                    <td>{fiscalRegimes.find(f => f._id === parseInt(member.fiscalRegime))?.name}</td>
                                    <td>{member.position}</td>
                                    <td className="text-center" data-html2canvas-ignore="true"><Link to={"#"} onClick={() => this.props.onDelete(member)} style={{ color: 'red' }} data-html2canvas-ignore="true">Eliminar</Link></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <Modal show={this.state.show} onHide={this.hideModal}>
                    <form>
                        <Modal.Header closeButton>
                            <Modal.Title>Agregar Administrador Único o Miembros del Consejo</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <input placeholder="Nombre" className="form-control" name="name" onChange={this.handleChange} value={data.name} required />
                                </div>
                                <div className="col-md-12 form-label-group mb-3">
                                    <select
                                      name="fiscalRegime"
                                      id="fiscalRegime"
                                      className="form-control"
                                      onChange={this.handleChange}
                                      value={data.fiscalRegime}>
                                        <option value="">Selecciona...</option>
                                        {this.state.fiscalRegimes.map(fiscalRegime => (
                                          <option key={fiscalRegime._id} value={fiscalRegime._id}>
                                              {fiscalRegime.name}
                                          </option>
                                        ))}
                                    </select>
                                    <label>Régimen Fiscal</label>
                                </div>
                                <div className="col-md-12 mb-3">
                                    <input placeholder="Cargo" className="form-control" name="position" onChange={this.handleChange} value={data.position} required />
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-primary" onClick={this.handleSubmit}>Guardar</button>
                        </Modal.Footer>
                    </form>
                </Modal>
            </div>
        );
    }
}

export default Members;
