import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Check from "../common/check";
import MediationsTable from "./mediationsTable";
import { getCurrentUser } from "../../services/authService";
import { getMediations, mediationArchivedUpdate } from "../../services/mediationService";
import { paginate } from "../../utils/paginate";
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import _ from "lodash";

class Mediations extends Component {
    state = {
        mediations: [],
        currentPage: 1,
        pageSize: 100000,
        searchQuery: "",
        sortColumn: { path: "number", order: "asc" },
        startDate: null,
        endDate: null,
        focusedInput: null,
        isLoading: true
    }

    async populateUser() {
        const { data: user } = await getCurrentUser();
        if (user) this.setState({ user });
    }

    async populateMediations() {
        const { user } = this.state;
        const { data: mediations } = (user && user.role && user.role.actions.some(a => a.method === "all-mediation")) ? await getMediations() : [];
        this.setState({ mediations: mediations, isLoading: false });
    }

    async componentDidMount() {
        await this.populateUser();
        await this.populateMediations();
    }

    handleArchive = async updateMediation => {
        const originalMediations = this.state.mediations;
        const mediations = [...originalMediations ];
        const index = mediations.indexOf(updateMediation);
        mediations[index] = { ...mediations[index] };
        mediations[index].archived = !updateMediation.archived
        this.setState({ mediations });

        try {
            await mediationArchivedUpdate(updateMediation._id, !updateMediation.archived);
        } catch (ex) {
            if (ex.response && ex.response.status === 400)
                //toast.error("This movie has already been deleted.");
                this.setState({ mediations: originalMediations });
        }
    };

    render() {
        const { mediations, user, isLoading } = this.state;

        return (
            <div>
                <div className="form-row mt-3 mb-3">
                    <div className="col-lg-7">
                        <div className="float-left">
                            {/* {user && (user.role === "Abogado" || user.role === "Administrador") ? ( */}
                            {user && user.role && user.role.actions.some(a => a.method === "add-mediation") && (
                                <Link
                                    to="/mediations/new"
                                    className="btn btn-primary float-right"
                                >
                                    <i className="fa fa-plus" aria-hidden="true" /><span> </span>
                                    Nueva Mediación
                                </Link>
                            )}
                        </div>
                    </div>
                    {/* <div className="col-lg-5">
                        <div className="form-row">
                            <div  className="col-lg-5">
                                <div className="float-right">
                                    <SearchBox value={searchQuery} onChange={this.handleSearch} />
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="float-right">
                                    <DateRangePicker
                                        isOutsideRange={() => false}
                                        startDate={startDate} // momentPropTypes.momentObj or null,
                                        startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                                        endDate={endDate} // momentPropTypes.momentObj or null,
                                        endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                                        onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })} // PropTypes.func.isRequired,
                                        focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                        onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                                        startDatePlaceholderText="Fecha Inicio"
                                        endDatePlaceholderText="Fecha Fin"
                                        displayFormat="DD/MM/YYYY"
                                        showClearDates={true}
                                        showDefaultInputIcon={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
                <MediationsTable
                    mediations={mediations}
                    handleArchive={this.handleArchive}
                    user={user}
                    isLoading={isLoading}
                />
            </div>
        );
    }
}

export default Mediations;
