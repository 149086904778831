import http from "./httpService";

const apiEndpoint = process.env.REACT_APP_API_URL + "/denominations";

export function denominationUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getDenominations(filter) {
  return http.get(apiEndpoint, { params: filter });
}

export function getDenomination(denominationId) {
  return http.get(denominationUrl(denominationId));
}

export function saveDenomination(denomination) {
  if (denomination._id) {
    const body = { ...denomination };
    delete body._id;
    delete body.isPulledUses;
    return http.put(denominationUrl(denomination._id), body);
  }

  return http.post(apiEndpoint, denomination);
}

export function deleteDenomination(denominationId) {
  return http.delete(denominationUrl(denominationId));
}