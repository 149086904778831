import http from "./httpService";

const apiEndpoint = process.env.REACT_APP_API_URL + "/types";

export function typeUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getTypes() {
  return http.get(apiEndpoint);
}

export function getType(typeId) {
  return http.get(typeUrl(typeId));
}

export function saveType(type) {
  if (type._id) {
    const body = { ...type };
    delete body._id;
    return http.put(typeUrl(type._id), body);
  }

  return http.post(apiEndpoint, type);
}

export function deleteType(typeId) {
  return http.delete(typeUrl(typeId));
}
