import http from './httpService'

const apiEndpoint = process.env.REACT_APP_API_URL + '/objectives'

export function objectiveUrl (id) {
  return `${apiEndpoint}/${id}`
}

export function getObjectives (filter) {
  return http.get(apiEndpoint, { params: filter })
}

export function getObjective (objectiveId) {
  return http.get(objectiveUrl(objectiveId))
}

export function saveObjective (objective) {
  if (objective._id) {
    const body = { ...objective }
    delete body._id
    return http.put(objectiveUrl(objective._id), body)
  }

  return http.post(apiEndpoint, objective)
}

export function deleteObjective (objectiveId) {
  return http.delete(objectiveUrl(objectiveId))
}
