import React from 'react'
import Form from '../common/form'
import { SingleDatePicker } from 'react-dates'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import moment from 'moment/moment'
import logo from '../../logo-pdf.png'
import Joi from 'joi-browser'

export default class FhForm extends Form {

  state = {
    data: {},
    errors: {},
    focused: false,
  }
  schema = {
    _id: Joi.string(),
    date: Joi.date().required().label('Fecha'),
    instrument: Joi.string().required().label('Instrumento'),
    manifestation: Joi.string().required(),
    manifestationRepresentant: Joi.when('manifestation', {
      is: 'manifestation2',
      then: Joi.string().required(),
      otherwise: Joi.string().optional().allow('')
    }),
    bcname: Joi.when('manifestation', {
      is: 'manifestation2',
      then: Joi.string().required(),
      otherwise: Joi.string().optional().allow('')
    }).when('indetifyBC', {
      is: 'yes',
      then: Joi.string().required(),
      otherwise: Joi.string().optional().allow('')
    }),
    useRights: Joi.when('manifestation', {
      is: 'manifestation3',
      then: Joi.string().required(),
      otherwise: Joi.string().optional().allow('')
    }),
    indetifyBC: Joi.when('manifestation', {
      is: 'manifestation2',
      then: Joi.string().required(),
      otherwise: Joi.string().optional().allow('')
    })
  }

  handleDateChange = (name, value) => {
    this.props.onChange(name, `${value}`)
  }

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors }
    const errorMessage = this.validateProperty(input)
    if (errorMessage) errors[input.name] = errorMessage
    else delete errors[input.name]

    this.props.onChange(input.name, input.value)
    this.setState({ errors })
  }

  handleSubmit = async (e, status, data) => {
    e.preventDefault();
    const options = { abortEarly: false };
    const { error } = Joi.validate(data, this.schema, options);
    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    if (this.props.type === 'Otro') {
      delete errors['status'];
      delete errors['partners'];
      delete errors['currentPartners'];
      delete errors['files'];
      delete errors['members'];
      if(data.bcname === '') delete errors['bcname'];
    }
    console.log(errors);
    this.setState({ errors: errors || {} });
    if (Object.keys(errors).length === 0) {
      this.props.onSave(status, null);
    } else {
      this.props.onSave(status, errors);
    }

  };

  render() {
    const { errors } = this.state
    let data = this.props.person
    return (
      <>
        <div className="row" data-html2canvas-ignore="true">
          <div className="col-md-12 text-right">
            <button
              className="btn btn-primary"
              onClick={() => this.props.onPDF(`Fe de hechos - ${this.props.name}`, 'fh')}
            >
              Exportar
            </button>
          </div>
        </div>
        <form id="fh-form">
          <div className="form-row mb-3">
            <div className="col-md-9 mb-4 text-center">
              <h3>
                Manifestación sobre dueño beneficiario <br />
                beneficiario controlador PLD
              </h3>
            </div>
            <div className="col-md-3">
              <img src={logo} alt="logo" className="img-fluid" />
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-8 form-label-group mb-3">
              <input
                className="form-control"
                name="instrument"
                type="text"
                onChange={this.handleChange}
                value={data && data.instrument ? data.instrument : ''}
                error={errors['instrument']} />
              <label>Instrumento</label>
            </div>
            <div className="col-md-4 mb-3">
              <SingleDatePicker
                id="date"
                date={data && data.date ? moment(data.date) : null}
                onDateChange={(date) => this.handleDateChange('date', date)} // PropTypes.func.isRequired
                focused={this.state.focused}
                onFocusChange={({ focused }) => this.setState({ focused })}
                numberOfMonths={1}
                isOutsideRange={() => false}
                displayFormat="DD/MM/YYYY"
              />
            </div>
          </div>
          <hr />
          <div className="form-row">
            <div className="col-md-12 mb-3">
              Conforme a lo establecido en el artículo 3, fracción III de la Ley Federal para la Prevención e Identificación de Operaciones con Recursos de Procedencia Ilícita manifiesto, bajo protesta de decir
              verdad, que:
            </div>
          </div>
          <div className="form-row">
            <div className="form-check mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="manifestation"
                id="manifestation1"
                value="manifestation1"
                onChange={this.handleChange}
                checked={data.manifestation === 'manifestation1'} />
              <label className="form-check-label" htmlFor="manifestation1">
                1. En lo personal, soy yo quien recibirá los derechos de uso, goce, disfrute, aprovechamiento de este
                acto jurídico.
              </label>
            </div>
            <div className="form-check mb-3">
              <input
                className="form-check-input"
                type="radio"
                name="manifestation"
                id="manifestation2"
                value="manifestation2"
                onChange={this.handleChange}
                checked={data.manifestation === 'manifestation2'} />
              <label className="form-check-label" htmlFor="manifestation2">
                2. Mi representada es la persona que directamente disfrutará de los beneficios derivados de este acto
                jurídico con denominación o razón social siguiente:
                <input
                  className="form-control clear sm"
                  name="manifestationRepresentant"
                  type="text"
                  onChange={this.handleChange}
                  value={data && data.manifestationRepresentant ? data.manifestationRepresentant : ''}
                  error={errors['manifestationRepresentant']}
                  disabled={data.manifestation !== 'manifestation2'}
                />
                <div className="form-label-group">
                  Para este supuesto #2, usted Cliente o Usuario, ¿Tiene conocimiento o le es posible identificar a uno o más beneficiarios controladores (conforme a la definición previa referida)?
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio" name="indetifyBC"
                      id="iknowBCyes"
                      value="yes"
                      onChange={this.handleChange}
                      checked={data.indetifyBC === 'yes'}
                      disabled={data.manifestation !== 'manifestation2'}
                    />
                    <label className="form-check-label" htmlFor="manifestation2">
                      Si, siendo el siguiente:
                      <input
                        className="form-control clear sm"
                        name="bcname"
                        type="text"
                        onChange={this.handleChange}
                        value={data && data.bcname ? data.bcname : ''}
                        error={errors['bcname']}
                        disabled={data.manifestation !== 'manifestation2' || data.indetifyBC !== 'yes'}
                      />
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="indetifyBC"
                      id="iknowBCno"
                      value="no"
                      onChange={this.handleChange}
                      checked={data.indetifyBC === 'no'}
                      disabled={data.manifestation !== 'manifestation2'} />
                    <label className="form-check-label" htmlFor="manifestation21">
                      No
                    </label>
                  </div>
                </div>
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="manifestation"
                id="manifestation3"
                value="manifestation3"
                onChange={this.handleChange}
                checked={data.manifestation === 'manifestation3'} />
              <label className="form-check-label" htmlFor="manifestation1">
                3. Tengo conocimiento de la existencia del beneficiario Controlador/Dueño beneficiario, y es quien
                recibirá los derechos de uso, goce, disfrute o aprovechamiento de acto jurídico, siendo el siguiente:
                <input className="form-control clear sm"
                  name="useRights"
                  type="text"
                  onChange={this.handleChange}
                  value={data && data.useRights ? data.useRights : ""}
                  error={errors['useRights']}
                  disabled={data.manifestation !== 'manifestation3'}
                />
              </label>
            </div>
          </div>
          <div className="col-md-12 form-label-group mb-6 pt-3 text-center">
            <div className="sign-box"></div>
            <span> (Nombre y Firma) </span>
          </div>
          <div className="col-md-12 form-label-group" style={{ fontSize: 10 }}>
            <small className="font-smaller">
              <strong>
                Artículo 3 de la Ley Federal para la Prevención e Identificación de Operaciones con Recursos de Procedencia Ilícita.
                Para los efectos de esta Ley, se entenderá por: III. Beneficiario Controlador, a la persona o grupo de personas que: a) Por medio de otra o de cualquier
                acto, obtiene el beneficio derivado de éstos y es quien, en última instancia, ejerce los derechos de uso, goce, disfrute, aprovechamiento o disposición
                de un bien o servicio, o b) Ejerce el control de aquella persona moral que, en su carácter de cliente o usuario, lleve a cabo actos u operaciones con quien
                realice Actividades Vulnerables, así como las personas por cuenta de quienes celebra alguno de ellos. Se entiende que una persona o grupo de
                personas controla a una persona moral cuando, a través de la titularidad de valores, por contrato o de cualquier otro acto, puede: i) Imponer, directa o
                indirectamente, decisiones en las asambleas generales de accionistas, socios unos órganos equivalentes, o nombrar o destituir a la mayoría de los
                consejeros, administradores o sus equivalentes; ii) Mantener la titularidad de los derechos que permitan, directa o indirectamente, ejercer el voto
                respecto de más del cincuenta por ciento del capital social, o iii) Dirigir, directa o indirectamente, la administración, la estrategia o las principales
                políticas de la misma.
              </strong>
            </small>
          </div>
          <button className="btn btn-primary btn-large float-right mb-5" onClick={(e) => this.handleSubmit(e, 'pending', data)}>Guardar</button>
        </form>
      </>
    )
  }

}
