import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import Uploader from "../common/uploader"
import CurrentPartners from "./currentPartners";
import Members from "./members";
import Partners from './partners'
import { SingleDatePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import { upload, download } from '../../utils/s3';
import logo from '../../logo-pdf.png';

class LegalForm extends Form {
    state = {
        data: {
            partners: [],
            currentPartners: [],
            members: [],
            files: []
        },
        files: [],
        errors: {}
    };

    schema = {
        _id: Joi.string(),
        legalName: Joi.string()
            .required(),
        comercialName: Joi.string()
            .required(),
        nationality: Joi.string()
            .required(),
        numberInstrument: Joi.number()
            .required()
            .label("No. de instrumento"),
        birthDate: Joi.string().required(),
        notaryName: Joi.string()
            .required()
            .label("Nombre Fedatario"),
        numberNotary: Joi.number()
            .required(),
        entityNotary: Joi.string()
            .required(),
        folio: Joi.string()
            .required(),
        capital: Joi.number()
            .required(),
        birthCountry: Joi.string()
            .required(),
        taxId: Joi.string()
            .required(),
        phone: Joi.string()
            .required(),
        email: Joi.string()
            .required(),
        street: Joi.string()
            .required(),
        noExt: Joi.string()
            .required(),
        noInt: Joi.string()
            .required(),
        suburb: Joi.string()
            .required(),
        municipality: Joi.string()
            .required(),
        postalCode: Joi.string()
            .required(),
        state: Joi.string()
            .required(),
        country: Joi.string()
            .required(),
        capitalCurrency: Joi.string().required(),
        fiscalStreet: Joi.when('isSameAddress', {
            is: false,
            then: Joi.string().required(),
            otherwise: Joi.string().optional().allow('')
        }),
        fiscalNoExt: Joi.when('isSameAddress', {
            is: false,
            then: Joi.string().required(),
            otherwise: Joi.string().optional().allow('')
        }),
        fiscalNoInt: Joi.when('isSameAddress', {
            is: false,
            then: Joi.string().required(),
            otherwise: Joi.string().optional().allow('')
        }),
        fiscalSuburb: Joi.when('isSameAddress', {
            is: false,
            then: Joi.string().required(),
            otherwise: Joi.string().optional().allow('')
        }),
        fiscalMunicipality: Joi.when('isSameAddress', {
            is: false,
            then: Joi.string().required(),
            otherwise: Joi.string().optional().allow('')
        }),
        fiscalPostalCode: Joi.when('isSameAddress', {
            is: false,
            then: Joi.string().required(),
            otherwise: Joi.string().optional().allow('')
        }),
        fiscalState: Joi.when('isSameAddress', {
            is: false,
            then: Joi.string().required(),
            otherwise: Joi.string().optional().allow('')
        }),
        fiscalCountry: Joi.when('isSameAddress', {
            is: false,
            then: Joi.string().required(),
            otherwise: Joi.string().optional().allow('')
        }),
        partners: Joi.array(),
        currentPartners: Joi.array(),
        members: Joi.array()
            .optional(),
        files: Joi.array()
            .optional(),
        status: Joi.string()
            .optional(),
        isSameAddress: Joi.boolean(),
        fileActa: Joi.valid(true),
        fileInstrument: Joi.valid(true),
        fileActions: Joi.valid(true),
        fileAddress: Joi.when('isSameAddress', {
            is: false,
            then: Joi.valid(true),
            otherwise: Joi.valid(false),
        }),

    };

    validate = () => {
        const options = { abortEarly: false };
        const data = { ...this.props.person };

        data.fileActa = !!this.props.person?.files?.find(m => m.type === 'acta');
        data.fileInstrument = !!this.props.person?.files?.find(m => m.type === 'instrument');
        data.fileActions = !!this.props.person?.files?.find(m => m.type === 'actions');
        data.fileAddress = !!this.props.person?.files?.find(m => m.type === 'address');

        const { error } = Joi.validate(data, this.schema, options);
        if (!error) return null;

        const errors = {};
        for (let item of error.details) errors[item.path[0]] = item.message;
        console.log('Form Type: ', this.props.type)
        console.log('Errores de validación', errors);
        if (Object.keys(errors).length === 0) {
            return null;
        } else {
            // Haz algo más con el objeto si no está vacío
            return errors;
        }
    };

    handleCheckboxChange = ({ currentTarget: input }) => {
        this.props.onChange('isSameAddress', input.checked);
    }

    handleChange = ({ currentTarget: input }) => {
        const errors = { ...this.state.errors };
        const errorMessage = this.validateProperty(input);
        if (errorMessage) errors[input.name] = errorMessage;
        else delete errors[input.name];

        this.props.onChange(input.name, input.value);
        this.setState({ errors });
    };

    handleDateChange = (name, value) => {
        this.props.onChange(name, `${value}`);
    };

    handlePartner = async (partner) => {
        try {
            const person = this.props.person;
            const partners = person.partners || [];
            const isEdit = partners.findIndex(m => m._id === partner._id);
            if (isEdit === -1) {
                partners.push(partner);
            } else {
                partners[isEdit] = partner;
            }
            this.props.onSavePartner(partners);
        } catch (ex) {
        }
    }

    handleCurrentPartner = async (partner) => {
        try {
            const person = this.props.person;
            const currentPartners = person.currentPartners || [];
            const isEdit = currentPartners.findIndex(m => m._id === partner._id);
            if (isEdit === -1) {
                currentPartners.push(partner);
            } else {
                currentPartners[isEdit] = partner;
            }
            this.props.onSaveCurrentPartner(currentPartners);
        } catch (ex) {
        }
    }

    handleMember = async (member) => {
        try {
            const person = this.props.person;
            const members = person.members || [];
            const isEdit = members.findIndex(m => m._id === member._id);
            if (isEdit === -1) {
                members.push(member);
            } else {
                members[isEdit] = member;
            }

            this.props.onSaveMember(members);
        } catch (ex) {
        }
    }

    handleUpload = async (file, edit, type) => {
        try {
            if (!file._id) {
                const doc = file.getRawFile();
                if (doc) {
                    const bucket = `cff-documents`
                    const path = `${doc.name}`;
                    await upload(path, doc, bucket).then(async (s3) => {
                        this.props.onDocument({
                            name: path,
                            url: s3.Location,
                            type: type,
                            uid: file.uid
                        }, false);
                    });
                }
            }
        } catch (e) {
            console.log(e);
        }
    }

    handleRemoveUpload = (file) => {
        const { files } = this.props.person;
        const originalFiles = files;
        const docs = originalFiles.filter(m => m.uid !== file.uid);
        this.props.onDocument(docs, true);
    }

    handleSubmit = async (e, status) => {
        e.preventDefault();
        const errors = this.validate();
        this.setState({ errors: errors || {} });
        this.props.onSave(status, errors);
    };

    render() {
        const { errors } = this.state;
        let data = this.props.person;
        return (
            <>
                <div className="row" data-html2canvas-ignore="true">
                    <div className="col-md-12 text-right">
                        <button
                            className="btn btn-primary"
                            onClick={() => this.props.onPDF(`Persona Moral - ${this.props.name}`, 'moral')}
                        >
                            Exportar
                        </button>
                    </div>
                </div>
                <form>
                    <div className="form-row">
                        <div className="col-md-9 mb-3 text-center">
                            <h3>PERSONA MORAL <br /> ANEXO <br /> FORMATO BENEFICIARIO CONTROLADOR 32-B TER y 32-B QUATER</h3>
                        </div>
                        <div className="col-md-3">
                            <img src={logo} alt="logo" className="img-fluid" />
                        </div>
                        <div className="col-md-12 form-label-group mb-3">
                            <input className="form-control" name="legalName" type="text" onChange={this.handleChange} value={data && data.legalName ? data.legalName : ""} error={errors['legalName']} />
                            <label>Denominación</label>
                        </div>
                        <div className="col-md-6 form-label-group mb-3">
                            <input className="form-control" name="comercialName" type="text" onChange={this.handleChange} value={data && data.comercialName ? data.comercialName : ""} error={errors['comercialName']} />
                            <label>Nombre comercial</label>
                        </div>
                        <div className="col-md-6 form-label-group mb-3">
                            <input className="form-control" name="nationality" type="text" onChange={this.handleChange} value={data && data.nationality ? data.nationality : ""} error={errors['nationality']} />
                            <label>Nacionalidad</label>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-12 form-label-group mb-6">
                            <h5>Datos de constitución de la persona moral o celebración del acto jurídico</h5>
                        </div>
                        <div className="col-md-4 form-label-group mb-3">
                            <input className="form-control" name="numberInstrument" type="number" onChange={this.handleChange} value={data && data.numberInstrument ? data.numberInstrument : ""} error={errors['numberInstrument']} />
                            <label>No. de instrumento</label>
                        </div>
                        <div className="col-md-4 mb-3">
                            <SingleDatePicker
                                date={data && data.birthDate ? moment(data.birthDate) : null} // momentPropTypes.momentObj or null
                                onDateChange={(date) => this.handleDateChange('birthDate', date)} // PropTypes.func.isRequired
                                focused={this.state.focused} // PropTypes.bool
                                onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
                                displayFormat="DD/MM/YYYY"
                                numberOfMonths={1}
                                isOutsideRange={() => false}
                            />
                        </div>
                        <div className="col-md-4 form-label-group mb-3">
                            <input className="form-control" name="notaryName" type="text" onChange={this.handleChange} value={data && data.notaryName ? data.notaryName : ""} error={errors['notaryName']} />
                            <label>Nombre Fedatario</label>
                        </div>
                        <div className="col-md-4 form-label-group mb-3">
                            <input className="form-control" name="numberNotary" type="number" onChange={this.handleChange} value={data && data.numberNotary ? data.numberNotary : ""} error={errors['numberNotary']} />
                            <label>No. Fedatario</label>
                        </div>
                        <div className="col-md-4 form-label-group mb-3">
                            <input className="form-control" name="entityNotary" type="text" onChange={this.handleChange} value={data && data.entityNotary ? data.entityNotary : ""} error={errors['entityNotary']} />
                            <label>Entidad</label>
                        </div>
                        <div className="col-md-4 form-label-group mb-3">
                            <input className="form-control" name="folio" type="text" onChange={this.handleChange} value={data && data.folio ? data.folio : ""} error={errors['folio']} />
                            <label>Folio/Datos de inscripción</label>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-8 form-label-group mb-6">
                            <input className="form-control" name="capital" type="number" onChange={this.handleChange} value={data && data.capital ? data.capital : ""} error={errors['capital']} />
                            <label>Capital social total al momento de constitución</label>
                        </div>
                        <div className="col-md-4 form-label-group mb-6">
                            <input className="form-control" name="capitalCurrency" type="text" onChange={this.handleChange} value={data && data.capitalCurrency ? data.capitalCurrency : ""} error={errors['capitalCurrency']} />
                            <label>Moneda</label>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-12 form-label-group mb-6">
                            <Partners onPartner={this.handlePartner} partners={data.partners || []} onDelete={this.props.onDeletePartner} />
                        </div>
                        <div className="col-md-12 mb-3 table-pr">
                            <CurrentPartners onPartner={this.handleCurrentPartner} partners={data.currentPartners || []} onDelete={this.props.onDeleteCurrentPartner} />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-12 mb-3 table-pr">
                            <Members onMember={this.handleMember} members={data.members} onDelete={this.props.onDeleteMember} />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-12 form-label-group mb-3">
                            <input className="form-control" name="birthCountry" type="text" onChange={this.handleChange} value={data && data.birthCountry ? data.birthCountry : ""} error={errors['birthCountry']} />
                            <label>Pais o jurisdicción de residencia para efectos fiscales</label>
                        </div>
                        <div className="col-md-12 form-label-group mb-3">
                            <input className="form-control" name="taxId" type="text" onChange={this.handleChange} value={data && data.taxId ? data.taxId : ""} error={errors['taxId']} />
                            <label>Clave en el rfc o equivalente de ser residente fiscal en el extranjero</label>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-12 form-label-group mb-6">
                            <h5>Datos de Contacto</h5>
                        </div>
                        <div className="col-md-6 form-label-group mb-3">
                            <input className="form-control" name="phone" type="text" onChange={this.handleChange} value={data && data.phone ? data.phone : ""} error={errors['phone']} />
                            <label>Teléfono</label>
                        </div>
                        <div className="col-md-6 form-label-group mb-3">
                            <input className="form-control" name="email" type="text" onChange={this.handleChange} value={data && data.email ? data.email : ""} error={errors['email']} />
                            <label>Correo electrónico</label>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-12 form-label-group mb-6">
                            <h5>Domicilio Convencional</h5>
                        </div>
                        <div className="col-md-3 form-label-group mb-3">
                            <input className="form-control" name="street" type="text" onChange={this.handleChange} value={data && data.street ? data.street : ""} error={errors['street']} />
                            <label>Calle</label>
                        </div>
                        <div className="col-md-3 form-label-group mb-3">
                            <input className="form-control" name="noExt" type="text" onChange={this.handleChange} value={data && data.noExt ? data.noExt : ""} error={errors['noExt']} />
                            <label>Número exterior</label>
                        </div>
                        <div className="col-md-3 form-label-group mb-3">
                            <input className="form-control" name="noInt" type="text" onChange={this.handleChange} value={data && data.noInt ? data.noInt : ""} error={errors['noInt']} />
                            <label>Número interior</label>
                        </div>
                        <div className="col-md-3 form-label-group mb-3">
                            <input className="form-control" name="suburb" type="text" onChange={this.handleChange} value={data && data.suburb ? data.suburb : ""} error={errors['suburb']} />
                            <label>Colonia</label>
                        </div>
                        <div className="col-md-3 form-label-group mb-3">
                            <input className="form-control" name="municipality" type="text" onChange={this.handleChange} value={data && data.municipality ? data.municipality : ""} error={errors['suburb']} />
                            <label>Municipio o alcaldía</label>
                        </div>
                        <div className="col-md-3 form-label-group mb-3">
                            <input className="form-control" name="postalCode" type="text" onChange={this.handleChange} value={data && data.postalCode ? data.postalCode : ""} error={errors['postalCode']} />
                            <label>Código Postal</label>
                        </div>
                        <div className="col-md-3 form-label-group mb-3">
                            <input className="form-control" name="state" type="text" onChange={this.handleChange} value={data && data.state ? data.state : ""} error={errors['state']} />
                            <label>Estado</label>
                        </div>
                        <div className="col-md-3 form-label-group mb-3">
                            <input className="form-control" name="country" type="text" onChange={this.handleChange} value={data && data.country ? data.country : ""} error={errors['country']} />
                            <label>País</label>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-12 form-label-group mb-6">
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id="isSameAddress" name="isSameAddress" onChange={this.handleCheckboxChange} checked={data && data.isSameAddress ? data.isSameAddress : false} />
                                <label className="custom-control-label" htmlFor="isSameAddress">Declaro que mi domicilio fiscal es el mismo que el particular</label>
                            </div>
                        </div>
                    </div>
                    {data && !data.isSameAddress && (
                        <div className="form-row">
                            <div className="col-md-12 mb-6">
                                <h5>Domicilio Fiscal (Debe corresponder con la CSF)</h5>
                            </div>
                            <div className="col-md-3 form-label-group mb-3">
                                <input className="form-control" name="fiscalStreet" type="text" onChange={this.handleChange} value={data && data.fiscalStreet ? data.fiscalStreet : ""} error={errors['fiscalStreet']} />
                                <label>Calle</label>
                            </div>
                            <div className="col-md-3 form-label-group mb-3">
                                <input className="form-control" name="fiscalNoExt" type="text" onChange={this.handleChange} value={data && data.fiscalNoExt ? data.fiscalNoExt : ""} error={errors['fiscalNoExt']} />
                                <label>Número exterior</label>
                            </div>
                            <div className="col-md-3 form-label-group mb-3">
                                <input className="form-control" name="fiscalNoInt" type="text" onChange={this.handleChange} value={data && data.fiscalNoInt ? data.fiscalNoInt : ""} error={errors['fiscalNoInt']} />
                                <label>Número interior</label>
                            </div>
                            <div className="col-md-3 form-label-group mb-3">
                                <input className="form-control" name="fiscalSuburb" type="text" onChange={this.handleChange} value={data && data.fiscalSuburb ? data.fiscalSuburb : ""} error={errors['fiscalSuburb']} />
                                <label>Colonia</label>
                            </div>
                            <div className="col-md-3 form-label-group mb-3">
                                <input className="form-control" name="fiscalMunicipality" type="text" onChange={this.handleChange} value={data && data.fiscalMunicipality ? data.fiscalMunicipality : ""} error={errors['fiscalMunicipality']} />
                                <label>Municipio o alcaldía</label>
                            </div>
                            <div className="col-md-3 form-label-group mb-3">
                                <input className="form-control" name="fiscalPostalCode" type="text" onChange={this.handleChange} value={data && data.fiscalPostalCode ? data.fiscalPostalCode : ""} error={errors['fiscalPostalCode']} />
                                <label>Código Postal</label>
                            </div>
                            <div className="col-md-3 form-label-group mb-3">
                                <input className="form-control" name="fiscalState" type="text" onChange={this.handleChange} value={data && data.fiscalState ? data.fiscalState : ""} error={errors['fiscalState']} />
                                <label>Estado</label>
                            </div>
                            <div className="col-md-3 form-label-group mb-3">
                                <input className="form-control" name="fiscalCountry" type="text" onChange={this.handleChange} value={data && data.fiscalCountry ? data.fiscalCountry : ""} error={errors['fiscalCountry']} />
                                <label>País</label>
                            </div>
                        </div>
                    )}
                    <div className="form-row" data-html2canvas-ignore="true">
                        <div className="col-md-12 form-label-group mb-6">
                            <h5>Documentos</h5>
                        </div>
                        <div className="col-md-12 form-label-group mb-6">
                            <h5>Acta constitutiva con inscripción al RPC.</h5>
                            {
                                data.files && data.files.filter(f => f.type === 'acta').length > 0 &&
                                (<Uploader files={data.files.filter(f => f.type === 'acta')} onUpload={this.handleUpload} onRemove={this.handleRemoveUpload} type={'acta'} bucket={'cff-documents'} />)
                            }
                            {
                                (!data.files || (data.files && data.files.filter(f => f.type === 'acta').length === 0)) &&
                                (<Uploader files={[]} onUpload={this.handleUpload} onRemove={this.handleRemoveUpload} type={'acta'} bucket={'cff-documents'} />)
                            }
                        </div>
                        <div className="col-md-12 form-label-group mb-6">
                            <h5>Instrumento en el que conste la actual administración.</h5>
                            {
                                data.files && data.files.filter(f => f.type === 'instrument').length > 0 &&
                                (<Uploader files={data.files.filter(f => f.type === 'instrument')} onUpload={this.handleUpload} onRemove={this.handleRemoveUpload} type={'instrument'} bucket={'cff-documents'} />)
                            }
                            {
                                (!data.files || (data.files && data.files.filter(f => f.type === 'instrument').length === 0)) &&
                                (<Uploader files={[]} onUpload={this.handleUpload} onRemove={this.handleRemoveUpload} type={'instrument'} bucket={'cff-documents'} />)
                            }
                        </div>
                        <div className="col-md-12 form-label-group mb-6">
                            <h5>Documento que acredite la actual estructura accionaria (instrumento público, asiento de libro de registro de acciones, aviso de actualización de estructura accionaria SAT, aviso de actualización de estructura accionaria PSM, etc.).</h5>
                            {
                                data.files && data.files.filter(f => f.type === 'actions').length > 0 &&
                                (<Uploader files={data.files.filter(f => f.type === 'actions')} onUpload={this.handleUpload} onRemove={this.handleRemoveUpload} type={'actions'} bucket={'cff-documents'} />)
                            }
                            {
                                (!data.files || (data.files && data.files.filter(f => f.type === 'actions').length === 0)) &&
                                (<Uploader files={[]} onUpload={this.handleUpload} onRemove={this.handleRemoveUpload} type={'actions'} bucket={'cff-documents'} />)
                            }
                        </div>
                        <div className="col-md-12 form-label-group mb-6">
                            <h5>Constancia de Situación Fiscal o Equivalente</h5>
                            {
                                data.files && data.files.filter(f => f.type === 'tax').length > 0 &&
                                (<Uploader files={data.files.filter(f => f.type === 'tax')} onUpload={this.handleUpload} onRemove={this.handleRemoveUpload} type={'tax'} bucket={'cff-documents'} />)
                            }
                            {
                                (!data.files || (data.files && data.files.filter(f => f.type === 'tax').length === 0)) &&
                                (<Uploader files={[]} onUpload={this.handleUpload} onRemove={this.handleRemoveUpload} type={'tax'} bucket={'cff-documents'} />)
                            }
                        </div>
                        {data && data.isSameAddress && (
                            <div className="col-md-12 form-label-group mb-6">
                                <h5>Comprobante de domicilio</h5>
                                {
                                    data.files && data.files.filter(f => f.type === 'address-home').length > 0 &&
                                    (<Uploader files={data.files.filter(f => f.type === 'address-home')} onUpload={this.handleUpload} onRemove={this.handleRemoveUpload} type={'address-home'} bucket={'cff-documents'} />)
                                }
                                {
                                    (!data.files || (data.files && data.files.filter(f => f.type === 'address-home').length === 0)) &&
                                    (<Uploader files={[]} onUpload={this.handleUpload} onRemove={this.handleRemoveUpload} type={'address-home'} bucket={'cff-documents'} />)
                                }
                            </div>
                        )}
                    </div>
                    <button className="btn btn-primary btn-large float-right mb-5" onClick={(e) => this.handleSubmit(e, 'pending')}>Guardar</button>
                    {/*{!this.validate() && (<button className="btn btn-success btn-large float-right mb-5 mr-3" onClick={(e) => this.handleSubmit(e, 'complete')}>Enviar</button>)}*/}
                </form>
            </>
        );
    }
}

export default LegalForm;
