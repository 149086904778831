import React, { Component } from 'react';
import { Link } from "react-router-dom";
import UsersTable from "./usersTable";
import { getCurrentUser } from "../services/authService";
import { getUsers, deleteUser, saveUser } from "../services/userService";
import { paginate } from "../utils/paginate";
import ActionsTableUsers from './ActionsUser';
import { getRoles } from "../services/roleService";
import _ from "lodash";
import { toast } from 'react-toastify';


class Users extends Component {
    state = {
        users: [],
        currentPage: 1,
        pageSize: 20,
        sortColumn: { path: "name", order: "asc" },
        roles: [],
    }

    async populateUser() {
        const { data: user } = await getCurrentUser();
        if (user) this.setState({ user });
    }

    async populateRoles() {
        const { data: roles } = await getRoles();
        this.setState({ roles });
    }

    async populateUsers() {
        const { user } = this.state;
        const { data: users } = await getUsers();
        const usersUpdate = users.map(u => {
            u.nameUser = u.name;
            u.name = <Link to={`/users/${u._id}`}>{u.name}</Link>
            u.role = u.role && u.role.name
            u.delete = <ActionsTableUsers key={u._id} user={user} u={u} handleDelete={this.handleDelete} handleUpdate={this.handleUpdate} />;
            return u;
        });

        this.setState({ users: usersUpdate });
    }


    async componentDidMount() {
        await this.populateUser();
        await this.populateUsers();
        await this.populateRoles();
    }


    handlePageChange = page => {
        this.setState({ currentPage: page });
    };

    handleSearch = query => {
        this.setState({ searchQuery: query, currentPage: 1 });
    };

    handleDelete = async user => {
        const originalUsers = this.state.users;
        const users = originalUsers.filter(m => m !== user);
        this.setState({ users });
        try {
            await deleteUser(user._id);
        } catch (ex) {
            this.setState({ users: originalUsers });
        }
    };

    handleUpdate = async (user) => {
        try {
            const role_id = this.state.roles.find(r => r.name === user.role)._id;
            const userSave = {
                _id: user._id,
                name: user.nameUser,
                email: user.email,
                role: role_id,
                goal: user.goal,
                comRegular: user.comRegular,
                comValuation: user.comValuation,
                testimnyNotification: !user.testimnyNotification
            };
            await saveUser(userSave);
            await this.populateUsers();
            if(user.testimnyNotification){
                toast.success("Las notificaciones de testimonio se desactivaron correctamente.");
            }else{
                toast.success("Las notificaciones de testimonio se activaron correctamente.");
            }
        } catch (ex) {
            toast.error("Ocurrió un error al activar las notificaciones de testimonio.");
        }
    };

    getPagedData = () => {
        const {
            pageSize,
            currentPage,
            sortColumn,
            searchQuery,
            users: allUsers
        } = this.state;

        let filtered = allUsers;
        if (searchQuery)
            filtered = allUsers.filter(m =>
                m.name.toLowerCase().startsWith(searchQuery.toLowerCase()) ||
                m.email.toLowerCase().startsWith(searchQuery.toLowerCase()) ||
                m.role.toLowerCase().startsWith(searchQuery.toLowerCase()) ||
                m.goal.toString().toLowerCase().startsWith(searchQuery.toLowerCase()) ||
                m.comRegular.toString().toLowerCase().startsWith(searchQuery.toLowerCase()) ||
                m.comValuation.toString().toLowerCase().startsWith(searchQuery.toLowerCase())
            );

        const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

        const users = paginate(sorted, currentPage, pageSize);

        return { totalCount: filtered.length, data: users };
    };

    handleSort = sortColumn => {
        this.setState({ sortColumn });
    };

    render() {
        //const { length: count } = this.state.users;
        const { sortColumn } = this.state;
        const { user } = this.props;

        //if (count === 0) return <p>There are no instrumentos in database.</p>;

        const { data: users } = this.getPagedData();
        return (
            <div>
                <div className="form-row mt-3 mb-3">
                    <div className="col-lg-10">
                        <div className="float-left">
                            {user && (
                                <Link
                                    to="/users/new"
                                    className="btn btn-primary float-right"
                                >
                                    <i className="fa fa-plus" aria-hidden="true" /><span> </span>
                                    Nuevo Usuario
                                </Link>
                            )}
                        </div>
                    </div>
                    {/* <div className="col-lg-2">
                        <SearchBox value={searchQuery} onChange={this.handleSearch} />
                    </div> */}
                </div>
                <UsersTable
                    users={users}
                    sortColumn={sortColumn}
                    // onDelete={this.handleDelete}
                    onSort={this.handleSort}
                />
                {/* <Pagination
                    itemsCount={totalCount}
                    pageSize={pageSize}
                    currentPage={currentPage}
                    onPageChange={this.handlePageChange}
                /> */}
            </div>
        );
    }
}

export default Users;