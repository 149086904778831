import React, { Component } from 'react'
import Joi from 'joi-browser'
import Input from './input'
import Select from './select'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Textarea from './textarea'

class Form extends Component {
  state = {
    data: {},
    errors: {}
  }

  validate = () => {
    const options = { abortEarly: false }
    const { error } = Joi.validate(this.state.data, this.schema, options)
    if (!error) return null

    const errors = {}
    for (let item of error.details) errors[item.path[0]] = item.message
    return errors
  }

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value }
    const schema = { [name]: this.schema[name] }
    const { error } = Joi.validate(obj, schema)
    return error ? error.details[0].message : null
  }

  handleSubmit = e => {
    e.preventDefault()

    // const errors = this.validate();
    // this.setState({ errors: errors || {} });
    // if (errors) return;

    this.doSubmit()
  }

  handleChange = async ({ currentTarget: input }) => {
    const errors = { ...this.state.errors }
    // console.log(input)
    //const errorMessage = this.validateProperty(input);
    // if (errorMessage) errors[input.name] = errorMessage;
    // else delete errors[input.name];
    if (input.name === 'role') {
      const data = { ...this.state.data }
      data['roleId'] = input.value
      this.setState({ data })
    }

    const data = { ...this.state.data }
    data[input.name] = input.value

    this.setState({ data, errors })
  }

  handleChangeDate = date => {
    const data = { ...this.state.data }
    data['paymentDate'] ? (data['paymentDate'] = date) : (data['date'] = date)
    this.setState({ data })
    //data.statusId = status ? status[0]._id : data.statusId;
    //this.setState({ data });
  }

  renderButton (label) {
    return (
      <button className="btn btn-primary">
        {label}
      </button>
    )
  }

  renderSelect (name, label, options, handleChange = null, disabled = false) {
    const { data, errors } = this.state

    return (
      <Select
        name={name}
        value={data[name]?._id || data[name]}
        label={label}
        options={options}
        onChange={handleChange || this.handleChange}
        error={errors[name]}
        disabled={disabled}
      />
    )
  }

  renderSelect2 (name, label, options) {
    return (
      <div className="form-group">
        <label htmlFor={name}>{label}</label>
        <select name={name} id={name} onChange={this.handleChange} className="form-control">
          <option value=""/>
          {options.map(option => (
            <option key={option._id} value={option._id}>
              {option.number} - {option.type} - {option.description}
            </option>
          ))}
        </select>
      </div>
    )
  }

  renderInput (name, label, type = 'text', readOnly, disabled, required) {
    const { data, errors } = this.state

    return (
      <Input
        type={type}
        name={name}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        error={errors[name]}
        readOnly={readOnly}
        disabled={disabled}
        required={required}
      />
    )
  }

  renderTextArea (name, label, rows = 3) {
    const { data, errors } = this.state

    return (
      <Textarea
        name={name}
        label={label}
        value={data[name]}
        rows={rows}
        onChange={this.handleChange}
        error={errors[name]}
      />
    )
  }

  renderDate (name, label, handleChangeDate) {
    const { data, errors } = this.state
    // console.log(handleChangeDate, 'handleChangeDate')
    return (
      <div className="form-label-group">
        <DatePicker
          id={name}
          selected={data[name]}
          onChange={handleChangeDate}
          dateFormat="dd/MM/yyyy"
          className="form-control"
          placeholderText={label}
        />
        <label htmlFor={name}>{label}</label>
        {errors[name] && <div className="alert alert-danger">{errors[name]}</div>}
      </div>
    )
  }
}

export default Form
