import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Check from "./common/check";
import ValuationsTable from "./valuationsTable";
import { getCurrentUser } from "../services/authService";
import { getValuations, valuationArchivedUpdate, deleteValuation } from "../services/valuationService";
import { paginate } from "../utils/paginate";
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment  from 'moment';
import _ from "lodash";

class Valuations extends Component {
    state = {
        valuations: [],
        currentPage: 1,
        pageSize: 1000,
        sortColumn: { path: "number", order: "asc" },
        startDate: null,
        endDate: null,
        focusedInput: null,
        isLoading: true
     }

    async populateUser () {
        const {data: user } = await getCurrentUser();
        if(user) this.setState({ user });
    }

    async populateValuations() {
        const { user } = this.state;
        const { data: valuations } = user && user.role && user.role.actions.some(a => a.method === "all-valuation") ? await getValuations() : [];
        this.setState({ valuations: valuations, isLoading: false });
    }

    async componentDidMount() {
        await this.populateUser();
        await this.populateValuations();
    }

    handleArchive = async updateValuation => {
        const originalValuations = this.state.valuations;
        const valuations = [...originalValuations];
        const index = valuations.indexOf(updateValuation);
        valuations[index] = { ...valuations[index] };
        valuations[index].archived = !updateValuation.archived
        this.setState({valuations})

        try {
            await valuationArchivedUpdate(updateValuation._id, !updateValuation.archived);
        } catch (ex) {
            if (ex.response && ex.response.status === 400)
            this.setState({ valuations: originalValuations });
        }
    };

    handleDelete = async valuation => {
        const originalValuations = this.state.valuations;
        const valuations = originalValuations.filter(m => m !== valuation);
        this.setState({ valuations });

        try {
            await deleteValuation(valuation._id);
        } catch (ex) {
            this.setState({ valuations: originalValuations });
        }
    };

    render() {
        const { sortColumn, valuations, user, isLoading } = this.state;

        return (
            <div>
                <div className="form-row mt-3 mb-3">
                    <div className="col-lg-7">
                        <div className="float-left">
                            { user && user.role && user.role.actions.some(a => a.method === "add-valuation") && (
                            // {user && (user.role === "Abogado" || user.role === "Administrador") ? (
                                <Link
                                    to="/valuations/new"
                                    className="btn btn-primary float-right"
                                    >
                                    <i className="fa fa-plus" aria-hidden="true" /><span> </span>
                                    Nuevo Avaluo
                                </Link>
                            )}
                        </div>
                    </div>
                </div>
                <ValuationsTable
                    valuations={valuations}
                    user={user}
                    handleArchive={this.handleArchive}
                    handleDelete={this.handleDelete}
                    isLoading={isLoading}
                />
            </div>
        );
    }
}

export default Valuations;
