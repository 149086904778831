import Joi from "joi-browser";
import Form from "../common/form";
import Uploader from "../common/uploader"
import { SingleDatePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import { upload } from '../../utils/s3';
import logo from '../../logo-pdf.png'
import React from 'react'

class FisicForm extends Form {
    state = {
        data: {
            partners: [],
            members: [],
            files: [],
            civilStatus: "single"
        },
        genders: [
            { _id: "male", name: "Masculino" },
            { _id: "female", name: "Femenino" }
        ],
        civilStatus: [
            { _id: "single", name: "Soltero" },
            { _id: "marriedPartnership", name: "Casado - Sociedad Conyugal" },
            { _id: "marriedSeparation", name: "Casado - Separación de Bienes" },
            { _id: "concubinage", name: "Concubinato" }
        ],
        typesId: [
            { _id: "ine", name: "INE/IFE" },
            { _id: "passport", name: "Pasaporte" },
            { _id: "driver_licence", name: "Licencia de conducir" },
            { _id: "professional_license", name: "Cédula profesional" },
            { _id: "migration", name: "Forma Migratoria" },
            { _id: "resident_card", name: "Tarjeta de residente" },
            { _id: "other", name: "Otro" },

        ],
        patrimonialRegime:[
            { _id: "separation_of_goods", name: "Separación de bienes" },
            { _id: "conjugal_society", name: "Sociedad conyugal" },

        ],
        focused1: false,
        focused2: false,
        files: [],
        docs: [],
        errors: {},
        uploaders: {
            single: true,
            concubinage: true,
            married: true,
            maritalRegime: true
        }
    };

    // validate with Joi  #physic-person-form
    schema = {
        _id: Joi.string(),
        personType: Joi.string().required(),
        name: Joi.string().required(),
        lastName: Joi.string().required(),
        motherLastName: Joi.string().required(),
        birthDate: Joi.string().required(),
        gender: Joi.string().required(),
        alias: Joi.string().required(),
        bornCity: Joi.string().required(),
        bornState: Joi.string().required(),
        bornCountry: Joi.string().required(),
        nacionalities: Joi.string().required(),
        profession: Joi.string().required(),
        business: Joi.string().required(),
        curp: Joi.string().required(),
        taxId: Joi.string().required(),
        idType: Joi.string().required(),
        idNumber: Joi.string().required(),
        emisor: Joi.string().required(),
        civilStatus: Joi.string().required(),
        spouseName: Joi.when('civilStatus', {
            is: 'single',
            then: Joi.string().optional().allow(''),
            otherwise: Joi.string().required()
        }),
        street: Joi.string().required(),
        exteriorNumber: Joi.string().required(),
        interiorNumber: Joi.string().required(),
        neighborhood: Joi.string().required(),
        city: Joi.string().required(),
        postalCode: Joi.string().required(),
        municipality: Joi.string().required(),
        state: Joi.string().required(),
        country: Joi.string().required(),
        countryResidence: Joi.string().required(),
        streetPhysical: Joi.when('isSameAddress', {
            is: false,
            then: Joi.string().required(),
            otherwise: Joi.string().optional().allow('')
        }),
        exteriorNumberPhysical: Joi.when('isSameAddress', {
            is: false,
            then: Joi.string().required(),
            otherwise: Joi.string().optional().allow('')
        }),
        interiorNumberPhysical: Joi.when('isSameAddress', {
            is: false,
            then: Joi.string().required(),
            otherwise: Joi.string().optional().allow('')
        }),
        neighborhoodPhysical: Joi.when('isSameAddress', {
            is: false,
            then: Joi.string().required(),
            otherwise: Joi.string().optional().allow('')
        }),
        cityPhysical: Joi.when('isSameAddress', {
            is: false,
            then: Joi.string().required(),
            otherwise: Joi.string().optional().allow('')
        }),
        postalCodePhysical: Joi.when('isSameAddress', {
            is: false,
            then: Joi.string().required(),
            otherwise: Joi.string().optional().allow('')
        }),
        municipalityPhysical: Joi.when('isSameAddress', {
            is: false,
            then: Joi.string().required(),
            otherwise: Joi.string().optional().allow('')
        }),
        statePhysical: Joi.when('isSameAddress', {
            is: false,
            then: Joi.string().required(),
            otherwise: Joi.string().optional().allow('')
        }),
        countryPhysical: Joi.when('isSameAddress', {
            is: false,
            then: Joi.string().required(),
            otherwise: Joi.string().optional().allow('')
        }),
        phone: Joi.string().required(),
        email: Joi.string().required(),
        cellphone: Joi.string().required(),
        streetForeign: Joi.string().required(),
        exteriorNumberForeign: Joi.string().required(),
        interiorNumberForeign: Joi.string().required(),
        neighborhoodForeign: Joi.string().required(),
        cityForeign: Joi.string().required(),
        postalCodeForeign: Joi.string().required(),
        municipalityForeign: Joi.string().required(),
        stateForeign: Joi.string().required(),
        countryForeign: Joi.string().required(),
        nameRepresentative: Joi.string().required(),
        lastNameRepresentative: Joi.string().required(),
        motherLastNameRepresentative: Joi.string().required(),
        birthDateRepresentative: Joi.string().required(),
        genderRepresentative: Joi.string().required(),
        aliasRepresentative: Joi.string().required(),
        bornCityRepresentative: Joi.string().required(),
        bornStateRepresentative: Joi.string().required(),
        bornCountryRepresentative: Joi.string().required(),
        nacionalitiesRepresentative: Joi.string().required(),
        professionRepresentative: Joi.string().required(),
        businessRepresentative: Joi.string().required(),
        curpRepresentative: Joi.string().required(),
        taxIdRepresentative: Joi.string().required(),
        idTypeRepresentative: Joi.string().required(),
        idNumberRepresentative: Joi.string().required(),
        emisorRepresentative: Joi.string().required(),
        civilStatusRepresentative: Joi.string().required(),
        spouseNameRepresentative: Joi.when('civilStatusRepresentative', {
            is: 'single',
            then: Joi.string().optional().allow(''),
            otherwise: Joi.string().required()
        }),
        manifestation: Joi.string().required(),
        manifestationRepresentant: Joi.when('manifestation', {
            is: 'manifestation2',
            then: Joi.string().required(),
            otherwise: Joi.string().optional().allow('')
        }),
        bcname: Joi.when('manifestation', {
            is: 'manifestation2',
            then: Joi.string().required(),
            otherwise: Joi.string().optional().allow('')
        }),
        indetifyBC: Joi.when('manifestation', {
            is: 'manifestation2',
            then: Joi.string().required(),
            otherwise: Joi.string().optional().allow('')
        }),
        useRights: Joi.string().required(),
        iknowBC2: Joi.string().required(),
        partners: Joi.array(),
        currentPartners: Joi.array(),
        members: Joi.array().optional(),
        isSameAddress: Joi.boolean(),
        files: Joi.array().optional(),
        status: Joi.string().optional(),
    };

    validate = () => {
        const options = { abortEarly: false };
        const { error } = Joi.validate(this.props.person, this.schema, options);
        if (!error) return null;
        const errors = {};
        for (let item of error.details) errors[item.path[0]] = item.message;
        console.log('Errores de validación', this.props.type);
        if(this.props.type === 'Protocolización') {
            delete errors['manifestation'];
            delete errors['useRights'];
        }

        if (Object.keys(errors).length === 0) {
            return null;
          } else {
            // Haz algo más con el objeto si no está vacío
            return errors;
          }
        
    };

    handleChange = ({ currentTarget: input }) => {
        const errors = { ...this.state.errors };
        const errorMessage = this.validateProperty(input);
        if (errorMessage) errors[input.name] = errorMessage;
        else delete errors[input.name];

        this.props.onChange(input.name, input.value);
        this.setState({ errors });
    };

    handleCheckboxChange = ({ currentTarget: input }) => {
        this.props.onChange('isSameAddress', input.checked);
    }

    handleSelectChange = ({ currentTarget: input }) => {
        const { data } = this.state;
        const errors = { ...this.state.errors };
        const errorMessage = this.validateProperty(input);
        if (errorMessage) errors[input.name] = errorMessage;
        else delete errors[input.name];

        this.handleCivilStatus(input.value);
        this.props.onChange(input.name, input.value);
    };

    handleCivilStatus = (civilStatus) => {
        let { uploaders } = this.state;
        if (civilStatus === 'concubinage') {
            uploaders.concubinage = true;
            uploaders.married = false;
        } else if (civilStatus === 'marriedPartnership' || civilStatus === 'marriedSeparation') {
            uploaders.married = true;
            uploaders.concubinage = false;
        } else if (civilStatus === 'single') {
            uploaders.concubinage = false;
            uploaders.married = false;
        }
    };

    handleDateChange = (name, value) => {
        this.props.onChange(name, `${value}`);
    };

    handleUpload = async (file, edit, type) => {
        try {
            if (!file._id) {
                const doc = file.getRawFile();
                if (doc) {
                    const bucket = `cff-documents`
                    const path = `${doc.name}`;
                    await upload(path, doc, bucket).then(async (s3) => {
                        this.props.onDocument({
                            name: path,
                            url: s3.Location,
                            type: type,
                            uid: file.uid,
                            getRawFile: file.getRawFile
                        }, false);
                    });
                }
            }
        } catch (e) {
            console.log(e);
        }
    }

    handleRemoveUpload = (file) => {
        const { files } = this.props.person;
        const originalFiles = files;
        const docs = originalFiles.filter(m => m.uid !== file.uid);
        this.props.onDocument(docs, true);
    }

    handleSubmit = async (e, status) => {
        e.preventDefault();
        const errors = this.validate();
        console.log("🚀 ~ file: fisicForm.jsx:292 ~ FisicForm ~ handleSubmit= ~ errors:", errors);
        this.setState({ errors: errors || {} });
        this.props.onSave(status, errors);
    };

    render() {
        const { uploaders, errors } = this.state;
        let data = this.props.person;
        this.handleCivilStatus(data.civilStatus);

        // Fisic form
        return (
          <>
              <div className="row" data-html2canvas-ignore="true">
                  <div className="col-md-12 text-right">
                      <button
                        className="btn btn-primary"
                        onClick={() => this.props.onPDF(`Persona Física - ${this.props.name}`, 'physic')}
                      >
                          Exportar
                      </button>
                  </div>
              </div>
            <form id="physic-person-form">
                <div className="form-row">
                    <div className="col-md-9 mb-4 text-center">
                        <h3>PERSONA FÍSICA <br /> ANEXO <br /> FORMATO BENEFICIARIO CONTROLADOR 32-B TER y 32-B QUATER</h3>
                    </div>
                    <div className="col-md-3">
                      <img src={logo} alt="logo" className="img-fluid" />
                    </div>
                  <div className="col-md-12">
                    <div className="form-row">
                      <div className="col-md-7 d-flex align-items-center">
                        <div className="form-check form-check-inline">
                          <input className="form-check-input" type="radio" name="personType" id="personType1" value="Mexicano" onChange={this.handleChange} checked={data.personType === 'Mexicano'} error={errors.personType}/>
                          <label className="form-check-label" htmlFor="personType1">Mexicano</label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input className="form-check-input" type="radio" name="personType" id="personType2" value="Extranjero visitante" onChange={this.handleChange} checked={data.personType === 'Extranjero visitante'} error={errors.personType}/>
                          <label className="form-check-label" htmlFor="personType2">Extranjero visitante</label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input className="form-check-input" type="radio" name="personType" id="personType3" value="Residente" onChange={this.handleChange} checked={data.personType === 'Residente'} error={errors.personType}/>
                          <label className="form-check-label" htmlFor="personType3">Residente (temporal o permanente)</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr/>
                  <div className="col-md-12 form-label-group mb-6">
                    <h5>Datos generales de identificación</h5>
                  </div>
                    <div className="col-md-4 form-label-group mb-3">
                        <input className="form-control" name="name" type="text" onChange={this.handleChange} value={data && data.name ? data.name : ""} error={errors['name']} />
                        <label>Nombre(s)</label>
                    </div>
                    <div className="col-md-4 form-label-group mb-3">
                        <input className="form-control" name="lastName" type="text" onChange={this.handleChange} value={data && data.lastName ? data.lastName : ""} error={errors['lastName']} />
                        <label>Apellido Paterno</label>
                    </div>
                    <div className="col-md-4 form-label-group mb-3">
                        <input className="form-control" name="motherLastName" type="text" onChange={this.handleChange} value={data && data.motherLastName ? data.motherLastName : ""} error={errors['motherLastName']} />
                        <label>Apellido Materno</label>
                    </div>

                    <div className="col-md-4 mb-3">
                        <SingleDatePicker
                          id="birthDate"
                          date={data && data.birthDate ? moment(data.birthDate) : null} // momentPropTypes.momentObj or null
                          onDateChange={(date) => this.handleDateChange('birthDate', date)} // PropTypes.func.isRequired
                          focused={this.state.focused1} // PropTypes.bool
                          onFocusChange={({ focused }) => this.setState({ focused1: focused })} // PropTypes.func.isRequired
                          displayFormat="DD/MM/YYYY"
                          numberOfMonths={1}
                          isOutsideRange={() => false}
                        />
                    </div>
                    <div className="col-md-4 form-label-group mb-3">
                        <select name="gender" id="gender" className="form-control" onChange={this.handleChange} error={errors['gender']} value={data.gender}>
                            <option value="">Selecciona...</option>
                            {this.state.genders.map(option => (
                              <option key={option._id} value={option._id}>
                                  {option.name}
                              </option>
                            ))}
                        </select>
                        <label>Sexo</label>
                    </div>
                    <div className="col-md-4 form-label-group mb-3">
                        <input  className="form-control" name="alias" type="text" onChange={this.handleChange} value={data && data.alias ? data.alias : ""} error={errors['alias']} />
                        <label>Alias</label>
                    </div>
                    <div className="col-md-3 form-label-group mb-3">
                        <input   className="form-control" name="bornCity" type="text" onChange={this.handleChange} value={data && data.bornCity ? data.bornCity : ""} error={errors['bornCity']} />
                        <label>Ciudad de nacimiento</label>
                    </div>
                    <div className="col-md-3 form-label-group mb-3">
                        <input  className="form-control" name="bornState" type="text" onChange={this.handleChange} value={data && data.bornState ? data.bornState : ""} error={errors['bornState']} />
                        <label>Estado de nacimiento</label>
                    </div>
                    <div className="col-md-3 form-label-group mb-3">
                        <input   className="form-control" name="bornCountry" type="text" onChange={this.handleChange} value={data && data.bornCountry ? data.bornCountry : ""} error={errors['bornCountry']} />
                        <label>País de nacimiento</label>
                    </div>
                    <div className="col-md-3 form-label-group mb-3">
                        <input  className="form-control" name="nacionalities" type="text" onChange={this.handleChange} value={data && data.nacionalities ? data.nacionalities : ""} error={errors['nacionalities']} />
                        <label>Nacionalidad(es)</label>
                    </div>
                    <div className="col-md-6 form-label-group mb-6">
                        <input  className="form-control" name="profession" type="text" onChange={this.handleChange} value={data && data.profession ? data.profession : ""} error={errors['profession']} />
                        <label>Profesión</label>
                    </div>
                    <div className="col-md-6 form-label-group mb-6">
                        <input  className="form-control" name="business" type="text" onChange={this.handleChange} value={data && data.business ? data.business : ""} error={errors['business']} />
                        <label>Giro del negocio</label>
                    </div>
                    <div className="col-md-6 form-label-group mb-3">
                        <input  className="form-control" name="curp" type="text" onChange={this.handleChange} value={data && data.curp ? data.curp : ""} error={errors['curp']} />
                        <label>CURP o su equivalente en otro pais</label>
                    </div>
                    <div className="col-md-6 form-label-group mb-3">
                        <input  className="form-control" name="taxId" type="text" onChange={this.handleChange} value={data && data.taxId ? data.taxId : ""} error={errors['taxId']} />
                        <label>RFC o TaxID</label>
                    </div>
                    <div className="col-md-4 form-label-group mb-3">
                        <select name="idType" id="idType" className="form-control" onChange={this.handleChange} value={data.idType} error={errors['idType']}>
                            <option value="">Selecciona...</option>
                            {this.state.typesId.map(option => (
                              <option key={option._id} value={option._id}>
                                  {option.name}
                              </option>
                            ))}
                        </select>
                        <label>Tipo de identificación</label>
                    </div>
                    <div className="col-md-4 form-label-group mb-3">
                        <input  className="form-control" name="idNumber" type="text" onChange={this.handleChange} value={data && data.idNumber ? data.idNumber : ""} error={errors['idNumber']} />
                        <label>Numero o clave</label>
                    </div>
                    <div className="col-md-4 form-label-group mb-3">
                        <input className="form-control" name="emisor" type="text" onChange={this.handleChange} value={data && data.emisor ? data.emisor : ""} error={errors['emisor']} />
                        <label>Autoridad que emite</label>
                    </div>
                    <div className="col-md-6 form-label-group mb-3">
                        <select name="civilStatus" id="civilStatus" className="form-control" onChange={this.handleSelectChange} value={data.civilStatus} error={errors['civilStatus']}>
                            <option value="">Selecciona...</option>
                            {this.state.civilStatus.map(option => (
                              <option key={option._id} value={option._id}>
                                  {option.name}
                              </option>
                            ))}
                        </select>
                        <label>Estado civil</label>
                    </div>
                    <div className="col-md-6 form-label-group mb-3">
                        <input
                               className="form-control"
                               name="spouseName"
                               type="text"
                               onChange={this.handleChange}
                               value={data && data.spouseName ? data.spouseName : ""}
                               error={errors['spouseName']}
                               disabled={data?.civilStatus === "single"}
                        />
                        <label>Nombre del cónyuge</label>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-12 form-label-group mb-6">
                        <h5>Domicilio particular de la persona física</h5>
                    </div>
                    <div className="col-md-6 form-label-group mb-3">
                        <input className="form-control" name="street" type="text" onChange={this.handleChange} value={data && data.street ? data.street : ""} error={errors['street']} />
                        <label>Calle</label>
                    </div>
                    <div className="col-md-3 form-label-group mb-3">
                        <input  className="form-control" name="exteriorNumber" type="text" onChange={this.handleChange} value={data && data.exteriorNumber ? data.exteriorNumber : ""} error={errors['exteriorNumber']} />
                        <label>Número exterior</label>
                    </div>
                    <div className="col-md-3 form-label-group mb-3">
                        <input  className="form-control" name="interiorNumber" type="text" onChange={this.handleChange} value={data && data.interiorNumber ? data.interiorNumber : ""} error={errors['interiorNumber']} />
                        <label>Número interior</label>
                    </div>
                    <div className="col-md-6 form-label-group mb-3">
                        <input  className="form-control" name="neighborhood" type="text" onChange={this.handleChange} value={data && data.neighborhood ? data.neighborhood : ""} error={errors['neighborhood']} />
                        <label>Colonia o Urbanización</label>
                    </div>
                    <div className="col-md-4 form-label-group mb-3">
                        <input  className="form-control" name="city" type="text" onChange={this.handleChange} value={data && data.city ? data.city : ""} error={errors['city']} />
                        <label>Ciudad o Población</label>
                    </div>
                    <div className="col-md-2 form-label-group mb-3">
                        <input  className="form-control" name="postalCode" type="text" onChange={this.handleChange} value={data && data.postalCode ? data.postalCode : ""} error={errors['postalCode']} />
                        <label>Código postal</label>
                    </div>
                    <div className="col-md-4 form-label-group mb-3">
                        <input  className="form-control" name="municipality" type="text" onChange={this.handleChange} value={data && data.municipality ? data.municipality : ""} error={errors['municipality']} />
                        <label>Alcaldía o municipio</label>
                    </div>
                    <div className="col-md-4 form-label-group mb-3">
                        <input  className="form-control" name="state" type="text" onChange={this.handleChange} value={data && data.state ? data.state : ""} error={errors['state']} />
                        <label>Estado</label>
                    </div>
                    <div className="col-md-4 form-label-group mb-3">
                        <input  className="form-control" name="country" type="text" onChange={this.handleChange} value={data && data.country ? data.country : ""} error={errors['country']} />
                        <label>País</label>
                    </div>
                    <div className="col-md-6 form-label-group mb-3">
                        <input  className="form-control" name="countryResidence" type="text" onChange={this.handleChange} value={data && data.countryResidence ? data.countryResidence : ""} error={errors['countryResidence']} />
                        <label>País o jurisdicción de residencia para efectos fiscales</label>
                    </div>
                    <div className="col-md-12 form-label-group mb-3">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" name="isSameAddress" id="isSameAddress" onChange={this.handleCheckboxChange} checked={data && data.isSameAddress ? data.isSameAddress : false} />
                            <label className="form-check-label" htmlFor="isSameAddress">
                                Declaro que mi domicilio fiscal es el mismo que el particular
                            </label>
                        </div>
                    </div>
                </div>
                {data && !data.isSameAddress && (
                <div className="form-row">
                    <div className="col-md-12 mb-6">
                        <h5>Domicilio Fiscal</h5>
                    </div>
                    <div className="col-md-6 form-label-group mb-3">
                        <input className="form-control" name="streetPhysical" type="text" onChange={this.handleChange} value={data && data.streetPhysical ? data.streetPhysical : ""} error={errors['streetPhysical']} />
                        <label>Calle</label>
                    </div>
                    <div className="col-md-3 form-label-group mb-3">
                        <input className="form-control" name="exteriorNumberPhysical" type="text" onChange={this.handleChange} value={data && data.exteriorNumberPhysical ? data.exteriorNumberPhysical : ""} error={errors['exteriorNumberPhysical']} />
                        <label>Número exterior</label>
                    </div>
                    <div className="col-md-3 form-label-group mb-3">
                        <input className="form-control" name="interiorNumberPhysical" type="text" onChange={this.handleChange} value={data && data.interiorNumberPhysical ? data.interiorNumberPhysical : ""} error={errors['interiorNumberPhysical']} />
                        <label>Número interior</label>
                    </div>
                    <div className="col-md-6 form-label-group mb-3">
                        <input className="form-control" name="neighborhoodPhysical" type="text" onChange={this.handleChange} value={data && data.neighborhoodPhysical ? data.neighborhoodPhysical : ""} error={errors['neighborhoodPhysical']} />
                        <label>Colonia o Urbanización</label>
                    </div>
                    <div className="col-md-4 form-label-group mb-3">
                        <input className="form-control" name="cityPhysical" type="text" onChange={this.handleChange} value={data && data.cityPhysical ? data.cityPhysical : ""} error={errors['cityPhysical']} />
                        <label>Ciudad o Población</label>
                    </div>
                    <div className="col-md-2 form-label-group mb-3">
                        <input className="form-control" name="postalCodePhysical" type="text" onChange={this.handleChange} value={data && data.postalCodePhysical ? data.postalCodePhysical : ""} error={errors['postalCodePhysical']} />
                        <label>Código postal</label>
                    </div>
                    <div className="col-md-4 form-label-group mb-3">
                        <input className="form-control" name="municipalityPhysical" type="text" onChange={this.handleChange} value={data && data.municipalityPhysical ? data.municipalityPhysical : ""} error={errors['municipalityPhysical']} />
                        <label>Alcaldía o municipio</label>
                    </div>
                    <div className="col-md-4 form-label-group mb-3">
                        <input className="form-control" name="statePhysical" type="text" onChange={this.handleChange} value={data && data.statePhysical ? data.statePhysical : ""} error={errors['statePhysical']} />
                        <label>Estado</label>
                    </div>
                    <div className="col-md-4 form-label-group mb-3">
                        <input className="form-control" name="countryPhysical" type="text" onChange={this.handleChange} value={data && data.countryPhysical ? data.countryPhysical : ""} error={errors['countryPhysical']} />
                        <label>País</label>
                    </div>
                </div>
                  )}
                <div className="form-row">
                    <div className="col-md-12 form-label-group mb-6">
                        <h5>Datos de Contacto</h5>
                    </div>
                    <div className="col-md-6 form-label-group mb-3">
                        <input className="form-control" name="phone" type="text" onChange={this.handleChange} value={data && data.phone ? data.phone : ""} error={errors['phone']} />
                        <label>Teléfono fijo</label>
                    </div>
                    <div className="col-md-6 form-label-group mb-3">
                        <input className="form-control" name="cellphone" type="text" onChange={this.handleChange} value={data && data.cellphone ? data.cellphone : ""} error={errors['phone']} />
                        <label>Celular</label>
                    </div>
                    <div className="col-md-6 form-label-group mb-3">
                        <input className="form-control" name="email" type="text" onChange={this.handleChange} value={data && data.email ? data.email : ""} error={errors['email']} />
                        <label>Correo electrónico</label>
                    </div>
                </div>

                <div className="form-row">
                    <div className="col-md-12 form-label-group mb-6">
                        <h5>En caso de personas físicas extranjeras: Domicilio de contacto en México</h5>
                    </div>
                    <div className="col-md-6 form-label-group mb-3">
                        <input className="form-control" name="streetForeign" type="text" onChange={this.handleChange} value={data && data.streetForeign ? data.streetForeign : ""} error={errors['streetForeign']} />
                        <label>Calle</label>
                    </div>
                    <div className="col-md-3 form-label-group mb-3">
                        <input className="form-control" name="exteriorNumberForeign" type="text" onChange={this.handleChange} value={data && data.exteriorNumberForeign ? data.exteriorNumberForeign : ""} error={errors['exteriorNumberForeign']} />
                        <label>Número exterior</label>
                    </div>
                    <div className="col-md-3 form-label-group mb-3">
                        <input className="form-control" name="interiorNumberForeign" type="text" onChange={this.handleChange} value={data && data.interiorNumberForeign ? data.interiorNumberForeign : ""} error={errors['interiorNumberForeign']} />
                        <label>Número interior</label>
                    </div>
                    <div className="col-md-6 form-label-group mb-3">
                        <input  className="form-control" name="neighborhoodForeign" type="text" onChange={this.handleChange} value={data && data.neighborhoodForeign ? data.neighborhoodForeign : ""} error={errors['neighborhoodForeign']} />
                        <label>Colonia o Urbanización</label>
                    </div>
                    <div className="col-md-4 form-label-group mb-3">
                        <input  className="form-control" name="cityForeign" type="text" onChange={this.handleChange} value={data && data.cityForeign ? data.cityForeign : ""} error={errors['cityForeign']} />
                        <label>Ciudad o Población</label>
                    </div>
                    <div className="col-md-2 form-label-group mb-3">
                        <input className="form-control" name="postalCodeForeign" type="text" onChange={this.handleChange} value={data && data.postalCodeForeign ? data.postalCodeForeign : ""} error={errors['postalCodeForeign']} />
                        <label>Código postal</label>
                    </div>
                    <div className="col-md-4 form-label-group mb-3">
                        <input className="form-control" name="municipalityForeign" type="text" onChange={this.handleChange} value={data && data.municipalityForeign ? data.municipalityForeign : ""} error={errors['municipalityForeign']} />
                        <label>Alcaldía  o municipio</label>
                    </div>

                    <div className="col-md-4 form-label-group mb-3">
                        <input className="form-control" name="stateForeign" type="text" onChange={this.handleChange} value={data && data.stateForeign ? data.stateForeign : ""} error={errors['stateForeign']} />
                        <label>Estado</label>
                    </div>
                    <div className="col-md-4 form-label-group mb-3">
                        <input  className="form-control" name="countryForeign" type="text" onChange={this.handleChange} value={data && data.countryForeign ? data.countryForeign : ""} error={errors['countryForeign']} />
                        <label>País</label>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-12 form-label-group mb-6">
                        <h5>En caso de actuar representando - Datos generales del apoderado</h5>
                    </div>
                {/*  Mismos datos generales de identificación pero para representante   */}
                    <div className="col-md-4 form-label-group mb-3">
                        <input className="form-control" name="nameRepresentative" type="text" onChange={this.handleChange} value={data && data.nameRepresentative ? data.nameRepresentative : ""} error={errors['nameRepresentative']} />
                        <label>Nombre(s)</label>
                    </div>
                    <div className="col-md-4 form-label-group mb-3">
                        <input  className="form-control" name="lastNameRepresentative" type="text" onChange={this.handleChange} value={data && data.lastNameRepresentative ? data.lastNameRepresentative : ""} error={errors['lastNameRepresentative']} />
                        <label>Apellido Paterno</label>
                    </div>
                    <div className="col-md-4 form-label-group mb-3">
                        <input  className="form-control" name="motherLastNameRepresentative" type="text" onChange={this.handleChange} value={data && data.motherLastNameRepresentative ? data.motherLastNameRepresentative : ""} error={errors['motherLastNameRepresentative']} />
                        <label>Apellido Materno</label>
                    </div>

                    <div className="col-md-4 mb-3">
                        <SingleDatePicker
                          date={data && data.birthDateRepresentative ? moment(data.birthDateRepresentative) : null}
                          onDateChange={(date) => this.handleDateChange('birthDateRepresentative', date)} // PropTypes.func.isRequired
                          focused={this.state.focused2} // PropTypes.bool
                          onFocusChange={({ focused }) => this.setState({ focused2: focused })} // PropTypes.func.isRequired
                          displayFormat="DD/MM/YYYY"
                          numberOfMonths={1}
                          isOutsideRange={() => false}
                          id={"birthDateRepresentative"}
                        />
                    </div>
                    <div className="col-md-4 form-label-group mb-3">
                        <select name="genderRepresentative" id="genderRepresentative" className="form-control" onChange={this.handleChange} error={errors['gender']} value={data.genderRepresentative}>
                            <option value="">Selecciona...</option>
                            {this.state.genders.map(option => (
                              <option key={option._id} value={option._id}>
                                  {option.name}
                              </option>
                            ))}
                        </select>
                        <label>Sexo</label>
                    </div>
                    <div className="col-md-4 form-label-group mb-3">
                        <input   className="form-control" name="aliasRepresentative" type="text" onChange={this.handleChange} value={data && data.aliasRepresentative ? data.aliasRepresentative : ""} error={errors['aliasRepresentative']} />
                        <label>Alias</label>
                    </div>
                    <div className="col-md-3 form-label-group mb-3">
                        <input  className="form-control" name="bornCityRepresentative" type="text" onChange={this.handleChange} value={data && data.bornCityRepresentative ? data.bornCityRepresentative : ""} error={errors['bornCityRepresentative']} />
                        <label>Ciudad de nacimiento</label>
                    </div>
                    <div className="col-md-3 form-label-group mb-3">
                        <input  className="form-control" name="bornStateRepresentative" type="text" onChange={this.handleChange} value={data && data.bornStateRepresentative ? data.bornStateRepresentative : ""} error={errors['bornStateRepresentative']} />
                        <label>Estado de nacimiento</label>
                    </div>
                    <div className="col-md-3 form-label-group mb-3">
                        <input   className="form-control" name="bornCountryRepresentative" type="text" onChange={this.handleChange} value={data && data.bornCountryRepresentative ? data.bornCountryRepresentative : ""} error={errors['bornCountryRepresentative']} />
                        <label>País de nacimiento</label>
                    </div>
                    <div className="col-md-3 form-label-group mb-3">
                        <input  className="form-control" name="nacionalitiesRepresentative" type="text" onChange={this.handleChange} value={data && data.nacionalitiesRepresentative ? data.nacionalitiesRepresentative : ""} error={errors['nacionalitiesRepresentative']} />
                        <label>Nacionalidad(es)</label>
                    </div>
                    <div className="col-md-6 form-label-group mb-6">
                        <input  className="form-control" name="professionRepresentative" type="text" onChange={this.handleChange} value={data && data.professionRepresentative ? data.professionRepresentative : ""} error={errors['professionRepresentative']} />
                        <label>Profesión</label>
                    </div>
                    <div className="col-md-6 form-label-group mb-6">
                        <input  className="form-control" name="businessRepresentative" type="text" onChange={this.handleChange} value={data && data.businessRepresentative ? data.businessRepresentative : ""} error={errors['businessRepresentative']} />
                        <label>Giro del negocio</label>
                    </div>
                    <div className="col-md-6 form-label-group mb-3">
                        <input className="form-control" name="curpRepresentative" type="text" onChange={this.handleChange} value={data && data.curpRepresentative ? data.curpRepresentative : ""} error={errors['curpRepresentative']} />
                        <label>CURP o su equivalente en otro pais</label>
                    </div>
                    <div className="col-md-6 form-label-group mb-3">
                        <input className="form-control" name="taxIdRepresentative" type="text" onChange={this.handleChange} value={data && data.taxIdRepresentative ? data.taxIdRepresentative : ""} error={errors['taxIdRepresentative']} />
                        <label>RFC o TaxID</label>
                    </div>
                    <div className="col-md-4 form-label-group mb-3">
                        <select name="idTypeRepresentative" id="idTypeRepresentative" className="form-control" onChange={this.handleChange} error={errors['idType']} value={data.idTypeRepresentative}>
                            <option value="">Selecciona...</option>
                            {this.state.typesId.map(option => (
                              <option key={option._id} value={option._id}>
                                  {option.name}
                              </option>
                            ))}
                        </select>
                        <label>Tipo de identificación</label>
                    </div>
                    <div className="col-md-4 form-label-group mb-3">
                        <input  className="form-control" name="idNumberRepresentative" type="text" onChange={this.handleChange} value={data && data.idNumberRepresentative ? data.idNumberRepresentative : ""} error={errors['idNumberRepresentative']} />
                        <label>Numero o clave</label>
                    </div>
                    <div className="col-md-4 form-label-group mb-3">
                        <input  className="form-control" name="emisorRepresentative" type="text" onChange={this.handleChange} value={data && data.emisorRepresentative ? data.emisorRepresentative : ""} error={errors['emisorRepresentative']} />
                        <label>Autoridad que emite</label>
                    </div>
                    <div className="col-md-6 form-label-group mb-3">
                        <select name="civilStatusRepresentative" id="civilStatusRepresentative" className="form-control" onChange={this.handleChange} error={errors['civilStatus']} value={data.civilStatusRepresentative}>
                            <option value="">Selecciona...</option>
                            {this.state.civilStatus.map(option => (
                              <option key={option._id} value={option._id}>
                                  {option.name}
                              </option>
                            ))}
                        </select>
                        <label>Estado civil</label>
                    </div>
                    <div className="col-md-6 form-label-group mb-3">
                        <input 
                               className="form-control"
                               name="spouseNameRepresentative"
                               type="text"
                               onChange={this.handleChange}
                               value={data && data.spouseNameRepresentative ? data.spouseNameRepresentative : ""} error={errors['spouseNameRepresentative']}
                               disabled={data?.civilStatusRepresentative === "single"}
                        />
                        <label>Nombre del cónyuge</label>
                    </div>
                </div>

                { this.props.type === 'Constitutiva' && (
                <div className="form-row">
                    <div className="col-md-12 form-label-group mb-6">
                        <h5>Manifestación sobre dueño beneficiario / beneficiario controlador PLD</h5>
                    </div>
                    <div className="col-md-12 form-label-group mb-6">
                        <small>
                            Conforme a lo establecido en la fracción III de la Ley Federal para la Prevención e Identificación de Operaciones con Recursos de Procedencia Ilícita
                            manifiesto, bajo protesta de decir verdad, que
                        </small>
                    </div>
                    <div className="col-md-12 form-label-group mb-5">
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="manifestation" id="manifestation1" value="manifestation1" onChange={this.handleChange} checked={data.manifestation === 'manifestation1'} />
                            <label className="form-check-label" htmlFor="manifestation1">
                                1. En lo personal, soy yo quien recibirá los derechos de uso, goce, disfrute, aprovechamiento de este acto jurídico.
                            </label>
                        </div>
                        <div className="form-check mb-0">
                            <input className="form-check-input" type="radio" name="manifestation" id="manifestation2" value="manifestation2" onChange={this.handleChange} checked={data.manifestation === 'manifestation2'} />
                            <label className="form-check-label" htmlFor="manifestation1">
                                2. Mi representada es la persona que directamente disfrutará de los beneficios derivados de este acto jurídico con denominación o
                                razón social siguiente:
                                <input className="form-control clear sm"
                                       name="manifestationRepresentant"
                                       type="text"
                                       onChange={this.handleChange}
                                       value={data && data.manifestationRepresentant ? data.manifestationRepresentant : ""}
                                       error={errors['manifestationRepresentant']}
                                       disabled={data.manifestation !== 'manifestation2'}
                                />
                                <div className="form-label-group">
                                    Para ese supuesto #2, usted Cliente o Usuario, ¿Tiene conocimiento o le es posible identificar a uno o más beneficiarios controladores (conforme a la definición previa referida)?
                                    <div className="form-check">
                                        <input className="form-check-input"
                                               type="radio" name="indetifyBC"
                                               id="iknowBCyes"
                                               value="yes"
                                               onChange={this.handleChange}
                                               checked={data.indetifyBC === 'yes'}
                                               disabled={data.manifestation !== 'manifestation2'}
                                        />
                                        <label className="form-check-label" htmlFor="manifestation21">
                                            Si, siendo el siguiente:
                                            <input
                                              className="form-control clear sm"
                                              name="bcname"
                                              type="text"
                                              onChange={this.handleChange}
                                              value={data && data.bcname ? data.bcname : ''}
                                              error={errors['bcname']}
                                              disabled={data.manifestation !== 'manifestation2' || data.indetifyBC === 'no'}
                                            />
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          name="indetifyBC"
                                          id="iknowBCno"
                                          value="no"
                                          onChange={this.handleChange}
                                          checked={data.indetifyBC === 'no'}
                                          disabled={data.manifestation !== 'manifestation2'}/>
                                        <label className="form-check-label" htmlFor="manifestation21">
                                            No
                                        </label>
                                    </div>
                                </div>
                            </label>
                        </div>
                        <div className="mb-0">
                            <label className="form-check-label pt-0" htmlFor="manifestation1">
                                3. Tengo conocimiento de la existencia del beneficiario Controlador/Dueño beneficiario, y es quien recibirá los derechos de uso,
                                goce, disfrute o aprovechamiento de este acto jurídico, siendo el siguiente:
                            </label>
                            <input className="form-control clear sm" name="useRights" type="text" onChange={this.handleChange} value={data && data.useRights ? data.useRights : ""} error={errors['useRights']} />
                        </div>
                    </div>
                    <div className="col-md-12 form-label-group mb-6 pt-3 text-center">
                        <div className="sign-box"></div>
                        <span> (Nombre y Firma) </span>
                    </div>
                    <div className="col-md-12 form-label-group" style={{fontSize:10}}>
                        <small className="font-smaller">
                            <strong>Artículo 3 de la Ley Federal para la Prevención e Identificación de Operaciones con Recursos de Procedencia Ilícita.</strong> Para los efectos de esta Ley, se entenderá por:III. Beneficiario
                            Controlador, a la persona o grupo de personas que: a) Por medio de otra o de cualquier acto, obtiene el beneficio derivado de éstos y es quien, en última instancia, ejerce los
                            derechos de uso, goce, disfrute, aprovechamiento o disposición de un bien o servicio, o b) Ejerce el control de aquella persona moral que, en su carácter de cliente o usuario, lleve
                            a cabo actos u operaciones con quien realice Actividades Vulnerables, así como las personas por cuenta de quienes celebra alguno de ellos. Se entiende que una persona o grupo
                            de personas controla a una persona moral cuando, a través de la titularidad de valores, por contrato o de cualquier otro acto, puede: i) Imponer, directa o indirectamente, decisiones
                            en las asambleas generales de accionistas, socios unos órganos equivalentes, o nombrar o destituir a la mayoría de los consejeros, administradores o sus equivalentes; ii) Mantener
                            la titularidad de los derechos que permitan, directa o indirectamente, ejercer el voto respecto de más del cincuenta por ciento del capital social, o iii) Dirigir, directa o indirectamen-
                            te, la administración, la estrategia o las principales políticas de la misma.
                        </small>
                    </div>
                </div>
                  ) }
                <div className="form-row mt-1">
                    <div className="col-md-12 form-label-group mb-0">
                        <h5>Manifestación relacionada a las disposiciones del CFF y las RMF
                            en materia de beneficiario controlador</h5>
                    </div>
                    <div className="col-md-12 form-label-group" style={{fontSize:10}}>
                        <small className="font-smaller">
                            Declaro, bajo protesta de decir verdad, lo siguiente: (i) que me fue solicitada la información suficiente y necesaria para identificar a los Beneficiarios Controladores a que se refiere el
                            Código Fiscal de la Federación; (ii) que toda la información y documentación que fue entregada es verdadera y la misma corresponde a la realidad; y (iii) que no cuento con información
                            y / o documentación adicional que pudiere afectar la solicitud e investigación realizada por el prestador del servicio de fe pública ligado a este formulario y a las operaciones que
                            derivan de la misma:
                        </small>
                    </div>
                    <div className="col-md-1 form-label-group mb-0">
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="iknowBC2" id="iknowBCyes" value="yes" onChange={this.handleChange} checked={data.iknowBC2 === 'yes'} />
                            <label className="form-check-label" htmlFor="manifestation21">
                              Si
                            </label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="iknowBC2" id="iknowBCno" value="no" onChange={this.handleChange} checked={data.iknowBC2 === 'no'} />
                            <label className="form-check-label" htmlFor="manifestation21">
                                No
                            </label>
                        </div>
                    </div>
                    <div className="col-md-11 form-label-group mb-0">
                        <strong className="font-smaller">Tengo conocimiento del dueño beneficiario o beneficiario controlador en términos del CFF, su Reglamento y Reglas de Carácter General.</strong>
                    </div>
                    <div className="col-md-12 form-label-group text-center">
                        <div className="sign-box mt-0"></div>
                        <span> (Nombre y Firma) </span>
                    </div>
                    <div className="col-md-12 form-label-group mb-0">
                        <p className="font-smaller">
                            *En caso de si tener conocimiento de la existencia de Beneficiario Controlador adicional al que suscribe, se adjunta copia de toda la información actualizada y me comprometo a que, en
caso de que existan cambios posteriores a la misma, hacerle llegar las constancias correspondientes.
                        </p>
                        <p className="font-smaller">
                            <strong>Artículo 32-B Quáter CFF.</strong> Para efectos de este Código se entenderá por beneficiario controlador a la persona física o grupo de personas físicas que: I. Directamente o por medio de otra u
                            otras o de cualquier acto jurídico, obtiene u obtienen el beneficio derivado de su participación en una persona moral, un fideicomiso o cualquier otra figura jurídica, así como de cualquier
                            otro acto jurídico, o es quien o quienes en última instanciaejerceoejercenlosderechosdeuso,goce,disfrute,aprovechamientoodisposiciónde un bien o servicio o en cuyo nombre se realiza una
                            transacción, aun y cuando lo haga o hagan de forma contingente. II. Directa, indirectamente o de forma contingente, ejerzan el control de la persona moral, fideicomiso o cualquier otra
                            figura jurídica.
                        </p>
                        <p className="font-smaller">
                            Se entiende que una persona física o grupo de personas físicas ejerce el control cuando, a través de la titularidad de valores, por contrato o por cualquier otro acto jurídico, puede o pueden:
                            a) Imponer, directa o indirectamente, decisiones en las asambleas generales de accionistas, socios u órganos equivalentes, o nombrar o destituir a la mayoría de los consejeros, administrado-
                            res o sus equivalentes. B) Mantener la titularidad de los derechos que permitan, directa o indirectamente, ejercer el voto respecto de más del 15% del capital social o bien. c) Dirigir, directa
                            o indirectamente, la administración, la estrategia o las principales políticas de la persona moral, fideicomiso o cualquier otra figura jurídica.
                        </p>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-12 form-label-group mb-6">
                        <h5>Criterios para la determinación de la condición de beneficiario controlador de las personas morales</h5>
                    </div>
                    <div className="col-md-12 form-label-group" style={{fontSize:10}}>
                        <small className="font-smaller">
                            Entiendo que, para los efectos del artículo 32-B Ter del CFF, en la identificación de los beneficiarios controladores, se deberá aplicar lo dispuesto en el artículo 32-B Quáter, fracciones I y II,
                            incisos a), b) y c) de forma sucesiva, como criterios para su determinación. Para efectos de la obtención de la información a que se refiere el artículo 32-B Ter del CFF, las personas morales
                            deberán identificar, verificar y validar la información sobre los beneficiarios controladores, indicando los porcentajes de participación en el capital de la persona moral, incluyendo la
                            información relativa a la cadena de titularidad, en los casos en que el beneficiario controlador lo sea indirectamente. Se entiende por cadena de titularidad el supuesto en que se ostente
                            la propiedad indirecta, a través de otras personas morales. Así mismo, las personas morales también deberán identificar, verificar y validar la información relativa a la cadena de control, en
                            los casos en los que el beneficiario controlador lo sea por medios distintos a la propiedad. Se entiende por cadena de control el supuesto en que se ostente el control indirectamente, a
                            través de otras personas morales, fideicomisos o cualquier otra figura jurídica. Cuando no se identifique a persona física alguna bajo los criterios establecidos en esta regla, en relación con
                            el artículo 32-B Quáter del CFF, se considerará como beneficiario controlador a la persona física que ocupe el cargo de administrador único de la persona moral o equivalente. En caso de
                            que la persona moral cuente con un consejo de administración u órgano equivalente, cada miembro de dicho consejo se considerará como beneficiario controlador de la persona moral.
                        </small>
                    </div>
                    <div className="col-md-12 form-label-group text-center">
                        <p>
                            DECLARO, BAJO PROTESTA DE DECIR VERDAD, QUE LOS DATOS ASENTADOS SON VERDADEROS
                        </p>
                        <div className="sign-box mt-0"></div>
                        <span> (Nombre y Firma) </span>
                    </div>
                </div>
                <div className="form-row mt-5" data-html2canvas-ignore="true">
                    <div className="col-md-12 form-label-group mb-6">
                        <h5>Documentos</h5>
                    </div>
                    <div className="col-md-12 form-label-group mb-6">
                        <h5>Identificación del cliente</h5>
                        {
                            data.files && data.files.filter(f => f.type === 'identification').length > 0 &&
                            (<Uploader files={data.files.filter(f => f.type === 'identification')} onUpload={this.handleUpload} onRemove={this.handleRemoveUpload} type={'identification'} bucket={'cff-documents'}/>)
                        }
                        {
                            (!data.files || (data.files && data.files.filter(f => f.type === 'identification').length === 0)) &&
                            (<Uploader files={[]} onUpload={this.handleUpload} onRemove={this.handleRemoveUpload} type={'identification'} bucket={'cff-documents'}/>)
                        }
                    </div>
                    {
                        uploaders.married && (
                        <div className="col-md-12 form-label-group mb-6">
                            <h5>Acta de matrimonio</h5>
                            {
                              data.files && data.files.filter(f => f.type === 'acta').length > 0 &&
                              (<Uploader
                                files={data.files.filter(f => f.type === 'acta')}
                                onUpload={this.handleUpload}
                                onRemove={this.handleRemoveUpload}
                                type={'acta'}
                                bucket={'cff-documents'}
                              />)
                            }
                            {
                              (!data.files || (data.files && data.files.filter(f => f.type === 'acta').length === 0)) &&
                              (<Uploader
                                files={[]}
                                onUpload={this.handleUpload}
                                onRemove={this.handleRemoveUpload}
                                type={'acta'}
                                bucket={'cff-documents'}
                              />)
                            }
                        </div>
                      )
                    }
                    {
                        (uploaders.concubinage || uploaders.married) &&
                                (<div className="col-md-12 form-label-group mb-6">
                                    <h5>Identificación del cónyuge</h5>
                                    {
                                        data.files && data.files.filter(f => f.type === 'idMarried').length > 0 &&
                                        (<Uploader
                                          files={data.files.filter(f => f.type === 'idMarried')}
                                          onUpload={this.handleUpload}
                                          onRemove={this.handleRemoveUpload}
                                          type={'idMarried'}
                                          bucket={'cff-documents'}
                                        />)
                                    }
                                    {
                                        (!data.files || (data.files && data.files.filter(f => f.type === 'idMarried').length === 0)) &&
                                        (<Uploader
                                          files={[]}
                                          onUpload={this.handleUpload}
                                          onRemove={this.handleRemoveUpload}
                                          type={'idMarried'}
                                          bucket={'cff-documents'}
                                        />)
                                    }
                                </div>)
                    }

                    <div className="col-md-12 form-label-group mb-6">
                        <h5>Comprobante de domicilio</h5>
                        {
                            data.files && data.files.filter(f => f.type === 'address').length > 0 &&
                            (<Uploader
                              files={data.files.filter(f => f.type === 'address')}
                              onUpload={this.handleUpload}
                              onRemove={this.handleRemoveUpload}
                              type={'address'}
                              bucket={'cff-documents'}
                            />)
                        }
                        {
                            (!data.files || (data.files && data.files.filter(f => f.type === 'address').length === 0)) &&
                            (<Uploader
                              files={[]}
                              onUpload={this.handleUpload}
                              onRemove={this.handleRemoveUpload}
                              type={'address'}
                              bucket={'cff-documents'}
                            />)
                        }
                    </div>
                    <div className="col-md-12 form-label-group mb-6">
                        <h5>Constancia de Situación Fiscal</h5>
                        {
                            data.files && data.files.filter(f => f.type === 'tax').length > 0 &&
                            (<Uploader
                              files={data.files.filter(f => f.type === 'tax')}
                              onUpload={this.handleUpload}
                              onRemove={this.handleRemoveUpload}
                              type={'tax'}
                              bucket={'cff-documents'}
                            />)
                        }
                        {
                            (!data.files || (data.files && data.files.filter(f => f.type === 'tax').length === 0)) &&
                            (<Uploader
                              files={[]}
                              onUpload={this.handleUpload}
                              onRemove={this.handleRemoveUpload}
                              type={'tax'}
                              bucket={'cff-documents'}
                            />)
                        }
                    </div>
                </div>
                <button className="btn btn-primary btn-large float-right mb-5" onClick={(e) => this.handleSubmit(e, 'pending')}>Guardar</button>
                {/*{!this.validate() && (<button className="btn btn-success btn-large float-right mb-5 mr-3" onClick={(e) => this.handleSubmit(e, 'complete')}>Enviar</button>)}*/}
            </form>
          </>
        );
    }
}

export default FisicForm;
//module.exports = Joi.extend(JoiDate);
