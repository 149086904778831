import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { ObjectID } from 'bson';
import { Link } from "react-router-dom";
import Moment from 'react-moment';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';

class Copies extends Component {
    state = {
        show: false,
        data: {
            _id: new ObjectID(),
            number: 0,
            date: "",
            requestor: "",
            delivery: "",
            issue: ""
        }
    };

    showModal = edit => {
        let { data } = this.state;
        if (edit) {
            data = { _id: edit._id, number: edit.number, date: moment(edit.date), requestor: edit.requestor, delivery: edit.delivery, issue: edit.issue };
        } else {
            data = { _id: new ObjectID(), number: "", date: "", requestor: "", delivery: "", issue: "" };
        }
        this.setState({ show: true, data });
    };

    hideModal = () => {
        this.setState({ show: false });
    };

    handleChange = ({ currentTarget: input }) => {
        //const errors = { ...this.state.errors };
        //const errorMessage = this.validateProperty(input);
        // if (errorMessage) errors[input.name] = errorMessage;
        // else delete errors[input.name];

        const data = { ...this.state.data };
        data[input.name] = input.value;

        this.setState({ data });
    };

    handleDateChange = date => {
        const { data } = this.state;
        data.date = date;
        this.setState({ data })
    };

    handleSubmit = e => {
        e.preventDefault();
        const { data } = this.state;
        let editData = this.props.copies.filter(m => m._id === data._id);

        if (editData.length < 1)
            data['_id'] = new ObjectID();

        this.setState({ data: data, show: false });
        this.props.onCopy(data);
        // const errors = this.validate();
        // this.setState({ errors: errors || {} });
        // if (errors) return;

        // this.doSubmit();
    };

    render() {
        const { data } = this.state;
        const { user } = this.props;

        return (
            <div>
                <div className="form-row">
                    <div className="col-md-6 mb-6">
                        <h5>Ejemplares</h5>
                    </div>
                    {
                        user && user.role && user.role.actions.some(a => a.method === "add-copy-mediation") && (
                            <div className="col-md-6 mb-6 pr-0">
                                <a className="btn btn-info btn-sm float-right link-add" onClick={() => this.showModal(false)}><i className="fa fa-plus" /></a>
                            </div>
                        )
                    }
                </div>
                <div className="form-row">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">ID</th>
                                <th scope="col">Fecha de expedición</th>
                                <th scope="col">Solicitante</th>
                                <th scope="col">Datos de entrega</th>
                                <th scope="col">Expedido a nombre de</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.copies.map(copy => (
                                <tr key={copy._id}>
                                    <td><Link to="#" onClick={() => user && user.role && user.role.actions.some(a => a.method === "edit-copy-mediation") && this.showModal(copy)}>{copy.number}</Link></td>
                                    <td><Moment format="DD/MM/YYYY">{copy.date}</Moment></td>
                                    <td>{copy.requestor}</td>
                                    <td>{copy.delivery}</td>
                                    <td>{copy.issue}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <Modal show={this.state.show} onHide={this.hideModal}>
                    <form>
                        <Modal.Header closeButton>
                            <Modal.Title>Agregar Ejemplares</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="form-row">
                                <div className="col-md-12 form-label-group mb-3">
                                    <input placeholder="Numero de ejemplar" className="form-control" name="number" type="number" onChange={this.handleChange} value={data.number} />
                                    <label>Numero de ejemplar</label>
                                </div>
                                <div className="col-md-12 mb-3">
                                    <SingleDatePicker
                                        date={data.date} // momentPropTypes.momentObj or null
                                        onDateChange={(date) => this.handleDateChange(date)} // PropTypes.func.isRequired
                                        focused={this.state.focused} // PropTypes.bool
                                        onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
                                        displayFormat="DD/MM/YYYY"
                                        numberOfMonths={1}
                                        isOutsideRange={() => false}
                                        id="dateCopies"
                                        placeholder={"Fecha"}
                                    />
                                </div>
                                <div className="col-md-12 form-label-group mb-3">
                                    <input placeholder="Solicitante" className="form-control" name="requestor" type="string" onChange={this.handleChange} value={data.requestor} />
                                    <label>Solicitante</label>
                                </div>
                                <div className="col-md-12 form-label-group mb-3">
                                    <input placeholder="Datos de entrega" className="form-control" name="delivery" type="string" onChange={this.handleChange} value={data.delivery} />
                                    <label>Datos de entrega</label>
                                </div>
                                <div className="col-md-12 form-label-group mb-3">
                                    <input placeholder="Expedido a nombre de" className="form-control" name="issue" type="string" onChange={this.handleChange} value={data.issue} />
                                    <label>Expedido a nombre de</label>
                                </div>
                            </div>
                        </Modal.Body>
                        {
                            user && user.role && user.role.actions.some(a => a.method === "save-copy-mediation") && (
                                <Modal.Footer>
                                    <button className="btn btn-primary" onClick={this.handleSubmit}>Guardar</button>
                                </Modal.Footer>
                            )
                        }
                    </form>
                </Modal>
            </div>
        );
    }
}

export default Copies;