import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import Check from '../common/check'
import Table from '../common/materia-reac-table/table'

class InstrumentsTable extends Component {
  columns = [
    {
      accessorKey: 'number',
      header: 'No. Instrumento',
      selector: row => row.number,
      Cell: ({ row }) => <Link
        to={(this.props.user && this.props.user.role && this.props.user.role.actions.some(a => a.method === 'edit-instrument')) ? `/instruments/${row.original._id}` : '#'}
        searchvalue={row.original.number}
        className={row.original.isBC ? 'badge badge-warning' : ''}>
        {String(row.original.number)}
        {row.original.excel !== undefined && row.original.excel !== 'no' ? <i
          className={row.excel === 'yes' ? 'fas fa-file-excel text-success' : 'fas fa-file-excel text-primary'}> </i> : ''}
      </Link>,
      size: 140,
    },
    {
      accessorKey: 'type',
      header: 'Tipo',
      size: 80,
    },
    {
      accessorKey: 'date',
      Cell: ({ cell }) => cell.getValue() ? moment(cell.getValue()).format('DD/MM/YYYY') : '-',
      header: 'Fecha',
      size: 110,

    },
    {
      accessorKey: 'book',
      header: 'Libro',
      size: 80,
    },
    {
      accessorKey: 'parts',
      header: 'Partes',
      size: 250,
    },
    {
      accessorKey: 'description',
      header: 'Objeto',
      size: 250,
    },
    {
      accessorKey: 'responsible',
      header: 'Responsable',
      size: 200,
    },
    {
      accessorKey: 'isDigital',
      Cell: ({ cell, row }) => <a href="#" onClick={(e) => this.props.handleDigital(e, row.original)}>{!!row.original.isDigital ? 'Sí' : 'No'} </a>,
      header: 'Digital',
      size: 120,
      enableHiding: false,
    },
    {
      accessorKey: 'archived',
      Cell: ({ row }) => <Check archived={row.original.archived} onClick={() => this.props.handleArchive(row.original)}></Check>,
      header: 'Archivado',
      size: 120,
    },
  ]

  deleteRowAction = ({ row, table }) => <button
    onClick={(e) => window.confirm('Esta seguro que desea eliminar el instrumento?') && this.props.handleDelete(row.original)}
    className="btn btn-danger btn-sm">
    Eliminar
  </button>

  render () {
    const { instruments, user, isPending } = this.props
    return (this.columns && user ?
        <Table
          columns={this.columns}
          data={instruments}
          user={user}
          initialState={{
            columnVisibility: {
              archived: (user && user.role && user.role.actions.some(a => a.method === 'archive-instrument')),
              isDigital: isPending
            }
          }}
          rowAction={
            !!this.props.handleDelete &&
            (user && user.role && user.role.actions.some(a => a.method === 'delete-instrument')) ?
              this.deleteRowAction : null
          }
          isLoading={this.props.isLoading}
        /> : <></>
    )
  }
}

export default InstrumentsTable
