import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Modal } from 'react-bootstrap';
import { currencyFormat } from "../../utils/currency";
import { getConfigurations } from "../../services/configurationService";
import { ObjectID } from 'bson';

class Calculator extends Component {
    state = { 
        show: false,
        data: {      
            quantity: 1,
            set: 1,            
            cost_per_sheet: 0,
            cost_per_set: 0,
            discount: 0,
            subtotal: 0,
            total: 0         
        },
        copies: [],
        cost: 0
    };

    async populateConfig() {
        const { data } = this.state; 
        let { cost } = this.state; 
        const { data: configurations } = await getConfigurations();
        data.cost_per_sheet = Number(configurations.filter(config => config.number === 6)[0].value); 
        data.cost_per_set = Number(configurations.filter(config => config.number === 7)[0].value);         
        cost = data.cost_per_sheet;

        this.setState({ data, cost })
    }

    async componentDidMount() {
        await this.populateConfig();
    }

    showModal = () => { 
      this.setState({ show: true });     
    };
  
    hideModal = () => {
      this.setState({ show: false });
    };

    handleChange = ({ currentTarget: input }) => {
        //const errors = { ...this.state.errors };
        //const errorMessage = this.validateProperty(input);
        // if (errorMessage) errors[input.name] = errorMessage;
        // else delete errors[input.name];

        const { data } = this.state; 
        let { cost } = this.state;
        const amount = data.cost_per_sheet;  
        data[input.name] = input.value;    
        //cost = Number(data.quantity) >= 4 ?  (Number(data.quantity) === 4 ? (amount * data.set) : ((((Number(data.quantity) - 3) * data.cost_per_set) + amount)) * data.set) : ((Number(data.quantity) * 350) * Number(data.set));
        cost = Number(data.quantity) < 4 ?  (data.cost_per_sheet * data.set) : (((((Number(data.quantity) - 3) * data.cost_per_set) + amount)) * data.set);
        let discount = data.subtotal * (data.discount / 100);        
        data.total = data.subtotal - discount;

        this.setState({ data, cost });
    };

    handleAdd = () => {
        const { data, copies, cost } = this.state;        
        let copy = {
            _id: new ObjectID(),
            sheets: data.quantity,
            set: data.set,
            cost: cost
        }
        copies.push(copy);
        
        data.subtotal = copies.map(c => parseFloat(c.cost)).reduce((prev, next) => prev + next);
        let discount = data.subtotal * (data.discount / 100);        
        data.total = data.subtotal - discount;
        data.quantity = 1;
        data.set = 1;

        this.setState({ data });
    };

    handleDelete = (copy) => {
        const { data, copies } = this.state;
        const updateCopies = copies.filter(c => c !== copy);
        console.log(updateCopies);
        data.subtotal = updateCopies.length > 0 ? updateCopies.map(c => parseFloat(c.cost)).reduce((prev, next) => prev + next) : 0;
        let discount = data.subtotal * (data.discount / 100);        
        data.total = data.subtotal - discount;
        
        this.setState({ copies: updateCopies, data });
    } 

    render() { 
        const { data } = this.state;
        return (
            <div className="form-row">
                <div className="col-md-9 mb-3">
                    {this.props.element && this.props.element.items && this.props.element.items.map(i => (
                        <div key={i}>
                            <Link style={{textDecoration: "underline", cursor:"pointer", color: '#000'}} to={`/instruments/${i._id}`} >
                                <i className="fa fa-book" /> {i.name}
                            </Link>
                        </div>
                    ))}
                    <div>
                        {this.props.element && this.props.element.length > 0 && (
                            this.props.element.map(b => (
                                <>
                                    <Link style={{textDecoration: "underline", cursor:"pointer", color: '#000'}} to={`/boxes/${b._id}`} >
                                        <i className="fa fa-university" /> {b.numberBox} - {b.description}
                                    </Link>
                                </>
                            ))
                        )}
                    </div>
                </div>
                {this.props.show && (
                    <div className="col-md-3 mb-3 text-right">
                        <a className="" style={{textDecoration: "underline", cursor:"pointer"}} onClick={this.showModal}><i className="fa fa-info-circle" /> Abrir Calculadora</a>
                    </div>
                )}                
                <Modal show={this.state.show} onHide={this.hideModal}>
                    <form> 
                        <Modal.Header closeButton>
                            <Modal.Title>Calculadora</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>                               
                            <div className="form-row">
                                <div className="form-row col-md-12 mb-3">
                                    <div className="col-md-3 form-label-group">
                                        {/* <label>{currencyFormat(data.cost_per_sheet)}</label> */}
                                        <input placeholder="Precio" className="form-control" name="cost_per_sheet" type="text" value={currencyFormat(data.cost_per_sheet)} disabled />                                
                                        <label>Precio</label>
                                    </div>
                                    <div className="col-md-3 form-label-group">
                                        <input placeholder="Hojas" className="form-control" name="quantity" type="number" onChange={this.handleChange} value={data.quantity} min={1} required/>                                
                                        <label>Hojas</label>
                                    </div>
                                    <div className="col-md-3 form-label-group">
                                        <input placeholder="Juegos" className="form-control" name="set" type="number" onChange={this.handleChange} value={data.set} min={1} required/>                                
                                        <label>Juegos:</label>
                                    </div>
                                    <div className="col-md-3" style={{ textAlign: "center"}}>
                                        <a className="btn btn-info link-add" style={{ width: "3rem", height: "3.15rem"}} onClick={this.handleAdd}><i className="fa fa-plus" style={{ verticalAlign: "sub"}}/></a>
                                    </div>
                                </div>   
                                <div className="form-row col-md-12 mb-3">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col">Numero de hojas</th>
                                                <th scope="col">Numero de juegos</th>
                                                <th scope="col">Costo</th>
                                                <th scope="col">Acción</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.copies.map(copy => (                                
                                                <tr key={ copy._id }>
                                                    <td>{copy.sheets}</td>
                                                    <td>{copy.set}</td>
                                                    <td>{currencyFormat(copy.cost)}</td>
                                                    <td className="text-center"><Link to="#" onClick={() => this.handleDelete(copy)} style={{color: 'red'}}>Eliminar</Link></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div> 
                                <div className="form-row col-md-12 mb-3">
                                    <div className="col-md-6">
                                        <label>Descuento %:</label>
                                    </div>
                                    <div className="col-md-6">
                                        <input placeholder="Descuento" className="form-control" name="discount" type="number" onChange={this.handleChange} value={data.discount} required/>                                
                                    </div>
                                </div> 
                                <div className="form-row col-md-12 mb-3">
                                    <div className="col-md-6">
                                        <label>Subtotal:</label>
                                    </div>
                                    <div className="col-md-6">
                                        <label>{currencyFormat(data.subtotal)}</label>
                                    </div>
                                </div> 
                                <div className="form-row col-md-12 mb-3" style={{fontWeight: "bold"}}>
                                    <div className="col-md-6">
                                        <label>Total:</label>
                                    </div>
                                    <div className="col-md-6">
                                        <label>{currencyFormat(data.total)}</label>
                                    </div>
                                </div>                             
                            </div>    
                        </Modal.Body>
                    </form>   
                </Modal>
            </div>
        );
    }
}
 
export default Calculator;