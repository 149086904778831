import React, { Component } from "react";
import { Link } from "react-router-dom";
import Table from "./common/table";
import { Modal } from 'react-bootstrap';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { toast } from "react-toastify";
import moment from 'moment';
import { getCurrentUser } from "../services/authService";
import { getMerchants, saveMerchant, deleteMerchant } from '../services/merchantService';

class PcmList extends Component {
    state = {
        pcm: [],
        data: {
            word: '',
            startDate: moment(),
            endDate: moment(),
        },
        show: false,
        focusedStartDate: false,
        focusedEndDate: false
    }

    columns = [
        {
            field: "word",
            label: "Palabra a Buscar",
            width: 150
        },
        { field: "startDate", label: "Inicio de Busqueda", width: 150 },
        { field: "endDate", label: "Fin de Busqueda", width: 150 }
    ];

    deleteColumn = {
        key: "delete",
        field: "delete",
        label: "Acciones",
        width: 130
    };

    showModal = () => {
        this.setState({ show: true });
    };

    hideModal = () => {
        this.setState({ show: false });
    };

    mapToViewModel(merchant) {
        console.log(merchant);
        return {
            _id: merchant._id,
            word: merchant.word,
            startDate: moment(merchant.startDate),
            endDate: moment(merchant.endDate),
        };
    }

    handleChange = ({ currentTarget: input }) => {
        const data = { ...this.state.data };
        data[input.name] = input.value;
        this.setState({ data });
    };

    handleDateChange = (date, name) => {
        const { data } = this.state;
        data[name] = date;
        this.setState({ data })
    };

    async populateUser() {
        const { data: user } = await getCurrentUser();
        if (user && user.role && user.role.actions.some(a => a.method === "delete-rpc")) this.columns.push(this.deleteColumn);

        const columns = this.columns;
        this.setState({ columns });
    }

    async componentDidMount() {
        await this.populateUser();
        const { data: pcm } = await getMerchants();
        const pcmUpdate = pcm && pcm.map(i => {
            i.word = <Link to="#" className={i.status === "found" ? "badge badge-success" : ""}>{i.word}</Link>
            i.startDate = moment(i.startDate).format("DD/MM/YYYY");
            i.endDate = moment(i.endDate).format("DD/MM/YYYY");
            i.delete = <button onClick={(e) => window.confirm("Esta seguro que desea eliminar la busqueda?") && this.handleDelete(i)} className="btn btn-danger btn-sm">Eliminar</button>;
            return i;
        });
        this.setState({ pcm: pcmUpdate });
    }

    getPagedData = () => {
        const {
            pcm: allPcm,
        } = this.state;

        let filtered = allPcm;
        filtered = allPcm.filter(m =>
        ((this.props.match.params.status === 'review' && (m.status === this.props.match.params.status || m.status === 'found')) ||
            (m.status === this.props.match.params.status))

        );

        return { data: filtered };
    };

    handleDelete = async merchant => {
        const originalPcm = this.state.pcm;
        const pcm = originalPcm.filter(m => m !== merchant);
        this.setState({ pcm });

        try {
            await deleteMerchant(merchant._id);
        } catch (ex) {
            // if (ex.response && ex.response.status === 404)
            // toast.error("This client has already been deleted.");

            this.setState({ pcm: originalPcm });
        }
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        const { data } = this.state;
        await saveMerchant(data);
        this.hideModal();
        this.props.history.push("/pcm/pending");
        toast.success("El instrumento se guardo correctamente.");
    };

    render() {
        const { word, startDate, endDate } = this.state.data;
        const { data: pcm } = this.getPagedData();

        return (
            <div>
                <Modal show={this.state.show} onHide={this.hideModal}>
                    <form>
                        <Modal.Header closeButton>
                            <Modal.Title>Completa el formulario</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="form-row">
                                <div className="col-md-12 form-label-group mb-3">
                                    <input placeholder="Palabra a Buscar" className="form-control" name="word" onChange={this.handleChange} value={word} />
                                    <label>Palabra a Buscar</label>
                                </div>
                                <div className="col-md-12 mb-3">
                                    <SingleDatePicker
                                        date={startDate} // momentPropTypes.momentObj or null
                                        onDateChange={(date) => this.handleDateChange(date, 'startDate')} // PropTypes.func.isRequired
                                        focused={this.state.focusedStartDate} // PropTypes.bool
                                        onFocusChange={({ focused: focusedStartDate }) => this.setState({ focusedStartDate })} // PropTypes.func.isRequired
                                        displayFormat="DD/MM/YYYY"
                                        numberOfMonths={1}
                                        isOutsideRange={() => false}
                                        placeholder={"Inicio de Busqueda"}
                                        id="startDate"
                                    />
                                </div>
                                <div className="col-md-12 mb-3">
                                    <SingleDatePicker
                                        date={endDate} // momentPropTypes.momentObj or null
                                        onDateChange={(date) => this.handleDateChange(date, 'endDate')} // PropTypes.func.isRequired
                                        focused={this.state.focusedEndDate} // PropTypes.bool
                                        onFocusChange={({ focused: focusedEndDate }) => this.setState({ focusedEndDate })} // PropTypes.func.isRequired
                                        displayFormat="DD/MM/YYYY"
                                        numberOfMonths={1}
                                        isOutsideRange={() => false}
                                        placeholder={"Fin de Busqueda"}
                                        id="endDate"
                                    />
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-primary" onClick={this.handleSubmit}>Guardar</button>
                        </Modal.Footer>
                    </form>
                </Modal>
                <div className="form-row mt-3 mb-3">
                    <div className="col-lg-7">
                        <div className="float-left">
                            <React.Fragment>
                                <Link
                                    to="#"
                                    onClick={() => this.showModal()}
                                    className="btn btn-primary float-right"
                                >
                                    <i className="fa fa-plus" aria-hidden="true" /><span> </span>
                                    Nuevo PSM
                                </Link>
                            </React.Fragment>
                        </div>
                    </div>
                </div>
                <Table
                    columns={this.columns}
                    data={pcm}
                />
            </div>
        );
    }
}

export default PcmList;
