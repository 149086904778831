import React from "react";
import { Redirect } from "react-router-dom";
import Joi from "joi-browser";
import Form from "./common/form";
import { Auth } from 'aws-amplify';
import logo from '../logo.png';

class LoginForm extends Form {
  state = {
    // data: { username: "", password: "" },
    // errors: {}
    data: { email: '', password: '', code: '' },
    error: '',
    loggedUser: '',
  };

  schema = {
    email: Joi.string()
      .required()
      .label("Email"),
    password: Joi.string()
      .required()
      .label("Password")
  };

  redirect = async (user) => {
    localStorage.setItem('user', JSON.stringify(user));
    window.location.href = '/dashboard';
  }

  doSubmit = async () => {
    // try {
    //   const { data } = this.state;
    //   await auth.login(data.username, data.password);

    //   const { state } = this.props.location;
    //   window.location = state ? state.from.pathname : "/";
    // } catch (ex) {
    //   if (ex.response && ex.response.status === 400) {
    //     const errors = { ...this.state.errors };
    //     errors.username = ex.response.data;
    //     this.setState({ errors });
    //   }
    // }
    const { email, password, code } = this.state.data;
    const { loggedUser } = this.state;

    try {
      if (loggedUser && code) {
        Auth.confirmSignIn(
          loggedUser,
          code,
          loggedUser.challengeName,
        ).then(() => this.redirect(loggedUser))
          .catch((err) => this.setState({ error: err.message }));
      } else {
        Auth.signIn(email, password)
          .then((user) => {
            console.log('User >>> ', user);
            if (user.challengeName === 'SMS_MFA') {
              this.setState({ loggedUser: user });
            } else {
              this.redirect(user);
            }
          })
          .catch((err) => {
            console.log(err);
            this.setState({ error: err.message });
          });
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        this.setState({ error: 'Vuelve a intentarlo' });
      }
    }
  };

  render() {
    const user = localStorage.getItem('user');
    if (user) return <Redirect to="/dashboard" />;

    const { data, error } = this.state;
    const { loggedUser } = this.state;

    return (
      <form onSubmit={this.handleSubmit} className="form-signin">
        <div className="text-center mb-4">
          <img className="mb-4" src={logo} alt="" width="50%" height="100%" />
        </div>
        {loggedUser ? (
          <>
            <div className="form-label-group">
              <input name="code" className="form-control" placeholder="Codigo de Verificación" value={data.code} onChange={this.handleChange} required />
              <label>Codigo de Verificación</label>
            </div>
          </>) :
          (<>
            <div className="form-label-group">
              <input type="email" name="email" className="form-control" placeholder="Correo electronico" value={data.email} onChange={this.handleChange} required />
              <label>Correo electronico</label>
            </div>

            <div className="form-label-group">
              <input type="password" name="password" className="form-control" placeholder="Contraseña" value={data.password} onChange={this.handleChange} required />
              <label>Contraseña</label>
            </div>
          </>)}
        {error && <label style={{ color: 'red' }}>{error}</label>}
        <button className="btn btn-lg btn-primary btn-block">Ingresar</button>
      </form>
    );
  }
}

export default LoginForm;
