import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { ObjectID } from 'bson';
import { Link } from "react-router-dom";

class Offices extends Component {
    state = {
        show: false,
        data: {
            _id: new ObjectID(),
            name: "",
        }
    };

    handleChange = ({ currentTarget: input }) => {
        const { data } = this.state;
        data[input.name] = input.value;

        this.setState({ data });
    };

    showModal = edit => {
        let { data } = this.state;
        if (edit) {
            data = { _id: edit.name };
        } else {
            data = { _id: new ObjectID(), name: "" };
        }

        this.setState({ data: data, show: true });
    };

    hideModal = () => {
        this.setState({ show: false });
    };

    handleSubmit = e => {
        e.preventDefault();
        const { data } = this.state;
        let editData = this.props.offices.filter(m => m._id === data._id);

        if (editData.length < 1)
            data._id = new ObjectID();

        this.setState({ data: data, show: false });
        this.props.onOffice(data);
    };

    render() {
        const { data } = this.state;
        return (
            <div>
                <div className="form-row">
                    <div className="col-md-6 mb-6">
                        <h5>Asunto</h5>
                    </div>
                    <div className="col-md-6 mb-6 pr-0">
                        <a className="btn btn-info btn-sm float-right link-add" onClick={() => this.showModal(false)}><i className="fa fa-plus" /></a>
                    </div>
                </div>
                <div className="form-row form-row">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">Nombre</th>
                                <th scope="col">Acción</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.offices.map(office => (
                                <tr key={office._id}>
                                    <td><Link to="#" onClick={() => this.showModal(office)}>{office.name}</Link></td>
                                    <td className="text-center"><Link to="#" onClick={() => this.props.onDelete(office)} style={{ color: 'red' }}>Eliminar</Link></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <Modal show={this.state.show} onHide={this.hideModal}>
                    <form>
                        <Modal.Header closeButton>
                            <Modal.Title>Agregar Asunto</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <input placeholder="Nombre" className="form-control" name="name" type="text" onChange={this.handleChange} value={data.name} />
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-primary" onClick={this.handleSubmit}>Guardar</button>
                        </Modal.Footer>
                    </form>
                </Modal>
            </div>
        );
    }
}

export default Offices;