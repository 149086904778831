import React, { Component } from 'react';
import { Link } from "react-router-dom";
import ConfigurationsTable from "./configurationsTable";
import { getConfigurations } from "../services/configurationService";
import { paginate } from "../utils/paginate";

class Configurations extends Component {
    state = { 
        configurations: [],
        currentPage: 1,
        pageSize: 20,
        sortColumn: { path: "number", order: "asc" }        
     }

     async componentDidMount() {            
        const { data: configurations } = await getConfigurations();   
        const configurationsUpdate = configurations.map((c,index) => {
            c.number = <Link to={`/configurations/${c._id}`} key={c.number} searchvalue={c.number}>{String(c.number)}</Link>            
            return c;
        });

        this.setState({ configurations: configurationsUpdate });
    }

     handlePageChange = page => {
        this.setState({ currentPage: page });
      };

      handleSearch = query => {
        this.setState({ searchQuery: query, currentPage: 1 });
     };

     getPagedData = () => {
        const {
          pageSize,
          currentPage,
          configurations: allConfigurations,
        } = this.state;
    
        let filtered = allConfigurations;
        // if (searchQuery)
        //     filtered = allClients.filter(m =>
        //         m.name.toLowerCase().startsWith(searchQuery.toLowerCase())                
        //     );

        // const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
    
        const configurations = paginate(filtered, currentPage, pageSize);
    
        return { totalCount: filtered.length, data: configurations };
      };

      handleSort = sortColumn => {
        this.setState({ sortColumn });
      };

    render() { 
        //const { length: count } = this.state.configurations;
        const { sortColumn  } = this.state;
        //if (count === 0) return <p>There are no instrumentos in database.</p>;

        const { data: configurations } = this.getPagedData();
        return (
            <div>
                {/* <div className="form-row mt-3 mb-3">
                    <div className="col-lg-10">                            
                    </div>                    
                    <div className="col-lg-2">
                        <SearchBox value={searchQuery} onChange={this.handleSearch} />
                    </div>
                </div> */}
                <ConfigurationsTable
                    configurations={configurations}
                    sortColumn={sortColumn}
                    onSort={this.handleSort}
                />
                {/* <Pagination
                    itemsCount={totalCount}
                    pageSize={pageSize}
                    currentPage={currentPage}
                    onPageChange={this.handlePageChange}
                />                 */}
            </div>
        );
    }
}
 
export default Configurations;