import http from "./httpService";

const apiEndpoint = process.env.REACT_APP_API_URL + "/clients";

export function clientUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getClients() {
  return http.get(apiEndpoint);
}

export function getClient(clientId) {
  return http.get(clientUrl(clientId));
}

export function saveClient(client) {
  if (client._id) {
    const body = { ...client };
    delete body._id;
    return http.put(clientUrl(client._id), body);
  }

  return http.post(apiEndpoint, client);
}

export function deleteClient(clientId) {
  return http.delete(clientUrl(clientId));
}

export function findByName(clientName) {
  return http.get(apiEndpoint + '/by-name/' + clientName);
}
