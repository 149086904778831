import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { ObjectID } from 'bson';
import { Link } from "react-router-dom";
import Autocomplete from 'react-autocomplete'
import { currencyFormat } from "../../../utils/currency";

class Fees extends Component {
    state = {
        show: false,
        data: {
            _id: new ObjectID(),
            concept: "",
            amount: 0,
            quantity: 1,
            currency: "MXN",
            iva: 0,
            discount: 0,
            total: "",
            hasIva: true,
            showDiscount: true,
            checked: false,
            invoiceId: ""
        },
        concepts: []
    };

    async populateBox() {
        const concepts2 = new Set();
        const concepts = [];
        for (const { fees } of this.props.boxes) {
            for (const { concept } of fees) {
                if (!concepts2.has(concept)) {
                    concepts.push({ _id: new ObjectID(), name: concept })
                    concepts2.add(concept)
                }
            }
        }
        this.setState({ concepts });
    }

    async componentDidMount() {
        this.populateBox();
    }

    showModal = edit => {
        let { data } = this.state;
        if (edit) {
            data = { _id: edit._id, concept: edit.concept, amount: edit.amount, discount: edit.discount, quantity: edit.quantity, currency: edit.currency, iva: edit.iva, total: edit.total, hasIva: edit.hasIva, checked: edit.checked, invoiceId: edit.invoiceId };
        } else {
            data = { _id: new ObjectID(), concept: "", amount: "", discount: 0, quantity: 1, currency: "MXN", iva: "", total: "", hasIva: true, checked: false, invoiceId: "" };
        }

        this.setState({ data: data, show: true });
    };

    hideModal = () => {
        this.setState({ show: false });
    };

    handleChange = ({ currentTarget: input }) => {
        const { data } = this.state;
        data[input.name] = input.value;
        data.total = parseFloat((data.amount * data.quantity) * (data.currency === "USD" ? this.props.usd : 1)).toFixed(2);
        data.total = data.total - data.discount;
        data.total = data.total.toFixed(2);
        data.iva = data.hasIva ? parseFloat(data.total * 0.16).toFixed(2) : 0;

        this.setState({ data });
    };

    handleIva = ({ currentTarget: input }) => {
        const { data } = this.state;

        if (input.checked) {
            data.iva = 0;
            data.hasIva = false;
        } else {
            data.iva = parseFloat(data.amount * 0.16);
            data.hasIva = true;
        }

        this.setState({ data });
    };

    handleDiscount = ({ currentTarget: input }) => {
        const { data } = this.state;

        if (input.checked) {
            data.showDiscount = true;
        } else {
            data.showDiscount = false;
        }

        this.setState({ data });
    };

    handleSubmit = e => {
        e.preventDefault();
        const { data } = this.state;
        let editData = this.props.fees.filter(m => m._id === data._id);

        if (editData.length < 1)
            data._id = new ObjectID();

        this.setState({ data: data, show: false });
        this.props.onFee(data);
        // const errors = this.validate();
        // this.setState({ errors: errors || {} });
        // if (errors) return;

        // this.doSubmit();
    };

    conceptsFiltered(concept) {
        return this.state.concepts.filter(({ name }) => name.toLowerCase().includes(concept.toLowerCase()))
    }

    render() {
        const { data } = this.state;
        const { user } = this.props;
        return (
            <div>
                <div className="form-row">
                    <div className="col-md-6 mb-6">
                        <h5>Honorarios</h5>
                    </div>
                    {user && user.role && user.role.actions.some(a => a.method === "add-fees-box") && this.props.addItems && (
                        <div className="col-md-6 mb-6 pr-0">
                            <button type="button" className="btn btn-info btn-sm float-right link-add" onClick={() => this.showModal(false)}><i className="fa fa-plus" /></button>
                        </div>
                    )}
                </div>
                <div className="form-row form-row">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">Concepto</th>
                                <th scope="col">Monto</th>
                                <th scope="col">Cant.</th>
                                <th scope="col">Descuento (MXN)</th>
                                <th scope="col">Total (MXN)</th>
                                <th scope="col">Tipo de Moneda</th>
                                <th scope="col">IVA</th>
                                <th scope="col">Acción</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.fees.map(fee => (
                                <tr key={fee._id}>
                                    <td><Link to="#" onClick={() => (user && user.role && user.role.actions.some(a => a.method === "edit-fees-box")) ? this.showModal(fee) : ''}>{fee.concept}</Link></td>
                                    <td>{currencyFormat(fee.amount)}</td>
                                    <td>{fee.quantity}</td>
                                    <td>{fee.discount && fee.showDiscount ? currencyFormat(fee.discount) : ""}</td>
                                    <td>{currencyFormat(fee.total)}</td>
                                    <td>{fee.currency}</td>
                                    <td>{currencyFormat(fee.iva)}</td>
                                    {user && user.role && user.role.actions.some(a => a.method === "delete-fees-box") && (
                                        <td className="text-center"><Link to="#" onClick={() => this.props.onDelete(fee)} style={{ color: 'red' }}>Eliminar</Link></td>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <Modal show={this.state.show} onHide={this.hideModal}>
                    <form>
                        <Modal.Header closeButton>
                            <Modal.Title>Agregar Honorarios</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <Autocomplete
                                        inputProps={{ id: 'itemId', name: 'itemId', className: 'form-control', placeholder: 'Concepto' }}
                                        wrapperStyle={{ position: '', background: '#b8daff' }}
                                        value={data.concept}
                                        items={this.conceptsFiltered(data.concept)}
                                        getItemValue={(item) => item.name}
                                        onSelect={(value, item) => {
                                            data.concept = item.name
                                            this.setState({ data })
                                        }}
                                        onChange={(event, value) => {
                                            data.concept = value
                                            this.setState({ data })
                                        }}
                                        renderMenu={children => (
                                            <div className="menu">
                                                {children}
                                            </div>
                                        )}
                                        renderItem={(item, isHighlighted) => (
                                            <div
                                            className={`item ${isHighlighted ? 'item-highlighted' : ''}`}
                                            key={item._id}
                                            >{item.name}</div>
                                        )}
                                    />
                                </div>
                                <div className="form-row col-md-12">
                                    <div className="col-md-9 mb-3">
                                        <input
                                          placeholder="Monto"
                                          className="form-control"
                                          name="amount"
                                          type="number"
                                          onChange={this.handleChange}
                                          value={data.amount}
                                          required/>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <select
                                          name="currency"
                                          id="currency"
                                          className="form-control"
                                          onChange={this.handleChange}
                                          value={data.currency}
                                        >
                                            <option value="MXN">MXN</option>
                                            <option value="USD">USD</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-12 mb-3">
                                    <input
                                      placeholder="Cantidad"
                                      className="form-control"
                                      name="quantity"
                                      type="number"
                                      onChange={this.handleChange}
                                      value={data.quantity}
                                      min={1}
                                      required/>
                                </div>
                                <div className="col-md-12 mb-3">
                                    <input
                                      placeholder="Descuento"
                                      className="form-control"
                                      name="discount"
                                      type="number"
                                      onChange={this.handleChange}
                                      value={data.discount}/>
                                </div>
                                <div className="col-md-12 mb-3">
                                    <input
                                      placeholder="Total"
                                      className="form-control"
                                      name="total"
                                      type="number"
                                      readOnly
                                      onChange={this.handleChange}
                                      value={data.total}/>
                                </div>
                                <div className="col-md-12 mb-3">
                                    <input
                                      placeholder="IVA"
                                      className="form-control"
                                      name="iva"
                                      type="number"
                                      readOnly
                                      onChange={this.handleChange}
                                      value={data.iva}/>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="col-md-12 form-check">
                                    <input
                                      type="checkbox"
                                      name="is-iva"
                                      className="form-check-input"
                                      checked={!data.hasIva}
                                      onChange={this.handleIva}/>
                                    <label className="form-check-label">No incluir IVA</label>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="col-md-12 form-check">
                                    <input
                                      type="checkbox"
                                      name="is-iva"
                                      className="form-check-input"
                                      checked={data.showDiscount}
                                      onChange={this.handleDiscount}/>
                                    <label className="form-check-label">Mostrar descuento</label>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-primary" onClick={this.handleSubmit}>Guardar</button>
                        </Modal.Footer>
                    </form>
                </Modal>
            </div>
        );
    }
}

export default Fees;
