import React, { Component } from "react";
import Table from "./common/table";

class ReceivablesDaysTable extends Component {
  columns = [ 
    { 
        field: "name", 
        label: "Nombre",
        width: 200        
    },
    { 
        field: "total", 
        label: "Total",
        width: 200        
    }  
  ];

  render() {
    const { receivablesDays } = this.props;

    return (
      <Table
        columns={this.columns}
        data={receivablesDays}
      />
    );
  }
}

export default ReceivablesDaysTable;
