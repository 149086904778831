import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { ObjectID } from 'bson'
import Moment from 'react-moment'
import Autocomplete from 'react-autocomplete'
import { SingleDatePicker } from 'react-dates'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import moment from 'moment'
import { getRnieNames } from '../../services/instrumentService'

class RnieTable extends Component {
  state = {
    data: {
      _id: new ObjectID(),
      active: true,
      applicant: '',
      representative: '',
      contact: '',
      noticeDate: this.props.rnie.noticeDate ? moment(this.props.rnie.noticeDate) : moment(),
      exits: this.props.rnie.exist ? this.props.rnie.exist : false

    },
    names: [],
    date: this.props.date,
    show: false,
    focused: false
  }

  async getRnieNames () {
    const date = this.state.date
    const filter = { date: moment(date).format('MM/DD/YYYY') }
    const { data: names } = await getRnieNames(filter)
    this.setState({ names })
  }

  async componentDidMount () {
    await this.getRnieNames()
  }

  showModal = (edit) => {
    const data = {
      _id: edit._id,
      active: true,
      applicant: edit.applicant,
      representative: edit.representative,
      contact: edit.contact,
      noticeDate: edit.noticeDate ? moment(edit.noticeDate) : moment(),
      exist: edit.exist
    }

    this.setState({ data, show: true })
  }

  hideModal = () => {
    this.setState({ show: false })
  }

  handleChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data }
    data[input.name] = input.value
    this.setState({ data })
  }

  handleDateChange = (date) => {
    const { data } = this.state
    data.noticeDate = date
    this.setState({ data })
  }

  handleCheck = (e, type) => {
    const { data } = this.state
    if (e.target.checked) {
      data.active = true
      this.showModal()
    } else {
      data.active = false
    }
    this.props.onCheck(e, type)
    this.setState({ data })
  }

  matchStateToTerm = (name, value) => {
    return (
      name.applicant.toLowerCase().indexOf(value.toLowerCase()) !== -1
    )
  }

  fakeRequest = async (value) => {
    let { data, disabled, date } = this.state
    const filter = { date: moment(date).format('MM/DD/YYYY') }
    let { data: names } = await getRnieNames(filter)

    if (value) {
      names = names.filter(name => this.matchStateToTerm(name, value))
      if (names.length === 0) {
        disabled = false
        data._id = new ObjectID()
        data.representative = ''
        data.contact = ''
        data.noticeDate = moment()
        data.exist = false
      }
    } else {
      disabled = false
    }
    this.setState({ data, names, disabled })
  }

  handleSubmit = (e, status) => {
    e.preventDefault()
    const { data } = this.state
    data.status = status
    // let editData = this.props.fees.filter(m => m._id === data._id);

    // if(editData.length < 1)
    //     data._id = new ObjectID();

    this.setState({ data: data, show: false })
    this.props.onRnie(data)
  }

  render () {
    const { rnie, user } = this.props
    const { data, names } = this.state
    return (
      <>
        <div className="col-md-6 mb-6">
          <h5>RNIE</h5>
        </div>
        <div className="col-md-6 mb-6 pr-0">
          <a className="btn btn-info btn-sm float-right link-add" onClick={() => this.showModal(false)}><i
            className="fa fa-plus"/></a>
        </div>
        {rnie.map(rnie => (
          <>
            <div className="col-md-6 mb-6">
              <h5>
                <span
                  className={rnie.status === 'presented' ? 'badge badge-success' : (rnie.status === 'pending' ? 'badge badge-secondary' : 'badge badge-danger')}>
                    {rnie.status === 'presented' ? 'Presentado' : (rnie.status === 'pending' ? 'Pendiente' : (rnie.status === 'delayed' ? 'No Presentado' : ''))}
                </span>
              </h5>
            </div>
            <div className="col-md-12 mb-3 table-pr">
              <div className="form-row form-row">
                <table className="table table-bordered">
                  <thead>
                  <tr>
                    <th scope="col">Solicitante</th>
                    <th scope="col">Representante</th>
                    <th scope="col">Contacto</th>
                    <th scope="col">Fecha de presentación</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr key={rnie._id}>
                    <td>
                      {rnie.status !== 'presented' ?
                        <Link to="#" onClick={() => this.showModal(rnie)}>{rnie.applicant}</Link> :
                        rnie.applicant}
                    </td>
                    <td>{rnie.representative}</td>
                    <td>{rnie.contact}</td>
                    <td>{rnie.noticeDate ? (<Moment format="DD/MM/YYYY">{rnie.noticeDate}</Moment>) : ''}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </>
        ))}
        <Modal show={this.state.show} onHide={this.hideModal}>
          <form>
            <Modal.Header closeButton>
              <Modal.Title>Completa el formulario</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-row">
                <div className="col-md-12 form-label-group mb-3">
                  < Autocomplete
                    inputProps={{
                      id: 'applicant',
                      name: 'applicant',
                      className: 'form-control',
                      placeholder: 'Solicitante'
                    }}
                    wrapperStyle={{ position: '', background: '#b8daff' }}
                    value={data.applicant}
                    items={names}
                    getItemValue={(item) => item.applicant}
                    selectOnBlur={true}
                    onSelect={(value, item) => {
                      data._id = new ObjectID()
                      data.applicant = item.applicant
                      data.representative = item.representative
                      data.contact = item.contact
                      data.noticeDate = moment(item.noticeDate)
                      data.exist = true
                      // set the menu to only the selected item
                      this.setState({ data, disabled: true })
                      // or you could reset it to a default list again
                      //this.setState({ clients: clients })
                    }}
                    onChange={(event, value) => {
                      data.applicant = value
                      this.setState({ data })
                      this.fakeRequest(value)
                    }}
                    renderMenu={children => (
                      <div className="menu">
                        {children}
                      </div>
                    )}
                    renderItem={(item, isHighlighted) => (
                      <div
                        className={`item ${isHighlighted ? 'item-highlighted' : ''}`}
                        key={item._id}
                      >{item.applicant}</div>
                    )}
                  />
                </div>
                <div className="col-md-12 form-label-group mb-3">
                  <input placeholder="Representante" className="form-control" name="representative"
                         onChange={this.handleChange} value={data.representative} disabled={data.exist}/>
                  <label>Representante</label>
                </div>
                <div className="col-md-12 form-label-group mb-3">
                  <input placeholder="Contacto" className="form-control" name="contact" onChange={this.handleChange}
                         value={data.contact} disabled={data.exist}/>
                  <label>Contacto</label>
                </div>
                <div className="col-md-12 mb-3">
                  <SingleDatePicker
                    date={data.noticeDate} // momentPropTypes.momentObj or null
                    onDateChange={(date) => this.handleDateChange(date)} // PropTypes.func.isRequired
                    focused={this.state.focused} // PropTypes.bool
                    onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
                    displayFormat="DD/MM/YYYY"
                    numberOfMonths={1}
                    isOutsideRange={() => false}
                    placeholder={'Fecha de presentación'}
                    id="noticeDate"
                    disabled={data.exist}
                  />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-primary" onClick={(e) => this.handleSubmit(e, 'pending')}>Guardar</button>
              {user && user.role && user.role.actions.some(a => a.method === 'send-rnie') && (
                <button className="btn btn-success" onClick={(e) => this.handleSubmit(e, 'presented')}>Enviar a
                  presentados</button>
              )}
            </Modal.Footer>
          </form>
        </Modal>
      </>
    )
  }
}

export default RnieTable
