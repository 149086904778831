import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import BoxesTable from './components/boxesTable'
import { boxReviewUpdate, deleteBox, getBoxes } from '../../services/boxService'
import { getCurrentUser } from '../../services/authService'
import { getBoxesUser } from '../../services/userService'

class Boxes extends Component {
  state = {
    boxes: [],
    clients: [],
    currentPage: 1,
    pageSize: 10000,
    searchQuery: '',
    sortColumn: { path: 'numberBox', order: 'asc' },
    startDate: null,
    endDate: null,
    focusedInput: null,
    isLoading: true,
  }

  async populateUser() {
    const { data: user } = await getCurrentUser()
    if (user) this.setState({ user })
  }

  async populateBoxes() {
    const { user } = this.state
    const { data: boxes } = user && user.role && user.role.actions.some(a => a.method === 'all-box') ? await getBoxes() : await getBoxesUser(user._id)

    this.setState({ boxes, isLoading: false })
  }

  async componentDidMount() {
    await this.populateUser()
    await this.populateBoxes()
  }

  handleArchive = async updateBox => {
    const originalBoxes = this.state.boxes
    const boxes = [...originalBoxes] // Required to update state
    const index = boxes.indexOf(updateBox)
    boxes[index] = { ...boxes[index] } // Required to update state
    boxes[index].review = !updateBox.review
    this.setState({ boxes })
    try {
      await boxReviewUpdate(updateBox._id, !updateBox.review)
    } catch (ex) {
      if (ex.response && ex.response.status === 400)
        //toast.error("This movie has already been deleted.");
        this.setState({ boxes: originalBoxes })
    }
  }

  handleDelete = async box => {
    const originalBoxes = this.state.boxes
    const boxes = originalBoxes.filter(m => m !== box)
    this.setState({ boxes })

    try {
      await deleteBox(box._id)
    } catch (ex) {
      this.setState({ boxes: originalBoxes })
    }
  }

  render() {
    const { user, boxes, isLoading } = this.state
    return (
      <div id="boxes-grid">
        <div className="form-row mt-3 mb-3">
          <div className="col-lg-7">
            <div className="float-left">
              {user && user.role && user.role.actions.some(a => a.method === 'add-box') && (
                <React.Fragment>
                  <Link to="/boxes/new" className="btn btn-primary float-right">
                    <i className="fa fa-plus" aria-hidden="true" /><span> </span>
                    Nueva Factura
                  </Link>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
        <BoxesTable
          user={user}
          boxes={boxes}
          handleArchive={this.handleArchive}
          handleDelete={this.handleDelete}
          isLoading={isLoading}
        />
      </div>
    )
  }
}

export default Boxes
