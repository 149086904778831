import React from "react";
import Joi from "joi-browser";
import Form from "./common/form";
import { getStatus, saveStatus } from "../services/statusService";

class StatusForm extends Form {
  state = {
    data: {
      name: "",
    },
    errors: {}
  };

  schema = {
    _id: Joi.string(),
    name: Joi.string()
      .required()
      .label("Nombre")
  };

  async populateStatus() {
    try {
      const statusId = this.props.match.params.id;
      if (statusId === "new") return;

      const { data: status } = await getStatus(statusId);
      this.setState({ data: this.mapToViewModel(status) });
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  }

  async componentDidMount() {
    await this.populateStatus();
  }

  mapToViewModel(status) {
    return {
      _id: status._id,
      name: status.name
    };
  }

  doSubmit = async () => {
    await saveStatus(this.state.data);

    this.props.history.push("/status");
  };

  render() {
    return (
      <div>
        <form className="mt-4" onSubmit={this.handleSubmit}>
          <div className="form-row">
            <div className="col-md-4 mb-3">
              {this.renderInput("name", "Nombre del estatus")}
            </div>
          </div>
          <button className="btn btn-primary float-right" type="submit">Guardar</button>
        </form>
      </div>
    );
  }
}

export default StatusForm;
