import http from "./httpService";

const apiEndpoint = process.env.REACT_APP_API_URL + "/responsible";

export function responsibleUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getResponsibles() {
  return http.get(apiEndpoint);
}

export function getResponsible(responsibleId) {
  return http.get(responsibleUrl(responsibleId));
}

export function saveResponsible(responsible) {
  if (responsible._id) {
    const body = { ...responsible };
    delete body._id;
    return http.put(responsibleUrl(responsible._id), body);
  }

  return http.post(apiEndpoint, responsible);
}

export function deleteResponsible(responsibleId) {
  return http.delete(responsibleUrl(responsibleId));
}
