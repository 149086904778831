import React, { Component } from "react";
import { Link } from "react-router-dom";
import Table from "./common/table";

class ConfigurationsTable extends Component {
  columns = [
    {
      field: "number",
      label: "ID",
      width: 100
    },
    {
      field: "name",
      label: "Configuración",
      width: 900
    },
    { 
      field: "value", 
      label: "Valor" 
    },
  ];

  render() {
    const { configurations, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        data={configurations}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default ConfigurationsTable;
