import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { ObjectID } from 'bson';
import Testimonials from "./testimonials";
import { SingleDatePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

class Testimonies extends Component {
    state = {
        show: false,
        data: {
            _id: new ObjectID(),
            number: 1,
            date: "",
            requestor: this.props.user.name,
            active: true,
            items: this.props.testimony && this.props.testimony.items,
        },
    };

    showModal = edit => {
        // let { data } = this.state;
        // if(edit){
        //     data = { _id: edit._id, number: edit.number, date: moment(edit.date), requestor: edit.requestor, delivery: edit.delivery, issue: edit.issue };
        // } else {
        //     data = { _id: new ObjectID(), number: "", date: "", requestor: "", delivery: "", issue: "" };
        // }
        this.setState({ show: true });
    };

    hideModal = () => {
        this.setState({ show: false });
    };

    handleChange = ({ currentTarget: input }) => {
        //const errors = { ...this.state.errors };
        //const errorMessage = this.validateProperty(input);
        // if (errorMessage) errors[input.name] = errorMessage;
        // else delete errors[input.name];

        const data = { ...this.state.data };
        data[input.name] = input.value;

        this.setState({ data });
    };

    handleDateChange = date => {
        const { data } = this.state;
        data.date = date;
        this.setState({ data })
    };

    handleTestimonial = async (testimony, status) => {
        const { data } = this.state;
        const testimonies = data.items;
        const editData = testimonies.findIndex(m => m._id === testimony._id);
        testimonies[editData] = testimony;
        data.items = testimonies;
        this.setState({ data });
        this.props.onTestimony(data);
    };

    handleDelete = async (testimony) => {
        const { data } = this.state;
        const testimonies = data.items;
        const deleteIndex = testimonies.findIndex(m => m._id === testimony._id);
        testimonies.splice(deleteIndex, 1);
        this.setState({ data });
        this.props.onTestimony(data);
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const { data } = this.state;
        let newTestimonials = [];
        for( let i = 0; i < data.number; i++ ){
            newTestimonials.push({
                _id: new ObjectID().toHexString(),
                number: "",
                dateRequest: new Date(),
                dateExpedition: "",
                requestor: "",
                delivery: "",
                issue: "",
                used: false,
                status: 'pending',
                files: [],
            });
        }
        if (data.items?.length){
            data.items.push(...newTestimonials)
        }else{
            data.items = newTestimonials
        }
        // let editData = this.props.testimonies.filter(m => m._id === data._id);

        // if(editData.length < 1)
        //     data['_id'] = new ObjectID();
        this.setState({ data: data, show: false });
        this.props.onTestimony(data);
        // const errors = this.validate();
        // this.setState({ errors: errors || {} });
        // if (errors) return;

        // this.doSubmit();
    };

    render() {
        const { data } = this.state;
        const { user, testimony, users } = this.props;

        return (
            <div>
                <div className="form-row">
                    <div className="col-md-6 mb-6">
                        <h5>Testimonios</h5>
                    </div>
                    {/*{*/}
                    {/*    user && user.role && user.role.actions.some(a => a.method === "add-testimony-instrument") && testimony && (*/}
                    {/*        <div className="col-md-6 mb-1 pr-0">*/}
                    {/*            <a className="btn btn-info float-right link-add" onClick={() => this.showModal(false)}>Activar</a>*/}
                    {/*        </div>*/}
                    {/*    )*/}
                    {/*}*/}
                </div>
                <Testimonials
                  users={users}
                  testimony={testimony}
                  testimonials={testimony && testimony.items}
                  onTestimonial={this.handleTestimonial}
                  onDelete={this.handleDelete}
                  instrument={this.props.instrument}/>
                <Modal show={this.state.show} onHide={this.hideModal}>
                    <form>
                        <Modal.Header closeButton>
                            <Modal.Title>Agregar Testimonios</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="form-row">
                                <div className="col-md-12 form-label-group mb-3">
                                    <input placeholder="Solicitante" className="form-control" name="requestor" onChange={this.handleChange} value={data.requestor} disabled={true} />
                                    <label>Solicitante</label>
                                </div>
                                <div className="col-md-12 form-label-group mb-3">
                                    <input placeholder="Número de testimonios" className="form-control" name="number" type="number" onChange={this.handleChange} value={data.number} />
                                    <label>¿Cuantos testimonios deseas expedir?</label>
                                </div>
                            </div>
                        </Modal.Body>
                        {
                            user && user.role && user.role.actions.some(a => a.method === "save-testimony-instrument") && (
                                <Modal.Footer>
                                    <button className="btn btn-primary" onClick={(e) => this.handleSubmit(e)}>Guardar</button>
                                </Modal.Footer>
                            )
                        }
                    </form>
                </Modal>
            </div>
        );
    }
}

export default Testimonies;
