import React from "react";
import Joi from "joi-browser";
import Form from "./common/form";
import { getType, saveType } from "../services/typeService";
//import { getGenres } from "../services/genreService";

class TypeForm extends Form {
  state = {
    data: {
      name: "",
    },
    errors: {}
  };

  schema = {
    _id: Joi.string(),
    name: Joi.string()
      .required()
      .label("Nombre")
  };

//   async populateGenres() {
//     const { data: genres } = await getGenres();
//     this.setState({ genres });
//   }

  async populateType() {
    try {
      const typeId = this.props.match.params.id;
      if (typeId === "new") return;

      const { data: type } = await getType(typeId);
      this.setState({ data: this.mapToViewModel(type) });
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  }

  async componentDidMount() {
   //await this.populateGenres();
    await this.populateType();
  }

  mapToViewModel(type) {
    return {
      _id: type._id,
      name: type.name
    };
  }

  doSubmit = async () => {
    await saveType(this.state.data);

    this.props.history.push("/types");
  };

  render() {
    return (
      <div>
        <form className="mt-4" onSubmit={this.handleSubmit}>
          <div className="form-row">
            <div className="col-md-4 mb-3">
              {this.renderInput("name", "Nombre del tipo")}
            </div>
          </div>
          <button className="btn btn-primary float-right" type="submit">Guardar</button>
        </form>
      </div>
    );
  }
}

export default TypeForm;
