import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import BoxesTable from './boxesTable'
import { boxReviewUpdate, getExpenses, updateExpense } from '../../../services/boxService'
import { getCurrentUser } from '../../../services/authService'
import BoxesProviders from './boxesProviders'
import { getInstruments } from '../../../services/instrumentService'
import { getValuations } from '../../../services/valuationService'
import { getMediations } from '../../../services/mediationService'
import { toast } from 'react-toastify'

class ProvidersPayment extends Component {
  state = {
    user: null,
    expenses: [],
    isLoading: true,

  }

  async populateUser () {
    const { data: user } = await getCurrentUser()
    if (user) this.setState({ user })
  }

  async populateExpenses () {
    const { data: expenses } = await getExpenses()
    this.setState({ expenses, isLoading: false })
  }

  async componentDidMount () {
    await this.populateUser()
    await this.populateExpenses()
  }

  handleChange = async (e, expense) => {
    const originalExpenses = this.state.expenses
    const expenses = [...originalExpenses] // Required to update state
    const index = expenses.indexOf(expense)
    expenses[index] = { ...expenses[index] } // Required to update state
    expenses[index].status = e.target.value
    this.setState({ expenses })
    try {
      await updateExpense(expense._id, e.target.value)
      toast.success('Gasto actualizado correctamente')
    } catch (ex) {
      if (ex.response && ex.response.status === 400)
        //toast.error("This movie has already been deleted.");
        this.setState({ expenses: originalExpenses })
    }
  }

  render () {
    const { user, expenses, isLoading } = this.state
    return (
      <div id="expenses-grid">
        <BoxesProviders
          user={user}
          expenses={expenses}
          isLoading={isLoading}
          handleChange={this.handleChange}
        />
      </div>
    )
  }
}

export default ProvidersPayment
