import React from 'react'
import { Link } from 'react-router-dom'
import Form from '../common/form'
import 'react-sortable-tree/style.css' // This only needs to be imported once in your app
import { getCurrentUser } from '../../services/authService'
import { deleteCff, getCffs } from '../../services/cffService'
import CffTable from './cffTable';

class CffList extends Form {
  state = {
    cff: [],
    data: {
      fiscalRegime: '',
      email: ''
    },
    show: false,
    focusedStartDate: false,
    focusedEndDate: false,
    child: [],
    errors: {}
  }

  columns = [
    {
      field: 'nameCff',
      label: 'Nombre',
      width: 500
    },
    { field: 'status', label: 'Estatus', width: 500 }
  ]

  deleteColumn = {
    key: 'delete',
    field: 'delete',
    label: 'Acciones'
  }

  async populateUser () {
    const { data: user } = await getCurrentUser()
    //if (user && user.role && user.role.actions.some(a => a.method === "delete-cff"))
    this.columns.push(this.deleteColumn)

    const columns = this.columns
    this.setState({ columns })
  }

  async componentDidMount () {
    await this.populateUser()
    const { data: cff } = await getCffs()
    const cffUpdate = cff && cff.map(i => {
      i.aux = i.status
      i.status = <span
        className={i.status === 'pending' ? 'badge badge-warning' : (i.status === 'review' ? 'badge badge-primary' : 'badge badge-success')}>{i.status === 'pending' ? 'Pendiente' : (i.status === 'review' ? 'En revisión' : 'Terminado')}</span>
      delete i.structure
      delete i.instrument
      return i
    })
    this.setState({ cff: cffUpdate })
  }

  getPagedData = () => {
    const {
      cff: allCff,
    } = this.state

    let filtered = allCff
    filtered = allCff.filter(m => {
        return ((m.status.props.children === 'Terminado' ? 'complete' : (m.status.props.children === 'En revisión' ? 'review' : 'pending')) === this.props.match.params.status)
      }
    )

    return { data: filtered }
  }

  handleDelete = async cf => {
    const originalCff = this.state.cff
    const cff = originalCff.filter(c => c !== cf)
    this.setState({ cff })

    try {
      await deleteCff(cf._id)
    } catch (ex) {
      // if (ex.response && ex.response.status === 404)
      // toast.error("This client has already been deleted.");

      this.setState({ cff: originalCff })
    }
  }

  render () {
    const { data: cff } = this.getPagedData()

    return (
      <div>
        <div className="form-row mt-3 mb-3">
          <div className="col-lg-7">
            <div className="float-left">
              <React.Fragment>
                <Link
                  to="/cff/new"
                  className="btn btn-primary float-right"
                >
                  <i className="fa fa-plus" aria-hidden="true"/><span> </span>
                  Nuevo BC
                </Link>
              </React.Fragment>
            </div>
          </div>
        </div>

        <CffTable
          cff={cff}
          isLoading={this.props.isLoading}
          handleDelete={this.handleDelete}
        />

      </div>
    )
  }
}

export default CffList
