import http from "./httpService";

const apiEndpoint = process.env.REACT_APP_API_URL + "/forms";

export function formUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getForms() {
  return http.get(apiEndpoint);
}

export function getForm(formId) {
  return http.get(formUrl(formId));
}

export function getFormsByCff(cffId) {
  return http.get(`${apiEndpoint}/cff/${cffId}`);
}

export function saveForm(form) {
  if (form._id) {
    const body = { ...form };
    delete body._id;
    return http.put(formUrl(form._id), body);
  }

  return http.post(apiEndpoint, form);
}

export function deleteForm(formId) {
  return http.delete(formUrl(formId));
}
