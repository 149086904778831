import React, { Component } from 'react'
import Table from '../common/materia-reac-table/table'
import { Link } from 'react-router-dom'
import Check from '../common/check'
import moment from 'moment'
import { getCurrentUser } from '../../services/authService'
import { getInstruments, instrumentSpecialUpdate, saveInstrument } from '../../services/instrumentService'

class RpcList extends Component {

  state = {
    rpc: [],
    isLoading: true
  }

  columns = [
    {
      accessorKey: 'number',
      header: 'No. Instrumento',
      Cell: ({ row }) => <Link to={`/instruments/${row.original._id}`} searchvalue={row.original.number}
                               className={row.original.rpc && row.original.rpc.status === 'delayed' ? 'badge badge-danger' : ''}>{String(row.original.number)}</Link>,
      size: 150
    },
    {
      accessorKey: 'status',
      header: 'Estatus',
      size: 150
    },
    {
      accessorKey: 'date',
      header: 'Fecha instrumento',
      Cell: ({ cell }) => moment(cell.getValue()).format('DD/MM/YYYY'),
      size: 150
    },
    {
      accessorKey: 'state',
      header: 'Estado',
      Cell: ({ cell, row }) => row.original.rpc ? row.original.rpc.state : '',
      size: 150
    },
    {
      accessorKey: 'responsible',
      header: 'Responsable',
      size: 150
    },
    {
      accessorKey: 'type',
      Cell: ({ cell, row }) => row.original.rpc ? row.original.rpc.type : '',
      header: 'Tipo de aviso',
      size: 120
    },
    {
      accessorKey: 'entranceDate',
      header: 'Fecha de ingreso',
      Cell: ({ cell, row }) => row.original.rpc ? moment(row.original.rpc.entranceDate).format('DD/MM/YYYY') : '',
      size: 150
    },
    {
      accessorKey: 'inscriptionDate',
      header: 'Fecha de inscripción',
      Cell: ({ cell, row }) => row.original.rpc ? moment(row.original.rpc.inscriptionDate).format('DD/MM/YYYY') : '',
      size: 150
    },
    {
      accessorKey: 'comments',
      header: 'Comentarios',
      Cell: ({ cell, row }) => row.original.rpc ? row.original.rpc.comments : '',
      size: 150
    },
    {
      accessorKey: 'special',
      header: 'Caso especial',
      Cell: ({ cell, row }) => <Check
        archived={row.original.rpc && row.original.rpc.special ? row.original.rpc.special : false}
        onClick={() => this.handleSpecial(row.original)}></Check>,
      size: 120
    }
  ]

  deleteColumn = {
    key: 'delete',
    accessorKey: 'delete',
    header: 'Acciones',
    size: 130
  }

  async populateUser () {
    const { data: user } = await getCurrentUser()
    this.setState({ user })
  }

  async componentDidMount () {
    await this.populateUser()
    const { data: instruments } = await getInstruments({ type: 'rpc' })
    this.setState({ rpc: instruments, isLoading: false })
  }

  handleSpecial = async (instrument) => {
    const originalInstruments = this.state.rpc
    const instruments = [...originalInstruments] // Required to update state
    const index = instruments.indexOf(instrument)
    const isSpecial = !!instrument.rpc.special

    instruments[index] = { ...instruments[index] }
    instruments[index].rpc.special = !isSpecial

    this.setState({ rpc: instruments })
    try {
      await instrumentSpecialUpdate(instrument._id, !isSpecial)
      const url = isSpecial ? '/rpc/pending' : '/rpc/special'
      this.props.history.push(url)
    } catch (ex) {
      if (ex.response && ex.response.status === 400)
        //toast.error("This movie has already been deleted.");
        this.setState({ rpc: originalInstruments })
    }
  }

  handleDelete = async (instrument) => {
    const originalRpc = this.state.rpc
    const updateRpc = originalRpc.filter(p => p._id !== instrument._id)
    this.setState({ rpc: updateRpc })

    try {
      instrument.rpc = {}
      delete instrument.responsible
      delete instrument.name
      await saveInstrument(instrument)
    } catch (ex) {
      // if (ex.response && ex.response.status === 404)
      // toast.error("This client has already been deleted.");

      this.setState({ rpc: originalRpc })
    }
  }

  getPagedData = () => {
    const {
      rpc: allRpc,
    } = this.state

    let filtered = allRpc
    filtered = allRpc.filter(m => {
      if (m.rpc?.active === true && (this.props.match.params.status === 'pending' && (m.rpc?.status === this.props.match.params.status || m.rpc?.status === 'delayed'))) {
        return !m.rpc?.special === true
      } else if (this.props.match.params.status === 'inscribed' && m.rpc?.status === 'inscribed') {
        return !m.rpc?.special === true
      } else if (this.props.match.params.status === 'special') {
        return m.rpc?.special === true
      }
    })

    return { data: filtered }
  }

  deleteRowAction = ({ row }) => <button
    onClick={(e) => window.confirm('Esta seguro que desea eliminar el RPC?') && this.handleDelete(row.original)}
    className="btn btn-danger btn-sm">
    Eliminar
  </button>

  render () {
    const { isLoading, user } = this.state

    const { data: rpc } = this.getPagedData()

    return (user && this.columns ?
        <Table
          columns={this.columns}
          data={rpc}
          rowAction={(user && user.role && user.role.actions.some(a => a.method === 'delete-rpc')) ? this.deleteRowAction : null}
          isLoading={isLoading}
        /> : <></>
    )
  }
}

export default RpcList
