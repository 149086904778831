import http from './httpService'

const apiEndpoint = process.env.REACT_APP_API_URL + '/boxes'

export function boxUrl (id) {
  return `${apiEndpoint}/${id}`
}

export function getBoxes (filter) {
  return http.get(apiEndpoint, { params: filter })
}

export function getBox (boxId) {
  return http.get(boxUrl(boxId))
}

export function getBoxInstrument (instrumentId) {
  return http.get(`${apiEndpoint}/instrument/${instrumentId}`)
}

export function saveBox (box) {
  if (box._id) {
    const body = { ...box }
    delete body._id
    return http.put(boxUrl(box._id), body)
  }
  return http.post(apiEndpoint, box)
}

export function deleteBox (boxId) {
  return http.delete(boxUrl(boxId))
}

export function boxReviewUpdate (boxId, review) {
  const body = {
    review: review
  }
  return http.put(boxUrl(boxId) + '/review', body)
}

export function getExpenses () {
  return http.get(`${apiEndpoint}/providers-payment`)
}

export function updateExpense (id, status) {
  return http.put(`${apiEndpoint}/providers-payment/${id}`, { status })
}

export function notificationBox (boxId, user) {
  const body = {
    user: user
  }
  return http.post(`${apiEndpoint}/${boxId}/notification`, body)
}