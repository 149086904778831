import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import { ObjectID } from 'bson'
import Autocomplete from 'react-autocomplete'
import { getRnieNames } from '../../services/instrumentService'
import moment from 'moment'

class Rnie extends Component {
  state = {
    data: {
      _id: new ObjectID(),
      active: false,
      applicant: '',
      representative: '',
      contact: '',
      exist: false
    },
    names: [],
    show: false,
    disabled: false,
    date: this.props.date
  }

  async getRnieNames () {
    const date = this.state.date
    const filter = { date: moment(date).format('MM/DD/YYYY') }
    const { data: names } = await getRnieNames(filter)
    this.setState({ names })
  }

  async componentDidMount () {
    await this.getRnieNames()
  }

  showModal = () => {
    this.setState({ show: true })
  }

  hideModal = () => {
    this.setState({ show: false })
  }

  handleChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data }
    console.log('data', data)
    data[input.name] = input.value
    this.setState({ data })
  }

  handleCheck = (e, type) => {
    const { data } = this.state
    if (e.target.checked) {
      data.active = true
      this.showModal()
    } else {
      data.active = false
    }
    this.props.onCheck(e, type)
    this.setState({ data })
  }

  matchStateToTerm = (name, value) => {
    return (
      name.applicant.toLowerCase().indexOf(value.toLowerCase()) !== -1
    )
  }

  fakeRequest = async (value) => {
    let { data, disabled } = this.state
    const date = this.state.date
    const filter = { date: moment(date).format('MM/DD/YYYY') }
    let { data: names } = await getRnieNames(filter)

    if (value) {
      names = names.filter(name => this.matchStateToTerm(name, value))
      if (names.length === 0) {
        disabled = false
        data.representative = ''
        data.contact = ''
        data.exist = false
      }
    } else {
      disabled = false
    }
    this.setState({ data, names, disabled })
  }

  handleSubmit = e => {
    e.preventDefault()
    const { data } = this.state
    data.status = 'pending'
    // let editData = this.props.fees.filter(m => m._id === data._id);

    // if(editData.length < 1)
    //     data._id = new ObjectID();

    this.setState({ data: data, show: false })
    this.props.onRnie(data)
    // const errors = this.validate();
    // this.setState({ errors: errors || {} });
    // if (errors) return;

    // this.doSubmit();
  }

  render () {
    const { data, names, disabled } = this.state
    const { rnie, user } = this.props

    return (
      <>
        <input type="checkbox" id="rnie" onClick={(e) => this.handleCheck(e, 'rnie')}
               checked={rnie && rnie.length > 0 ? true : false} disabled={rnie && rnie.length > 0 ? true : false}/>
        <label className="form-check-label" htmlFor="rnie">RNIE</label>
        <Modal show={this.state.show} onHide={this.hideModal}>
          <form>
            <Modal.Header closeButton>
              <Modal.Title>Completa el formulario</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-row">
                <div className="col-md-12 form-label-group mb-3">
                  < Autocomplete
                    inputProps={{
                      id: 'applicant',
                      name: 'applicant',
                      className: 'form-control',
                      placeholder: 'Solicitante'
                    }}
                    wrapperStyle={{ position: '', background: '#b8daff' }}
                    value={data.applicant}
                    items={names}
                    getItemValue={(item) => item.applicant}
                    selectOnBlur={true}
                    onSelect={(value, item) => {
                      data.applicant = item.applicant
                      data.applicant = item.applicant
                      data.representative = item.representative
                      data.contact = item.contact
                      data.noticeDate = item.noticeDate
                      data.exist = false
                      // set the menu to only the selected item
                      this.setState({ data, disabled: false })
                      // or you could reset it to a default list again
                      //this.setState({ clients: clients })
                    }}
                    onChange={(event, value) => {
                      data.applicant = value
                      this.setState({ data })
                      this.fakeRequest(value)
                    }}
                    renderMenu={children => (
                      <div className="menu" key={children}>
                        {children}
                      </div>
                    )}
                    renderItem={(item, isHighlighted) => (
                      <div
                        className={`item ${isHighlighted ? 'item-highlighted' : ''}`}
                        key={item._id}
                      >{item.applicant}</div>
                    )}
                  />
                </div>
                <div className="col-md-12 form-label-group mb-3">
                  <input placeholder="Representante" className="form-control" name="representative"
                         onChange={this.handleChange} value={data.representative} disabled={disabled}/>
                  <label>Representante</label>
                </div>
                <div className="col-md-12 form-label-group mb-3">
                  <input placeholder="Contacto" className="form-control" name="contact" onChange={this.handleChange}
                         value={data.contact} disabled={disabled}/>
                  <label>Contacto</label>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {
                user && user.role && user.role.actions.some(a => a.method === 'save-rnie') && (
                  <button className="btn btn-primary" onClick={this.handleSubmit}>Guardar</button>
                )
              }
            </Modal.Footer>
          </form>
        </Modal>
      </>
    )
  }
}

export default Rnie
