import React from "react";
import Joi from "joi-browser";
import { ObjectID } from 'bson';
import Form from "./common/form";
import Copies from "./copies";
import { getCurrentUser } from "../services/authService";
import { getMediations, getMediation, saveMediation } from "../services/mediationService";
import moment  from 'moment';
import _ from "lodash";
import { SingleDatePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';


class MediationForm extends Form {
  state = {
    data: {
      number: 0,
      type: "",
      date: "",
      mediations: "",
      description: "",
      datePresentation: "",
      dateRegister: "", 
      copies: []
    },    
    types: [{ _id: new ObjectID(), "name":"Poliza" },{ _id: new ObjectID(), "name":"Acta" }],
    errors: {},
    focused: false,
    focusedPresentation: false,
    focusedRegister: false,
    readOnly: false
  };

  schema = {
    _id: Joi.string(),
    number: Joi.number()
      .required()
      .min(0)
      .max(100)
      .label("Numero de Convenio"),
    type: Joi.string()
      .required()
      .label("Tipo"),
    date: Joi.date()
      .required()
      .label("Fecha"),
    mediations: Joi.string()
      .required()
      .label("Mediados"),
    description: Joi.string()
      .required()
      .label("Descripción"),
    datePresentation: Joi.date()
      .required()
      .label("Fecha de presentación a registro"),
    copies: Joi.string()
      .required()
      .label("Copias")
  };

  async populateUser () {
    const {data: user } = await getCurrentUser();
    if(user) this.setState({ user });
  }

  async populateMediation() {
    try {
      const mediationId = this.props.match.params.id;
      if (mediationId === "new"){ 
        const { data } = this.state ;
        const { data: mediations } = await getMediations();      
        let numbersMediation = [];
        for (let mediation of mediations){
          numbersMediation.push(mediation.number);
        }  
        const minAgreement = numbersMediation.length > 0 ? (_.max(numbersMediation) + 1) : 1;
        data.number = minAgreement;

        this.setState({ data });
        return;
      }

      const { data: mediation } = await getMediation(mediationId);
      this.setState({ data: this.mapToViewModel(mediation) });
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  }

  async componentDidMount() {
    await this.populateUser(); 
    await this.populateMediation(); 
  }

  mapToViewModel(mediation) {
    return {
      _id: mediation._id,
      number: mediation.number,
      type: mediation.type,
      date: moment(mediation.date),
      mediations: mediation.mediations,
      description: mediation.description,
      datePresentation: moment(mediation.datePresentation),
      dateRegister: moment(mediation.dateRegister),
      copies: mediation.copies,
      archived: mediation.archived
    };
  }

  doSubmit = async () => {
    await saveMediation(this.state.data);

    this.props.history.push("/mediations");
  };

  handleCopy = async (copy) => {                
    const { data } = this.state;
    const copies = data.copies;
    const editData = copies.findIndex(m => m._id === copy._id);      

    if(editData < 0){
        copies.push(copy);      
    } else {
        copies[editData] = copy;
    }

    this.setState({ data });  
  };

  handleDateChange = date => {
    const { data } = this.state;
    data.date = date;
    this.setState({ data })
  }  

  handleDateChange2 = date => {
    const { data } = this.state;
    data.datePresentation = date;
    this.setState({ data })
  }
  
  handleDateChange3 = date => {
    const { data } = this.state;
    data.dateRegister = date;
    this.setState({ data })
  }

  render() {
    const { data, user } = this.state;    

    return (
      <div>
        <form className="mt-4" onSubmit={this.handleSubmit}>
          <div className="form-row">
            <div className="col-md-4 form-label-group mb-3">              
              <input placeholder="Numero de Convenio" className="form-control" name="number" type="number" onChange={this.handleChange} value={data.number} />                                
              <label>Numero de Convenio</label>
            </div>
            <div className="col-md-4 mb-3">
              {this.renderInput("type", "Clave de Convenio")}
            </div>
            <div className="col-md-4 mb-3">
                {/* <label>Fecha</label> */}
                <SingleDatePicker
                    date={data.date} // momentPropTypes.momentObj or null
                    onDateChange={date => this.handleDateChange(date)} // PropTypes.func.isRequired
                    focused={this.state.focused} // PropTypes.bool
                    onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
                    displayFormat="DD/MM/YYYY"
                    numberOfMonths={1}
                    id="dateMediation"                    
                    isOutsideRange={() => false} 
                    placeholder={"Fecha"}
                />
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-4 mb-3">
              {this.renderInput("mediations", "Mediados")}
            </div>
            <div className="col-md-4 mb-3">
              {this.renderInput("description", "Descripción")}              
            </div>
            <div className="col-md-4 mb-3">
                {/* <label>Fecha de presentación a registro</label> */}
                <SingleDatePicker
                    date={data.datePresentation} // momentPropTypes.momentObj or null
                    onDateChange={date => this.handleDateChange2(date)} // PropTypes.func.isRequired
                    focused={this.state.focusedPresentation} // PropTypes.bool
                    onFocusChange={({ focused: focusedPresentation }) => this.setState({ focusedPresentation })} // PropTypes.func.isRequired
                    displayFormat="DD/MM/YYYY"
                    numberOfMonths={1}
                    id="datePresentationMediation"
                    isOutsideRange={() => false} 
                    placeholder={"Fecha de presentación a registro"}
                />
            </div>
            <div className="col-md-4 mb-3">
                {/* <label>Fecha de registro</label> */}
                <SingleDatePicker
                    date={data.dateRegister} // momentPropTypes.momentObj or null
                    onDateChange={date => this.handleDateChange3(date)} // PropTypes.func.isRequired
                    focused={this.state.focusedRegister} // PropTypes.bool
                    onFocusChange={({ focused: focusedRegister }) => this.setState({ focusedRegister })} // PropTypes.func.isRequired
                    displayFormat="DD/MM/YYYY"
                    numberOfMonths={1}
                    id="dateRegisterMediation"
                    isOutsideRange={() => false} 
                    placeholder="Fecha de registro"
                />
            </div>
          </div>
          {
            user && user.role && user.role.actions.some(a => a.method === "copy-mediation") && (
              <div className="form-row">
                <div className="col-md-12 mb-3 table-pr">  
                  <Copies onCopy={this.handleCopy} copies={data.copies} user={user} />              
                </div>
              </div>
            )
          }          
          {
            user && user.role && user.role.actions.some(a => a.method === "save-mediation") && (
              <button className="btn btn-primary float-right" type="submit">Guardar</button>
            )
          }          
        </form>
      </div>
    );
  }
}

export default MediationForm;
