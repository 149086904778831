import React, { Component } from "react";
import Table from "./common/table";

class OriginatorsTable extends Component {
  columns = [
    { 
        field: "name", 
        label: "Nombre del originador"
    },   
    { field: "abbreviation", label: "Abreviación", width: 150 }, 
    { field: "percentage", label: "Porcentaje", width: 150 }
  ];

  render() {
    const { originators, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        data={originators}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default OriginatorsTable;
