import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import { ObjectID } from 'bson'
import { Link } from 'react-router-dom'
import Moment from 'react-moment'
import { SingleDatePicker } from 'react-dates'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import moment from 'moment'
import Uploader from '../common/uploader'
import { upload } from '../../utils/s3'
import CustomFileList from '../common/customFileList'

class Testimonials extends Component {
  state = {
    show: false,
    data: {
      _id: new ObjectID(),
      number: '',
      dateRequest: '',
      dateExpedition: '',
      responsible: {
        _id: '',
        name: ''
      },
      delivery: '',
      issue: '',
      status: 'pending',
      box: '',
      files: [],
      notes: ''
    },
    focused: false
  }

  showModal = edit => {
    let { data } = this.state
    data = {
      _id: edit._id ? edit._id : new ObjectID().toHexString(),
      number: edit.number ? edit.number : '',
      dateExpedition: moment(edit.dateExpedition ? edit.dateExpedition : new Date()),
      dateRequest: moment(edit.dateRequest),
      responsible: edit.responsible || { _id: '', name: '' },
      requestor: edit.requestor ? edit.requestor : '',
      issue: edit.issue ? edit.issue : '',
      status: edit.status,
      box: edit.box ? edit.box : '',
      files: edit.files ? edit.files : [],
      notes: edit.notes ? edit.notes : ''
    }
    this.setState({ show: true, data })
  }

  hideModal = () => {
    this.setState({ show: false })
  }

  handleResponsible = (responsible) => {
    const { data } = this.state
    data.responsible = responsible
    this.setState({ data })
  }

  handleChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data }
    data[input.name] = input.value

    this.setState({ data })
  }

  handleDateChange = dateExpedition => {
    const { data } = this.state
    data.dateExpedition = dateExpedition
    this.setState({ data })
  }

  handleDelete = (e, testimony) => {
    e.preventDefault()
    this.props.onDelete(testimony)
  }

  handleSubmit = (e, status) => {
    e.preventDefault()
    const { data } = this.state
    data.status = status
    this.setState({ data: data, show: false })
    this.props.onTestimonial(data, status)
  }

  handleUpload = async (file, edit, type) => {
    try {
      if (!file._id) {
        const doc = file.getRawFile()
        if (doc) {
          const { data } = this.state
          const bucket = 'instruments-files'
          const path = `${this.props.instrument.number}/${doc.name}`
          await upload(path, doc, bucket).then(async (s3) => {
            data.files.push({
              name: path,
              url: s3.Location,
              type: type,
              uid: file.uid,
              extension: file.extension,
              size: file.size,
            })
            this.setState({ data })
          })
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  handleRemoveUpload = (file) => {
    const { data } = this.state
    data.files = data.files.filter(m => m.uid !== file.uid)
    this.setState({ data })
  }

  render () {
    const { data } = this.state
    let { testimonials, users } = this.props
    testimonials = (testimonials && testimonials.length > 0) ? testimonials : []
    return (
      <>
        <div className="form-row">
          <table className="table table-bordered">
            <thead>
            <tr>
              <th scope="col">No.</th>
              <th scope="col">F. requerimiento</th>
              <th scope="col">F. expedición</th>
              <th scope="col">Solicita</th>
              <th scope="col">Expedido a</th>
              <th scope="col">Caja</th>
              <th scope="col">Estatus</th>
              <th scope="col">Notas</th>
              <th scope="col" width="100">Comprobantes</th>
              <th scope="col" width="155">Acciones</th>
            </tr>
            </thead>
            <tbody>
            {testimonials.map((item, index) => (
              <tr key={index}>
                <td>{item.number}</td>
                <td><Moment format="DD/MM/YYYY">{item.dateRequest}</Moment></td>
                <td>{item.dateExpedition && (<Moment format="DD/MM/YYYY">{item.dateExpedition}</Moment>)}</td>
                <td>{item.responsible?.name}</td>
                <td>{item.issue}</td>
                <td>{item.box ? `${item.box.numberBox} - ${item.box.description}` : ''}</td>
                <td>{item.status === 'pending' ? 'Pendiente' : 'Finalizado'}</td>
                <td>{item.notes}</td>
                <td>{<CustomFileList files={item.files} bucket={'instruments-files'}/>}</td>
                <td>
                  {
                    item.status !== 'completed' && (
                      <Link onClick={() => this.showModal(item)} to="#" className="btn btn-info btn-sm">Editar</Link>
                    )
                  }
                  {
                    item.status !== 'completed' && (
                      <Link onClick={(e) => this.handleDelete(e, item)} to="#"
                            className="btn btn-info btn-sm ml-2">Eliminar</Link>
                    )
                  }
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
        <Modal show={this.state.show} onHide={this.hideModal}>
          <form>
            <Modal.Header closeButton>
              <Modal.Title>Agregar Testimonios</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-row">
                <div className="col-md-12 form-label-group mb-3">
                  <input placeholder="Numero de testimonio"
                         className="form-control"
                         name="number"
                         type="text"
                         onChange={this.handleChange} value={data.number}/>
                  <label>Numero de testimonio</label>
                </div>
                <div className="col-md-12 mb-3">
                  <SingleDatePicker
                    date={data.dateExpedition} // momentPropTypes.momentObj or null
                    onDateChange={(dateExpedition) => this.handleDateChange(dateExpedition)} // PropTypes.func.isRequired
                    focused={this.state.focused} // PropTypes.bool
                    onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
                    displayFormat="DD/MM/YYYY"
                    numberOfMonths={1}
                    isOutsideRange={() => false}
                    placeholder={'Fecha de Expedición'}
                    id="dateTestimony"
                  />
                </div>
                <div className="col-md-12 form-label-group mb-3">
                  <select
                    id="responsible"
                    name="responsible"
                    className="form-control"
                    onChange={(e) => this.handleResponsible({ _id: e.target.value, name: e.target.selectedOptions[0].text })}
                    value={data.responsible._id}>
                    <option value="">Selecciona...</option>
                    {users.map(r => (
                      <option key={r.name} value={r._id}>
                        {r.name}
                      </option>
                    ))}
                  </select>
                  <label>Solicita</label>
                </div>
                <div className="col-md-12 form-label-group mb-3">
                  <input placeholder="Expedido a nombre de" className="form-control" name="issue" type="string"
                         onChange={this.handleChange} value={data.issue}/>
                  <label>Expedido a nombre de</label>
                </div>
                <div className="col-md-12 form-label-group mb-3">
                  <input
                    type="text"
                    placeholder="Notas"
                    className="form-control"
                    name="notes"
                    onChange={this.handleChange}
                    value={data.notes} />
                  <label>Notas</label>
                </div>
                <div className="col-md-12 form-label-group mb-3">
                  Comprobantes
                  <Uploader files={data.files || []}
                            onUpload={this.handleUpload}
                            onRemove={this.handleRemoveUpload}
                            bucket={'instruments-files'}
                            type={'testimony'}/>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-primary" onClick={(e) => this.handleSubmit(e, 'pending')}>Guardar</button>
              <button className="btn btn-success" onClick={(e) => this.handleSubmit(e, 'completed')}>Finalizado</button>
            </Modal.Footer>

          </form>
        </Modal>
      </>
    )
  }
}

export default Testimonials
