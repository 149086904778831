import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { ObjectID } from 'bson';
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Moment from 'react-moment';
import { currencyFormat } from "../../../utils/currency";

class Payments extends Component {
    state = {
        show: false,
        data: {
            _id: new ObjectID(),
            note: "",
            date: new Date(),
            amount: 0,
            type: "",
            currency: "MXN"
        },
        types: [{ _id: new ObjectID(), name: "Banorte" }, { _id: new ObjectID(), name: "Scotiabank" }, { _id: new ObjectID(), name: "Efectivo" }, { _id: new ObjectID(), name: "Otro" }]
    };

    showModal = edit => {
        let { data } = this.state;
        if (edit) {
            data = { _id: edit._id, note: edit.note, date: new Date(edit.date), amount: edit.amount, type: edit.type, currency: edit.currency };
        } else {
            data = { _id: new ObjectID(), note: "", date: new Date(), amount: "", currency: "MXN", type: "" };
        }
        this.setState({ show: true, data });
    };

    hideModal = () => {
        this.setState({ show: false });
    };

    handleChange = ({ currentTarget: input }) => {
        //const errors = { ...this.state.errors };
        //const errorMessage = this.validateProperty(input);
        // if (errorMessage) errors[input.name] = errorMessage;
        // else delete errors[input.name];

        const data = { ...this.state.data };

        if (input.name === "amount") {
            data[input.name] = Math.max(Number(input.min), Math.min(Number(this.props.total), Number(input.value)));
        } else {
            data[input.name] = input.value;
        }

        this.setState({ data });
    };

    handleChangeDate = date => {
        const data = { ...this.state.data };
        data["date"] = date;
        this.setState({ data })
    };

    handleSubmit = e => {
        e.preventDefault();
        const { data } = this.state;
        let editData = this.props.payments.filter(m => m._id === data._id);

        if (editData.length < 1)
            data['_id'] = new ObjectID();

        this.setState({ data: data, show: false });
        this.props.onPayment(data);
        // const errors = this.validate();
        // this.setState({ errors: errors || {} });
        // if (errors) return;

        // this.doSubmit();
    };

    render() {
        const { data, types } = this.state;
        const { user } = this.props;

        return (
            <div>
                <div className="form-row">
                    <div className="col-md-6 mb-6">
                        <h5>Pagos</h5>
                    </div>
                    <div className="col-md-6 mb-6 pr-0">
                        {user && user.role && user.role.actions.some(a => a.method === "add-payments-box") && this.props.total > 0 && (
                            <button type="button" className="btn btn-dark btn-sm float-right link-add" onClick={() => this.showModal(false)}><i className="fa fa-plus" /></button>
                        )}
                    </div>
                </div>
                <div className="form-row form-row">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">Fecha</th>
                                <th scope="col">Monto</th>
                                <th scope="col">Tipo de Moneda</th>
                                <th scope="col">Tipo de pago</th>
                                <th scope="col">Nota</th>
                                <th scope="col">Acción</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.payments.map(payment => (
                                <tr key={payment._id}>
                                    <td><Link to="#" onClick={() => (user && user.role && user.role.actions.some(a => a.method === "edit-invoices-box").length > 0) ? this.showModal(payment) : ""}><Moment format="DD/MM/YYYY">{payment.date}</Moment></Link></td>
                                    <td>{currencyFormat(payment.amount)}</td>
                                    <td>{payment.currency}</td>
                                    <td>{payment.type}</td>
                                    <td>{payment.note}</td>
                                    {user && user.role && user.role.actions.some(a => a.method === "delete-payments-box") && (
                                        <td className="text-center"><Link to="#" onClick={() => this.props.onDelete(payment)} style={{ color: 'red' }}>Eliminar</Link></td>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <Modal show={this.state.show} onHide={this.hideModal}>
                    <form>
                        <Modal.Header closeButton>
                            <Modal.Title>Agregar Pagos</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="form-row">
                                <div className="col-md-12 mb-3">
                                    <label>Pago de Factura</label>
                                    <DatePicker
                                        selected={data.date}
                                        onChange={this.handleChangeDate}
                                        dateFormat="dd/MM/yyyy"
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-row col-md-12">
                                    <div className="col-md-9 mb-3">
                                        <input placeholder="Monto" className="form-control" name="amount" type="number" onChange={this.handleChange} value={data.amount} max={this.props.total} />
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <select name="currency" value={data.currency} id="currency" className="form-control" onChange={this.handleChange}>
                                            <option value="MXN">MXN</option>
                                            <option value="USD">USD</option>
                                        </select>
                                    </div>
                                </div>
                                {/* <div className="col-md-12 mb-3">
                                    <input placeholder="Monto" className="form-control" name="amount" type="number" onChange={this.handleChange} value={data.amount} min={0} max={this.props.total}/>
                                </div> */}
                                <div className="col-md-12 mb-3">
                                    <input placeholder="Nota" className="form-control" name="note" type="string" onChange={this.handleChange} value={data.note} />
                                </div>
                                <div className="col-md-12 mb-3">
                                    <select name="type" id="type" className="form-control" value={data.type} onChange={this.handleChange}>
                                        <option value="">Selecciona...</option>
                                        {types.map(option => (
                                            <option key={option._id} value={option.name}>
                                                {option.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-primary" onClick={this.handleSubmit}>Guardar</button>
                        </Modal.Footer>
                    </form>
                </Modal>
            </div>
        );
    }
}

export default Payments;
