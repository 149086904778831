import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import { ObjectID } from 'bson'
import { Link } from 'react-router-dom'

class CurrentPartners extends Component {
  state = {
    show: false,
    data: {
      name: '',
      percentage: 0,
      fiscalRegime: '',
    },
    fiscalRegimes: [{ _id: 1, name: 'Persona Física' }, { _id: 2, name: 'Persona Moral' }],
    errors: {}
  }

  showModal = edit => {
    let { data } = this.state
    if (edit) {
      data = { _id: edit._id, name: edit.name, percentage: edit.percentage, fiscalRegime: edit.fiscalRegime }
    } else {
      data = { _id: new ObjectID(), name: '', percentage: 0 }
    }

    this.setState({ data: data, show: true })
  }

  hideModal = () => {
    this.setState({ show: false })
  }

  handleChange = ({ currentTarget: input }) => {
    const { data } = this.state
    data[input.name] = input.name === 'percentage' ? parseInt(input.value) : input.value

    this.setState({ data })
    this.validate()
  }

  validate = () => {
    const { data } = this.state
    let errors = {}

    if (!data.name) errors.name = 'Nombre requerido'
    if (!data.percentage) errors.percentage = 'Porcentaje requerido'
    if (!data.fiscalRegime) errors.fiscalRegime = 'Régimen fiscal requerido'

    this.setState({ errors })
    return Object.keys(errors).length === 0
  }

  handleSubmit = e => {
    e.preventDefault()
    const { data } = this.state
    if (!this.validate()) return
    this.setState({ show: false })
    this.props.onPartner(data)
  }

  render () {
    const { data, fiscalRegimes } = this.state

    return (
      <div>
        <div className="form-row">
          <div className="col-md-8 mb-6">
            <h5>Nombre completo de socios actuales: (*con participación del 15% o más)</h5>
          </div>
          <div className="col-md-4 mb-6 pr-0">
            <Link className="btn btn-info btn-sm float-right link-add" to={'#'} onClick={() => this.showModal(false)} data-html2canvas-ignore="true">
              <i className="fa fa-plus"/>
            </Link>
          </div>
        </div>
        <div className="form-row form-row">
          <table className="table table-bordered">
            <thead>
            <tr>
              <th scope="col">Nombre / Denominación</th>
              <th scope="col">Régimen Fiscal</th>
              <th scope="col">Porcentaje</th>
              <th data-html2canvas-ignore="true"></th>
            </tr>
            </thead>
            <tbody>
            {this.props && this.props.partners && this.props.partners.map(partner => (
              <tr key={partner._id}>
                <td><Link to={'#'} onClick={() => this.showModal(partner)}>{partner.name}</Link></td>
                <td>{fiscalRegimes.find(f => f._id === parseInt(partner.fiscalRegime))?.name}</td>
                <td>{partner.percentage}</td>
                <td className="text-center" data-html2canvas-ignore="true">
                  <Link
                  to={'#'}
                  onClick={() => this.props.onDelete(partner)}
                  style={{ color: 'red' }}>Eliminar</Link></td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
        <Modal show={this.state.show} onHide={this.hideModal}>
          <form>
            <Modal.Header closeButton>
              <Modal.Title>Agregar Socio</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-row">
                <div className="col-md-12 form-label-group mb-3">
                  <input
                    placeholder="Nombre"
                    className={`form-control ${this.state.errors.name ? 'is-invalid' : 'is-valid'}`}
                    name="name"
                    onChange={this.handleChange}
                    value={data.name}/>
                  <label>Nombre</label>
                  {this.state.errors.name && <div className="invalid-feedback">{this.state.errors.name}</div>}
                </div>
                <div className="col-md-12 form-label-group mb-3">
                  <select
                    name="fiscalRegime"
                    id="fiscalRegime"
                    className={`form-control ${this.state.errors.fiscalRegime ? 'is-invalid' : 'is-valid'}`}
                    onChange={this.handleChange}
                    value={data.fiscalRegime}>
                    <option value="">Selecciona...</option>
                    {this.state.fiscalRegimes.map(fiscalRegime => (
                      <option key={fiscalRegime._id} value={fiscalRegime._id}>
                        {fiscalRegime.name}
                      </option>
                    ))}
                  </select>
                  <label>Régimen Fiscal</label>
                  {this.state.errors.fiscalRegime &&
                    <div className="invalid-feedback">{this.state.errors.fiscalRegime}</div>}
                </div>
                <div className="col-md-12 form-label-group mb-3">
                  <input
                    placeholder="Porcentaje"
                    className={`form-control ${this.state.errors.percentage ? 'is-invalid' : 'is-valid'}`}
                    name="percentage"
                    type="number"
                    onChange={this.handleChange}
                    value={data.percentage}
                    min={1}/>
                  <label>Porcentaje</label>
                  {this.state.errors.percentage &&
                    <div className="invalid-feedback">{this.state.errors.percentage}</div>}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-primary" onClick={this.handleSubmit}>Guardar</button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    )
  }
}

export default CurrentPartners
