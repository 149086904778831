import http from "./httpService";

const apiEndpoint = process.env.REACT_APP_API_URL + "/valuations";

export function valuationUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getValuations() {
  return http.get(apiEndpoint);
}

export function getValuation(valuationId) {
  return http.get(valuationUrl(valuationId));
}

export function saveValuation(valuation) {
  if (valuation._id) {
    const body = { ...valuation };
    delete body._id;
    return http.put(valuationUrl(valuation._id), body);
  }

  return http.post(apiEndpoint, valuation);
}

export function deleteValuation(valuationId) {
  return http.delete(valuationUrl(valuationId));
}

export function valuationArchivedUpdate(valuationId, archived) {
  const body = {
    archived: archived
  }
  return http.put(valuationUrl(valuationId)+'/archived', body)
}

