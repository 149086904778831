import React from "react";

const Select = ({ name, label, options, value, error, ...rest }) => {
  return (
    <div className="form-label-group">
      <select name={name} id={name} {...rest} className="form-control" value={value || ''}>
        <option value="">Selecciona...</option>
        {options.length && options.map(option => (
          <option key={option._id} value={option._id}>
            {option.name}
          </option>
        ))}
      </select>
      <label htmlFor={name}>{label}</label>
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

export default Select;
