import React, { Component } from "react";
import { Modal } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { ObjectID } from 'bson';
import Moment from 'react-moment';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';

class RpcTable extends Component {
    state = {
        data: {
            _id: new ObjectID(),
            active: this.props.rpc.active,
            state: this.props.rpc.state,
            event: this.props.rpc.event,
            type: this.props.rpc.type,
            special: this.props.rpc.special,
            entranceDate: this.props.rpc.entranceDate ? moment(this.props.rpc.entranceDate) : moment(),
            inscriptionDate: this.props.rpc.inscriptionDate ? moment(this.props.rpc.inscriptionDate) : moment(),
            comments: this.props.rpc.comments
        },
        types: ["Siger 1", "Siger 2", "Fisico"],
        states: ["Aguascalientes", "Baja California", "Baja California Sur", "Campeche",
            "Coahuila", "Colima", "Chiapas", "Chihuahua", "Durango", "Distrito Federal",
            "Guanajuato", "Guerrero", "Hidalgo", "Jalisco", "México", "Michoacán", "Morelos",
            "Nayarit", "Nuevo León", "Oaxaca", "Puebla", "Querétaro", "Quintana Roo",
            "San Luis Potosí", "Sinaloa", "Sonora", "Tabasco", "Tamaulipas", "Tlaxcala",
            "Veracruz", "Yucatán", "Zacatecas"],
        show: false,
        focused: false,
        focusedInscription: false
    }

    showModal = () => {
        this.setState({ show: true });
    };

    hideModal = () => {
        this.setState({ show: false });
    };

    handleChange = ({ currentTarget: input }) => {
        console.log(input.checked);
        const data = { ...this.state.data };
        data[input.name] = input.value;
        if (input.name === "special" && input.checked) {
            data['special'] = true;
        } else if (input.name === "special" && !input.checked) {
            data['special'] = false;
        }
        console.log(data);
        this.setState({ data });
    };

    handleDateChange = (date, name) => {
        const { data } = this.state;
        data[name] = date;
        this.setState({ data })
    };

    handleCheck = (e, type) => {
        const { data } = this.state;
        if (e.target.checked) {
            data.active = true;
            this.showModal();
        } else {
            data.active = false;
        }
        this.props.onCheck(e, type);
        this.setState({ data });
    }

    handleSubmit = (e, status) => {
        e.preventDefault();
        const { data } = this.state;
        data.status = status;
        // let editData = this.props.fees.filter(m => m._id === data._id);

        // if(editData.length < 1)
        //     data._id = new ObjectID();

        this.setState({ data: data, show: false });
        this.props.onRpc(data);
    };

    render() {
        const { rpc, user } = this.props;
        const { data, states, types } = this.state;

        return (
            <>
                <div className="form-row form-row">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">Estado</th>
                                <th scope="col">Acto</th>
                                <th scope="col">Tipo</th>
                                <th scope="col">Fecha de ingreso</th>
                                <th scope="col">Fecha de presentación</th>
                                <th scope="col">Comentarios</th>
                                <th scope="col">Caso especial</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{rpc.status !== 'inscribed' ? <Link to="#" onClick={() => this.showModal(rpc)}>{rpc.state}</Link> : rpc.state}</td>
                                <td>{rpc.event}</td>
                                <td>{rpc.type}</td>
                                <td>{rpc.entranceDate ? (<Moment format="DD/MM/YYYY">{rpc.entranceDate}</Moment>) : ""}</td>
                                <td>{rpc.inscriptionDate ? (<Moment format="DD/MM/YYYY">{rpc.inscriptionDate}</Moment>) : ""}</td>
                                <td>{rpc.comments}</td>
                                <td><i
                                    className={rpc.special ? "fas fa-folder" : "far fa-folder"}
                                    aria-hidden="true"
                                />{rpc.special}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <Modal show={this.state.show} onHide={this.hideModal}>
                    <form>
                        <Modal.Header closeButton>
                            <Modal.Title>Completa el formulario</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="form-row">
                                <div className="col-md-12 form-label-group mb-3">
                                    <select name="state" id="state" className="form-control" onChange={this.handleChange} >
                                        <option value="">Selecciona...</option>
                                        {states.map(state => (
                                            <option key={state} selected={(state === data.state) ? true : false} value={state}>
                                                {state}
                                            </option>
                                        ))}
                                    </select>
                                    <label>Estado</label>
                                </div>
                                <div className="col-md-12 form-label-group mb-3">
                                    <input placeholder="Acto" className="form-control" name="event" onChange={this.handleChange} value={data.event} />
                                    <label>Acto</label>
                                </div>
                                <div className="col-md-12 form-label-group mb-3">
                                    <select name="type" id="type" className="form-control" onChange={this.handleChange} >
                                        <option value="">Selecciona...</option>
                                        {types.map(type => (
                                            <option key={type} selected={(type === data.type) ? true : false} value={type}>
                                                {type}
                                            </option>
                                        ))}
                                    </select>
                                    <label>Tipo de aviso</label>
                                </div>
                                <div className="col-md-12 mb-3">
                                    <SingleDatePicker
                                        date={data.entranceDate} // momentPropTypes.momentObj or null
                                        onDateChange={(date) => this.handleDateChange(date, 'entranceDate')} // PropTypes.func.isRequired
                                        focused={this.state.focused} // PropTypes.bool
                                        onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
                                        displayFormat="DD/MM/YYYY"
                                        numberOfMonths={1}
                                        isOutsideRange={() => false}
                                        placeholder={"Fecha de ingreso"}
                                        id="entranceDate"
                                    />
                                </div>
                                <div className="col-md-12 mb-3">
                                    <SingleDatePicker
                                        date={data.inscriptionDate} // momentPropTypes.momentObj or null
                                        onDateChange={(date) => this.handleDateChange(date, 'inscriptionDate')} // PropTypes.func.isRequired
                                        focused={this.state.focusedInscription} // PropTypes.bool
                                        onFocusChange={({ focused: focusedInscription }) => this.setState({ focusedInscription })} // PropTypes.func.isRequired
                                        displayFormat="DD/MM/YYYY"
                                        numberOfMonths={1}
                                        isOutsideRange={() => false}
                                        placeholder={"Fecha de inscripción"}
                                        id="inscriptionDate"
                                    />
                                </div>
                                <div className="col-md-12 form-label-group mb-3">
                                    <input placeholder="Comentarios" className="form-control" name="comments" onChange={this.handleChange} value={data.comments} />
                                    <label>Comentarios</label>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="col-md-12 form-check">
                                    <input type="checkbox" name="special" className="form-check-input" onChange={this.handleChange} checked={data.special} />
                                    <label className="form-check-label">Caso especial</label>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-primary" onClick={(e) => this.handleSubmit(e, 'pending')}>Guardar</button>
                            {user && user.role && user.role.actions.some(a => a.method === "send-rpc") &&
                                (<button className="btn btn-success" onClick={(e) => this.handleSubmit(e, 'inscribed')}>Enviar a inscritos</button>
                                )}
                        </Modal.Footer>
                    </form>
                </Modal>
            </>
        );
    }
}

export default RpcTable;
