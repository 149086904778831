import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import TestimonyTable from './components/testimonyTable'
import { boxReviewUpdate, deleteBox, getBoxes } from '../../services/boxService'
import { getCurrentUser } from '../../services/authService'
import { getBoxesUser } from '../../services/userService'
import { getInstruments } from '../../services/instrumentService'
class Requestfortestimony extends Component {
  state = {
    instruments: [],
    boxes: [],
    clients: [],
    currentPage: 1,
    pageSize: 10000,
    searchQuery: '',
    sortColumn: { path: 'numberBox', order: 'asc' },
    startDate: null,
    endDate: null,
    focusedInput: null,
    isLoading: true,
  }

  async populateInstruments() {
    const { user } = this.state
    const { data: instruments } = user && user.role && user.role.actions.some(a => a.method === 'all-instrument') ? await getInstruments() : []
    this.setState({ instruments: instruments, isLoading: false })
  }

  async populateUser() {
    const { data: user } = await getCurrentUser()
    if (user) this.setState({ user })
  }

  async populateBoxes() {
    const { user } = this.state;
    const { instruments } = this.state;
    let boxesData;
    if (user && user.role && user.role.actions.some(a => a.method === 'all-box')) {
      const response = await getBoxes();
      boxesData = response.data;
    } else {
      const response = await getBoxesUser(user._id);
      boxesData = response.data;
    }
    const filteredBoxes = boxesData.filter(box => box.type === "Testimonio" && !box.review);
    const filterBoxesInstruments = boxesData.filter(box => box.type === "Instrumento" && !box.review);
    const boxesWithInstrumentsTestimonio = filteredBoxes.map(box => {
      const instrument = instruments.find(instr => instr.name === box.itemNumber);
      if (instrument) {
        return { ...box, instrument: instrument };
      }
      return box;
    });

    const boxesWithInstrumentsInstrumento = filterBoxesInstruments.slice(0, 1).map(box => {
      const iteration = box.itemNumber.split(',');
      const boxes = iteration.map(item => {
        const trimmedItem = item.trim(); // Trim the item for comparison
        const instrument = instruments.find(instr => instr.name.trim() === trimmedItem);
    
        if (instrument && Array.isArray(instrument.testimony.items)) {
          const matchingItem = instrument.testimony.items.find(item => item.box?._id === box._id);
          if (matchingItem) {
            const data = { ...box };
            data.instrument = instrument;
            data.itemNumber = instrument.name;
            return data;
          }
        }
      });
    
      const boxesFiltered = boxes.filter(box => box !== undefined);
      return boxesFiltered;
    });

    this.setState({ boxes: boxesWithInstrumentsTestimonio.concat(boxesWithInstrumentsInstrumento.flat()), isLoading: false });
  }



  async componentDidMount() {
    await this.populateUser();
    await this.populateInstruments();
    await this.populateBoxes();
  }

  handleArchive = async updateBox => {
    const originalBoxes = this.state.boxes
    const boxes = [...originalBoxes] // Required to update state
    const index = boxes.indexOf(updateBox)
    boxes[index] = { ...boxes[index] } // Required to update state
    boxes[index].review = !updateBox.review
    this.setState({ boxes })
    try {
      await boxReviewUpdate(updateBox._id, !updateBox.review);
      await this.populateBoxes();
    } catch (ex) {
      if (ex.response && ex.response.status === 400)
        //toast.error("This movie has already been deleted.");
        this.setState({ boxes: originalBoxes })
    }
  }

  render() {
    const { user, boxes, isLoading } = this.state
    return (
      <div className="box">
        <TestimonyTable
          user={user}
          boxes={boxes}
          handleArchive={this.handleArchive}
          isLoading={isLoading}
        />
      </div>
    )
  }
}

export default Requestfortestimony
