import axios from "axios";
import qs from 'qs';

const url = `https://accounts.accesscontrol.windows.net/${process.env.REACT_APP_TENANT_ID}/tokens/OAuth/2`;
const options = {
    headers: { 
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': 'application/json; odata=verbose',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Max-Age': '2592000'
    }    
};
const data = qs.stringify({
  grant_type: "client_credentials",
  client_id: process.env.REACT_APP_CLIENT_ID + process.env.REACT_APP_TENANT_ID,
  client_secret: process.env.REACT_APP_CLIENT_SECRET,
  resource: process.env.REACT_APP_RESOURCE + process.env.REACT_APP_TENANT_ID
});

export function authToken() {
  axios.post(url,data,options).then((result) => {
  });
  return "fssdf"
}

