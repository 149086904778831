import http from './httpService'

const apiEndpoint = process.env.REACT_APP_API_URL + '/providers'

export function getProviders (filter) {
  return http.get(apiEndpoint, { params: filter })
}

export function getProvider (id) {
  return http.get(`${apiEndpoint}/${id}`)
}

export function saveProvider (provider) {
  if (provider._id) {
    const body = { ...provider }
    delete body._id
    return http.put(`${apiEndpoint}/${provider._id}`, body)
  }
  return http.post(apiEndpoint, provider)
}

export function deleteProvider (id) {
  return http.delete(`${apiEndpoint}/${id}`)
}
