import React, { Component } from 'react';
import { Link } from "react-router-dom";
import DenominationsTable from "./denominationsTable";
import { toast } from "react-toastify";
import { getCurrentUser } from "../../services/authService";
import { getDenominations, saveDenomination } from "../../services/denominationService";
import { getConfigurations } from "../../services/configurationService";
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import _ from "lodash";

class Denominations extends Component {
    state = {
        denominations: [],
        expiredDays: 0,
        isLoading: true
    }

    async populateUser() {
        const { data: user } = await getCurrentUser();
        if (user) this.setState({ user });
    }

    async populateMediations () {
        const { user } = this.state;
        const { data: denominations } = user && user.role && user.role.actions.some(a => a.method === "all-denomination") ? await getDenominations() : [];
        const denominationsUpdate = denominations && denominations.map(m => {
            if(m.status === 'uses'){
                return m;
            }
            m.status = m.status === 'available' ? 'Disponible' : (m.status === 'expired' ? 'Vencido' : (m.status === 'next_expired' ? 'Próximo a vencer' : 'No Disponible') );
            return m;
        });

        this.setState({ denominations: denominationsUpdate, isLoading: false });
    }

    async populateConfigurations() {
        const { data: configurations } = await getConfigurations();
        const expiredDays = configurations.filter(config => config.number === 5)[0].value;

        this.setState({ expiredDays });
    }

    async componentDidMount() {
        await this.populateUser();
        await this.populateMediations();
        await this.populateConfigurations();
    }


    handleUse = async (denomination) => {
        const originalDenominations = this.state.denominations;
        const denominations = [...originalDenominations ];

        try {
            const { data: updateDenomination } = await saveDenomination(this.mapToViewModel(denomination));
            const index = denominations.findIndex(d => d._id === updateDenomination._id);
            denominations[index] = { ...denominations[index] };
            denominations[index].useBy = updateDenomination.useBy;
            denominations[index].status = updateDenomination.status;

            this.setState({ denominations })
            toast.success("La denominación se uso correctamente.");
        } catch (ex) {
            // console.log(ex.response)
            toast.error("Intetar nuevamente.");
        }
    }

    mapToViewModel(denomination) {
        return {
            _id: denomination._id,
            name: denomination.name.props.children,
            type: denomination.type,
            account: denomination.account,
            created: moment(denomination.created,'DD/MM/YYYY').format(),
            expired: moment(denomination.expired,'DD/MM/YYYY').format(),
            status: "used",
            useBy: this.state.user.name
        };
    }

    getPagedData = () => {
        const {
            denominations: allDenominations,
        } = this.state;

        let filtered = allDenominations;
        // console.log(filtered);
        filtered = allDenominations.filter(m =>
          
          (this.props.match.params.status === 'available' && m.status === 'Disponible' && !m.isPulledApart) ||
          (this.props.match.params.status === 'available' && m.status === 'Próximo a vencer' && !m.isPulledApart) ||
          (this.props.match.params.status === 'used' && m.status === 'No Disponible' && !m.isPulledApart) ||
          (this.props.match.params.status === 'used' && m.status === 'Vencido' && !m.isPulledApart) ||
          (this.props.match.params.status === 'apart' && m.isPulledApart && m.status !== 'uses') ||
          (this.props.match.params.status === 'uses' && m.status === 'uses')
        );
        return { data: filtered };
    };

    render() {
        const { user, isLoading } = this.state;
        const { data: denominations } = this.getPagedData();

        return (
            <div>
                <div className="form-row mt-3 mb-3">
                    <div className="col-lg-7">
                        <div className="float-left">
                            { user && user.role && user.role.actions.some(a => a.method === "add-denomination") && (
                              <Link
                                to={`/denominations/${this.props.match.params.status}/new`}
                                className="btn btn-primary float-right"
                              >
                                  <i className="fa fa-plus" aria-hidden="true" /><span> </span>
                                  Nueva Denominación
                              </Link>
                            )}
                        </div>
                    </div>
                </div>
                <DenominationsTable
                    denominations={denominations}
                    user={user}
                    status={this.props.match.params.status}
                    handleUse={this.handleUse}
                    isLoading={isLoading}
                />
            </div>
        );
    }
}

export default Denominations;
