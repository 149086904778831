import React, { Component } from 'react';
import { Link } from "react-router-dom";
import RolesTable from "./rolesTable";
import { getCurrentUser } from "../../services/authService";
import { getRoles } from "../../services/roleService";

class Roles extends Component {
    state = {
        roles: []
    }

    async populateUser () {
        const {data: user } = await getCurrentUser();
        if(user) this.setState({ user });
    }

    async populateRoles() {
        const { data: roles } = await getRoles();
        console.log(roles);
        const rolesUpdate = roles.map(r => {
            r.name = <Link to={`/roles/${r._id}`} key={r.name} searchvalue={r.name}>{r.name}</Link>;
            r.actions = r.actions.map((item) =>(item.name)).join(', ');
            delete r.status;
            return r;
        })
        this.setState({ roles: rolesUpdate });
    }

    async componentDidMount() {
        await this.populateRoles();
    }

    render() {
        const { roles } = this.state;

        return (
            <div>
                <div className="form-row mt-3 mb-3">
                    <div className="col-lg-10">
                        <div className="float-left">
                                <Link
                                    to="/roles/new"
                                    className="btn btn-primary float-right"
                                    >
                                    <i className="fa fa-plus" aria-hidden="true" /><span> </span>
                                    Nuevo Rol
                                </Link>
                        </div>
                    </div>
                </div>
                <RolesTable
                    roles={roles}
                />
            </div>
        );
    }
}

export default Roles;
