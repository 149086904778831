import axios from "axios";
import { Auth } from 'aws-amplify';
//import logger from "./logService";
//import { toast } from "react-toastify";

axios.interceptors.response.use(null, error => {
  console.log(error);
  if (error.response?.status === 403 || error.response?.status === 401) {
    localStorage.removeItem('user');
    Auth.signOut().then(() => window.location = '/login');
  }
  return Promise.reject(error);
});

// function setJwt(jwt) {
//   axios.defaults.headers.common["x-auth-token"] = jwt;
// }

function isJSON(str) {
  try {
      if(str === null) return false;

      return JSON.parse(str);
  } catch (e) {
      return false;
  }
}

axios.defaults.headers.get['Content-Type'] = 'application/x-ndjson  ';
const user = isJSON(localStorage.getItem('user'));
if (user && user.signInUserSession) axios.defaults.headers.common['x-auth-token'] = user.signInUserSession.accessToken.jwtToken;

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  //setJwt
};
