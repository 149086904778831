import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import { getCurrentUser } from '../../services/authService'
import {
  getMetrics,
  getMetricsClients,
  getMetricsInstruments,
  getMetricsInstrumentsCount,
  getMetricsLawyer,
  getMetricsMonthly,
  getMetricsOriginators, getMetricsProvidersPayment
} from '../../services/metricService'
import { currencyFormat, percentageFormat } from '../../utils/currency'
import moment from 'moment'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import { saveAs } from 'file-saver'
import { generate_workbook } from '../../utils/js2xlsx'
import { getCalendarEvents } from '../../services/calendarService'
import DashboardCalendar from './calendar'
import { getObjectives } from '../../services/objectiveService'

const currencyFormatter = (item) => [currencyFormat(item)]
const percentageFormatter = (item) => [percentageFormat(item)]

class Dashboard extends Component {
  state = {
    month: moment().format('MM'),
    year: moment().format('YYYY'),
    metrics: [],
    metricsYear: [],
    metricsLawyer: [],
    metricsChecker: [],
    metricsLawyerTotal: [],
    metricsLawyerResp: [],
    metricsBestClients: [],
    metricsClients: [],
    metricsMonthly: [],
    metricsInstruments: [],
    metricsOriginators: [],
    metricsProvidersPayment: [],
    events: [],
    metricsObjectives: [],
  }

  renderCustomizedLabel = ({
    cx, cy, midAngle, outerRadius, value, name, fill
  }) => {
    const RADIAN = Math.PI / 180
    const sin = Math.sin(-RADIAN * midAngle)
    const cos = Math.cos(-RADIAN * midAngle)
    const sx = cx + (outerRadius + 10) * cos
    const sy = cy + (outerRadius + 10) * sin
    const mx = cx + (outerRadius + 30) * cos
    const my = cy + (outerRadius + 30) * sin
    const ex = mx + (cos >= 0 ? 1 : -1) * 22
    const ey = my
    const textAnchor = cos >= 0 ? 'start' : 'end'

    return (
      <g>
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${name}`}</text>
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
          {`${currencyFormat(value)}`}
        </text>
      </g>
    )
  }

  renderCustomLab = ({
    cx, cy, midAngle, outerRadius, value, name, fill
  }) => {
    return (
      <g>
        <text>
          {`${currencyFormat(value)}`}
        </text>
      </g>
    )
  }

  renderLegend = (props) => {
    return (
      props.payload.map((entry, index) => (entry.value))
    )
  }

  customizedLabel = ({ x, y, fill, width, height, value, midAngle }) => {
    const fireOffset = value ? value.toString().length < 1 : false
    const offset = fireOffset ? -30 : 5

    return (<text x={x + width - offset} y={y + height - 5} fill={fireOffset ? '#285A64' : '#fff'} fontSize={9}
      textAnchor="end">
      {value}
    </text>)
  }

  customizedX = ({ x, y, stroke, payload }) => {
    return (<g transform={`translate(${x},${y})`}>
      <text fontSize={8} x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
        {payload.value}
      </text>
    </g>)
  }

  populateUser = async () => {
    const auth = await Auth.currentAuthenticatedUser()
    if (auth) {
      const { data: user } = await getCurrentUser()
      if (user) this.setState({ user })
    }
  }

  async populateEvents() {
    const { data: events } = await getCalendarEvents()
    if (events) this.setState({ events })
  }

  async populateMetrics(filter) {
    const { user } = this.state
    //const { data: metrics } =  (user.role !== "Administrador" && user.role !== "Manager") ? await getMetrics(user._id,filter) : await getMetrics("false",filter);
    const { data: metrics } = user && user.role && user.role.actions.some(a => a.method === 'all-general-dashboard') ? await getMetrics('false', filter) : await getMetrics(user._id, filter)
    this.setState({ metrics: metrics.metricsMonth, metricsYear: metrics.metricsYear })
  }

  async populateMetricsLawyer(filter) {
    const { user } = this.state
    filter.status = 'Cobrado'
    if (user && user.role && !user.role.actions.some(a => a.method === 'all-lawyers-dashboard')) filter.user = user._id

    const { data: metricsLawyer } = await getMetricsLawyer(filter)
    this.setState({ metricsLawyer: metricsLawyer.lawyer })
    this.setState({ metricsChecker: metricsLawyer.checker })
  }

  async populateMetricsLawyerTotal(filter) {
    filter.status = ''
    filter.limit = 3
    const { data: metricsLawyerTotal } = await getMetricsLawyer(filter)
    this.setState({ metricsLawyerTotal: metricsLawyerTotal.lawyer })
  };

  async populateMetricsLawyerResp(filter) {
    filter.status = ''
    filter.sort = 'countResp'
    const { data: metricsLawyerResp } = await getMetricsLawyer(filter)
    this.setState({ metricsLawyerResp: metricsLawyerResp.lawyer })
  };

  async populateMetricsBestClients(filter) {
    filter.cobrado = 'true'
    const { data: metricsBestClients } = await getMetricsClients(filter)
    this.setState({ metricsBestClients })
  }

  async populateMetricsClients(filter) {
    filter.cobrado = 'false'
    const { data: metricsClients } = await getMetricsClients(filter)
    this.setState({ metricsClients })
  }

  async populateMetricsMonthly() {
    const { data: metricsMonthly } = await getMetricsMonthly(null)
    metricsMonthly.map(m => {
      switch (m.mes) {
        case 5: {
          m.mes = 'May'
          break
        }
        case 6: {
          m.mes = 'Jun'
          break
        }
        case 7: {
          m.mes = 'Jul'
          break
        }
        case 8: {
          m.mes = 'Ago'
          break
        }
        case 9: {
          m.mes = 'Sep'
          break
        }
        case 10: {
          m.mes = 'Oct'
          break
        }
        case 11: {
          m.mes = 'Nov'
          break
        }
        case 12: {
          m.mes = 'Dic'
          break
        }
        default: {
          break
        }
      }
      return m
    })
    this.setState({ metricsMonthly })
  }

  async populateMetricsInstruments() {
    const filter = { archived: true }
    const { data: metricsInstruments } = await getMetricsInstruments(filter)
    const { data: metricsInstrumentsCount } = await getMetricsInstrumentsCount(filter)
    this.setState({ metricsInstruments, metricsInstrumentsCount })
  }

  async populateMetricsInstrumentsCount(filter) {
    const { data: metricsInstrumentsCount } = await getMetricsInstrumentsCount(filter)
    this.setState({ metricsInstrumentsCount })
  }

  async populateMetricsOriginators(filter) {
    const { data: metricsOriginators } = await getMetricsOriginators(filter)
    this.setState({ metricsOriginators })
  }

  async populateMetricsProvidersPayment(filter) {
    const { data: metricsProvidersPayment } = await getMetricsProvidersPayment(filter)
    this.setState({ metricsProvidersPayment })
  }

  async populateMetricsObjectives() {
    const { data: metricsObjectives } = await getObjectives({})
    if (this.state.user.role.name === 'Administrador') {
      this.setState({ metricsObjectives })
    } else {
      const updatedData = metricsObjectives.map(item => {
        const porcentaje = (item.current / item.target) * 100;
        return { ...item, current: porcentaje, target: 100 };
      });
      this.setState({ metricsObjectives: updatedData });
    }
  }

  async componentDidMount() {
    let { user } = this.state
    let filter = {}
    let currentDate = moment() // Obtiene la fecha actual del sistema
    let dateIni = currentDate.startOf('month').format() // Establece la fecha inicial como el primer día del mes actual
    let dateEnd = currentDate.endOf('month').format()

    filter = {
      dateIni: dateIni,
      dateEnd: dateEnd,
      user: user && user._id ? user._id : null
    }

    await this.populateUser()
    await this.populateEvents()
    await this.populateMetricsLawyer({})
    await this.populateMetricsLawyerTotal({})
    await this.populateMetricsLawyerResp({})
    await this.populateMetricsBestClients({})
    await this.populateMetricsClients({})
    await this.populateMetricsMonthly()
    await this.populateMetricsInstruments()
    await this.populateMetricsOriginators(filter)
    await this.populateMetrics({})
    await this.populateMetricsProvidersPayment({})
    await this.populateMetricsObjectives()
  }

  handleMetrics = boxesArchived => {
    this.props.metArchives(boxesArchived)
  }

  handleChange = async (e, type, status) => {
    let { month, year, user } = this.state
    let filter = {}
    let date = ((e.target.name === 'month') ? e.target.value : month) + '/' + ((e.target.name === 'year') ? e.target.value : year)
    month = moment(date, 'MM/YYYY').format('MM')
    year = moment(date, 'MM/YYYY').format('YYYY')
    let dateIni = moment(date, 'MM/YYYY').format()
    let dateEnd = moment(date, 'MM/YYYY').endOf('month').format()

    filter = {
      dateIni: dateIni,
      dateEnd: dateEnd,
      status: status
    }
    if (type === 'bestClients') await this.populateMetricsBestClients(filter)
    if (type === 'genClients') await this.populateMetricsClients(filter)
    if (type === 'responsible') await this.populateMetricsLawyerResp(filter)
    if (type === 'lawyer' && status !== 'Cobrado') await this.populateMetricsLawyerTotal(filter)
    if (type === 'lawyer') await this.populateMetricsLawyer(filter)
    if (type === 'originator') await this.populateMetricsOriginators(filter)
    if (type === 'general') {
      await this.populateMetrics(filter)
      await this.populateMetricsProvidersPayment(filter)
    }

    await this.populateMetricsInstrumentsCount({
      dateIni: dateIni,
      dateEnd: dateEnd,
      user: user._id
    })

    this.setState({ month, year })
  }

  handleExport = async (status) => {
    let { month, year } = this.state
    let filter = {}
    let date = month + '/' + year
    month = moment(date, 'MM/YYYY').format('MM')
    year = moment(date, 'MM/YYYY').format('YYYY')
    let dateIni = moment(date, 'MM/YYYY').format()
    let dateEnd = moment(date, 'MM/YYYY').endOf('month').format()

    filter = {
      dateIni: dateIni,
      dateEnd: dateEnd,
      status: status,
      export: true
    }
    const { data: exportData } = await getMetricsLawyer(filter)

    const filename = `Comisiones_Abogados_${(moment().format('YYYYMMDDHHmmss'))}.xlsx`
    let rows = []
    exportData.forEach(element => {
      let {
        numberBox,
        responsible,
        originator,
        checker,
        goal,
        comOriginator,
        percentageResponsible,
        percentageOriginator,
        total,
        totalFees,
        paymentDate
      } = element
      paymentDate = moment(element.paymentDate).format('DD/MM/YYYY')
      rows.push([numberBox, responsible, originator, checker, goal, comOriginator, percentageResponsible, percentageOriginator, totalFees, total, paymentDate])
    })

    saveAs(
      await generate_workbook({
        sheets: [{
          name: 'Comisiones',
          cols: [
            { title: 'Número de caja', width: 15 },
            { title: 'Responsable', width: 25 },
            { title: 'Originador', width: 25 },
            { title: 'Revisor', width: 25 },
            { title: 'Objetivo', width: 20 },
            { title: 'Comisión Originador', width: 20 },
            { title: 'Porcentaje Responsable', width: 20 },
            { title: 'Porcentaje Originador', width: 20 },
            { title: 'Total Honorarios', width: 20 },
            { title: 'Total', width: 25 },
            { title: 'Fecha de pago', width: 35 }
          ],
          data: rows,
        }],
        props: {
          Title: 'Comisiones Abogados',
          CreatedDate: new Date(),
        },
        type: 'blob',
      }),
      filename,
    )
  }

  render() {
    const {
      user,
      events,
      metrics,
      metricsYear,
      metricsLawyer,
      metricsChecker,
      metricsLawyerTotal,
      metricsLawyerResp,
      metricsBestClients,
      metricsClients,
      metricsMonthly,
      metricsInstruments,
      metricsInstrumentsCount,
      metricsOriginators,
      metricsProvidersPayment,
      metricsObjectives,
      month,
      year
    } = this.state
    return (
      <div>
        {user && user.role && user.role.actions.some(a => a.method === 'edit-last-instrument') && (
          <Link style={{ textDecoration: 'underline', cursor: 'pointer', color: '#000', marginBottom: 2 }}
            to={`/configurations/609fd5228aa2cb387b19f5b6`}>
            <i className="fa fa-book" /> Último Instrumento
          </Link>
        )}

        <h4 className="my-2" style={{ fontWeight: 400 }}>General</h4>
        <select className="my-3" name="month" id="general" onChange={(e, val) => this.handleChange(e, 'general')}>
          <option selected={month === '01' ? true : false} value="01">Enero</option>
          <option selected={month === '02' ? true : false} value="02">Febrero</option>
          <option selected={month === '03' ? true : false} value="03">Marzo</option>
          <option selected={month === '04' ? true : false} value="04">Abril</option>
          <option selected={month === '05' ? true : false} value="05">Mayo</option>
          <option selected={month === '06' ? true : false} value="06">Junio</option>
          <option selected={month === '07' ? true : false} value="07">Julio</option>
          <option selected={month === '08' ? true : false} value="08">Agosto</option>
          <option selected={month === '09' ? true : false} value="09">Septiembre</option>
          <option selected={month === '10' ? true : false} value="10">Octubre</option>
          <option selected={month === '11' ? true : false} value="11">Noviembre</option>
          <option selected={month === '12' ? true : false} value="12">Diciembre</option>
        </select>
        <select name="year" id="year" onChange={(e, val) => this.handleChange(e, 'general')}>
          <option selected={year === '2019' ? true : false} value="2019">2019</option>
          <option selected={year === '2020' ? true : false} value="2020">2020</option>
          <option selected={year === '2021' ? true : false} value="2021">2021</option>
          <option selected={year === '2022' ? true : false} value="2022">2022</option>
          <option selected={year === '2023' ? true : false} value="2023">2023</option>
          <option selected={year === '2024' ? true : false} value="2024">2024</option>
          <option selected={year === '2025' ? true : false} value="2025">2025</option>
        </select>
        {(user && (user.role.name === 'Administrador' || user.role.name === 'Manager')) && (
          <>
            <h4 className="my-2" style={{ fontWeight: 400 }}>Totales por Periodo</h4>
            <div className="row mb-2 metrics">
              {metricsYear.filter(p => p.total > 0).map((entry, index) =>
                <div className="col-xl col-lg-6 col-md mb-3">
                  <div className={`card border-left-${entry.color} shadow h-100 py-2`}>
                    <div className="card-body">
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div className={`text-xs font-weight-bold text-${entry.color} text-uppercase mb-1`}>
                            {entry.name}
                          </div>
                          <div className="h5 mb-0 font-weight-bold text-gray-800">{currencyFormat(entry.total)}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>)}
        <h4 className="my-2" style={{ fontWeight: 400 }}>Totales por Mes</h4>
        <div className="row mb-2 metrics">
          {metrics.filter(p => p.total > 0).map((entry, index) =>
            <div className="col-xl-3 col-md-3 mb-3">
              <div className={`card border-left-${entry.color} shadow h-100 py-2`}>
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className={`text-xs font-weight-bold text-${entry.color} text-uppercase mb-1`}>
                        {entry.name}
                      </div>
                      <div className="h5 mb-0 font-weight-bold text-gray-800">{currencyFormat(entry.total)}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {(user && (user.role === 'Abogado')) && (
          <div className="row mb-2 metrics">
            <div className="col-xl col-lg-6 col-md mb-3">
              <div className={`card border-left-danger shadow h-100 py-2`}>
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className={`text-xs font-weight-bold text-danger text-uppercase mb-1`}>
                        Pendientes de archivo
                      </div>
                      <div
                        className="h5 mb-0 font-weight-bold text-gray-800">{metricsInstrumentsCount?.pendienteArchivar}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-3 mb-3">
              <div className={`card border-left-success shadow h-100 py-2`}>
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className={`text-xs font-weight-bold text-success text-uppercase mb-1`}>
                        Pendientes de facturar
                      </div>
                      <div
                        className="h5 mb-0 font-weight-bold text-gray-800">{metricsInstrumentsCount?.pendienteFacturar}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-3 mb-3">
              <div className={`card border-left-primary shadow h-100 py-2`}>
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className={`text-xs font-weight-bold text-primary text-uppercase mb-1`}>
                        RPC en tráite
                      </div>
                      <div
                        className="h5 mb-0 font-weight-bold text-gray-800">{metricsInstrumentsCount?.rpcEnTramite}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>)
        }
        <div className="card-deck mb-3 text-center">
          <div className="card mb-4 shadow-sm">
            <div className="card-header">
              <h4 className="mt-0 mb-2" style={{ fontWeight: 400, fontSize: '0.9rem' }}>Calendario</h4>
              <div className="row">
                <div className="col">
                  <span className="badge badge-home-office">Home Office</span>
                </div>
                <div className="col">
                  <span className="badge badge-in-office">En Oficina</span>
                </div>
                <div className="col">
                  <span className="badge badge-vacation">Vacaciones</span>
                </div>
                <div className="col">
                  <span className="badge badge-guard">Guardia</span>
                </div>
                <div className="col">
                  <span className="badge badge-fuera">Fuera del país</span>
                </div>
                <div className="col">
                  <span className="badge badge-ext">Diligencia</span>
                  </div>
              </div>
            </div>
            <DashboardCalendar events={events} />
          </div>
          <div className="card mb-4 shadow-sm">
            <div className="card-header">
              <h4 className="my-0" style={{ fontWeight: 400, fontSize: '0.9rem' }}>Objetivos</h4>
            </div>
            <div style={{ width: '100%', height: 300 }}>
              <ResponsiveContainer>
                <BarChart
                  layout="vertical"
                  data={metricsObjectives}
                  margin={{ left: 15 }}
                >
                  <CartesianGrid strokeDasharray="6 6" />
                  <XAxis type="number" />
                  <YAxis dataKey="name" type="category" tick={{ fontSize: 10, width: 100 }} />
                  <Tooltip labelLine={false} formatter={user?.role?.name === 'Administrador' ? currencyFormatter : percentageFormatter} />
                  <Legend />
                  <Bar name="Objetivos" dataKey="target" fill="#fb0005" />
                  <Bar name="Actual" dataKey="current" fill="#57d56c" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
          {user && user.role && user.role.actions.some(a => a.method === 'providers-payment') && (
            <div className="card mb-4 shadow-sm">
              <div className="card-header">
                <h4 className="my-0" style={{ fontWeight: 400, fontSize: '0.9rem' }}>Pagos a Proveedores</h4>
                <select className="mt-2 mr-1" name="month" id="lawyer" onChange={(e, val) => this.handleChange(e, 'general', '')}>
                  <option selected={month === '01' ? true : false} value="01">Enero</option>
                  <option selected={month === '02' ? true : false} value="02">Febrero</option>
                  <option selected={month === '03' ? true : false} value="03">Marzo</option>
                  <option selected={month === '04' ? true : false} value="04">Abril</option>
                  <option selected={month === '05' ? true : false} value="05">Mayo</option>
                  <option selected={month === '06' ? true : false} value="06">Junio</option>
                  <option selected={month === '07' ? true : false} value="07">Julio</option>
                  <option selected={month === '08' ? true : false} value="08">Agosto</option>
                  <option selected={month === '09' ? true : false} value="09">Septiembre</option>
                  <option selected={month === '10' ? true : false} value="10">Octubre</option>
                  <option selected={month === '11' ? true : false} value="11">Noviembre</option>
                  <option selected={month === '12' ? true : false} value="12">Diciembre</option>
                </select>
                <select name="year" id="year" onChange={(e, val) => this.handleChange(e, 'lawyer', '')}>
                  <option selected={year === '2019' ? true : false} value="2019">2019</option>
                  <option selected={year === '2020' ? true : false} value="2020">2020</option>
                  <option selected={year === '2021' ? true : false} value="2021">2021</option>
                  <option selected={year === '2022' ? true : false} value="2022">2022</option>
                  <option selected={year === '2023' ? true : false} value="2023">2023</option>
                  <option selected={year === '2024' ? true : false} value="2024">2024</option>
                  <option selected={year === '2025' ? true : false} value="2025">2025</option>
                </select>
              </div>
              <div style={{ width: '100%', height: 300 }}>
                <ResponsiveContainer>
                  <BarChart
                    data={metricsProvidersPayment}>
                    <XAxis
                      dataKey="status"
                      fontFamily="sans-serif"
                      tickSize={-5}
                      dy={10}
                      type="category" tick={{ fontSize: 8, width: 50 }}
                    />
                    <YAxis hide />
                    <CartesianGrid
                      vertical={false}
                      stroke="#ebf3f0"
                    />
                    <Bar
                      dataKey="amount"
                      fontFamily="sans-serif"
                      barSize={70}
                      label={this.customizedLabel}
                    >
                      {
                        metricsProvidersPayment.map((entry, index) => (
                          <Cell key={index} fill={index < 3 ? '#13a005' : '#ededed'} />
                        ))
                      }
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>

          )}
          {user && user.role && user.role.actions.some(a => a.method === 'monthly-dashboard') && (
            <div className="card mb-4 shadow-sm">
              <div className="card-header">
                <h4 className="my-0" style={{ fontWeight: 400, fontSize: '0.9rem' }}>Métricas Mensual</h4>
              </div>
              <div style={{ width: '100%', height: 300 }}>
                <ResponsiveContainer>
                  <LineChart data={metricsMonthly}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="mes" />
                    <YAxis tick={{ fontSize: 10, width: 20 }} />
                    <Tooltip formatter={currencyFormatter} />
                    <Legend />
                    <Line type="monotone" dataKey="cobrado" stroke="#8884d8" activeDot={{ r: 8 }} />
                    <Line type="monotone" dataKey="no_cobrado" stroke="#82ca9d" />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
          )}
          {user && user.role && user.role.actions.some(a => a.method === 'best-payments-dashboard') && (
            <div className="card mb-4 shadow-sm">
              <div className="card-header">
                <h4 className="my-0" style={{ fontWeight: 400, fontSize: '0.9rem' }}>Los 10 más pagadores</h4>
                <select className="mt-2 mr-1" name="month" id="monthly"
                  onChange={(e, val) => this.handleChange(e, 'bestClients')}>
                  <option selected={month === '01' ? true : false} value="01">Enero</option>
                  <option selected={month === '02' ? true : false} value="02">Febrero</option>
                  <option selected={month === '03' ? true : false} value="03">Marzo</option>
                  <option selected={month === '04' ? true : false} value="04">Abril</option>
                  <option selected={month === '05' ? true : false} value="05">Mayo</option>
                  <option selected={month === '06' ? true : false} value="06">Junio</option>
                  <option selected={month === '07' ? true : false} value="07">Julio</option>
                  <option selected={month === '08' ? true : false} value="08">Agosto</option>
                  <option selected={month === '09' ? true : false} value="09">Septiembre</option>
                  <option selected={month === '10' ? true : false} value="10">Octubre</option>
                  <option selected={month === '11' ? true : false} value="11">Noviembre</option>
                  <option selected={month === '12' ? true : false} value="12">Diciembre</option>
                </select>
                <select name="year" id="year" onChange={(e, val) => this.handleChange(e, 'bestClients')}>
                  <option selected={year === '2019' ? true : false} value="2019">2019</option>
                  <option selected={year === '2020' ? true : false} value="2020">2020</option>
                  <option selected={year === '2021' ? true : false} value="2021">2021</option>
                  <option selected={year === '2022' ? true : false} value="2022">2022</option>
                  <option selected={year === '2023' ? true : false} value="2023">2023</option>
                  <option selected={year === '2024' ? true : false} value="2024">2024</option>
                  <option selected={year === '2025' ? true : false} value="2025">2025</option>
                </select>
              </div>
              <div style={{ width: '100%', height: 300 }}>
                <ResponsiveContainer>
                  <BarChart
                    layout="vertical"
                    data={metricsBestClients}
                    margin={{ left: 30 }}
                  >
                    <CartesianGrid strokeDasharray="6 6" />
                    <XAxis type="number" />
                    <YAxis dataKey="name" type="category" tick={{ fontSize: 9, width: 120 }} />
                    <Tooltip formatter={currencyFormatter} />
                    {/* <Legend /> */}
                    <Bar dataKey="total" fill="#7abaff" />
                    {/* <Bar dataKey="no_cobrado" fill="#ffc658" /> */}
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          )}
          {user && user.role && user.role.actions.some(a => a.method === 'best-generators-dashboard') && (
            <div className="card mb-4 shadow-sm">
              <div className="card-header">
                <h4 className="my-0" style={{ fontWeight: 400, fontSize: '0.9rem' }}>Los 10 más generadores</h4>
                <select className="mt-2 mr-1" name="month" id="monthly"
                  onChange={(e, val) => this.handleChange(e, 'genClients')}>
                  <option selected={month === '01' ? true : false} value="01">Enero</option>
                  <option selected={month === '02' ? true : false} value="02">Febrero</option>
                  <option selected={month === '03' ? true : false} value="03">Marzo</option>
                  <option selected={month === '04' ? true : false} value="04">Abril</option>
                  <option selected={month === '05' ? true : false} value="05">Mayo</option>
                  <option selected={month === '06' ? true : false} value="06">Junio</option>
                  <option selected={month === '07' ? true : false} value="07">Julio</option>
                  <option selected={month === '08' ? true : false} value="08">Agosto</option>
                  <option selected={month === '09' ? true : false} value="09">Septiembre</option>
                  <option selected={month === '10' ? true : false} value="10">Octubre</option>
                  <option selected={month === '11' ? true : false} value="11">Noviembre</option>
                  <option selected={month === '12' ? true : false} value="12">Diciembre</option>
                </select>
                <select name="year" id="year" onChange={(e, val) => this.handleChange(e, 'clients')}>
                  <option selected={year === '2019' ? true : false} value="2019">2019</option>
                  <option selected={year === '2020' ? true : false} value="2020">2020</option>
                  <option selected={year === '2021' ? true : false} value="2021">2021</option>
                  <option selected={year === '2022' ? true : false} value="2022">2022</option>
                  <option selected={year === '2023' ? true : false} value="2023">2023</option>
                  <option selected={year === '2024' ? true : false} value="2024">2024</option>
                  <option selected={year === '2025' ? true : false} value="2025">2025</option>
                </select>
              </div>
              <div style={{ width: '100%', height: 300 }}>
                <ResponsiveContainer>
                  <BarChart
                    layout="vertical"
                    data={metricsClients}
                    margin={{ left: 30 }}
                  >
                    <CartesianGrid strokeDasharray="6 6" />
                    <XAxis type="number" />
                    <YAxis dataKey="name" type="category" tick={{ fontSize: 9, width: 120 }} />
                    <Tooltip formatter={currencyFormatter} />
                    {/* <Legend /> */}
                    <Bar dataKey="total" fill="#ed969e" />
                    {/* <Bar dataKey="no_cobrado" fill="#ffc658" /> */}
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          )}
          {user && user.role && user.role.actions.some(a => a.method === 'originators-dashboard') && (
            <div className="card mb-4 shadow-sm">
              <div className="card-header">
                <h4 className="my-0" style={{ fontWeight: 400, fontSize: '0.9rem' }}>Métricas Originadores</h4>
                <select className="mt-2 mr-1" name="month" id="originator"
                  onChange={(e, val) => this.handleChange(e, 'originator')}>
                  <option selected={month === '01' ? true : false} value="01">Enero</option>
                  <option selected={month === '02' ? true : false} value="02">Febrero</option>
                  <option selected={month === '03' ? true : false} value="03">Marzo</option>
                  <option selected={month === '04' ? true : false} value="04">Abril</option>
                  <option selected={month === '05' ? true : false} value="05">Mayo</option>
                  <option selected={month === '06' ? true : false} value="06">Junio</option>
                  <option selected={month === '07' ? true : false} value="07">Julio</option>
                  <option selected={month === '08' ? true : false} value="08">Agosto</option>
                  <option selected={month === '09' ? true : false} value="09">Septiembre</option>
                  <option selected={month === '10' ? true : false} value="10">Octubre</option>
                  <option selected={month === '11' ? true : false} value="11">Noviembre</option>
                  <option selected={month === '12' ? true : false} value="12">Diciembre</option>
                </select>
                <select name="year" id="year" onChange={(e, val) => this.handleChange(e, 'originator')}>
                  <option selected={year === '2019' ? true : false} value="2019">2019</option>
                  <option selected={year === '2020' ? true : false} value="2020">2020</option>
                  <option selected={year === '2021' ? true : false} value="2021">2021</option>
                  <option selected={year === '2022' ? true : false} value="2022">2022</option>
                  <option selected={year === '2023' ? true : false} value="2023">2023</option>
                  <option selected={year === '2024' ? true : false} value="2024">2024</option>
                  <option selected={year === '2025' ? true : false} value="2025">2025</option>
                </select>
              </div>
              <div style={{ width: '100%', height: 300 }}>
                <ResponsiveContainer>
                  <BarChart
                    layout="vertical"
                    data={metricsOriginators}
                  >
                    <CartesianGrid strokeDasharray="6 6" />
                    <XAxis type="number" />
                    <YAxis dataKey="name" type="category" tick={{ fontSize: 14, width: 100 }} />
                    <Tooltip formatter={currencyFormatter} />
                    <Bar dataKey="commission" fill="#8884d8" />
                    <Bar dataKey="total" fill="#82ca9d" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          )}
          {user && user.role && (user.role.actions.some(a => a.method === 'lawyers-dashboard') || user.role.actions.some(a => a.method === 'all-lawyers-dashboard')) && (
            <div className="card mb-4 shadow-sm">
              <div className="card-header">
                <h4 className="my-0" style={{ fontWeight: 400, fontSize: '0.9rem' }}>Comisiones Abogados</h4>
                <select className="mt-2 mr-1" name="month" id="lawyer"
                  onChange={(e, val) => this.handleChange(e, 'lawyer', 'Cobrado')}>
                  <option selected={month === '01' ? true : false} value="01">Enero</option>
                  <option selected={month === '02' ? true : false} value="02">Febrero</option>
                  <option selected={month === '03' ? true : false} value="03">Marzo</option>
                  <option selected={month === '04' ? true : false} value="04">Abril</option>
                  <option selected={month === '05' ? true : false} value="05">Mayo</option>
                  <option selected={month === '06' ? true : false} value="06">Junio</option>
                  <option selected={month === '07' ? true : false} value="07">Julio</option>
                  <option selected={month === '08' ? true : false} value="08">Agosto</option>
                  <option selected={month === '09' ? true : false} value="09">Septiembre</option>
                  <option selected={month === '10' ? true : false} value="10">Octubre</option>
                  <option selected={month === '11' ? true : false} value="11">Noviembre</option>
                  <option selected={month === '12' ? true : false} value="12">Diciembre</option>
                </select>
                <select className="mr-1" name="year" id="year"
                  onChange={(e, val) => this.handleChange(e, 'lawyer', 'Cobrado')}>
                  <option selected={year === '2019' ? true : false} value="2019">2019</option>
                  <option selected={year === '2020' ? true : false} value="2020">2020</option>
                  <option selected={year === '2021' ? true : false} value="2021">2021</option>
                  <option selected={year === '2022' ? true : false} value="2022">2022</option>
                  <option selected={year === '2023' ? true : false} value="2023">2023</option>
                  <option selected={year === '2024' ? true : false} value="2024">2024</option>
                  <option selected={year === '2025' ? true : false} value="2025">2025</option>
                </select>
                {user && user.role && user.role.actions.some(a => a.method === 'export-lawyers-dashboard') && (
                  <button className="btn btn-secondary btn-sm" onClick={() => this.handleExport('Cobrado')}><i
                    className="fa fa-file-excel" aria-hidden="true" /> Exportar</button>
                )
                }
              </div>
              <div style={{ width: '100%', height: 300 }}>
                <ResponsiveContainer>
                  <BarChart
                    layout="vertical"
                    data={metricsLawyer}
                    margin={{ left: 15 }}
                  >
                    <CartesianGrid strokeDasharray="6 6" />
                    <XAxis type="number" />
                    <YAxis dataKey="name" type="category" tick={{ fontSize: 10, width: 100 }} />
                    <Tooltip labelLine={false} formatter={currencyFormatter} />
                    <Bar dataKey="total" fill="#82ca9d" />
                    <Bar dataKey="goal" fill="#ffc658" />
                    <Bar dataKey="comResponsible" fill="#8884d8" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          )}
          {user && user.role && (user.role.actions.some(a => a.method === 'lawyers-dashboard') || user.role.actions.some(a => a.method === 'all-lawyers-dashboard')) && (
            <div className="card mb-4 shadow-sm">
              <div className="card-header">
                <h4 className="my-0" style={{ fontWeight: 400, fontSize: '0.9rem' }}>Comisiones Revisores</h4>
                <select className="mt-2 mr-1" name="month" id="lawyer"
                  onChange={(e, val) => this.handleChange(e, 'lawyer', 'Cobrado')}>
                  <option selected={month === '01' ? true : false} value="01">Enero</option>
                  <option selected={month === '02' ? true : false} value="02">Febrero</option>
                  <option selected={month === '03' ? true : false} value="03">Marzo</option>
                  <option selected={month === '04' ? true : false} value="04">Abril</option>
                  <option selected={month === '05' ? true : false} value="05">Mayo</option>
                  <option selected={month === '06' ? true : false} value="06">Junio</option>
                  <option selected={month === '07' ? true : false} value="07">Julio</option>
                  <option selected={month === '08' ? true : false} value="08">Agosto</option>
                  <option selected={month === '09' ? true : false} value="09">Septiembre</option>
                  <option selected={month === '10' ? true : false} value="10">Octubre</option>
                  <option selected={month === '11' ? true : false} value="11">Noviembre</option>
                  <option selected={month === '12' ? true : false} value="12">Diciembre</option>
                </select>
                <select className="mr-1" name="year" id="year"
                  onChange={(e, val) => this.handleChange(e, 'lawyer', 'Cobrado')}>
                  <option selected={year === '2019' ? true : false} value="2019">2019</option>
                  <option selected={year === '2020' ? true : false} value="2020">2020</option>
                  <option selected={year === '2021' ? true : false} value="2021">2021</option>
                  <option selected={year === '2022' ? true : false} value="2022">2022</option>
                  <option selected={year === '2023' ? true : false} value="2023">2023</option>
                  <option selected={year === '2024' ? true : false} value="2024">2024</option>
                  <option selected={year === '2025' ? true : false} value="2025">2025</option>
                </select>
              </div>
              <div style={{ width: '100%', height: 300 }}>
                <ResponsiveContainer>
                  <BarChart
                    layout="vertical"
                    data={metricsChecker}
                    margin={{ left: 15 }}
                  >
                    <CartesianGrid strokeDasharray="6 6" />
                    <XAxis type="number" />
                    <YAxis dataKey="name" type="category" tick={{ fontSize: 10, width: 100 }} />
                    <Tooltip labelLine={false} formatter={currencyFormatter} />
                    <Bar dataKey="total" fill="#82ca9d" />
                    <Bar dataKey="comChecker" fill="#8884d8" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          )}
          {user && user.role && user.role.actions.some(a => a.method === 'instruments-dashboard') && (
            <div className="card mb-4 shadow-sm">
              <div className="card-header">
                <h4 className="my-0" style={{ fontWeight: 400, fontSize: '0.9rem' }}>Métricas Instrumentos</h4>
              </div>
              <div style={{ width: '100%', height: 300 }}>
                <ResponsiveContainer>
                  <BarChart
                    layout="vertical"
                    data={metricsInstruments}
                    margin={{ left: 50, right: 50 }}
                  >
                    <CartesianGrid strokeDasharray="6 6" />
                    <XAxis type="number" />
                    <YAxis dataKey="name" type="category" tick={{ fontSize: 10, width: 150 }} />
                    <Tooltip />
                    {/* <Legend /> */}
                    <Bar dataKey="total" barSize={20} fill="#86cfda" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          )}
          {user && user.role && user.role.actions.some(a => a.method === 'best-worker-dashboard') && (
            <div className="card mb-4 shadow-sm">
              <div className="card-header">
                <h4 className="my-0" style={{ fontWeight: 400, fontSize: '0.9rem' }}>Responsable más generador</h4>
                <select className="mt-2 mr-1" name="month" id="lawyer"
                  onChange={(e, val) => this.handleChange(e, 'lawyer', '')}>
                  <option selected={month === '01' ? true : false} value="01">Enero</option>
                  <option selected={month === '02' ? true : false} value="02">Febrero</option>
                  <option selected={month === '03' ? true : false} value="03">Marzo</option>
                  <option selected={month === '04' ? true : false} value="04">Abril</option>
                  <option selected={month === '05' ? true : false} value="05">Mayo</option>
                  <option selected={month === '06' ? true : false} value="06">Junio</option>
                  <option selected={month === '07' ? true : false} value="07">Julio</option>
                  <option selected={month === '08' ? true : false} value="08">Agosto</option>
                  <option selected={month === '09' ? true : false} value="09">Septiembre</option>
                  <option selected={month === '10' ? true : false} value="10">Octubre</option>
                  <option selected={month === '11' ? true : false} value="11">Noviembre</option>
                  <option selected={month === '12' ? true : false} value="12">Diciembre</option>
                </select>
                <select name="year" id="year" onChange={(e, val) => this.handleChange(e, 'lawyer', '')}>
                  <option selected={year === '2019' ? true : false} value="2019">2019</option>
                  <option selected={year === '2020' ? true : false} value="2020">2020</option>
                  <option selected={year === '2021' ? true : false} value="2021">2021</option>
                  <option selected={year === '2022' ? true : false} value="2022">2022</option>
                  <option selected={year === '2023' ? true : false} value="2023">2023</option>
                  <option selected={year === '2024' ? true : false} value="2024">2024</option>
                  <option selected={year === '2025' ? true : false} value="2025">2025</option>
                </select>
              </div>
              <div style={{ width: '100%', height: 300 }}>
                <ResponsiveContainer>
                  <BarChart
                    data={metricsLawyerTotal}>
                    <XAxis
                      dataKey="name"
                      fontFamily="sans-serif"
                      tickSize={-5}
                      dy={10}
                      type="category" tick={{ fontSize: 8, width: 50 }}
                    />
                    <YAxis hide />
                    <CartesianGrid
                      vertical={false}
                      stroke="#ebf3f0"
                    />
                    <Bar
                      dataKey="total"
                      fontFamily="sans-serif"
                      barSize={70}
                      label={this.customizedLabel}
                    >
                      {
                        metricsLawyerResp.map((entry, index) => (
                          <Cell key={index} fill={index < 3 ? '#7abaff' : '#ededed'} />
                        ))
                      }
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          )}
          {user && user.role && user.role.actions.some(a => a.method === 'best-collector-dashboard') && (
            <div className="card mb-4 shadow-sm">
              <div className="card-header">
                <h4 className="my-0" style={{ fontWeight: 400, fontSize: '0.9rem' }}>El que más cobra</h4>
                <select className="mt-2 mr-1" name="month" id="lawyer"
                  onChange={(e, val) => this.handleChange(e, 'lawyer', 'Cobrado')}>
                  <option selected={month === '01' ? true : false} value="01">Enero</option>
                  <option selected={month === '02' ? true : false} value="02">Febrero</option>
                  <option selected={month === '03' ? true : false} value="03">Marzo</option>
                  <option selected={month === '04' ? true : false} value="04">Abril</option>
                  <option selected={month === '05' ? true : false} value="05">Mayo</option>
                  <option selected={month === '06' ? true : false} value="06">Junio</option>
                  <option selected={month === '07' ? true : false} value="07">Julio</option>
                  <option selected={month === '08' ? true : false} value="08">Agosto</option>
                  <option selected={month === '09' ? true : false} value="09">Septiembre</option>
                  <option selected={month === '10' ? true : false} value="10">Octubre</option>
                  <option selected={month === '11' ? true : false} value="11">Noviembre</option>
                  <option selected={month === '12' ? true : false} value="12">Diciembre</option>
                </select>
                <select name="year" id="year" onChange={(e, val) => this.handleChange(e, 'lawyer', 'Cobrado')}>
                  <option selected={year === '2019' ? true : false} value="2019">2019</option>
                  <option selected={year === '2020' ? true : false} value="2020">2020</option>
                  <option selected={year === '2021' ? true : false} value="2021">2021</option>
                  <option selected={year === '2022' ? true : false} value="2022">2022</option>
                  <option selected={year === '2023' ? true : false} value="2023">2023</option>
                  <option selected={year === '2024' ? true : false} value="2024">2024</option>
                  <option selected={year === '2025' ? true : false} value="2025">2025</option>
                </select>
              </div>
              <div style={{ width: '100%', height: 300 }}>
                {/* <ResponsiveContainer>
                                    <BarChart
                                        data={metricsLawyer}
                                        width={500}
                                        height={300}
                                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                        <XAxis
                                            dataKey="name"
                                            //tick={{ fontSize: 8, width: 30 }}
                                            tick={this.customizedX}
                                        />
                                        <YAxis hide />
                                        <CartesianGrid
                                            vertical={false}
                                            stroke="#ebf3f0"
                                        />
                                        <Bar
                                            dataKey="total"
                                            fontFamily="sans-serif"
                                            barSize={50}
                                            label={this.customizedLabel}
                                        >
                                            {
                                                metricsLawyerResp.map((entry, index) => (
                                                    <Cell key={index} fill={index < 3 ? '#ed969e' : '#ededed'} />
                                                ))
                                            }
                                        </Bar>
                                    </BarChart>
                                </ResponsiveContainer> */}

                <ResponsiveContainer>
                  <BarChart
                    layout="vertical"
                    data={metricsLawyer}
                    margin={{ left: 50, right: 50 }}
                  >
                    <CartesianGrid
                      vertical={false}
                      stroke="#ebf3f0"
                    />
                    <XAxis type="number" />
                    <YAxis dataKey="name" type="category" tick={{ fontSize: 10, width: 150 }} />
                    <Tooltip />
                    {/* <Legend /> */}
                    <Bar dataKey="total" barSize={20} fill="#86cfda" label={this.customizedLabel}>
                      {
                        metricsLawyerResp.map((entry, index) => (
                          <Cell key={index} fill={index < 3 ? '#ed969e' : '#ededed'} />
                        ))
                      }
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          )}
          {user && user.role && user.role.actions.some(a => a.method === 'best-responsible-dashboard') && (
            <div className="card mb-4 shadow-sm">
              <div className="card-header">
                <h4 className="my-0" style={{ fontWeight: 400, fontSize: '0.9rem' }}>ID por responsable</h4>
                <select className="mt-2 mr-1" name="month" id="lawyer"
                  onChange={(e, val) => this.handleChange(e, 'responsible', '')}>
                  <option selected={month === '01' ? true : false} value="01">Enero</option>
                  <option selected={month === '02' ? true : false} value="02">Febrero</option>
                  <option selected={month === '03' ? true : false} value="03">Marzo</option>
                  <option selected={month === '04' ? true : false} value="04">Abril</option>
                  <option selected={month === '05' ? true : false} value="05">Mayo</option>
                  <option selected={month === '06' ? true : false} value="06">Junio</option>
                  <option selected={month === '07' ? true : false} value="07">Julio</option>
                  <option selected={month === '08' ? true : false} value="08">Agosto</option>
                  <option selected={month === '09' ? true : false} value="09">Septiembre</option>
                  <option selected={month === '10' ? true : false} value="10">Octubre</option>
                  <option selected={month === '11' ? true : false} value="11">Noviembre</option>
                  <option selected={month === '12' ? true : false} value="12">Diciembre</option>
                </select>
                <select name="year" id="year" onChange={(e, val) => this.handleChange(e, 'responsible', '')}>
                  <option selected={year === '2019' ? true : false} value="2019">2019</option>
                  <option selected={year === '2020' ? true : false} value="2020">2020</option>
                  <option selected={year === '2021' ? true : false} value="2021">2021</option>
                  <option selected={year === '2022' ? true : false} value="2022">2022</option>
                  <option selected={year === '2023' ? true : false} value="2023">2023</option>
                  <option selected={year === '2024' ? true : false} value="2024">2024</option>
                  <option selected={year === '2025' ? true : false} value="2025">2025</option>
                </select>
              </div>
              <div style={{ width: '100%', height: 300 }}>
                {/* <ResponsiveContainer>
                                    <BarChart
                                        data={metricsLawyerResp}>
                                        <XAxis
                                            dataKey="name"
                                            fontFamily="sans-serif"
                                            tickSize={0}
                                            dy={10}
                                            type="category" tick={{ fontSize: 10, width: 100 }}
                                        />
                                        <YAxis hide />
                                        <CartesianGrid
                                            vertical={false}
                                            stroke="#ebf3f0"
                                        />
                                        <Bar
                                            dataKey="countResp"
                                            fontFamily="sans-serif"
                                            barSize={50}
                                            label={this.customizedLabel}
                                        >
                                            {
                                                metricsLawyerResp.map((entry, index) => (
                                                    <Cell key={index} fill={index < 3 ? '#61bf93' : '#ededed'} />
                                                ))
                                            }
                                        </Bar>
                                    </BarChart>
                                </ResponsiveContainer> */}

                <ResponsiveContainer>
                  <BarChart
                    layout="vertical"
                    data={metricsLawyerResp}
                    margin={{ left: 50, right: 50 }}
                  >
                    <CartesianGrid
                      vertical={false}
                      stroke="#ebf3f0"
                    />
                    <XAxis type="number" />
                    <YAxis dataKey="name" type="category" tick={{ fontSize: 10, width: 150 }} />
                    <Tooltip />
                    {/* <Legend /> */}
                    <Bar dataKey="countResp" barSize={20} fill="#86cfda" label={this.customizedLabel}>
                      {
                        metricsLawyerResp.map((entry, index) => (
                          <Cell key={index} fill={index < 3 ? '#61bf93' : '#ededed'} />
                        ))
                      }
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default Dashboard
