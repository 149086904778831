import http from "./httpService";

const apiEndpoint = process.env.REACT_APP_API_URL + "/mediations";

export function mediationUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getMediations() {
  return http.get(apiEndpoint);
}

export function getMediation(mediationId) {
  return http.get(mediationUrl(mediationId));
}

export function saveMediation(mediation) {
  if (mediation._id) {
    const body = { ...mediation };
    delete body._id;
    return http.put(mediationUrl(mediation._id), body);
  }

  return http.post(apiEndpoint, mediation);
}

export function deleteMediation(mediationId) {
  return http.delete(mediationUrl(mediationId));
}

export function mediationArchivedUpdate(mediationId, archived) {
  const body = {
    archived: archived
  }
  return http.put(mediationUrl(mediationId)+'/archived', body)
}

