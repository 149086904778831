import React, { Component } from "react";
import { getCurrentUser } from "../services/authService";
import Table from "./common/table";

class UsersTable extends Component {
  columns = [
    { 
        field: "name", 
        label: "Nombre del usuario",
        width: 300
    },   
    { field: "email", label: "Email", width: 300 },
    { field: "role", label: "Rol", width: 200 },
    { field: "goal", label: "Meta", width: 100 },
    { field: "comRegular", label: "Comisión Regular", width: 120 },
    { field: "comValuation", label: "Comisión Avaluos", width: 120}
  ];

  deleteColumn = {
    key: "delete",
    field: "delete",
    label: "Acciones",
    width: 120,
  };



  async componentDidMount() { 
    const { data: user } = await getCurrentUser();    
    if(user && user.role && user.role.actions.some(a => a.method === "delete-user")) this.columns.push(this.deleteColumn);    
    
    const columns = this.columns;
    this.setState({ columns }); 
  }

  render() {
    const { users, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        data={users}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default UsersTable;
