import React from 'react'
import Joi from 'joi-browser'
import Form from '../common/form'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import SortableTree, { changeNodeAtPath, insertNode, removeNodeAtPath } from 'react-sortable-tree'
import 'react-sortable-tree/style.css' // This only needs to be imported once in your app
import { getCff, saveCff } from '../../services/cffService'
import { getInstruments } from '../../services/instrumentService'
import Autocomplete from 'react-autocomplete'
import { getUsers } from '../../services/userService'
import { getFormsByCff } from '../../services/formService'

class CffForm extends Form {
    state = {
        show: false,
        currentNode: {},
        tree: {
            name: "",
            fiscalRegime: ""
        },
        data: {
            nameCff: "",
            responsible: "",
            structure: [],
            status: "",
            type: "",
        },
        instruments: [],
        users: [],
        errors: {},
        node: {},
        fiscalRegimes: [{ _id: 1, name: "Persona Física" }, { _id: 2, name: "Persona Moral" }],
        path: {},
        forms: [],
        isComplete: false,
        formsToBeDeleted: []
    }

    schema = {
        _id: Joi.string(),
        fiscalRegime: Joi.string()
            .required()
            .label("Nombre")
    };

    showModal = (path) => {
        // const tree = { name: "", fiscalRegime: 0 }
        this.setState({ show: true });
    };

    hideModal = () => {
        this.setState({ show: false });
    };

    async populateForm() {
        try {
            const cffId = this.props.match.params.id;

            if (cffId === "new") {
                return;
            }

            const { data: cff } = await getCff(cffId);
            const { data: instruments } = await getInstruments();

            this.setState({ data: this.mapToViewModel(cff) });
            this.setState({ instruments: instruments });
        } catch (ex) {
            if (ex.response && ex.response.status === 404)
                this.props.history.replace("/not-found");
        }
    }

    async populateUsers() {
        const { data: users } = await getUsers();
        this.setState({ users });
    }

    async populateForms() {
        const cffId = this.props.match.params.id;

        if (cffId === "new") {
            return;
        }
        const { data: forms } = await getFormsByCff(cffId);
        // set isComplete to true if all forms are complete
        const isComplete = forms.every(form => form.status === "complete");
        this.setState({ forms, isComplete });
    }

    async componentDidMount() {
        await this.populateForm();
        await this.populateUsers();
        await this.populateForms();
    }

    mapToViewModel(cff) {
        return {
            _id: cff._id,
            nameCff: cff.nameCff,
            responsible: cff.responsible?._id || "",
            structure: cff.structure,
            documentType: cff.documentType,
            status: cff.status,
            type: cff.type
        }
    }

    handleChageModal = ({ currentTarget: input }) => {
        const tree = { ...this.state.tree };
        tree[input.name] = input.value;
        tree.type = this.state.data.type;

        this.setState({ tree });
    };

    handleChangeInstrument = ({ currentTarget: input }) => {
        const { data } = this.state;
        data.instrument = input.value
        this.setState({ data });
    };

    handleChangeTree = (structure) => {
        const { data } = this.state;
        data.structure = structure
        this.setState({ data });
    }

    removeNode = (path, formId) => {
        let { data, formsToBeDeleted } = this.state;
        if (formId) {
            formsToBeDeleted.push(formId);
            data.structure.forEach(node => {
                const formsId = this.getChildrenFormsId(node, formId);
                formsToBeDeleted.push(...formsId)
            })
        }

        data.structure = removeNodeAtPath({
            treeData: data.structure,
            path,
            getNodeKey: ({ treeIndex }) => treeIndex,
        });

        this.setState({ data, formsToBeDeleted });
    }

    // get children forms id from data.structure
    getChildrenFormsId = (node, parentFormId, found = false) => {
        let formsId = [];
        if (!found) {
            found = node.formId === parentFormId;
        }
        if (node.children.length > 0) {
            node.children.forEach(child => {
                if (child.formId && found) {
                    formsId.push(child.formId);
                }
                formsId = formsId.concat(this.getChildrenFormsId(child, parentFormId, found));
            })
        }
        return formsId;
    }


    selectThis = (node, path) => {
        this.setState({ currentNode: node, path: path });
    }

    insertNewNode = () => {
        let { data, tree } = this.state;
        data.structure = insertNode({
            treeData: data.structure,
            depth: 0,
            minimumTreeIndex: data.structure.length,
            newNode: {
                title: tree.name,
                name: tree.name,
                fiscalRegime: tree.fiscalRegime,
                edit: true,
                children: []
            },
            getNodeKey: ({ treeIndex }) => treeIndex
        }).treeData

        this.setState({ data });
    }

    handleAdd = async (e) => {
        e.preventDefault();
        this.insertNewNode()
        this.hideModal();
    };

    handleSubmit = async (e, status) => {
        e.preventDefault();
        try {
            const { data, formsToBeDeleted } = this.state;
            data.status = status;
            const { data: cff } = await saveCff({ data, formsToBeDeleted });
            if (this.props.match.params.id === 'new') {
                cff.responsible = cff.responsible._id;
                this.setState({ data: cff })
                this.props.history.push(`/cff/${cff._id}`)
            } else {
                this.props.history.push(`/cff/list/${status}`);
            }
            toast.success("El instrumento se guardo correctamente.");
        } catch (ex) {
            toast.warning("Aún queda información por ingresar, favor de ingresarla antes de Enviar.");
        }
    };

    render() {
        const getNodeKey = ({ treeIndex }) => treeIndex;
        let { data, instruments, users, isComplete } = this.state;
        return (
            <div>
                <Modal show={this.state.show} onHide={this.hideModal}>
                    <form>
                        <Modal.Header closeButton>
                            <Modal.Title>Envía Formulario</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="form-row col-md-12">
                                <div className="col-md-12 form-label-group mb-3">
                                    <input placeholder="Nombre o Razon Social" className="form-control" name="name" onChange={this.handleChageModal} />
                                    <label>Nombre o Razón Social</label>
                                </div>
                                <div className="col-md-12 form-label-group mb-3">
                                    <select name="fiscalRegime" id="fiscalRegime" className="form-control" onChange={this.handleChageModal} >
                                        <option value="">Selecciona...</option>
                                        {this.state.fiscalRegimes.map(fiscalRegime => (
                                            <option key={fiscalRegime.name} value={fiscalRegime._id}>
                                                {fiscalRegime.name}
                                            </option>
                                        ))}
                                    </select>
                                    <label>Régimen Fiscal</label>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-primary" onClick={this.handleAdd}>Guardar</button>
                        </Modal.Footer>
                    </form>
                </Modal>
                <div className="form-row mt-4">
                    <div className="col-md-4 mb-3">
                        {this.renderInput("nameCff", "Nombre de Proyecto")}
                    </div>
                    <div className="col-md-4 mb-3">
                        {this.renderSelect("responsible", "Responsable", users)}
                    </div>
                    <div className="col-md-4 mb-3">
                        {this.renderSelect('type', 'Tipo de Documento', [
                            { _id: 'Constitutiva', name: 'Constitutiva' },
                            { _id: 'Protocolización', name: 'Protocolización' },
                            { _id: 'Otro', name: 'Otro' },
                        ], this.handleChange)}
                    </div>
                    {(data.status === 'review' || data.status === 'complete') && (
                        <div className="col-md-4 mb-3">
                            <Autocomplete
                                inputProps={{ id: 'instrument', name: 'instrument', className: 'form-control', placeholder: 'Instrumentos' }}
                                wrapperStyle={{ position: '', background: '#b8daff' }}
                                value={this.state.data.instrument?.name}
                                items={instruments}
                                getItemValue={(item) => item.name}
                                selectOnBlur={true}
                                onSelect={(value, item) => {
                                    data.instrument = {
                                        _id: item._id,
                                        name: item.name
                                    };
                                    this.setState({ data })
                                }}
                                onChange={(event, value) => {
                                    data.instrument = value;
                                    this.setState({ data })
                                }}
                                renderMenu={children => (
                                    <div className="menu">
                                        {children}
                                    </div>
                                )}
                                renderItem={(item, isHighlighted) => (
                                    <div className={`item ${isHighlighted ? 'item-highlighted' : ''}`} key={item._id}>{item.name}</div>
                                )}
                            />
                        </div>
                    )}
                </div>
                {data._id &&
                    (<div className="form-row mt-4">
                        <div className="col-md-4 mb-3">
                            <div className="form-label-group">
                                <input
                                    type="text"
                                    name="enlace"
                                    value={`https://cp8df.com/public/cff/${data._id}`}
                                    readOnly={true}
                                    className="form-control"
                                    onFocus={(e) => e.target.select()}
                                />
                                <label htmlFor={'enlace'}>Enlace</label>
                            </div>
                        </div>
                    </div>)
                }
                <div className="form-row">
                    <div className="col-md-6">
                        {(data.status !== 'review' && data.status !== 'complete') &&
                            (<button className='btn btn-primary btn-sm mb-3' onClick={(e) => {
                                e.preventDefault();
                                this.showModal();
                            }}>
                                <i className="fa fa-plus" /> Agregar Persona
                            </button>)}
                        <h5>Estructura</h5>
                    </div>
                </div>
                <div style={{ height: '100%' }}>
                    {
                        this.state.data && this.state.data.structure && (<SortableTree
                            treeData={this.state.data.structure}
                            onChange={treeData => this.handleChangeTree(treeData)}
                            isVirtualized={false}
                            canDrag={false}
                            generateNodeProps={({ node, path }) => ({
                                title: (
                                    <form onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.selectThis(node, path); }}>
                                        {node.edit ? (<input
                                            style={{ fontSize: "1rem" }}
                                            value={node.name}
                                            onChange={event => {
                                                const title = event.target.value;
                                                data.structure = changeNodeAtPath({
                                                    treeData: data.structure,
                                                    path,
                                                    getNodeKey,
                                                    newNode: { ...node, title, name: title }
                                                })
                                                this.setState({ data });
                                            }}
                                        />) : node.name}&nbsp;&nbsp;&nbsp;
                                        {node.formId &&
                                            (<button className='btn btn-info btn-sm mr-2' onClick={() => { this.props.history.replace({ pathname: `/form/${node.formId}`, state: { origen: '/cff', type: data.type }}); }}><i className="fa fa-book" /></button>)}
                                        {node.edit && (data.status !== 'review' && data.status !== 'complete') && (<button className='btn btn-danger btn-sm' onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.removeNode(path, node.formId) }} ><i className="fa fa-trash" /></button>)}
                                    </form>
                                )
                            })}
                        />)
                    }
                </div>
                {data.status !== 'pending' && data.status !== 'complete' && data.status !== "" && data.instrument?.name && <button className="btn btn-success float-right mr-4" onClick={(e) => this.handleSubmit(e, 'complete')}>Terminado</button>}
                {data.status === 'review' && <button className="btn btn-warning float-right mr-4" onClick={(e) => this.handleSubmit(e, 'pending')}>Enviar a Proceso</button>}
                {isComplete && <button className="btn btn-info float-right mr-4" onClick={(e) => this.handleSubmit(e, 'review')}>Enviar a Revisión</button>}
                {data.status !== 'review' && data.status !== 'complete' && <button className="btn btn-primary float-right mr-4" onClick={(e) => this.handleSubmit(e, 'pending')}>Guardar</button>}
            </div >
        );
    }
}

export default CffForm;
