import React from 'react'
import { Link } from 'react-router-dom'

import { getCurrentUser } from '../../services/authService'
import { deleteObjective, getObjectives } from '../../services/objectiveService'
import ObjectivesTable from './objectivesTable'

export default class Objectives extends React.Component {

  state = {
    objectives: [],
    isLoading: true,
  }

  async componentDidMount () {
    await this.populateObjectives()
  }

  async populateObjectives () {
    const { data: objectives } = await getObjectives()
    if (objectives) this.setState({ objectives, isLoading: false })
  }

  handleDelete = async objective => {
    const originalObjectives = this.state.objectives
    const objectives = originalObjectives.filter(m => m !== objective)
    this.setState({ objectives })

    try {
      await deleteObjective(objective._id)
    } catch (ex) {
      this.setState({ boxes: originalObjectives })
    }
  }

  render () {
    const { objectives, isLoading } = this.state
    const { user } = this.props
    return (
      <div id="objectives-grid">
        <h3>Objetivos</h3>
        <div className="form-row mt-3 mb-3">
          <div className="col-lg-7">
            <div className="float-left">
              {user?.role?.actions?.some(a => a.method === 'add-objective') && (
                <React.Fragment>
                  <Link to="/objectives/new" className="btn btn-primary float-right">
                    <i className="fa fa-plus" aria-hidden="true"/><span> </span>
                    Agregar Objetivo
                  </Link>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
        <ObjectivesTable
          user={user}
          objectives={objectives}
          onDelete={this.handleDelete}
          isLoading={isLoading}
        />
      </div>
    )
  }
}
