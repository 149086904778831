import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { saveCalendarEvent } from "../../services/calendarService";

export default class CalendarModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: props.dateInfo.type ? props.dateInfo.type : "",
      userId: props.dateInfo.user ? props.dateInfo.user._id : "",
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    // Actualiza el estado con el nuevo valor
    this.setState({ [name]: value });
  };

  handleSubmit = async () => {
    const { user, dateInfo } = this.props;
    const userModal = this.props.users.find((user) => user._id === this.state.userId);
    
    // Asegúrate de asignar un ID de usuario válido como una cadena
    const userId = userModal ? userModal._id.toString() : user._id.toString();
    
    const event = {
      user: userId,
      type: this.state.type,
      startDate: dateInfo.startStr,
      endDate: dateInfo.endStr,
      allDay: dateInfo.allDay,
    };

    if (dateInfo._id) {
      event._id = dateInfo._id;
    }
    
    await saveCalendarEvent(event);
    this.props.handleClose();
    this.setState({ type: "", userId: user._id });
  };

  handleChangeUser = (e) => {
    this.setState({ userId: e.target.value });
  };

  // funcion para el componente montado
  componentDidMount() {
    const { dateInfo } = this.props;
    if (dateInfo.user) {
      this.setState({
        userId: dateInfo.user,
        type: dateInfo.type,
      });
    } else {
      this.setState({
        userId: this.props.user._id,
        type: "",
      });
    }
  }

  // funcion para el componente actualizado

  componentDidUpdate(prevProps) {
    if (prevProps.dateInfo !== this.props.dateInfo) {
      const { dateInfo } = this.props;
      if (dateInfo.user) {
        this.setState({
          userId: dateInfo.user,
          type: dateInfo.type,
        });
      } else {
        this.setState({
          userId: this.props.user._id,
          type: "",
        });
      }
    }
  }

  render() {
    const { showModal, dateInfo, user } = this.props;
    const { type } = this.state;

    return (
      <>
        <Modal show={showModal} onHide={this.props.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Ingresar Día</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {user && user.role && user.role.name === "Administrador" ? (
              <>
                <h5 className="mb-3">Usuario:</h5>
                <select
                  name="user"
                  id="user"
                  value={this.state.userId}
                  className="form-control mb-3"
                  onChange={(e) => this.handleChangeUser(e)}
                >
                  <option disabled={true} value="">
                    Selecciona...
                  </option>
                  {this.props.users &&
                    this.props.users.map((user) => (
                      <option key={user._id} value={user._id}>
                        {user.name}
                      </option>
                    ))}
                </select>
              </>
            ) : (
              <h5 className="mb-3">Usuario: {user.name}</h5>
            )}
            <h5 className="mb-3">Fecha: {dateInfo.startStr}</h5>
            <form>
              <div className="form-label-group">
                <select
                  name="type"
                  id="type"
                  value={type}
                  className="form-control"
                  onChange={this.handleChange}
                >
                  <option disabled={true} value="">
                    Selecciona...
                  </option>
                  <option key="1" value="Home Office">
                    Home Office
                  </option>
                  <option key="2" value="En Oficina">
                    En Oficina
                  </option>
                  <option key="3" value="Vacaciones">
                    Vacaciones
                  </option>
                  <option key="4" value="Guardia">
                    Guardia
                  </option>
                  <option key="5" value="Fuera del pais">
                  Fuera del pais
                  </option>
                  <option key="6" value="Diligencia">
                  Diligencia
                  </option>
                </select>
                <label htmlFor="type">Tipo</label>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              className="mr-auto"
              onClick={this.props.handleClose}
            >
              Cerrar
            </Button>
            {dateInfo._id && (
              <Button
                variant="danger"
                onClick={() => this.props.handleDelete(dateInfo._id)}
              >
                Eliminar
              </Button>
            )}
            <Button variant="primary" onClick={this.handleSubmit}>
              Guardar
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
