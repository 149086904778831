import React from 'react'
import { Link } from 'react-router-dom'

import Table from '../common/materia-reac-table/table'

const ObjectivesTable = ({ user, objectives, onDelete, isLoading }) => {

  const columns = [
    {
      header: 'Concepto',
      accessorKey: 'name',
      Cell: ({ row }) => <Link
        to={(user?.role?.actions?.some(a => a.method === 'update-objective')) ? `/objectives/${row.original._id}` : `#`}
      >
        {String(row.original.name)}
      </Link>,
      size: 70,
    },
    {
      header: 'Objetivo',
      accessorKey: 'target',
      size: 110,
    },
    {
      header: 'Actual',
      accessorKey: 'current',
      size: 150,
    }
  ]

  const deleteRowAction = ({ row }) => <button
    onClick={(e) => window.confirm('Esta seguro que desea eliminar el objetivo?') && onDelete(row.original)}
    className="btn btn-danger btn-sm">
    Eliminar
  </button>

  return (
    (user && columns ?
        <Table
          columns={columns}
          data={objectives}
          user={user}
          rowAction={(user?.role?.actions?.some(a => a.method === 'delete-objective')) ? deleteRowAction : null}
          isLoading={isLoading}
        /> : <></>
    ))
}

export default ObjectivesTable
