import React from "react";
import Joi from "joi-browser";
import Form from "./common/form";
import { getConfiguration, saveConfiguration } from "../services/configurationService";

class ConfigurationForm extends Form {
  state = {
    data: {
      name: "",
      value: "",
    },
    errors: {}
  };

  schema = {
    _id: Joi.string(),
    name: Joi.string()
      .label("Nombre"),
    value: Joi.string()
      .label("Valor")
      .required()
  };

  async populateConfiguration() {
    try {
      const configurationId = this.props.match.params.id;
      if (configurationId === "new") return;

      const { data: configuration } = await getConfiguration(configurationId);
      this.setState({ data: this.mapToViewModel(configuration) });
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  }

  async componentDidMount() {
    await this.populateConfiguration();
  }

  mapToViewModel(configuration) {
    return {
      _id: configuration._id,
      number: configuration.number,
      name: configuration.name,
      value: configuration.value,
    };
  }

  doSubmit = async () => {
    await saveConfiguration(this.state.data);

    this.props.history.push("/configurations");
  };

  render() {
    return (
      <div>
        <form className="mt-4" onSubmit={this.handleSubmit}>
          <div className="form-row">
            <div className="col-md-6 mb-3">
              {this.renderInput("name", "Configuracion", "", true)}
            </div>
            <div className="col-md-6 mb-3">
              {this.renderInput("value", "Valor")}
            </div>
          </div>
          <button className="btn btn-primary float-right" type="submit">Guardar</button>
        </form>
      </div>
    );
  }
}

export default ConfigurationForm;
