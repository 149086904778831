import http from "./httpService";

const apiEndpoint = process.env.REACT_APP_API_URL + "/originators";

export function originatorUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getOriginators() {
  return http.get(apiEndpoint);
}

export function getOriginator(originatorId) {
  return http.get(originatorUrl(originatorId));
}

export function saveOriginator(originator) {
  if (originator._id) {
    const body = { ...originator };
    delete body._id;
    return http.put(originatorUrl(originator._id), body);
  }

  return http.post(apiEndpoint, originator);
}

export function deleteOriginator(originatorId) {
  return http.delete(originatorUrl(originatorId));
}
