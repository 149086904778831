import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Table from '../common/table'
import Check from '../common/check'
import moment from 'moment'
import { getCurrentUser } from '../../services/authService'
import { getInstruments, saveInstrument } from '../../services/instrumentService'

class DeclaranotTable extends Component {
  state = {
    declaranot: []
  }

  columns = [
    {
      field: 'number',
      label: 'No. Instrumento',
      width: 170
    },
    { field: 'date', label: 'Fecha instrumento', width: 180 },
    { field: 'type', label: 'Tipo de aviso', width: 150 },
    { field: 'noticeDate', label: 'Fecha de aviso', width: 180 },
    { field: 'reference', label: 'No. de referencia', width: 250 },
    { field: 'archived', label: 'Archivado', width: 120 }
  ]

  deleteColumn = {
    key: 'delete',
    field: 'delete',
    label: 'Acciones',
    width: 130
  }

  async populateUser () {
    const { data: user } = await getCurrentUser()
    if (user && user.role && user.role.actions.some(a => a.method === 'delete-declaranot')) this.columns.push(this.deleteColumn)

    const columns = this.columns
    this.setState({ columns })
  }

  async componentDidMount () {
    await this.populateUser()
    const { data: instruments } = await getInstruments({ type: 'declaranot' })
    let declaranotArray = []
    instruments.map(i => {
      let declaranot = {}
      declaranot.number = <Link to={`/instruments/${i._id}`} searchvalue={i.number}>{String(i.number)}</Link>
      declaranot.date = moment(i.date).format('DD/MM/YYYY')
      declaranot.type = i.declaranot && i.declaranot.type ? i.declaranot.type : ''
      declaranot.noticeDate = i.declaranot && i.declaranot.noticeDate ? moment(i.declaranot.noticeDate).format('DD/MM/YYYY') : ''
      declaranot.reference = i.declaranot && i.declaranot.reference ? i.declaranot.reference : ''
      declaranot.status = i.declaranot && i.declaranot.status ? i.declaranot.status : ''
      declaranot.active = i.declaranot && i.declaranot.active ? i.declaranot.active : ''
      declaranot.archived = <Check archived={i.declaranot && i.declaranot.archived ? i.declaranot.archived : false}
                                   onClick={() => this.handleArchive(i)}></Check>
      declaranot.delete = <button
        onClick={(e) => window.confirm('Esta seguro que desea eliminar el declaranot?') && this.handleDelete(declaranot, i)}
        className="btn btn-danger btn-sm">Eliminar</button>
      declaranotArray.push(declaranot)
      return i
    })
    this.setState({ declaranot: declaranotArray })
  }

  handleDelete = async (obj, instrument) => {
    const originalPld = this.state.declaranot
    const updatePld = originalPld.filter(p => p !== obj)
    this.setState({ declaranot: updatePld })

    try {
      instrument.declaranot = {}
      delete instrument.responsible
      delete instrument.name
      await saveInstrument(instrument)
    } catch (ex) {
      // if (ex.response && ex.response.status === 404)
      // toast.error("This client has already been deleted.");

      this.setState({ declaranot: originalPld })
    }
  }

  getPagedData = () => {
    const {
      declaranot: allPld,
    } = this.state

    let filtered = allPld
    filtered = allPld.filter(m =>
      m.active === true &&
      ((this.props.match.params.status === 'pending' && (m.status === this.props.match.params.status || m.status === 'delayed')) ||
        (m.status === this.props.match.params.status))
    )

    return { data: filtered }
  }

  render () {
    //const { declaranot } = this.state;

    const declaranot = this.state.declaranot

    return (
      <Table
        columns={this.columns}
        data={declaranot}
      />
    )
  }
}

export default DeclaranotTable
