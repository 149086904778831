import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import ClientsTable from './clientsTable'
import { deleteClient, getClients } from '../../services/clientService'

class Clients extends Component {
  state = {
    clients: [],
    // currentPage: 1,
    // pageSize: 10000,
    // sortColumn: { path: "name", order: "asc" }
  }

  async populateClients () {
    const { data: clients } = await getClients()
    const { user } = this.props
    const clientsUpdate = clients.map((c, index) => {
      c.name = <Link to={`/clients/${c._id}`} searchvalue={c.name}>{c.name}</Link>
      c.offices = c.offices.map((office) => office.name).join(', ')
      c.originator = c.originator ? c.originator.name : ''
      c.delete = <button onClick={(e) => window.confirm('Esta seguro que desea eliminar el cliente?') && this.handleDelete(c)} className="btn btn-danger btn-sm">Eliminar</button>
      return c
    })

    this.setState({ clients: clientsUpdate })
  }

  async componentDidMount () {
    await this.populateClients()
  }

  handleDelete = async client => {
    const originalClients = this.state.clients
    const clients = originalClients.filter(m => m !== client)
    this.setState({ clients })

    try {
      await deleteClient(client._id)
    } catch (ex) {
      // if (ex.response && ex.response.status === 404)
      // toast.error("This client has already been deleted.");

      this.setState({ clients: originalClients })
    }
  }

  //  handlePageChange = page => {
  //     this.setState({ currentPage: page });
  //   };

  //   handleSearch = query => {
  //     this.setState({ searchQuery: query, currentPage: 1 });
  //  };

  //  getPagedData = () => {
  //     const {
  //       pageSize,
  //       currentPage,
  //       sortColumn,
  //       searchQuery,
  //       clients: allClients,
  //     } = this.state;

  //     let filtered = allClients;
  //     if (searchQuery)
  //         filtered = allClients.filter(m =>
  //             m.name.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1
  //         );

  //     const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

  //     const clients = paginate(sorted, currentPage, pageSize);

  //     return { totalCount: filtered.length, data: clients };
  //   };

  //   handleSort = sortColumn => {
  //     this.setState({ sortColumn });
  //   };

  render () {
    const { user } = this.props
    const { clients } = this.state
    return (
      <div>
        <div className="form-row mt-3 mb-3">
          <div className="col-lg-10">
            <div className="float-left">
              {user && user.role && user.role.actions.some(a => a.method === 'add-client') && (
                <Link
                  to="/clients/new"
                  className="btn btn-primary float-right"
                >
                  <i className="fa fa-plus" aria-hidden="true"/><span> </span>
                  Nuevo Cliente
                </Link>
              )}
            </div>
          </div>
          {/* <div className="col-lg-2">
                        <SearchBox value={searchQuery} onChange={this.handleSearch} />
                    </div> */}
        </div>
        <ClientsTable
          clients={clients}
          user={user}
          // sortColumn={sortColumn}
          onDelete={this.handleDelete}
          // onSort={this.handleSort}
        />
        {/* <Pagination
                    itemsCount={totalCount}
                    pageSize={pageSize}
                    currentPage={currentPage}
                    onPageChange={this.handlePageChange}
                />                 */}
      </div>
    )
  }
}

export default Clients
