import http from './httpService'

const apiEndpoint = process.env.REACT_APP_API_URL + '/accounts-payable'

export function getAccountsPayable (filter) {
  return http.get(apiEndpoint, { params: filter })
}

export function getAccountPayable(id) {
  return http.get(`${apiEndpoint}/${id}`)
}

export function saveAccountPayable(accountPayable) {
  if (accountPayable._id) {
    const body = { ...accountPayable }
    delete body._id
    return http.put(`${apiEndpoint}/${accountPayable._id}`, body)
  }

  return http.post(apiEndpoint, accountPayable)
}

export function deleteAccountPayable(id) {
  return http.delete(`${apiEndpoint}/${id}`)
}
