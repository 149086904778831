import React from "react";
import Joi from "joi-browser";
import { ObjectID } from 'bson';
import Form from "./common/form";
import { getCurrentUser } from "../services/authService";
import { getValuation, saveValuation } from "../services/valuationService";
import { SingleDatePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment  from 'moment';

class ValuationForm extends Form {
  state = {
    data: {
      number: "",
      date: moment(),
      type: "",
      description: "",
      applicant: ""
    },
    types: [
      { _id: new ObjectID(), name: "Mobiliario y Equipo" },
      { _id: new ObjectID(), name: "Inmueble" },
      { _id: new ObjectID(), name: "Derechos" },
      { _id: new ObjectID(), name: "Acciones" },
      { _id: new ObjectID(), name: "Negocio en Marcha" },
      { _id: new ObjectID(), name: "Otros" }
    ],
    errors: {},
    focused: false
  };

  schema = {
    _id: Joi.string(),
    number: Joi.number()
      .required()
      .min(0)
      .label("Numero de Avaluo"),
    date: Joi.string()
      .required()
      .label("Fecha"),
    type: Joi.string()
      .required()
      .label("Tipo de bien"),
    description: Joi.string()
      .required()
      .label("Descripción"),
    applicant: Joi.string()
      .required()
      .label("Solicitante")
  };

  async populateValuation() {
    try {
      const valuationId = this.props.match.params.id;
      if (valuationId === "new") return;

      const { data: valuation } = await getValuation(valuationId);
      this.setState({ data: this.mapToViewModel(valuation) });
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  }

  async componentDidMount() {
    await this.populateValuation();

    const {data: user } = await getCurrentUser();
    if(user) this.setState({ user });
  }

  mapToViewModel(valuation) {
    return {
      _id: valuation._id,
      number: valuation.number,
      date: moment(valuation.date),
      type: valuation.type,
      description: valuation.description,
      applicant: valuation.applicant,
      archived: valuation.archived
    };
  }

  doSubmit = async () => {
    await saveValuation(this.state.data);

    this.props.history.push("/valuations");
  };

  handleDateChange = date => {
    const { data } = this.state;
    data.date = date;
    this.setState({ data })
  }  

  render() {
    const { data, user } = this.state;
    return (
      <div>
        <form className="mt-4" onSubmit={this.handleSubmit}>
          <div className="form-row">
            <div className="col-md-4 mb-3">
              {this.renderInput("number", "Numero de Avaluo", "number")}  
            </div>
            <div className="col-md-4 mb-3">
                <SingleDatePicker
                    date={data.date} // momentPropTypes.momentObj or null
                    onDateChange={date => this.handleDateChange(date)} // PropTypes.func.isRequired
                    focused={this.state.focused} // PropTypes.bool
                    onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
                    displayFormat="DD/MM/YYYY"
                    numberOfMonths={1}
                    id="dateValuation"
                    isOutsideRange={() => false} 
                    placeholder={"Fecha"}
                />
            </div>
            <div className="col-md-4 form-label-group  mb-3">              
              <select name="type" id="type" className="form-control" onChange={this.handleChange}>
                  <option value="">Selecciona...</option>        
                  { this.state.types.map(option => (
                      <option key={ option._id } selected={(option.name === data.type) ? true : false }  value={option.name}>
                          {option.name}
                      </option>
                  ))}
              </select>
              <label>Tipo de bien</label>
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-4 mb-3">
              {this.renderInput("description", "Descripcion")}
            </div>
            <div className="col-md-4 mb-3">
              {this.renderInput("applicant", "Solicitante")}
            </div>
          </div>
          {
            user && user.role && user.role.actions.some(a => a.method === "save-valuation") && (
              <button className="btn btn-primary float-right" type="submit">Guardar</button>
            )
          }          
        </form>
      </div>
    );
  }
}

export default ValuationForm;
