import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Check from './common/check'
import moment from 'moment/moment'
import Table from './common/materia-reac-table/table'

class ValuationsTable extends Component {
  columns = [
    {
      accessorKey: 'number',
      header: 'No. Avalúo',
      size: 120,
      Cell: ({ row }) => <Link
        to={this.props.user && this.props.user.role && this.props.user.role.actions.some(a => a.method === 'edit-valuation') ? `/valuations/${row.original._id}` : '#'}>
        {row.original.number}
      </Link>,
    },
    {
      accessorKey: 'date',
      header: 'Fecha',
      size: 130,
      Cell: ({ cell }) => cell.getValue() ? moment(cell.getValue()).format('DD/MM/YYYY') : '-',
    },
    {
      accessorKey: 'type',
      header: 'Tipo de bien',
      size: 180
    },
    {
      accessorKey: 'description',
      header: 'Descripción',
      size: 280
    },
    {
      accessorKey: 'applicant',
      header: 'Solicitante'
    },
    {
      accessorKey: 'archived',
      Cell: ({ row }) => <Check archived={row.original.archived}
                                onClick={() => this.props.handleArchive(row.original)}></Check>,
      header: 'Archivado',
      size: 120,
    },
  ]

  deleteRowAction = ({ row, table }) => <button
    onClick={(e) => window.confirm('Esta seguro que desea eliminar el avalúo?') && this.props.handleDelete(row.original)}
    className="btn btn-danger btn-sm">
    Eliminar
  </button>

  render () {
    const { valuations, user } = this.props

    return (this.columns && user ?
        <Table
          columns={this.columns}
          data={valuations}
          filter={this.filter}
          user={user}
          initialState={{
            columnVisibility: {
              archived: (user && user.role && user.role.actions.some(a => a.method === 'archive-valuation')),
            }
          }}
          rowAction={
            !!this.props.handleDelete &&
            (user && user.role && user.role.actions.some(a => a.method === 'delete-valuation')) ?
              this.deleteRowAction : null
          }
          isLoading={this.props.isLoading}
        /> : <></>
    )
  }
}

export default ValuationsTable
