import http from "./httpService";

const apiEndpoint = process.env.REACT_APP_API_URL + "/configurations";

export function configurationUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getConfigurations() {
  return http.get(apiEndpoint);
}

export function getConfiguration(configurationId) {
  return http.get(configurationUrl(configurationId));
}

export function saveConfiguration(configuration) {
  if (configuration._id) {
    const body = { ...configuration };
    delete body._id;
    return http.put(configurationUrl(configuration._id), body);
  }

  return http.post(apiEndpoint, configuration);
}

export function deleteConfiguration(configurationId) {
  return http.delete(configurationUrl(configurationId));
}
