import React, { Component } from 'react';
import { Link } from "react-router-dom";
import StatusTable from "./statusTable";
import { getAllStatus } from "../services/statusService";
import { paginate } from "../utils/paginate";
import _ from "lodash";

class Status extends Component {
    state = { 
        status: [],
        currentPage: 1,
        pageSize: 20,
        sortColumn: { path: "name", order: "asc" }
     }

     async componentDidMount() {            
        const { data: status } = await getAllStatus();   
        const statusUpdate = status.map(s => {
            s.name = <Link to={`/status/${s._id}`}>{s.name}</Link>     
            return s;
        });

        this.setState({ status: statusUpdate });
    }

     handlePageChange = page => {
        this.setState({ currentPage: page });
      };

      handleSearch = query => {
        this.setState({ searchQuery: query, currentPage: 1 });
     };

     getPagedData = () => {
        const {
          pageSize,
          currentPage,
          sortColumn,
          searchQuery,
          status: allStatus
        } = this.state;
    
        let filtered = allStatus;
        if (searchQuery)
            filtered = allStatus.filter(m =>
                m.name.toLowerCase().startsWith(searchQuery.toLowerCase())                
            );
        
        const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
    
        const status = paginate(sorted, currentPage, pageSize);
    
        return { totalCount: filtered.length, data: status };
      };

      handleSort = sortColumn => {
        this.setState({ sortColumn });
      };
    
    render() { 
        //const { length: count } = this.state.status;
        const { sortColumn } = this.state;
        const { user } = this.props;

        //if (count === 0) return <p>There are no instrumentos in database.</p>;

        const { data: status } = this.getPagedData();

        return (
            <div>
                <div className="form-row mt-3 mb-3">
                    <div className="col-lg-10">    
                        <div className="float-left">
                            {user && (
                                <Link
                                    to="/status/new"
                                    className="btn btn-primary float-right"                                
                                    >
                                    <i className="fa fa-plus" aria-hidden="true" /><span> </span>
                                    Nuevo Estatus
                                </Link>
                            )}                            
                        </div>
                    </div>                    
                    {/* <div className="col-lg-2">
                        <SearchBox value={searchQuery} onChange={this.handleSearch} />
                    </div> */}
                </div>
                <StatusTable
                    status={status}
                    sortColumn={sortColumn}
                    // onDelete={this.handleDelete}
                    onSort={this.handleSort}
                />
                {/* <Pagination
                    itemsCount={totalCount}
                    pageSize={pageSize}
                    currentPage={currentPage}
                    onPageChange={this.handlePageChange}
                /> */}
            </div>
        );
    }
}
 
export default Status;