import http from "./httpService";

const apiEndpoint = process.env.REACT_APP_API_URL + "/auth";
const tokenKey = "token";

//http.setJwt(getJwt());

export function login() {
  return http.get(apiEndpoint);
}

export function loginWithJwt(jwt) {
  localStorage.setItem(tokenKey, jwt);
}

export function logout() {
  localStorage.removeItem(tokenKey);
}

export function getCurrentUser() {
  try {
    return http.get(apiEndpoint);   
  } catch (ex) {
    return null;
  }
}

export function getJwt() {
  return localStorage.getItem(tokenKey);
}
