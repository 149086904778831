import { Component } from "react";
import auth from "../services/authService";
import { Auth } from 'aws-amplify';


class Logout extends Component {
  componentDidMount() {
    //auth.logout();
    localStorage.removeItem('user');
    Auth.signOut()
        .then(() => window.location = '/login');
  }

  render() {
    return null;
  }
}

export default Logout;
