import http from './httpService'

const apiEndpoint = process.env.REACT_APP_API_URL + '/calendar-events'

export function getCalendarEvents (type, user) {
  const body = { type, user };
  return http.post(`${apiEndpoint}/filter`, body);
}

export function getCalendarEvent(id) {
  return http.get(`${apiEndpoint}/${id}`)
}

export function saveCalendarEvent (event) {
  if (event._id) {
    const body = { ...event }
    delete body._id
    return http.put(`${apiEndpoint}/${event._id}`, body)
  }

  return http.post(apiEndpoint, event)
}

export function deleteCalendarEvent (id) {
  return http.delete(`${apiEndpoint}/${id}`)
}
