import React, { Component } from 'react'
import { saveAs } from 'file-saver'
import { generate_workbook } from '../../../utils/js2xlsx'
import moment from 'moment'
import { Link } from 'react-router-dom'
import Table from '../../common/materia-reac-table/table'
import Check from '../../common/check'

class BoxesTable extends Component {

  state = {
    header: '',
    startDate: null,
    endDate: null,
    focusedInput: null,
    searchQuery: '',
  }

  columns = [
    {
      header: 'ID',
      accessorKey: 'numberBox',
      Cell: ({ row }) => <Link
        to={(this.props.user && this.props.user.role && this.props.user.role.actions.some(a => a.method === 'edit-box')) ? `/boxes/${row.original._id}` : `#`}
        className={`badge ${row.original.status === "En Autorización" ? "badge-warning" : ""} ${row.original.color}` + (row.original.isMissingInvoiceData ? ' missing' : '')}>
        {String(row.original.numberBox)}
      </Link>,
      size: 70,
    },
    {
      header: 'Tipo',
      accessorKey: 'type',
      size: 110,
    },
    {
      header: 'Numero',
      accessorKey: 'itemNumber',
      size: 150,
    },
    {
      header: 'Facturas',
      accessorKey: 'invoices',
      size: 110,
    },
    {
      header: 'Cliente',
      size: 130,
      accessorKey: 'clientName',
    },
    {
      header: 'Originador',
      size: 140,
      accessorKey: 'originator',
    },
    {
      header: 'Responsable',
      size: 150,
      accessorKey: 'responsible',
    },
    {
      id: 'expenses',
      accessorKey: 'expenses',
      accessorFn: row => row.expenses ? row.expenses.reduce((sum, expense) => sum + expense.total, 0) : 0,
      Cell: ({ cell }) => cell.getValue()?.toLocaleString?.('es-MX', {
        style: 'currency',
        currency: 'MXN',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }),
      header: 'Gastos',
      size: 120,
    },
    {
      id: 'totalFees',
      accessorKey: 'totalFees',
      accessorFn: row => row.totalFees || 0,
      Cell: ({ cell }) => cell.getValue()?.toLocaleString?.('es-MX', {
        style: 'currency',
        currency: 'MXN',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }),
      header: 'Honorarios',
      size: 120,
    },
    {
      accessorKey: 'total',
      Cell: ({ cell }) => cell.getValue()?.toLocaleString?.('es-MX', {
        style: 'currency',
        currency: 'MXN',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }),
      header: 'Total',
      size: 120,
    },
    {
      accessorKey: 'createdAt',
      Cell: ({ cell }) => cell.getValue() ? moment(cell.getValue()).format('DD/MM/YYYY') : '-',
      header: 'Creado',
      size: 120,
    },
    {
      accessorKey: 'createdBy',
      Cell: ({ row }) => row.original.createdBy || '-',
      header: 'Creada por',
      size: 120,
    },
    {
      accessorKey: 'paymentDate',
      Cell: ({ row }) => row.original.paymentDate ? moment(row.original.paymentDate).format('DD/MM/YYYY') : '-',
      header: 'Pago en',
      size: 120,
    },
    {
      accessorKey: 'instructionInvoice',
      header: 'Instr. Factura',
      size: 350,
    },
    {
      header: 'Estatus',
      accessorKey: 'status',
      size: 120
    },
    {
      header: 'Revisado',
      accessorKey: 'review',
      Cell: ({ row }) => <Check archived={row.original.review} onClick={() => this.props.handleArchive(row.original)} />,
      size: 120,
    }
  ]

  handleExport = async (boxes, columns) => {
    const filename = `Cajas${(moment().format('YYYYMMDDHHmmss'))}.xlsx`
    let rows = []
    const cols = columns.map(col => {
      col.title = col.header
      col.width = 25
      return col
    })
    delete cols[15]
    delete cols[16]
    boxes.map((element) => {
      let data = []
      element.expenses = element.expenses.reduce((sum, expense) => sum + expense.total, 0)
      element.totalFees = element.totalFees || element.fees ? element.fees.reduce((sum, fee) => sum + fee.total, 0) : 0
      columns.map(col => data.push(element[col.accessorKey]))
      rows.push(data)
    })

    saveAs(
      await generate_workbook({
        sheets: [{
          name: 'Cajas',
          cols: cols,
          data: rows,
        }],
        props: {
          Title: 'Cajas',
          CreatedDate: new Date(),
        },
        type: 'blob',
      }),
      filename,
    )
  }
  deleteRowAction = ({ row, table }) => <button
    onClick={(e) => window.confirm('Esta seguro que desea eliminar la caja?') && this.props.handleDelete(row.original)}
    className="btn btn-danger btn-sm">
    Eliminar
  </button>

  render() {
    const { boxes, user } = this.props
    return (user && this.columns ?
      <Table
        columns={this.columns}
        data={boxes}
        dataExport={(user && user.role && user.role.actions.some(a => a.method === 'export-box') && (
          <button
            onClick={() => this.handleExport(boxes, this.columns)}
            className="btn btn-success float-right"
          >
            <i className="fa fa-download" aria-hidden="true" /><span> </span>
            Exportar
          </button>)
        )}
        initialState={{ columnVisibility: { review: !!(user && user.role && user.role.actions.some(a => a.method === 'archive-box')) } }}
        user={user}
        rowAction={(user && user.role && user.role.actions.some(a => a.method === 'delete-box')) ? this.deleteRowAction : null}
      /> : <></>
    )
  }
}

export default BoxesTable
