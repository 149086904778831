import http from "./httpService";

const apiEndpoint = process.env.REACT_APP_API_URL + "/actions";

export function actionUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getActions() {
  return http.get(apiEndpoint);
}

export function getAction(actionId) {
  return http.get(actionUrl(actionId));
}

export function saveAction(action) {
  if (action._id) {
    const body = { ...action };
    delete body._id;
    return http.put(actionUrl(action._id), body);
  }

  return http.post(apiEndpoint, action);
}

export function deleteAction(actionId) {
  return http.delete(actionUrl(actionId));
}
