import React, { Component } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import Table from '../../common/materia-reac-table/table'
import Check from '../../common/check'

class TestimonyTable extends Component {

  state = {
    header: '',
    startDate: null,
    endDate: null,
    focusedInput: null,
    searchQuery: '',
  }

  columns = [
    {
      header: 'ID',
      accessorKey: 'numberBox',
      Cell: ({ row }) => <Link
        to={(this.props.user && this.props.user.role && this.props.user.role.actions.some(a => a.method === 'edit-box')) ? `/boxes/${row.original._id}` : `#`}
        className={`badge ${row.original.color}` + (row.original.isMissingInvoiceData ? ' missing' : '')}>
        {String(row.original.numberBox)}
      </Link>,
      size: 70,
    },
    {
      accessorKey: 'numberInstrument',
      header: 'No. Instrumento',
      selector: row => row.instrument.number,
      Cell: ({ row }) => <Link
        to={(this.props.user && this.props.user.role && this.props.user.role.actions.some(a => a.method === 'edit-instrument')) ? `/instruments/${row.original.instrument?._id}` : '#'}
        searchvalue={row.original.instrument?.number}
        className={row.original.instrument?.isBC ? 'badge badge-warning' : ''}>
        {String(row.original.instrument?.number)}
        {row.original.instrument?.excel !== undefined && row.original.instrument?.excel !== 'no' ? <i
          className={row.excel === 'yes' ? 'fas fa-file-excel text-success' : 'fas fa-file-excel text-primary'}> </i> : ''}
      </Link>,
      size: 140,
    },
    {
      header: 'Tipo',
      accessorKey: 'type',
      size: 110,
    },
    {
      accessorKey: 'createdAt',
      Cell: ({ cell }) => cell.getValue() ? moment(cell.getValue()).format('DD/MM/YYYY') : '-',
      header: 'Creado',
      size: 120,
    },
    {
      accessorKey: 'createdBy',
      Cell: ({row}) => row.original.createdBy || '-',
      header: 'Creada por',
      size: 120,
    },
    {
      header: 'Numero',
      accessorKey: 'itemNumber',
      size: 150,
    },
    {
      header: 'Cliente',
      size: 130,
      accessorKey: 'clientName',
    },
    {
      header: 'Responsable',
      size: 150,
      accessorKey: 'responsible',
    },
    {
      header: 'Revisado',
      accessorKey: 'review',
      Cell: ({ row }) => <Check archived={row.original.review} onClick={() => this.props.handleArchive(row.original)}/>,
      size: 120,
    }
  ]

  
  render () {
    const { boxes, user } = this.props
    return (user && this.columns ?
        <Table
          columns={this.columns}
          data={boxes}
          initialState={{ columnVisibility: { review: !!(user && user.role && user.role.actions.some(a => a.method === 'archive-box')) } }}
          user={user}
        /> : <></>
    )
  }
}

export default TestimonyTable
