import React, { Component } from "react";
import { Link } from "react-router-dom";
import Moment from 'react-moment';
import moment  from 'moment';
import Table from '../common/materia-reac-table/table'

class DenominationsTable extends Component {
  columns = [
    {
      accessorKey: "name",
      Cell: ({ row }) =>  <Link to={this.props.user && this.props.user.role && this.props.user.role.actions.some(a => a.method === "edit-denomination") ? `/denominations/${this.props.status}/${row.original._id}` : "#"} searchvalue={row.original.name}>{row.original.name}</Link>,
      header: "Denominación",
      size: 200
    },
    {
      accessorKey: 'comments',
      header: 'Observaciones',
      size: 150
    },
    {
      accessorKey: 'type',
      header: 'Tipo',
      size: 170
    },
    {
      accessorKey: 'account',
      header: 'Cuenta',
      size: 100
    },
    {
      accessorKey: "created",
      Cell: ({ cell }) =>  moment(cell.getValue()).format('DD/MM/YYYY'),
      header: "Emisión",
      size: 120
    },
    {
      accessorKey: "expired",
      Cell: ({ cell }) =>  moment(cell.getValue()).format('DD/MM/YYYY'),
      header: "Vencimiento",
      size: 140
    },
    {
      accessorKey: "status",
      header: "Estado",
      size: 100
    },
    {
      accessorKey: "expired_of_days",
      Cell: ({ cell }) => moment().diff(moment(cell.getValue()), 'days'),
      header: "Dias para vencimiento",
      size: 130
    },
    // {
    //   accessorKey: "alert",
    //   header: "Alerta",
    //   size: 100
    // },
    {
      accessorKey: "useBy",
      Cell: ({ cell, row }) => (cell.getValue() === 'Disponible' || cell.getValue() === 'Próximo a vencer' ? ( <button onClick={(e) =>  this.props.user && this.props.user.role && this.props.user.role.actions.some(a => a.method === "use-denomination") && window.confirm("Esta seguro que desea usar la denominación?") && this.handleUse(row.original) } className="btn btn-info btn-sm">Usada</button>) : (cell.getValue() ? cell.getValue() : '')),
      header: "Usado por",
      show: true,
      size: 100
    }
  ];

  render() {
    const { denominations, user } = this.props;

    return (this.columns && user ?
      <Table
        columns={this.columns}
        data={denominations}
        user={user}
        isLoading={this.props.isLoading}
      />: <></>
    );
  }
}

export default DenominationsTable;
