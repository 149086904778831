import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import logo  from '../logo.png';

class SignupForm extends Component {
    state = {
        data: { email: '', password: '', phoneNumber: '' },
        error: '',
    };

    doRegister = () => {
        const { email, password, phoneNumber } = this.state.data;
        console.log(email, password, phoneNumber);
        Auth.signUp({
            username: email,
            password,
            attributes: {
                email,
                phone_number: `+52${phoneNumber}`,
            },
        }).then(async () => {
            window.location.href = '/verification';
        }).catch((err) => {
            console.log(err);
            this.setState({ error: err.message });
        });
    }

    handleSubmit = e => {
        e.preventDefault();

        this.doRegister();
    };

    handleChange = ({ currentTarget: input }) => {
        const data = { ...this.state.data };
        data[input.name] = input.value;

        this.setState({ data });
    };

    componentDidMount() {
        document.getElementById('root').classList.add('auth');
    }

    render() {
        const { data, error } = this.state;
        const user = localStorage.getItem('user');
        if (user) return <Redirect to="/dashboard" />;

        return (
            <form onSubmit={this.handleSubmit} className="form-signin">
                <div className="text-center mb-4">
                    <img className="mb-4" src={logo} alt="" width="50%" height="100%" />                    
                </div>
                <div className="form-label-group">
                    <input name="email" className="form-control" placeholder="Correo electronico" value={data.email} onChange={this.handleChange} required />
                    <label>Correo electronico</label>
                </div>
                <div className="form-label-group">
                    <input name="phoneNumber" className="form-control" placeholder="Número teléfonico" value={data.phoneNumber} onChange={this.handleChange} required />
                    <label>Número teléfonico</label>
                </div>
            
                <div className="form-label-group">
                    <input type="password" name="password" className="form-control" placeholder="Contraseña" value={data.password} onChange={this.handleChange} required />
                    <label>Contraseña</label>
                </div>  
                {error &&
                    <label color="red">{error}</label>
                }  
                <button className="btn btn-lg btn-primary btn-block">Ingresar</button>
            </form>
        );
    }
}

export default SignupForm;
