import React from "react";
import Joi from "joi-browser";
import Form from "./common/form";
import { getResponsible, saveResponsible } from "../services/responsibleService";
//import { getGenres } from "../services/genreService";

class ResponsibleForm extends Form {
  state = {
    data: {
      name: "",
      abbreviation: "",
      percentage: 0
    },
    errors: {}
  };

  schema = {
    _id: Joi.string(),
    name: Joi.string()
      .required()
      .label("Nombre")
  };

//   async populateGenres() {
//     const { data: genres } = await getGenres();
//     this.setState({ genres });
//   }

  async populateResponsible() {
    try {
      const responsibleId = this.props.match.params.id;
      if (responsibleId === "new") return;

      const { data: responsible } = await getResponsible(responsibleId);
      this.setState({ data: this.mapToViewModel(responsible) });
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  }

  async componentDidMount() {
   //await this.populateGenres();
    await this.populateResponsible();
  }

  mapToViewModel(responsible) {
    return {
      _id: responsible._id,
      name: responsible.name
    };
  }

  doSubmit = async () => {
    await saveResponsible(this.state.data);

    this.props.history.push("/responsible");
  };

  render() {
    return (
      <div>
        <form className="mt-4" onSubmit={this.handleSubmit}>
          <div className="form-row">
            <div className="col-md-4 mb-3">
              {this.renderInput("name", "Nombre del responsable")}
            </div>
            <div className="col-md-4 mb-3">
              {this.renderInput("abbreviation", "Abreviación")}
            </div>
            <div className="col-md-4 mb-3">
              {this.renderInput("percentage", "Porcentaje")}
            </div>
          </div>
          <button className="btn btn-primary float-right" type="submit">Guardar</button>
        </form>
      </div>
    );
  }
}

export default ResponsibleForm;
