import * as XLSX from 'xlsx';

/**
 * Create a new workbook
 *
 * @param {*} sheetNames
 * @param {*} props
 */
function create_workbook(sheetNames, props) {
    let workbook = XLSX.utils.book_new();
    workbook.Props = props;
    workbook.SheetNames = typeof sheetNames == 'string'
        ? [sheetNames]
        : sheetNames;

    return workbook;
}

/**
 * Generate Excel from definition object
 *
 * @param {*} definitions
 */
async function generate_workbook(definitions) {
    const { sheets = [], props, type = null } = definitions;
    let workbook = XLSX.utils.book_new();
    workbook.Props = props;

    await sheets.forEach(sheet => {
        let data = [], cols = [];

        (sheet.cols || []).forEach((item, index) => {
            cols.push(item.title);
        });
        data = [cols, ...sheet.data];
        workbook.SheetNames.push(sheet.name);
        workbook.Sheets[sheet.name] = js_to_sheet(data);

        for (let i = 1; i < sheet.data.length; i++) {
            let row = i + 1;
            let item = sheet.data[i];

            for (let j = 0; j < sheet.cols.length; j++) {
                try {
                    let cell = `${String.fromCharCode(65 + j)}${row}`;

                    if (sheet.cols[j].type === 'l') {
                        workbook.Sheets[sheet.name][cell].l = {
                            Target: item[`${j}`],
                            Tooltip: item[`${j}`],
                        };
                    }

                } catch { };
            }

            workbook.Sheets[sheet.name]['!cols'] = sheet.cols;
        }
    });

    if (['binary', 'blob'].includes(type)) {
        workbook = workbook_to_binary(workbook);
    }

    if (type === 'blob') {
        workbook = binary_to_blop(workbook);
    }

    return workbook;
}

/**
 * Convert js data to Excell sheets
 *
 * @param {*} data
 * @param {*} props
 */
function js_to_sheet(data) {
    let fn;
    if (Array.isArray(data)) {
        fn = XLSX.utils.aoa_to_sheet;
    } else if (Object.isObject(data)) {
        fn = XLSX.utils.object_to_sheet;
    } else {
        return null;
    }

    return fn(data);
}

/**
 * Convert Workbook to binary
 *
 * @param {*} workbook
 * @param {*} bookType
 */
function workbook_to_binary(workbook, bookType = 'xlsx') {
    return XLSX.write(workbook, {
        bookType,
        type: 'binary',
    });
}

/**
 * Convert binary to array buffer
 *
 * @param {*} sheet
 */
function binary_to_ab(binary) {
    let array_buffer = new ArrayBuffer(binary.length);
    let view = new Uint8Array(array_buffer);

    for (let i = 0; i < binary.length; i++) {
        view[i] = binary.charCodeAt(i) & 0xFF;
    }

    return array_buffer;
}

/**
 * Convert binary data to blop
 *
 * @param {*} binary
 * @param {*} type
 */
function binary_to_blop(binary, type = 'application/octet-stream') {
    return new Blob([binary_to_ab(binary)], { type });
}

export {
    create_workbook,
    generate_workbook,
};
