import React, { Component } from 'react';
import Joi from "joi-browser";
import Form from "../common/form";
import { Modal } from 'react-bootstrap';
import { toast } from "react-toastify";
import SortableTree, { insertNode, removeNodeAtPath, changeNodeAtPath } from 'react-sortable-tree';
import 'react-sortable-tree/style.css';
import { getCff, getFormsCff, saveCff } from '../../services/cffService';


class publicCff extends Form {
    state = {
        id: "",
        show: false,
        currentNode: {},
        tree: {
            name: "",
            fiscalRegime: ""
        },
        data: {
            nameCff: "",
            email: "",
            structure: [{ title: 'Agrega una Razon social', name: 'Agrega una Razon social', fiscalRegime: 0 }],
            status: ""
        },
        forms: [], // formsCff
        errors: {},
        node: {},
        fiscalRegimes: [{ _id: 1, name: "Persona Física" }, { _id: 2, name: "Persona Moral" }],
        path: {},
    }

    schema = {
        _id: Joi.string(),
        fiscalRegime: Joi.string()
            .required()
            .label("Nombre")
    };

    showModal = (path) => {
        const tree = { name: "", fiscalRegime: 0 }
        this.setState({ show: true, path, tree });
    };

    hideModal = () => {
        this.setState({ show: false });
    };

    async populateForm() {
        try {
            const cffId = this.props.match.params.id;
            if (cffId === "new") {
                return;
            }
            const { data: cff } = await getCff(cffId);
            const { data: forms } = await getFormsCff(cffId);
            this.setState({ data: this.mapToViewModel(cff) });
            this.setState({ forms: forms });
        } catch (ex) {
            if (ex.response && ex.response.status === 404)
                this.props.history.replace("/not-found");
        }
    }

    async componentDidMount() {
        await this.populateForm();
    }

    mapToViewModel(cff) {
        return {
            _id: cff._id,
            nameCff: cff.nameCff,
            email: cff.email,
            structure: cff.structure,
            status: cff.status,
            responsible: cff.responsible._id,
        };
    }

    handleChageModal = ({ currentTarget: input }) => {
        const tree = { ...this.state.tree };
        tree[input.name] = input.value;

        this.setState({ tree });
    };

    handleChangeTree = (structure) => {
        const { data } = this.state;
        data.structure = structure
        this.setState({ data });
    }

    removeNode = (path) => {
        let { data } = this.state;

        data.structure = removeNodeAtPath({
            treeData: data.structure,
            path,
            getNodeKey: ({ treeIndex }) => treeIndex,
        });

        this.setState({ data });
    }

    selectThis = (node, path) => {
        this.setState({ currentNode: node, path: path });
    }

    insertNewNode = () => {
        let { data, tree } = this.state;

        data.structure = insertNode({
            treeData: data.structure,
            depth: 0,
            minimumTreeIndex: data.structure.length,
            newNode: {
                title: tree.name,
                name: tree.name,
                fiscalRegime: tree.fiscalRegime,
                children: [],
                edit: true
            },
            getNodeKey: ({ treeIndex }) => treeIndex
        }).treeData

        this.setState({ data });
    }

    handleAdd = async (e) => {
        e.preventDefault();
        this.insertNewNode()
        this.hideModal();
    };

    handleSubmit = async (e, status) => {
        e.preventDefault();
        const { data, forms } = this.state;
        const pendings = forms.filter(form => form.status === 'pending');
        if (pendings.length > 0) {
            toast.error("Aún queda información por ingresar, favor de ingresarla antes de Enviar.");
            return;
        }
        try {
            this.setState({ id: data._id });
            data.status = status;
            const { data: cff } = await saveCff({ data });
            this.setState({ data: cff });
            toast.success("El cff se guardo correctamente.");
        } catch (ex) {
            const { data } = this.state;
            data._id = this.state.id;
            this.setState({ data });
            toast.error("Aún queda información por ingresar, favor de ingresarla antes de Enviar.");
        }
    };

    render() {
        const { data } = this.state;
        const getNodeKey = ({ treeIndex }) => treeIndex;
        return (
            <>
                {
                    data.status !== 'complete' ? (
                        <div>
                            <Modal show={this.state.show} onHide={this.hideModal}>
                                <form>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Envia Formulario</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="form-row col-md-12">
                                            <div className="col-md-12 form-label-group mb-3">
                                                <input placeholder="Nombre o Razon Social" className="form-control" name="name" onChange={this.handleChageModal} />
                                                <label>Nombre o Razon Social</label>
                                            </div>
                                            <div className="col-md-12 form-label-group mb-3">
                                                <select name="fiscalRegime" id="fiscalRegime" className="form-control" onChange={this.handleChageModal} >
                                                    <option value="">Selecciona...</option>
                                                    {this.state.fiscalRegimes.map(fiscalRegime => (
                                                        <option key={fiscalRegime.name} value={fiscalRegime._id}>
                                                            {fiscalRegime.name}
                                                        </option>
                                                    ))}
                                                </select>
                                                <label>Régimen Fiscal</label>
                                            </div>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button className="btn btn-primary" onClick={this.handleAdd}>Guardar</button>
                                    </Modal.Footer>
                                </form>
                            </Modal>
                            <div className="form-row">
                                <div className="col-md-6 mb-6">
                                    <h5>{data.nameCff} - {data.status === 'review' ? (<span className='badge badge-primary'>En Revision</span>) : (data.status === 'complete' ? <span className='badge badge-success'>Terminado</span> : <span className='badge badge-warning'>Pendiente</span>)}</h5>
                                </div>
                            </div>
                            <div style={{ height: '100%' }}>
                                {
                                    this.state.data && this.state.data.structure && (<SortableTree
                                        treeData={this.state.data.structure}
                                        onChange={treeData => this.handleChangeTree(treeData)}
                                        isVirtualized={false}
                                        generateNodeProps={({ node, path }) => ({
                                            title: (
                                                <form onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.selectThis(node, path); }}>
                                                    {node.edit ? (<input
                                                        style={{ fontSize: "1rem" }}
                                                        value={node.name}
                                                        onChange={event => {
                                                            const title = event.target.value;
                                                            data.structure = changeNodeAtPath({
                                                                treeData: data.structure,
                                                                path,
                                                                getNodeKey,
                                                                newNode: { ...node, title, name: title }
                                                            })
                                                            this.setState({ data });
                                                        }}
                                                    />) : node.name}&nbsp;&nbsp;&nbsp;
                                                    {/* {((node.fiscalRegime === "2" || node.fiscalRegime === 0) && (data.status !== 'review' && data.status !== 'complete')) &&
                                                        (<button className='btn btn-primary btn-sm mr-2' onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.showModal(path) }}><i className="fa fa-plus" /></button>)} */}
                                                    {node.formId &&
                                                        (<button className='btn btn-info btn-sm mr-2' onClick={() => {
                                                            if (data.status !== 'review' && data.status !== 'complete') {
                                                                this.props.history.replace({ pathname: `/form/${node.formId}`, state: { origen: '/public/cff' } });
                                                            }
                                                        }}><i className="fa fa-book" /></button>)
                                                    }
                                                    {
                                                        node.edit && (data.status !== 'review' && data.status !== 'complete') && (< button className='btn btn-danger btn-sm' onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.removeNode(path) }} ><i className="fa fa-trash" /></button>)
                                                    }
                                                </form>
                                            )
                                        })}
                                    />)
                                }
                            </div>
                            {(data.status !== 'review' && data.status !== 'complete') &&
                                (<><button className="btn btn-success float-right" onClick={(e) => this.handleSubmit(e, 'review')}>Enviar</button>
                                    {/* <button className="btn btn-primary float-right mr-4" onClick={(e) => this.handleSubmit(e, 'pending')}>Guardar</button>*/}</>)
                            }
                        </div>
                    ) : (<div>La información ha sido enviada con éxito. Esta liga ya no se encuentra disponible.</div>)
                }
            </>
        );
    }
}

export default publicCff;
