import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

class Sre extends Component {
    state = {
        data: {
            corporateName: "",
            noticeDate: "",
            type: ""
        },
        types: ["Constitutiva","Cambio de régimen"],
        show: false
    }

    showModal = () => {  
        this.setState({ show: true });     
    };

    hideModal = () => {
        this.setState({ show: false });
    };

    handleChange = ({ currentTarget: input }) => {
        const data = { ...this.state.data };         
        data[input.name] = input.value; 
        this.setState({ data });
    };

    handleDateChange = date => {        
        const { data } = this.state;
        data.noticeDate = date;
        this.setState({ data })
    };

    handleCheck = (e,type) => {
        const { data } = this.state;        
        if(e.target.checked){ 
            data.active = true;
            this.showModal();    
        } else {
            data.active = false;
        }
        this.props.onCheck(e,type);
        this.setState({ data });   
    }

    handleSubmit = e => {        
        e.preventDefault();
        const { data } = this.state;
        data.status = 'pending';
        // let editData = this.props.fees.filter(m => m._id === data._id);
        
        // if(editData.length < 1)
        //     data._id = new ObjectID();
        
        this.setState({ data: data, show: false }); 
        this.props.onSre(data); 
    };

    render() { 
        const { data, types } = this.state;
        const { sre, user } = this.props;

        return (
            <>
                <input type="checkbox" id="sre" onClick={(e) => this.handleCheck(e,"sre")} checked={sre && sre.active ? sre.active : false} disabled={sre && sre.status === "presented" ? true : false} />
                <label className="form-check-label" htmlFor="sre">SRE</label>
                <Modal show={this.state.show} onHide={this.hideModal}>
                    <form> 
                        <Modal.Header closeButton>
                            <Modal.Title>Completa el formulario</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>                               
                            <div className="form-row">                                
                                <div className="col-md-12 form-label-group mb-3">
                                    <select name="type" id="type" className="form-control" onChange={this.handleChange} >
                                        <option value="">Selecciona...</option>        
                                        { types.map(type => (
                                            <option key={ type } selected={(type === data.type) ? true : false }  value={type}>
                                                {type}
                                            </option>
                                        ))}
                                    </select> 
                                    <label>Tipo de aviso</label>
                                </div>
                            </div>                                                                        
                        </Modal.Body>
                            <Modal.Footer>   
                                {
                                    user && user.role && user.role.actions.some(a => a.method === "save-sre") && (                             
                                        <button className="btn btn-primary" onClick={this.handleSubmit}>Guardar</button>                                     
                                    )
                                }
                            </Modal.Footer>
                    </form>   
                </Modal>
            </>
        );
    }
}
 
export default Sre;