import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Check from "./common/check";
import {
    ResponsiveContainer, Tooltip, BarChart, CartesianGrid, XAxis, YAxis, Bar
  } from 'recharts';
import moment  from 'moment';
import InstrumentsTable from "./instruments/instrumentsTable";
import { getMetricsInstruments, getMetricsInstrumentsPendingInvoice } from '../services/metricService'
import {
    deleteInstrument,
    getInstruments,
    getInstrumentsPendingInvoice,
    instrumentArchivedUpdate
} from '../services/instrumentService'
import { getCurrentUser } from "../services/authService";
import _ from "lodash";


class BoxUnpaid extends Component {
    state = {
        metricsInstruments: [],
        instruments: [],
        sortColumn: { path: "number", order: "asc" },
        penalty: 0,
        isLoading: false
    }

    async populateUser() {
        const {data: user } = await getCurrentUser();
        if(user) this.setState({ user });
    }

    async populateMetricsInstruments () {
        const { user } = this.state;
        let filter = { used: false };
        if(user && user.role && !user.role.actions.some(a => a.method === "all-unpaid-instrument")) filter.name = user.name;
        const { data: metricsInstruments } = await getMetricsInstrumentsPendingInvoice(filter);
        this.setState({ metricsInstruments, isLoading: false });
    }

    async populateInstruments (name) {
        const { user } = this.state;
        let filter = {
            name: name
        }
        if(user && user.role && !user.role.actions.some(a => a.method === "all-unpaid-instrument")) filter.name = user.name;
        const { data: instruments } = await getInstrumentsPendingInvoice(filter);
        this.setState({ instruments: instruments });
    }

    async componentDidMount() {
        await this.populateUser();
        await this.populateMetricsInstruments();
        await this.populateInstruments();
    }

    onLoadInfo = async (data, index) => {
        const name = data.name ? (data.name) : "";
        await this.populateInstruments(name);
    }

    handleArchive = async updateInstrument => {
        const originalInstruments = this.state.instruments
        const instruments = [...originalInstruments] // Required to update state
        const index = instruments.indexOf(updateInstrument)
        instruments[index] = { ...instruments[index] }
        instruments[index].archived = !updateInstrument.archived
        this.setState({ instruments })
        try {
            await instrumentArchivedUpdate(updateInstrument._id, !updateInstrument.archived)
        } catch (ex) {
            if (ex.response && ex.response.status === 400)
              //console.log(originalInstruments);
              //toast.error("This movie has already been deleted.");
                this.setState({ instruments: originalInstruments })
        }
    }

    render() {
        const { metricsInstruments, instruments, user, isLoading } = this.state;

        return (
            <>
                <div className="card-deck mb-3 text-center">
                    <div className="card mb-4 shadow-sm">
                        <div className="card-header">
                            <h4 className="my-0" style={{fontWeight:400, fontSize: '0.9rem'}}>Instrumentos Pendientes de Facturar - <b>{metricsInstruments.reduce((prev, cur) => prev + cur.total, 0)}</b></h4>
                        </div>
                        <div style={{ width: '100%', height: 300 }}>
                            <ResponsiveContainer>
                                <BarChart
                                    layout="vertical"
                                    data={metricsInstruments}
                                    margin={{ left: 50, right: 50 }}
                                >
                                    <CartesianGrid strokeDasharray="6 6" />
                                    <XAxis type="number"/>
                                    <YAxis dataKey="name" type="category" tick={{ fontSize: 10, width: 150 }} />
                                    <Tooltip />
                                    <Bar dataKey="total" barSize={20} fill="#86cfda" onClick={(data, index) => this.onLoadInfo(data, index)} />
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                </div>
                <InstrumentsTable
                    instruments={instruments}
                    isPending={false}
                    handleArchive={this.handleArchive}
                    user={user}
                    isLoading={isLoading}
                />
            </>
        );
    }
}

export default BoxUnpaid;
