import React, { Component } from 'react';
import { Link } from "react-router-dom";
import TypesTable from "./typesTable";
import { getTypes } from "../services/typeService";
import { paginate } from "../utils/paginate";
import _ from "lodash";

class Types extends Component {
    state = { 
        types: [],
        currentPage: 1,
        pageSize: 20,
        sortColumn: { path: "name", order: "asc" }
     }

     async componentDidMount() {            
        const { data: types } = await getTypes();   
        const typesUpdate = types.map(t => {
            t.name = <Link to={`/types/${t._id}`}>{t.name}</Link>     
            return t;
        });

        this.setState({ types: typesUpdate });
    }

     handlePageChange = page => {
        this.setState({ currentPage: page });
      };

      handleSearch = query => {
        this.setState({ searchQuery: query, currentPage: 1 });
     };

     getPagedData = () => {
        const {
          pageSize,
          currentPage,
          sortColumn,
          searchQuery,
          types: allTypes
        } = this.state;
    
        let filtered = allTypes;
        if (searchQuery)
            filtered = allTypes.filter(m =>
                m.name.toLowerCase().startsWith(searchQuery.toLowerCase())                
            );
        
        const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
    
        const types = paginate(sorted, currentPage, pageSize);
    
        return { totalCount: filtered.length, data: types };
      };

      handleSort = sortColumn => {
        this.setState({ sortColumn });
      };

    render() { 
        //const { length: count } = this.state.types;
        const { sortColumn } = this.state;
        const { user } = this.props;

        //if (count === 0) return <p>There are no instrumentos in database.</p>;

        const { data: types } = this.getPagedData();
        return (
            <div>
                <div className="form-row mt-3 mb-3">
                    <div className="col-lg-10">    
                        <div className="float-left">
                            {user && (
                                <Link
                                    to="/types/new"
                                    className="btn btn-primary float-right"                                
                                    >
                                    <i className="fa fa-plus" aria-hidden="true" /><span> </span>
                                    Nuevo Tipo
                                </Link>
                            )}                            
                        </div>
                    </div>                    
                    {/* <div className="col-lg-2">
                        <SearchBox value={searchQuery} onChange={this.handleSearch} />
                    </div> */}
                </div>
                <TypesTable
                    types={types}
                    sortColumn={sortColumn}
                    // onDelete={this.handleDelete}
                    onSort={this.handleSort}
                />
                {/* <Pagination
                    itemsCount={totalCount}
                    pageSize={pageSize}
                    currentPage={currentPage}
                    onPageChange={this.handlePageChange}
                /> */}
            </div>
        );
    }
}
 
export default Types;