import React from "react";
import Joi from "joi-browser";
import Form from "./common/form";
import { getUser, saveUser } from "../services/userService";
import { getRoles } from "../services/roleService";

class UserForm extends Form {
  state = {
    data: {
      name: "",
      email: "",
      password: "",
      goal: 0,
      comRegular: 0,
      comValuation: 0,
    },
    errors: {},
    roles: [
      // {_id: new ObjectID(), name: 'Administrador'},
      // {_id: new ObjectID(), name: 'Manager'},
      // {_id: new ObjectID(), name: 'Abogado'},
      // {_id: new ObjectID(), name: 'Archivista'},
      // {_id: new ObjectID(), name: 'Gestor'}
    ],
    hasPassword: false,
  };

  schema = {
    name: Joi.string().required().label("Username"),
    email: Joi.string().required().label("Email"),
    role: Joi.string().required().label("Role"),
  };

  async populateUser() {
    try {
      const userId = this.props.match.params.id;
      if (userId === "new") {
        let hasPassword = true;
        this.setState({ hasPassword });
        return;
      }

      const { data: user } = await getUser(userId);
      this.setState({ data: this.mapToViewModel(user) });
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not-found");
    }
  }

  async populateRoles() {
    const { data: roles } = await getRoles();
    this.setState({ roles });
  }

  handleChange = ({ currentTarget: input }) => {
    // const errors = { ...this.state.errors };
    // const errorMessage = this.validateProperty(input);
    // if (errorMessage) errors[input.name] = errorMessage;
    // else delete errors[input.name];

    if (input.name === "role") {
      const data = { ...this.state.data };
      data["roleId"] = input.value;
      this.setState({ data });
    }

    const data = { ...this.state.data };
    data[input.name] = input.value;

    this.setState({ data });
  };

  async componentDidMount() {
    await this.populateUser();
    await this.populateRoles();
  }

  mapToViewModel(user) {
    return {
      _id: user._id,
      name: user.name,
      email: user.email,
      role: user.role,
      goal: user.goal,
      comRegular: user.comRegular,
      comValuation: user.comValuation,
    };
  }

  doSubmit = async () => {
    var urlActual = window.location.href;
    var regex = /new/;

    if (regex.test(urlActual)) {
      const body = {
        name: this.state.data.name,
        email: this.state.data.email,
        password: this.state.data.password,
        role: this.state.data.role,
        goal: this.state.data.goal,
        comRegular: this.state.data.comRegular,
        comValuation: this.state.data.comValuation,
        roleId: this.state.data.role,
      };
      console.log("🚀 ~ UserForm ~ doSubmit= ~ body:", body)
      await saveUser(body);
    } else {
      await saveUser(this.state.data);
    }
    this.props.history.push("/users");
  };

  render() {
    const { roles } = this.state;

    return (
      <div>
        <form className="mt-4" onSubmit={this.handleSubmit}>
          <div className="form-row">
            <div className="col-md-4 mb-3">
              {this.renderInput("name", "Nombre de usuario")}
            </div>
            <div className="col-md-4 mb-3">
              {this.renderInput("email", "Email")}
            </div>
            <div className="col-md-4 mb-3">
              {this.renderInput("password", "Contraseña", "password")}
            </div>
            <div className="col-md-4 mb-3">
              {this.renderSelect("role", "Rol", roles)}
              {/* <div className="form-label-group">                
                <select name="role" id="role" className="form-control" onChange={this.handleChange}>
                    <option value="">Selecciona...</option>        
                        { this.state.roles.map(option => (
                            <option key={ option._id } selected={(option.name === this.state.data.role) ? true : false }  value={option.name}>
                                {option.name}
                            </option>
                        ))}
                </select>  
                <label>Rol</label>      
              </div> */}
            </div>
            <div className="col-md-4 mb-3">
              {this.renderInput("goal", "Meta", "number")}
            </div>
            <div className="col-md-4 mb-3">
              {this.renderInput("comRegular", "Comisión Regular", "number")}
            </div>
            <div className="col-md-4 mb-3">
              {this.renderInput("comValuation", "Comisión Avaluos", "number")}
            </div>
          </div>
          <button className="btn btn-primary float-right" type="submit">
            Guardar
          </button>
        </form>
      </div>
    );
  }
}

export default UserForm;
