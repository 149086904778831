import React, { Component } from 'react';
import { Link } from "react-router-dom";
import ResponsibleTable from "./responsibleTable";
import Pagination from "./common/pagination";
import { getResponsibles } from "../services/responsibleService";
import { paginate } from "../utils/paginate";
import SearchBox from "./searchBox";
import _ from "lodash";

class Responsible extends Component {
    state = { 
        responsible: [],
        currentPage: 1,
        pageSize: 20,
        sortColumn: { path: "name", order: "asc" }
     }

     async componentDidMount() {            
        const { data: responsible } = await getResponsibles();   
        this.setState({ responsible });
    }

     handlePageChange = page => {
        this.setState({ currentPage: page });
      };

      handleSearch = query => {
        this.setState({ searchQuery: query, currentPage: 1 });
     };

     getPagedData = () => {
        const {
          pageSize,
          currentPage,
          sortColumn,
          searchQuery,
          responsible: allResponsible
        } = this.state;
    
        let filtered = allResponsible;
        if (searchQuery)
            filtered = allResponsible.filter(m =>
                m.name.toLowerCase().startsWith(searchQuery.toLowerCase()) ||
                m.abbreviation.toLowerCase().startsWith(searchQuery.toLowerCase()) ||
                m.percentage.toString().toLowerCase().startsWith(searchQuery.toLowerCase())
            );
        
        const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
    
        const responsible = paginate(sorted, currentPage, pageSize);
    
        return { totalCount: filtered.length, data: responsible };
      };

      handleSort = sortColumn => {
        this.setState({ sortColumn });
      };

    render() { 
        //const { length: count } = this.state.responsible;
        const { pageSize, currentPage, sortColumn, searchQuery } = this.state;
        const { user } = this.props;

        //if (count === 0) return <p>There are no instrumentos in database.</p>;

        const { totalCount, data: responsible } = this.getPagedData();
        return (
            <div>
                <div className="form-row mt-3 mb-3">
                    <div className="col-lg-10">    
                        <div className="float-left">
                            {user && (
                                <Link
                                    to="/responsible/new"
                                    className="btn btn-primary float-right"                                
                                    >
                                    <i className="fa fa-plus" aria-hidden="true" /><span> </span>
                                    Nuevo Responsable
                                </Link>
                            )}                            
                        </div>
                    </div>                    
                    <div className="col-lg-2">
                        <SearchBox value={searchQuery} onChange={this.handleSearch} />
                    </div>
                </div>
                <ResponsibleTable
                    responsible={responsible}
                    sortColumn={sortColumn}
                    // onDelete={this.handleDelete}
                    onSort={this.handleSort}
                />
                <Pagination
                    itemsCount={totalCount}
                    pageSize={pageSize}
                    currentPage={currentPage}
                    onPageChange={this.handlePageChange}
                />
            </div>
        );
    }
}
 
export default Responsible;