import awsS3 from 'aws-sdk/clients/s3';

const BUCKET = process.env.REACT_BUCKET_NAME
const REGION = process.env.REACT_BUCKET_REGION;

const S3 = new awsS3({
    apiVersion: '2006-03-01',
    region: REGION,
    credentials: {
        accessKeyId: process.env.REACT_BUCKET_ACCESS_KEY || 'AKIA6Q7NLQOYPVNLJL2V',
        secretAccessKey: process.env.REACT_BUCKET_SECRET || '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',
    },
});

/**
 * Get signed URL
 *
 * @param {string} path
 */
export const getSignedUrl = async (path, bucket = BUCKET) => {
    path = path.replace(`https://${bucket}.s3.${REGION}.amazonaws.com/`, '');
    const params = {
        Bucket: bucket,
        Key: path,
        Expires: 10,
    };
    return S3.getSignedUrl('getObject', params);
};

/**
 * Upload file to AWS S3
 *
 * @param {string} path
 * @param {(Buffer|Uint8Array|Blob|string|Readable)} file
 */
export const upload = async (path, file, bucket = BUCKET) => {
    const params = {
        Body: file,
        Bucket: bucket,
        Key: path,
    };
    const options = {
        partSize: 50 * 1024 * 1024,
        queueSize: 1,
    };
    return S3.upload(params, options).promise();
};

/**
 * Download file from AWS S3
 *
 * @param {string} path
 */
export const download = async (path, bucket = BUCKET) => {
    try {
        const url = await getSignedUrl(path, bucket);
        window.open(url);
    } catch (error) {
        return false;
    }

    return true;
};
