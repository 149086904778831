import React from 'react'
import Joi from 'joi-browser'
import Form from '../common/form'
import { findByName, getClient, saveClient } from '../../services/clientService'
import Offices from '../offices'
import { getCurrentUser } from '../../services/authService'
import { getOriginators } from '../../services/originatorService'

class ClientForm extends Form {
  state = {
    data: {
      name: '',
      originatorId: '',
      offices: []
    },
    errors: {},
    originators: {},
    isLoading: false,
  }

  schema = {
    _id: Joi.string(),
    name: Joi.string()
      .required()
      .label('Nombre')
  }

  async populateClient () {
    try {
      const clientId = this.props.match.params.id
      if (clientId === 'new') return

      const { data: client } = await getClient(clientId)
      this.setState({ data: this.mapToViewModel(client) })
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace('/not-found')
    }
  }

  async componentDidMount () {
    //await this.populateGenres();
    await this.populateClient()
    await this.populateOriginators()
    const { data: user } = await getCurrentUser()
    this.setState({user})
  }

  async populateOriginators() {
    const { data: originators } = await getOriginators();
    this.setState({ originators });
  }

  mapToViewModel (client) {
    console.log(client)
    return {
      _id: client._id,
      name: client.name,
      originatorId: client.originator?._id || '',
      offices: client.offices
    }
  }

  doSubmit = async () => {
    this.setState({ isLoading: true })
    try {
      await saveClient(this.state.data);
    } catch (e) {
      this.setState({ isLoading: false })
      alert(`Error al crear el cliente: \n${e.response.data}`)
      return
    }
    this.props.history.push("/clients");
  }

  handleOffice = async (office) => {
    try {
      const data = { ...this.state.data }
      const offices = data.offices
      const editData = offices.findIndex(m => m._id === office._id)

      if (editData < 0) {
        offices.push(office)
      } else {
        offices[editData] = office
      }

      this.setState({ data })
    } catch (ex) {
    }
  }

  handleDeleteOffice = office => {
    const { data } = this.state
    const originalFees = data.offices
    const offices = originalFees.filter(m => m !== office)
    data.offices = offices

    this.setState({ data })
  }

  render () {
    const { data, originators, isLoading } = this.state
    return (
      <div>
        <form className="mt-4" onSubmit={this.handleSubmit}>
          <div className="form-row">
            <div className="col-md-4 mb-3">
              {this.renderInput('name', 'Nombre del cliente', undefined, undefined, undefined, true)}
            </div>
            <div className="col-md-3 mb-3">
              {this.renderSelect("originatorId", "Originador", originators)}
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-6 mb-3 table-pr">
              <Offices offices={data.offices} onOffice={this.handleOffice} onDelete={this.handleDeleteOffice}/>
            </div>
          </div>
          <button className="btn btn-primary float-right" type="submit" disabled={isLoading}>
            {
              isLoading && <i className="fa fa-spinner fa-pulse fa-fw"></i>
            }
            Guardar
          </button>
        </form>
      </div>
    )
  }
}

export default ClientForm
