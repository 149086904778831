import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { ObjectID } from 'bson';

class Rpc extends Component {
    state = {
        data: {
            _id: new ObjectID(),
            active: false,
            state: "",
            event: "",
            type: "",
            special: false,
            comments: ""
        },
        types: ["Siger 1","Siger 2","Fisico"],
        states: ["Aguascalientes", "Baja California", "Baja California Sur", "Campeche",
                 "Coahuila", "Colima", "Chiapas", "Chihuahua", "Durango", "Distrito Federal",
                 "Guanajuato", "Guerrero", "Hidalgo", "Jalisco", "México", "Michoacán", "Morelos",
                 "Nayarit", "Nuevo León", "Oaxaca", "Puebla", "Querétaro", "Quintana Roo",
                 "San Luis Potosí", "Sinaloa", "Sonora", "Tabasco", "Tamaulipas", "Tlaxcala",
                 "Veracruz", "Yucatán", "Zacatecas"],
        show: false
    }

    showModal = () => {
        this.setState({ show: true });
    };

    hideModal = () => {
        this.setState({ show: false });
    };

    handleChange = ({ currentTarget: input }) => {
        console.log(input.checked);
        const data = { ...this.state.data };
        data[input.name] = input.value;
        data['special'] = false;
        if(input.name === "special" && input.checked) data['special'] = true;
        console.log(data);
        this.setState({ data });
    };

    handleDateChange = date => {
        const { data } = this.state;
        data.date = date;
        this.setState({ data })
    };

    handleCheck = (e,type) => {
        const { data } = this.state;
        if(e.target.checked){
            data.active = true;
            this.showModal();
        } else {
            data.active = false;
        }
        this.props.onCheck(e,type);
        this.setState({ data });
    }

    handleSubmit = e => {
        e.preventDefault();
        const { data } = this.state;
        data.status = 'pending';
        // let editData = this.props.fees.filter(m => m._id === data._id);

        // if(editData.length < 1)
        //     data._id = new ObjectID();

        this.setState({ data: data, show: false });
        this.props.onRpc(data);
        // const errors = this.validate();
        // this.setState({ errors: errors || {} });
        // if (errors) return;

        // this.doSubmit();
    };

    render() {
        const { data, types, states } = this.state;
        const { rpc, user } = this.props;

        return (
            <>
                <input type="checkbox" id="rpc" onClick={(e) => this.handleCheck(e,"rpc")} checked={rpc && rpc.active ? rpc.active : false} disabled={rpc && rpc.status === "inscribed" ? true : false} />
                <label className="form-check-label" htmlFor="rpc">RPC</label>
                <Modal show={this.state.show} onHide={this.hideModal}>
                    <form>
                        <Modal.Header closeButton>
                            <Modal.Title>Completa el formulario</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="form-row">
                                <div className="col-md-12 form-label-group mb-3">
                                    <select name="state" id="state" className="form-control" onChange={this.handleChange} >
                                        <option value="">Selecciona...</option>
                                        { states.map(state => (
                                            <option key={ state } selected={(state === data.state) ? true : false }  value={state}>
                                                {state}
                                            </option>
                                        ))}
                                    </select>
                                    <label>Estado</label>
                                </div>
                                <div className="col-md-12 form-label-group mb-3">
                                    <input placeholder="Acto" className="form-control" name="event" onChange={this.handleChange} value={data.event} />
                                    <label>Acto</label>
                                </div>
                                <div className="col-md-12 form-label-group mb-3">
                                    <select name="type" id="type" className="form-control" onChange={this.handleChange} value={data.type} >
                                        <option value="">Selecciona...</option>
                                        { types.map(type => (
                                            <option key={ type } value={type}>
                                                {type}
                                            </option>
                                        ))}
                                    </select>
                                    <label>Tipo de aviso</label>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="col-md-12 form-check">
                                    <input type="checkbox" name="special" className="form-check-input" onChange={this.handleChange} checked={data.special} />
                                    <label className="form-check-label">Caso especial</label>
                                </div>
                            </div>
                        </Modal.Body>
                            <Modal.Footer>
                            {
                                user && user.role && user.role.actions.some(a => a.method === "save-rpc") && (
                                    <button className="btn btn-primary" onClick={this.handleSubmit}>Guardar</button>
                                )
                            }
                            </Modal.Footer>
                    </form>
                </Modal>
            </>
        );
    }
}

export default Rpc;
