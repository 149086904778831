import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { currencyFormat } from "../utils/currency";
import ReceivablesTable from "./receivablesTable";
import ReceivablesDaysTable from "./receivablesDaysTable";
import ReceivablesClientsTable from "./receivablesClientsTable";
import { getReceivablesClients } from "../services/metricService";
import { getReceivables } from "../services/receivableService";

class Clients extends Component {
    state = { 
        backups: []     
     }

    async populateReceivables () {
        const { data: receivables } = await getReceivables();   

        const receivablesUpdate = receivables.map(r => {
            r['total'] = currencyFormat(parseFloat(r['15']) + parseFloat(r['30']) + parseFloat(r['45']) + parseFloat(r['60']) + parseFloat(r['90']) + parseFloat(r['120']) + parseFloat(r['150']));          
            r['15'] = currencyFormat(r['15']);
            r['30'] = currencyFormat(r['30']);
            r['45'] = currencyFormat(r['45']);
            r['60'] = currencyFormat(r['60']);
            r['90'] = currencyFormat(r['90']);
            r['120'] = currencyFormat(r['120']);
            r['150'] = currencyFormat(r['150']);
            r['180'] = r['180'] ? currencyFormat(r['180']) : currencyFormat(0);
            r['more'] = r['more'] ? currencyFormat(r['more']) : currencyFormat(0);                 
            return r;
        });
        this.setState({ receivables: receivablesUpdate });
    }

    // async populateReceivablesDays () {
    //     const { data: receivablesDays } = await getReceivablesDays();
    //     const receivablesDaysUpdate = receivablesDays.map(r => {
    //         r.total = currencyFormat(r.total)
    //         return r;
    //     });
    //     this.setState({ receivablesDays: receivablesDaysUpdate });
    // }

    async populateReceivablesClients () {
        const { data: receivablesClients } = await getReceivablesClients();
        const receivablesClientsUpdate = receivablesClients.map(r => {  
            r['15'] = (r.days['15'] ? currencyFormat(r.days['15']) : currencyFormat(0));
            r['30'] = (r.days['30'] ? currencyFormat(r.days['30']) : currencyFormat(0));
            r['45'] = (r.days['45'] ? currencyFormat(r.days['45']) : currencyFormat(0));
            r['60'] = (r.days['60'] ? currencyFormat(r.days['60']) : currencyFormat(0));
            r['90'] = (r.days['90'] ? currencyFormat(r.days['90']) : currencyFormat(0));
            r['120'] = (r.days['120'] ? currencyFormat(r.days['120']) : currencyFormat(0));
            r['150'] = (r.days['150'] ? currencyFormat(r.days['150']) : currencyFormat(0));
            r['180'] = (r.days['180'] ? currencyFormat(r.days['180']) : currencyFormat(0));
            r['more'] = (r.days['more'] ? currencyFormat(r.days['more']) : currencyFormat(0));
            r['total'] = currencyFormat(r.total);                
            return r;
        });
        this.setState({ receivablesClients: receivablesClientsUpdate });
    }

    async componentDidMount() {            
        await this.populateReceivables();
        await this.populateReceivablesClients();
        //await this.populateReceivablesDays();
    }

    render() { 
        const { receivables, receivablesDays, receivablesClients } = this.state;

        return (
            <div>
                <ReceivablesTable
                    receivables={receivables}
                />
                <ReceivablesDaysTable
                    receivablesDays={receivablesDays}
                />
                <ReceivablesClientsTable
                    receivablesClients={receivablesClients}
                />
            </div>
        );
    }
}
 
export default Clients;