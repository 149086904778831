import http from "./httpService";


const apiEndpoint = process.env.REACT_APP_API_URL + "/status";

export function statusUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getAllStatus(status) {  
  const params = (status && status.name ? "?name="+status.name : "");
  return http.get(apiEndpoint+params);
}

export function getStatus(statusId) {
  return http.get(statusUrl(statusId));
}

export function saveStatus(status) {
  if (status._id) {
    const body = { ...status };
    delete body._id;
    return http.put(statusUrl(status._id), body);
  }

  return http.post(apiEndpoint, status);
}

export function deleteStatus(statusId) {
  return http.delete(statusUrl(statusId));
}
