import http from "./httpService";

const apiEndpoint = process.env.REACT_APP_API_URL + "/payments";

export function paymentUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getPayments() {
  return http.get(apiEndpoint);
}

export function getPayment(paymentId) {
  return http.get(paymentUrl(paymentId));
}

export function savePayment(payment) {
  if (payment._id) {
    const body = { ...payment };
    delete body._id;
    return http.put(paymentUrl(payment._id), body);
  }

  return http.post(apiEndpoint, payment);
}

export function deletePayment(paymentId) {
  return http.delete(paymentUrl(paymentId));
}
