import React, { Component } from "react";
import { Link } from "react-router-dom";
import Table from "./common/table";
import moment  from 'moment';
import { getCurrentUser } from "../services/authService";
import { getConfigurations } from "../services/configurationService";
import { getInstruments, saveInstrument } from '../services/instrumentService';

class Penalties extends Component {
    state = {
        penalties: [],
        penaltyTotal: 0,
        user: ''
    }

    columns = [
        { 
            field: "number", 
            label: "No. Instrumento",
            width: 130
        },   
        { field: "date", label: "Fecha instrumento", width: 180 }, 
        { field: "parts", label: "Partes", width: 200 },
        { field: "description", label: "Objeto", width: 200 },
        { field: "total", label: "Monto", width: 100 },
        { field: "applyDate", label: "Fecha aplicación", width: 180 },
    ];

    applyColumn = {
        key: "apply",
        field: "apply",
        label: "Acciones",
        width: 130
    };

    async populateConfig () {        
        const { data: configurations } = await getConfigurations();  
        let [ penalty ] = configurations.filter(config => config.number === 9);     
        this.setState({ penaltyTotal: Number(penalty.value) })
    }

    async componentDidMount() {  
        const { data: user } = await getCurrentUser();    
        if(user && user.role && user.role.actions.some(a => a.method === "apply-penalty")) this.columns.push(this.applyColumn);
    
        const columns = this.columns;

        await this.populateConfig();
        const filter = {
            name: user.name,
            type: 'penalties'
        }
        const { data: instruments } = await getInstruments(filter); 
        let array = [] 
        instruments.map(i => {             
            let obj = {};
            obj.number = <Link id={i._id} to={`/instruments/${i._id}`} searchvalue={i.number}>{String(i.number)}</Link>
            obj.date = moment(i.date).format("DD/MM/YYYY");             
            obj.parts = i.parts ? i.parts : "";
            obj.description = i.description ? i.description : "";
            obj.applyDate = i.penalty && i.penalty.date ? moment(i.penalty.date).format("DD/MM/YYYY") : "";
            obj.status =  i.penalty && i.penalty.status ? i.penalty.status : "";
            obj.active = i.penalty && i.penalty.active ? i.penalty.active : false;
            obj.total = i.penalty && i.penalty.total ? i.penalty.total : 0;
            obj.apply= i.penalty && i.penalty.status === 'pending' ? <button onClick={(e) => window.confirm("Esta seguro que desea aplicar la Penallización?") && this.handleApply(obj,i)} className="btn btn-danger btn-sm">Aplicar</button> : "";
            array.push(obj);
            return i;                        
        });    
        this.setState({ columns, penalties: array });
    }

    handleApply = async (obj, instrument) => {   
        const { penaltyTotal } = this.state;   
        const originalPenalties = this.state.penalties;
        const updatePenalties = originalPenalties.filter(p => p !== obj);
        this.setState({ penalties: updatePenalties });

        try {
            instrument.penalty = {
                status: "apply",
                date: new Date(),
                total:  penaltyTotal,
                active: true
            };            
            delete instrument.responsible;
            delete instrument.name;
            await saveInstrument(instrument);
        } catch (ex) {
            // if (ex.response && ex.response.status === 404)
            // toast.error("This client has already been deleted.");

            this.setState({ penalties: originalPenalties });
        }
    };

    getPagedData = () => {
        const {
            penalties: allPenalty,
        } = this.state;
    
        let filtered = allPenalty;
        filtered = allPenalty.filter(m =>
            m.active === true  &&
            (m.status === this.props.match.params.status)         
        );
                
        return { data: filtered };
    };

    render() {
        const { data: penalties } = this.getPagedData();

        return (
            <Table
            columns={this.columns}
            data={penalties}
        />
        );
    }
}

export default Penalties;
