import React, { Component } from 'react';
import { generateDoc } from '../utils/document';

class Documents extends Component {
    state = { 
        name: "",
        fileObj: [],
        fileArray: [],
        file: [],
        loading: false
    }

    uploadMultipleFiles = (e) => {
        const { fileObj, fileArray } = this.state;

        fileObj.push(e.target.files)
        
        for (let i = 0; i < fileObj[0].length; i++) {
            fileArray.push(URL.createObjectURL(fileObj[0][i]))
        }
        this.setState({ file: fileArray })
    }

    uploadFiles = async (e) => {        
        e.preventDefault()     
        const doc = await generateDoc(this.state.file,this.state.name);
        // console.log(doc);
        this.setState({ file: [], fileArray: [], fileObj: [] })
    }

    render() {        
        const { loading } = this.state;
        return (
            <form>                
                <div className="form-group">
                    <input type="text" className="form-control" onChange={e => this.setState({name: e.target.value })} multiple />
                </div>
                <div className="form-group">
                    <input type="file" className="form-control" onChange={this.uploadMultipleFiles} multiple />
                </div>
                <button type="button" className="btn btn-primary btn-block" onClick={(e) => {
                        this.setState({ loading: true });
                        this.uploadFiles(e);
                    }                 
                }>
                    Generar PDF
                    { loading && (
                            <div className="spinner-border text-secondary">
                                <span className="sr-only">Loading...</span>
                            </div>
                        )
                    }                    
                </button>
            </form >
        );
    }
}
 
export default Documents;
