export function currencyFormat(number) {
  return (
    "$" +
    Number(number)
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
  );
}

export function percentageFormat(number) {
  // Redondear el número a 0 decimales
  const roundedNumber = Math.round(Number(number));

  // Agregar el símbolo de porcentaje y formatear con comas para separar los miles
  return roundedNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "%";
}
