import React, { Component } from "react";
//import auth from "../services/authService";
import { Link } from "react-router-dom";
import Table from "./common/table";

class ResponsibleTable extends Component {
  columns = [
    { 
        path: "name", 
        label: "Nombre del responsable",
        content: responsible => <Link to={`/responsible/${responsible._id}`}>{responsible.name}</Link>
    },   
    { path: "abbreviation", label: "Abreviación" }, 
    { path: "percentage", label: "Porcentaje" }
  ];

  render() {
    const { responsible, onSort, sortColumn } = this.props;

    return (
      <Table
        columns={this.columns}
        data={responsible}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default ResponsibleTable;
