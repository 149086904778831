import React, { Component } from 'react'
import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'
import FisicForm from './fisicForm'
import LegalForm from './legalForm'
import { getForm, saveForm } from '../../services/formService'
import { getCff } from '../../services/cffService'
import { toast } from 'react-toastify'
import FhForm from './fhForm'

class PublicForm extends Component {
  state = {
    data: {
      type: '',
      origen: '',
      name: '',
      fiscalRegime: '',
      cffId: '',
      status: '',
      person: {
        partners: [],
        currentPartners: [],
        members: []
      },
      formsToBeDeleted: []

    },
    cff: {}
  }

  async populateForm () {
    try {
      const formId = this.props.match.params.id
      const { data: form } = await getForm(formId)
      this.setState({ data: this.mapToViewModel(form) })
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace('/not-found')
    }
  }

  async populateCff () {
    const { data } = this.state
    if (!data.cffId) this.props.history.replace('/not-found')
    const { data: cff } = await getCff(data.cffId)
    this.setState({ cff })
  }

  mapToViewModel (form) {
    return {
      _id: form._id,
      name: form.name,
      fiscalRegime: form.fiscalRegime,
      cffId: form.cffId,
      status: form.status,
      person: form.person ? form.person : {}
    }
  }

  async componentDidMount () {
    await this.populateForm()
    await this.populateCff()
    this.handleSetOrigine()
  }

  handleSetOrigine = () => {
    const currentLocation = this.props.history.location;
    const { state } = currentLocation;
    if (state && state.origen) {
      this.setState({ origen: state.origen })
    }
  }


  handleChange = (name, value) => {
    const data = { ...this.state.data }
    data.person[name] = value

    this.setState({ data })
  }

  handleDocument = (files, edit) => {
    let data = { ...this.state.data }

    if (!edit) {
      if (data.person && data.person.files) {
        data.person.files.push(files)
      } else {
        data.person.files = [files]
      }
    } else {
      data.person.files = files
    }
    this.setState({ data })
  }

  handlePartner = (partners) => {
    const data = { ...this.state.data }
    data.person.partners = partners
    this.setState({ data })
  }

  handleCurrentPartner = (partners) => {
    const data = { ...this.state.data }
    data.person.currentPartners = partners
    this.setState({ data })
  }

  handleDeletePartner = (partner) => {
    const data = { ...this.state.data }
    data.person.partners = data.person.partners.filter(p => p._id !== partner._id)
    this.setState({ data })
  }

  handleDeleteCurrentPartner = (partner) => {
    const { data, cff } = this.state
    data.person.currentPartners = data.person.currentPartners.filter(p => p._id !== partner._id)

    if (!data.formsToBeDeleted) {
      data.formsToBeDeleted = []
    }
    data.formsToBeDeleted.push(partner._id)
    cff.structure.forEach(node => {
      const formsId = this.getChildrenFormsId(node, partner._id)
      data.formsToBeDeleted.push(...formsId)
    })

    this.setState({ data })
  }

  handleMember = (members) => {
    const data = { ...this.state.data }
    data.person.members = members
    this.setState({ data })
  }

  handleDeleteMember = (member) => {
    const { data, cff } = this.state
    data.person.members = data.person.members.filter(m => m._id !== member._id)
    if (!data.formsToBeDeleted) {
      data.formsToBeDeleted = []
    }
    data.formsToBeDeleted.push(member._id)
    cff.structure.forEach(node => {
      const formsId = this.getChildrenFormsId(node, member._id)
      data.formsToBeDeleted.push(...formsId)
    })

    this.setState({ data })
  }

  handleSubmit = async (status, errors) => {
    const { data } = this.state
    data.status = !errors ? 'complete' : 'pending'
    await saveForm(data)
    toast.success('El formulario se guardo correctamente.')
    this.props.history.push(`${this.state.origen}/${data.cffId}`);
    if(errors) {
      toast.warning('Aún queda información por ingresar, favor de ingresarla antes de Enviar.')
    }
  }

  getChildrenFormsId = (node, parentFormId, found = false) => {
    let formsId = []
    if (!found) {
      found = node.formId === parentFormId
    }
    if (node.children && node.children.length > 0) {
      node.children.forEach(child => {
        if (child.formId && found) {
          formsId.push(child.formId)
        }
        formsId = formsId.concat(this.getChildrenFormsId(child, parentFormId, found))
      })
    }
    return formsId
  }

  createPDF = async (name, type) => {
    const sizes = {
      moral: [1300, 1700],
      physic: [1300, 3600]
    }
    const pdf = new jsPDF('p', 'px', sizes[type])
    const data = await html2canvas(document.getElementById('2pdf'), { scale: 3 })
    const img = data.toDataURL('image/jpeg', 1.0)
    const imgProperties = pdf.getImageProperties(img)
    const pdfWidth = pdf.internal.pageSize.getWidth()
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width
    pdf.addImage(img, 'JPEG', 0, 0, pdfWidth, pdfHeight, '', 'NONE')
    pdf.save(`${name}.pdf`)
  }

  renderForm = () => {
    const { data, cff } = this.state
    if (cff.type === 'Otro') {
       return <FhForm
          type={cff.type}
         person={data.person}
         formId={data._id}
         name={data.name}
         onSave={this.handleSubmit}
         onChange={this.handleChange}
         onPDF={this.createPDF}
      />
    }else {
      switch (data.fiscalRegime) {
        case 1:
          return <FisicForm
            type={cff.type}
            person={data.person}
            formId={data._id}
            name={data.name}
            onSave={this.handleSubmit}
            onChange={this.handleChange}
            onDocument={this.handleDocument}
            onPDF={this.createPDF}
          />
        case 2:
          return <LegalForm
            type={cff.type}
            person={data.person}
            formId={data._id}
            name={data.name}
            onSave={this.handleSubmit}
            onChange={this.handleChange}
            onSavePartner={this.handlePartner}
            onSaveCurrentPartner={this.handleCurrentPartner}
            onDeletePartner={this.handleDeletePartner}
            onDeleteCurrentPartner={this.handleDeleteCurrentPartner}
            onDeleteMember={this.handleDeleteMember}
            onSaveMember={this.handleMember}
            onDocument={this.handleDocument}
            onPDF={this.createPDF}
          />

      }
    }
  }

  render () {
    const { data, cff } = this.state
    return (
      <div id="2pdf" className="col-md-10" style={{ margin: '0 auto' }}>
        {data && cff && this.renderForm()}
      </div>
    )
  }
}

export default PublicForm
